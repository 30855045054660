import { useState, useEffect, memo } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useUser } from "../../context/UserContext";
// import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import Unmute from "../../images/microphone-2.svg";
import Mute from "../../images/microphone-slash.svg";
import i18n from "../../l10n/strings.json";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import { onMessageReceived as audioControlHandler } from "../../services/WebSocketCallbacks/audiocontrolCallback";
import { addWebSocketListener } from "../../services/websocketUtils";

 interface AudioControlProps {
	updateAttendee?: (payload: any, updates: any) => void;
  }
  
    const AudioControl: React.FC<AudioControlProps> = ({ updateAttendee }) => {
	const user = useUser();
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	let participantDetailList = JSON.parse(
		localStorage.getItem("participantVolumeDetails") ?? ""
	);

	// console.log(localStorage.getItem("participantVolumeDetails"), "=======>>>> Local Storage content");

	const audioDetails = participantDetailList.find(
		(p: any) => p.email === user.email
	);

	const [isAudioMute, setIsAudioMute] = useState(
		Boolean(audioDetails.audio.master.audio_status === "mute")
	);
	const [prevVolume, setPrevVolume] = useState(
		audioDetails.audio.master.audio_level || 50
	);

	useEffect(() => {
		const onMessageReceived = (payload: any) => {
			if (payload.metadata.created_by === user.email) {
				if (payload.metadata.audio.master.audio_status === "unmute") {
					setPrevVolume(payload.metadata.audio.master.audio_level);
				}
			}
		};
		WebsocketClientUSerStatus.on("audioControl", onMessageReceived);
		return () => {
			WebsocketClientUSerStatus.off("audioControl", onMessageReceived);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		addWebSocketListener("audioControl", (payload) => {
			// console.log("audioControlHandler", payload)
			audioControlHandler(payload, user.email, setIsAudioMute);
			updateAttendee?.(payload, { currentPreset: payload.currentPreset });
			// onUpdatePresetName()
		});
	}, []);

	const audioControl = () => {
		let audioStatus =
			audioDetails.audio.master.audio_status === "mute" ? "unmute" : "mute";
		// let channel_1 = audioDetails.audio.channel_1.audio_level;
		// let channel_2 = audioDetails.audio.channel_2.audio_level;
		// let mic = audioDetails.audio.mic.audio_level;

		let message = {
			action: "audio_control",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			created_for: user.email,
			// audio_status: audioDetails.audio_status === "mute" ? "unmute" : "mute",
			// audio_level: audioDetails.audio_status === "mute" ? prevVolume ?? 50 : 0,
			audio: {
				updated_for: "master",
				master: {
					audio_status: audioStatus,
					audio_level: audioStatus === "unmute" ? prevVolume ?? 50 : 0,
				},
				channel_1: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.channel_1.audio_level,
				},
				channel_2: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.channel_2.audio_level,
				},
				mic: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.mic.audio_level,
				},
			},
		};
		WebsocketClientUSerStatus.handleAudioControl(message);
	};

	if (!audioDetails) {
		return null;
	}
	return (
		<Tooltip
			title={
				isAudioMute
					? i18n.jamSession.unmuteTooltip
					: i18n.jamSession.muteTooltip
			}
		>
			<IconButton sx={{ m: "0 8px !important", p: 0 }} onClick={audioControl}>
				{audioDetails.audio.master.audio_status === "mute" ? (
					<img src={Mute} alt="mute" />
				) : (
					<>
						<img src={Unmute} alt="unmute" />
					</>
				)}
			</IconButton>
		</Tooltip>
	);
}

export default memo(AudioControl);
