import React, { useState, useEffect, useRef,ChangeEvent } from "react";
import {
	Avatar,
	Button,
	Grid,
	IconButton,
	InputAdornment,
	Typography,
	FormHelperText,
	Select,
	MenuItem,
	Stack,
} from "@mui/material";
import CommentPostIcon from "../../images/Posts/comment-post.svg";
import OutlinedInput from "@mui/material/OutlinedInput";
import Picture from "../../images/Posts/picture-upload-blue.svg";
import Music from "../../images/Posts/music-upload-blue.svg";
import SelectMusic from "./selectMusic";
import { useUser } from "../../context/UserContext";
import ErrorAlert from "../Error/ErrorAlert";
import CancelIcon from "../../images/Group 4282.svg";
import CustomAudioPlayer from "../../pages/CustomAudioPlayer";
import ImageCropper from "../../pages/ImageCropper";
import CreateCommentDisabled from "../../images/Posts/comment-post-disabled.svg";
import { useAlertStatus } from "../../context/AlertStatusContext";
import { Link, useNavigate } from "react-router-dom";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import SettingsAlert from "../../pages/SettingsAlert";
interface User {
	id: number;
	profilePhotoUrl: string | null;
	profileTitle: string | null;
	// Add other properties as needed
	nickname: string | null;
	user: {

		login: string;
		firstName: string;
		lastName: string;
		// Add other properties as needed
	};
	userProfileUuid: string;
}
interface TagRecord {
	id: number;
	// Add other properties if there are any
  }
const privacyList = [
	{ label: "Most recent", value: "RECENT" },
	{ label: "Most relevant", value: "RELEVANT" },
];
const AddComment = ({
	isReplay,
	postId,
	sortComment,
	parentCommentId,
	postPrivacy,
	setMoreComments,
	setPostPrivacy,
	moreComments,
	totalCommentCount,
	setTotalCommentCount,
	setTotalChildCommentCount,
	totalChildCommentCount,
	postUUID,
	parentCommentUUID,
}: any) => {
	const user = useUser();
	const [commentText, setCommentText] = useState("");
	const [musicOpen, setMusicOpen] = useState(false);
	// const [postPrivacy, setPostPrivacy] = useState({ label: 'Latest', value: 'RECENT' })
	const token = localStorage.getItem("sessionId");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showAlert, setShowAlert] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertMsg, setAlertMsg] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertStatus, setAlertStatus] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [hasMore, setHasMore] = useState(true);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [itemPerPage, setItemPerPage] = useState(10);
	const [file, setFile] = useState<any>("");
	const [imagePreviewUrl, setImagePreviewUrl] = useState("");
	const [result, setResult] = useState("");
	const [open, setOpen] = useState(false);
	const [music, setMusic] = useState<any>("");
	const [dirtyComment, setDirtyComment] = useState(false);
	const inputFileRef = useRef<any>(null);
	const [profileImageUrl, setProfileImageUrl] = useState("");
	const [deviceMusic, setDeviceMusic] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [musicFromDevice, setMusicFromDevice] = useState<any>("");
	const [adding, setAdding] = useState(false);
	const { updateAlertStatus } = useAlertStatus();
	const [userList, setUserList] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState<string>("");
	const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
	const [autocompleteOptions, setAutocompleteOptions] =
		React.useState<User[]>();
	const [taggedUserList, setTaggedUserList] = useState<number[]>([]);
	const [isAtTyped, setIsAtTyped] = useState(false);
	const [postText, setPostText] = useState("");
	const [prevtaggedUserList, setPrevTaggedUserList] = useState<number[]>([]);
	const [fullNameArray, setFullNameArray] = useState<{ id: any; name: string; userProfileUuid:string }[]>([]);
	const navigate = useNavigate();
	const authorities = user.authorities.map(
		(authority: { name: string }) => authority.name
  );

	let searchTerm = "";
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	useEffect(() => {
		setProfileImageUrl(
			user.profilePhotoUrl
				? `${process.env.REACT_APP_PICTURE_URL}${user.profilePhotoUrl}`
				: ""
		);
	}, [user]);

	const handleEditClose = () => {
		setMusicOpen(false);
	};

	const sendComment = () => {
		if (commentText.length > 600) {
			setShowAlert(true);
			setAlertStatus(false);
			setAlertMsg("Max 600 characters allowed!");
		} else {
			setAdding(true);
			var formData = new FormData();
			formData.append(
				"commentType",
				imagePreviewUrl ? "PHOTO" : music ? "MUSIC" : "TEXT"
			);
			formData.append("parentCommentUUID", parentCommentUUID);
			formData.append("postUUID", postUUID);
			formData.append("commentOnPost", commentText);
			formData.append("musicUUID", music && music?.myMusicUuid ? music?.myMusicUuid : "");
			formData.append("file", file ? file : null);
			const taggedUserListPost : string[]  = findTaggedUsers(fullNameArray, commentText);

			//appended tagged user
			formData.append(
				"taggedUserProfileUUIDs", taggedUserListPost && taggedUserListPost.length > 0	? taggedUserListPost.join(",") : ""
			);

			// formData.append("totalReactions", '');
			// formData.append("userReaction", '');
			// formData.append("childComments", '');
			fetch(`${process.env.REACT_APP_BASE_URL}/api/comments/save`, {
				method: "POST",
				headers: {
					// "Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: formData,
				// JSON.stringify({
				//   parentCommentId:'0'
				// })
			})
				.then((res) => {
					if (res.status === 502) {
						navigate("/defaultPage");
					} else if (res.status >= 400) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
						setAdding(false);
					} else {
						handlePostPrivacy(postPrivacy);
						setCommentText("");
						setImagePreviewUrl("");
						setFile("");
						setMusic("");
						setAdding(false);
						// !isReplay && setTotalCommentCount(totalCommentCount + 1)
						// setLikeStatus(status)
						setTaggedUserList([]);
					}
				})
				.catch((err) => {
					setShowAlert(true);
					setAlertStatus(false);
					setAlertMsg("Network Error! Please try again..");
					setAdding(false);
				});
		}
	};

	const handlePostPrivacy = (newValue: any) => {
		setPostPrivacy(newValue);
		setLoading(true);
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/comments/allComments/tree?sortBy=${newValue}&page=0&size=${itemPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						postUUID: postUUID,
					}),
				}
			)
				.then((res) => {
					if (res.status === 502) {
						navigate("/defaultPage");
					} else if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length > 0) {
						!isReplay && setTotalCommentCount(data[0].totalParentCommentCount);
						setMoreComments(data);
						var nextPageNumber = pageNumber + 1;
						setPageNumber(nextPageNumber);
					}
					if (data.length < itemPerPage) {
						setHasMore(false);
					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleLoadMoreComments = () => {
		setLoading(true);
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/comments/allComments/tree?sortBy=${postPrivacy}&page=${pageNumber}&size=${itemPerPage}`,
				// `${process.env.REACT_APP_BASE_URL}/api/getAllPosts`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						postUUID: postUUID,
					}),
				}
			)
				.then((res) => {
					if (res.status === 502) {
						navigate("/defaultPage");
					} else if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length > 0) {
						if (pageNumber === 0) {
							setMoreComments(data);
						} else {
							setMoreComments([...moreComments, ...data]);
						}

						var nextPageNumber = pageNumber + 1;
						setPageNumber(nextPageNumber);
					}
					if (data.length < itemPerPage) {
						setHasMore(false);
					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleOpenEditor = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];
		if (file.type.startsWith("image/")) {
			setResult(URL.createObjectURL(file));
			setFile(file);
			if (file.size > 4 * 1024 * 1024) {
				updateAlertStatus(true, false, 'Please Select Image Smaller than 4 MB.');
			} else {
				setOpen(true);
			}
			// setOpen(true);
			if (inputFileRef.current) {
				inputFileRef.current.value = null; // Clear the input value
				const newInput = document.createElement("input");
				newInput.style.display = "none";
				newInput.type = "file";
				newInput.addEventListener("change", handleOpenEditor);
				inputFileRef.current.parentNode.replaceChild(
					newInput,
					inputFileRef.current
				);
				inputFileRef.current = newInput;
			}
		} else {
			updateAlertStatus(true, false, "Invalid file type");
		}
	};

	const handlePictureChange = () => {
		setImagePreviewUrl("");
		setFile("");
	};
	const isEnable = () => {
		let CommentTextLength = commentText ? commentText.trim().length : 0;
		if (
			(CommentTextLength > 0 && CommentTextLength <= 600) ||
			file !== "" ||
			music !== ""
		) {
			return false;
		} else {
			return true;
		}
	};

	const handleMusicChange = () => {
		setMusic("");
	};
	const [searchTermt, setSearchTermt] = useState("");

	function findTaggedUsers(
		fullNameArray: { id: number; name: string; userProfileUuid: string }[],
		postText: string
	): string[] {
		// Initialize taggedUserList
		const taggedList: string[] = [];

		// Separate single-word and double-word names from fullNameArray
		const singleWordNames = fullNameArray
			.filter((user) => !user.name.includes(" "))
			.map((user) => user.name.toLowerCase()); // Convert to lowercase for case-insensitive matching
		const doubleWordNames = fullNameArray
			.filter((user) => user.name.includes(" "))
			.map((user) => user.name.toLowerCase()); // Convert to lowercase for case-insensitive matching

		// Regular expressions for single-word and double-word tagged user names
		const singleWordRegex = /@([a-zA-Z0-9_]+)/g; // Single-word tags like "@sid"
		const doubleWordRegex = /@([a-zA-Z0-9_]+(?:\s+[a-zA-Z0-9_]+)?)/g; // Double-word tags like "@Ankita Jadhav"

		// Extract tagged user names from postText using both regexes
		const taggedUserNames = new Set<string>();

		// Match double-word usernames first
		const doubleWordMatches = postText?.match(doubleWordRegex) || [];
		for (const taggedUserName of doubleWordMatches) {
			const userName = taggedUserName.trim(); // No need to remove "@" here
			taggedUserNames.add(userName);

		}

		// Match single-word usernames (skip if already matched as part of a double-word)
		const singleWordMatches = postText?.match(singleWordRegex) || [];
		for (const taggedUserName of singleWordMatches) {
			const userName = taggedUserName.trim(); // No need to remove "@" here
			if (singleWordNames.includes(userName.toLowerCase()) && !taggedUserNames.has(userName)) {
				taggedUserNames.add(userName);
			}
		}

		// Map matched tagged usernames to their userProfileUuid
		if (taggedUserNames.size > 0) {
			// Loop through tagged user names
			taggedUserNames.forEach((taggedUserName) => {
				// Remove "@" from the tagged user name
				const userName = taggedUserName.trim();

				// Find the corresponding user in fullNameArray using case-insensitive matching
				const matchedUser = fullNameArray.find(
					(user) => user.name === userName
				);

				// If a match is found
				if (matchedUser && postText?.includes(taggedUserName)) {
					taggedList.push(matchedUser.userProfileUuid);
					const startIndex =
						postText?.indexOf(taggedUserName) + taggedUserName.length + 1;
					const remainingText = postText?.substring(startIndex);
					const nextTwoWords = remainingText.split(" ").slice(0, 2).join(" ");
					if (fullNameArray.find((user) => user.name === nextTwoWords)) {
						//console.log(" in if 2 ")
					}
				}
			});
		}
		return taggedList;
	}

	  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setCommentText(value);
		setInputValue(value);
		//const splitInput = value.split(' ');
		const splitInput = value.split(/[\s\n]+/);
		const lastTypedWord = splitInput[splitInput.length - 1];
		if (lastTypedWord.startsWith('@') && lastTypedWord.length >= 1) {
		const searchTerm = lastTypedWord.substring(1);
		const fetchData = () => {
					if (searchTerm.trim() === "") {
						setAutocompleteOptions([]);
						return;
					}

					fetch(
						`${process.env.REACT_APP_BASE_URL}/api/getAllTaggedUserProfileList?search=${searchTerm}`,
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`,
							},
						}
					)
						.then((response) => {
							if (!response.ok) {
								throw new Error(`HTTP error! Status: ${response.status}`);
							}
							return response.json();
						})
						.then((data) => {
							setAutocompleteOptions(data);
						})
						.catch((error) => {
							console.error("Error fetching data:", error);
						});
				};
				setShowAutocomplete(true);
				const debounceTimeout = setTimeout(() => {
					fetchData();
				}, 300);
				return () => {
					clearTimeout(debounceTimeout);
					setAutocompleteOptions([]);
				};
			}
		if (lastTypedWord.startsWith('@') && lastTypedWord.length < 1) {
			setAutocompleteOptions([]);
			setShowAutocomplete(false);
		} else {
			setAutocompleteOptions([]);
		}
		if (lastTypedWord.startsWith('@') && lastTypedWord.length >= 1) {
			setShowAutocomplete(true);
		} else {
			setShowAutocomplete(false);
		}
	};
	const handleAutocompleteSelect = (user: any, id: any, nickname: any, userProfileUuid:string) => {
		const startIndex = inputValue.lastIndexOf("@");
		const newText =
			inputValue.substring(0, startIndex) +
			`@${user.firstName && user.lastName
				? `${user.firstName} ${user.lastName}`
				: user.nickname
			} `;
		setInputValue(newText);
		setCommentText(newText);
		setSearchTermt("");
		const selectedName = `@${user.firstName} ${user.lastName} `;
		const newEntry = {
			id: id,
			name: `@${user.firstName && user.lastName
					? `${user.firstName} ${user.lastName}`
					: user.nickname
				}`,
				userProfileUuid: userProfileUuid
		};

	   setFullNameArray([...fullNameArray, newEntry]);
		setShowAutocomplete(false);
		setIsAtTyped(false);

	};
	return (
		<>
		<SettingsAlert/>
			<Grid
				item
				container
				columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
				columnGap={1}
				sx={{ ml: isReplay ? "64px" : "", mr: isReplay ? "30px" : "" }}
			>
				<Grid item xs={1.5}>
					<Avatar
						alt={user.firstName}
						src={profileImageUrl}
						sx={{ height: "50px", width: "50px" }}
					/>
				</Grid>
				<Grid item xs={9.5}>
					<OutlinedInput
						id="title"
						className="social-textField"
						sx={{
							borderRadius: "25px",
							backgroundColor: "white",
							borderColor: "#C5C5C5",
							pl: "20px",
							pr: "40px",
							flexDirection: "column",
							minHeight: "50px",
							fontSize: "16px !important",
							fontWeight: 400,
							"& .MuiOutlinedInput-input": {
								fontWeight: "400 !important",
							},
						}}
						value={commentText}
						// onChange={(e: any) => {
						//   // setCommentText(e.target.value);
						//   handleInputChange(e); // Call handleInputChange here
						// }}
						onChange={handleInputChange}
						placeholder="Comment on this"
						disabled = {authorities?.includes('ROLE_ADMIN') || isAdmin}
						required
						multiline
						error={(dirtyComment && commentText?.length > 600) || isAdmin}
						onFocus={() => setDirtyComment(true)}
						// onChange={handleInputChange}
						endAdornment={
							<>
								{showAutocomplete &&
									autocompleteOptions &&
									(autocompleteOptions ?? []) && (
										<Stack
											sx={{
												width: "99%",
												border: "1px solid #C2C2C3",
												boxShadow: "rgba(217, 231, 255, 0.77) 0px 1px 14px 0px",
												mt: "8px",
												zIndex: 1,
												maxHeight: "250px",
												overflowY: "auto",
											}}
										>
											{(autocompleteOptions ?? [])
												.filter((option) => typeof option !== "string")
												.map((option, index) => (
													<Stack
														flexDirection="column"
														alignItems="flex-start"
														sx={{ width: "100%", maxHeight: "250px" }}
													>
														<Stack
															flexDirection="row"
															alignItems="center"
															mt={1}
															sx={{
																width: "100%",
																marginTop: 0,
																maxHeight: "250px",
																padding: "8px",
															}}
															onKeyDown={(e) => {
																if (e.key === 'Enter') {
																  handleAutocompleteSelect(option?.user, option?.id, option?.nickname, option?.userProfileUuid);
																}
																}}
															  onClick={() =>
																handleAutocompleteSelect(option?.user, option?.id, option?.nickname, option?.userProfileUuid)
															  }
															  onSelect={(selectedOption) => {
																// Call handleTagging with the selected option value
																handleAutocompleteSelect(option?.user, option?.id, option?.nickname, option?.userProfileUuid)
																// Focus back on the post dialog input field after selecting an option
																}}
																tabIndex={0}
														>
															<Stack sx={{ mr: "10px" }}>
																<Avatar
																	alt={option.user.firstName}
																	src={
																		option.profilePhotoUrl
																			? `${process.env.REACT_APP_PICTURE_URL}${option.profilePhotoUrl}`
																			: ""
																	}
																	className="profile-img-style"
																	sx={{ width: "50px", height: "50px" }}
																/>
															</Stack>
															<Stack>
																<Stack
																	flexDirection="row"
																	alignItems="flex-start"
																>
																	<Typography
																		className="card-title-txt-sx"
																		sx={{
																			fontSize: "16px",
																			color: "#000000",
																			fontWeight: 500,
																			fontFamily: "Lexend",
																			lineHeight: "20px",
																			maxWidth: "12.125rem",
																			whiteSpace: "nowrap",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																		}}
																	>
																		{option.user.firstName && option.user.lastName
																			? `${option.user.firstName} ${option.user.lastName}`
																			: option.nickname}
																	</Typography>
																</Stack>
																{option.profileTitle !== null ? (
																	<Typography
																		className="card-sub-title-txt-sx"
																		sx={{
																			fontSize: "14px",
																			color: "#5B5B65",
																			fontWeight: 400,
																			fontFamily: "Lexend",
																			lineHeight: "17.5px",
																			maxWidth: "100%",
																			whiteSpace: "nowrap",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			textWrap: "wrap",
																		}}
																	>
																		{`${
																			option.profileTitle &&
																			option.profileTitle?.length > 35
																				? option.profileTitle?.substring(
																						0,
																						35
																				  ) + "..."
																				: option.profileTitle
																		}`}
																	</Typography>
																) : (
																	""
																)}
															</Stack>
														</Stack>

														<hr
															style={{
																color: "red !important",
																width: "100%",
																margin: 0,
															}}
														/>
													</Stack>
												))}
										</Stack>
									)}

	
								{imagePreviewUrl || music ? (
									
									<Grid container>
										{imagePreviewUrl && (
											<>
												<Grid item xs={7}>
													<img
														src={imagePreviewUrl}
														alt="Caption this"
														style={{ height: "200px", width: "100%" }}
													/>
												</Grid>
												<Grid item xs={5} sx={{ alignSelf: "center" }}>
												{!isAdmin && (
													<IconButton
														sx={{ alignSelf: "baseline" }}
														onClick={() => handlePictureChange()}
													>
														<img
															src={CancelIcon}
															style={{ height: "20px", width: "20px" }}
															alt=""
														/>
													</IconButton>
												)}
												</Grid>
											</>
										)}
										{music && (
											<>
												<Grid item xs={10}>
													<CustomAudioPlayer
														musicId={music.musicId}
														audioUrl={
															deviceMusic
																? `${music.musicUrl}`
																: music.musicUrl.includes("https://")
																? music.musicUrl
																: `${process.env.REACT_APP_PICTURE_URL}${music.musicUrl}`
														}
														displayDirectly={true}
														activeMenu=""
													/>
													{/* <audio crossOrigin="anonymous" controls controlsList="nodownload noremoteplayback noplaybackrate" style={{width: "225px"}}>
														<source
															src={deviceMusic ? `${music.musicUrl}` : music.musicUrl.includes('https://') ? music.musicUrl :  `${process.env.REACT_APP_PICTURE_URL}${music.musicUrl}`}
															type="audio/mpeg"
														></source>
													</audio> */}
												</Grid>
												<Grid
													item
													xs={2}
													sx={{
														alignSelf: "center",
														display: "flex",
														justifyContent: "end",
													}}
												>
													<IconButton
														sx={{ alignSelf: "baseline" }}
														onClick={() => handleMusicChange()}
													>
														<img
															src={CancelIcon}
															style={{ height: "20px", width: "20px" }}
															alt=""
														/>
													</IconButton>
												</Grid>
											</>
										)}
									</Grid>
								) : (
									""
								)}
								<InputAdornment
									position="end"
									sx={{
										alignSelf: "self-end",
										py: 1,
										marginTop: "-20px",
										mr: "-7%",
									}}
								>
									{adding ? (
										<IconButton
											aria-label="comment"
											// onClick={() => sendComment()}
											edge="end"
											disabled
										>
											<img src={CreateCommentDisabled} alt="" />
										</IconButton>
									) : (
										<IconButton
											aria-label="comment"
											onClick={() => sendComment()}
											edge="end"
											disabled={isEnable()}
										>
											<img
												src={
													(commentText.trim().length > 0 &&
														commentText.trim().length <= 600) ||
													file !== "" ||
													music !== ""
														? CommentPostIcon
														: CreateCommentDisabled
												}
												alt=""
												style={{ marginTop: isEnable() ? "6px" : "-12px" }}
											/>
										</IconButton>
									)}
								</InputAdornment>
							</>
						}
					/>
					{dirtyComment && (
						<FormHelperText sx={{ color: "#FD4B24" }}>
							{commentText?.length > 600 ? "Max characters should be 600" : ""}
						</FormHelperText>
					)}
				</Grid>
				<Grid item xs={12} container direction={"row-reverse"} sx={{ pr: 5 }}>
					{imagePreviewUrl !== "" || music !== "" ? (
						""
					) : (
						<>
							<Grid item xs={isReplay ? 3.5 : 3}>
								<label>
									<Button
										startIcon={
											<img
												src={Music}
												alt="Music"
												style={{ height: "24px", width: "24px" }}
											/>
										}
										onClick={() => setMusicOpen(true)}
										sx={{ color: "black", fontSize: "14px", pb: 0.5 }}
										disabled={isAdmin}
									>
										<Typography sx={{ color: "black", fontSize: "14px" }}>
											{" "}
											Music
										</Typography>
									</Button>
								</label>
							</Grid>
							<Grid item xs={3} sx={{ textAlign: "right" }}>
								<Button sx={{ pt: "12px", width: "max-content" }} disabled={isAdmin}>
									<label>
										<Grid container>
											<img
												src={Picture}
												alt=""
												style={{ height: "24px", width: "24px" }}
											/>
											&nbsp;
											<Typography sx={{ color: "black", fontSize: "14px" }}>
												Photo
											</Typography>
											<input
												id="photo-upload"
												ref={inputFileRef}
												type="file"
												accept =".jpg, .jpeg, .png, .gif"
												style={{ display: "none" }}
												onChange={(e) => handleOpenEditor(e)}
											/>
										</Grid>
									</label>
								</Button>
							</Grid>
						</>
					)}
				</Grid>
				{sortComment && moreComments.length > 1 && (
					<Grid item xs={3.5}>
						<Select
							value={postPrivacy}
							onChange={(event: any) => {
								handlePostPrivacy(event.target.value);
							}}
							// defaultValue={}
							fullWidth
							sx={{
								borderBottom: "none",
								"&:hover": {
									borderColor: "white !important",
								},
								backgroundColor: "#EEEEEE",
								borderRadius: "20px",
								height: "28px",
								"& .MuiOutlinedInput-notchedOutline": {
									borderColor: "white !important",
								},
								"& .MuiOutlinedInput-notchedOutline:focus-visible": {
									borderColor: "white",
								},
								"& .MuiOutlinedInput-notchedOutline:hover": {
									borderColor: "white",
								},
								"& .MuiOutlinedInput-root:hover": {
									borderColor: "white",
								},
								"& .MuiSelect-outlined:hover": {
									borderCollapse: "white !important",
								},
								"& .MuiSelect-root:hover": {
									borderCollapse: "white !important",
								},
								"& .MuiSelect-select:hover": {
									borderCollapse: "white !important",
								},
							}}
						>
							{privacyList.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
						{/* <Autocomplete
              freeSolo={false}
              disableClearable={true}
              // disablePortal
              options={privacyList}
              sx={{ width: '300', borderBottom: 'none' }}
              value={postPrivacy}
              onChange={(event, newValue) => {
                handlePostPrivacy(newValue as any);
              }}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              classes={classes}
              renderInput={(params) =>
                <TextField {...params} variant="standard"
                  sx={{ borderRadius: '20px', pl: 1, borderBottom: 'none' }} />}
            /> */}
					</Grid>
				)}
			</Grid>
			<SelectMusic
				musicOpen={musicOpen}
				handleMusicClose={handleEditClose}
				comment={true}
				setMusic={setMusic}
				setDeviceMusic={setDeviceMusic}
				isSelectFromDevice={true}
				setMusicFromDevice={setMusicFromDevice}
				setFile={setFile}
			/>
			<ImageCropper
				open={open}
				onClose={handleClose}
				imagePreviewUrl={result}
				setImagePreviewUrl={setImagePreviewUrl}
				setResult={setFile}
				post={true}
				photoUpload={handleClose}
				cover={false}
				comment={true}
			/>
		</>
	);
};

export default AddComment;
