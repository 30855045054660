import { memo, useEffect, useState, useRef } from "react";
import { useUser } from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import {
	Typography,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TablePagination,
	Tooltip,
	ListItemIcon,
	ListItemText,
	Button,
	TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Toolbar from "@mui/material/Toolbar";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import Box from "@mui/material/Box";
import fileDownload from "js-file-download";
import { useAlertStatus } from "../../context/AlertStatusContext";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import User from "../../services/user";

import { RecordingInterface } from "../../types/types";
import i18n from "../../l10n/strings.json";
import MusicCmp from "./MusicCmp";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Search from "../../images/search.svg";
import CloseIcon from "../../images/close.svg";
import Trash from "../../images/trash.svg";
import MoveToFolder from "../../images/folder-move.svg";
import Download from "../../images/document-download.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import CenterCircularProgress from "../CenterCircularProgress";
import CircularProgress from "@mui/material/CircularProgress";
import UserOctagon from "../../images/user-octagon.svg";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		folderDropdown: {
			background: "#FFF",
			border: "1.5px solid #0273E6",
			borderRadius: "15px !important",
			"& #demo-simple-select": {
				color: "#0273E6",
			},
			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
			"& .MuiSelect-icon": {
				color: "#0273E6",
			},
		},
		recordingBtn: {
			width: "100%",
			// background: (user.userAccountStatus == "ACTIVE") ? "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)" : "linear-gradient(126.25deg, rgba(0,0,0,0.2) 3.77%, rgba(0,0,0,0.2) 88.31%)",
			borderRadius: "18px !important",
			textAlign: "center",
			color: "white",
			fontSize: "0.9rem",
			fontWeight: "var(--joy-fontWeight-lg)",
			padding: "2% 0 !important",
			cursor: "pointer",
		},
		searchField: {
			// border: "1px solid #C5C5C5",
			borderRadius: "17px",
			"& fieldset": {
				border: "1px solid #C5C5C5",
				borderRadius: "17px",
			},
		},
		optionButtons: {
			border: "1.5px solid #0273E6 !important",
			borderRadius: "15px !important",
			color: "#0273E6 !important",
			// fontFamily: 'Lexend !important',
			fontStyle: "normal !important",
			fontWeight: "bold !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
	})
);

const multiselectOptions = [
	{
		id: "moveToProfilePlaylist",
		label: "Add to Profile Playlist",
		icon: UserOctagon,
		disabled: false,
	},
	{
		id: "download",
		label: "Download",
		icon: Download,
		disabled: false,
	},
	// {
	// 	id: "moveToFolder",
	// 	label: "Move",
	// 	icon: MoveToFolder,
	// 	disabled: true,
	// },
	{
		id: "delete",
		label: "Delete",
		icon: Trash,
		disabled: false,
	},
	{
		id: "remove",
		label: "Remove",
		icon: Trash,
		disabled: false,
	},
];

const SessionRecordings = (props: any) => {
	const {storageAvailable, storageConsumed} = props
	const user = useUser();
	// const navigate = useNavigate();
	// Alert states
	const [isLoading, setIsLoading] = useState(false); 
	const [downloadStatusMessage, setDownloadStatusMessage] = useState(""); 
	const location = useLocation();
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const { updateAlertStatus } = useAlertStatus();
	const classes = useStyles();
	const token = localStorage.getItem("sessionId");
	const [order, setOrder] = useState<Order>("asc");
	const [orderBy, setOrderBy] = useState<keyof RecordingInterface>("id");
	const [selected, setSelected] = useState<readonly number[]>([]);
	const [selectedUUID, setSelectedUUID] = useState<readonly string[]>([]);
	const [selectedFolder, setSelectedFolder] = useState("all");
	const [pageNumber, setPageNumber] = useState(0);
	const [itemPerPage, setItemPerPage] = useState(20);
	const [searchString, setSearchString] = useState("");
	const [previousSearchString, setPreviousSearchString] = useState("");
	const [searchMode, setSearchMode] = useState(false);
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [loading, setLoading] = useState(false);
	const ALLOWED_FILE_TYPES = "audio/*,.mp3,.wav,.mpeg";
	const ALLOWED_FILE_EXT = ["mp3", "wav", "mpeg"];
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [getNewList, setGetNewList] = useState(false);
	const inputFileRef = useRef<any>(null);
	// const [inProgress, setInProgress] = useState(false);
	// const [selectedSession, setSelectedSession] = useState<RecordingInterface | null>(null);
	// cons	t [recordings, setRecordings] = useState<Array<RecordingInterface>>([]);
	const [recordings, setRecordings] = useState<Array<RecordingInterface>>([]);
	// const [displayRecordings, setDisplayRecordings] = useState<
	// 	Array<RecordingInterface>
	// >([]);
	const [displayRecordings, setDisplayRecordings] =
		useState<Array<RecordingInterface>>(recordings);
	const [laodMusicDetails, setLoadMusicDetails] = useState(false);
	// const fileInputRef = useRef<any | undefined | HTMLInputElement>(null);
	const canStore = Number(props.storagePercentage) < 99.99;
	const storeFull = Number(props.storagePercentage) === 100;
	const [musicUploading, setMusicUploading] = useState(false); 

	
	const getRecordingList = () => {
		const UrlForAllMusic = `recordings/user?searchString=${searchString}&page=0&size=${itemPerPage}&folder=${props.activeMenu}&orderBy=file_name&inOrder=${fieldOrder}`;
		const urlForProfileMusic = `getAllMusic`;
		setLoading(true);
		setHasMore(true);
		setPageNumber(1);
		// setRecordings([]);
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/${
					props.profileMusic ? urlForProfileMusic : UrlForAllMusic
				}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: props.profileMusic
						? JSON.stringify({
							userProfileUuid: props.userProfileUUID,
						  })
						: JSON.stringify({
							userUuid: user.userUuid,
						  }),

					// body: JSON.stringify({
					// 	searchString: searchString,
					// 	pageNumber: pageNumber,
					// 	itemPerPage: itemPerPage,
					// }),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (props.profileMusic) {
						setRecordings(data);
						props.setLoadMusic && props.setLoadMusic(false);
					} else {
						if (data.content.length === 0) {
							setRecordings([]);
							setLoading(false);
						} else {
							if (data.content.length < 10) setHasMore(false);
							setRecordings(data.content);
							setPreviousSearchString(searchString);
							setPageNumber(1);
							setLoading(false);
						}
					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					setLoading(false);
					props.setLoadMusic && props.setLoadMusic(false);
				});
		} catch (error) {
			setLoading(false);
			props.setLoadMusic && props.setLoadMusic(false);
		}
	};

	const saveDataToProfilePlaylist = (itemsToAdd: any) => {
		const myMusicUuid = itemsToAdd.map((item: { myMusicUuid: any; }) => item.myMusicUuid);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/mymusic/profile-playlist`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(
				myMusicUuid.flatMap((uuidArray: any) => uuidArray),
			),
		})
			.then((response) => {
				if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				// props.handleClose(true, "nameSuccess");
				if (result !== null) {
					setSelected([]);
					setSelectedUUID([])
					getRecordingList();
					updateAlertStatus(true, true, "Profile music successfully uploaded.");
				}
			});
	};

	// useEffect(() => {
	// 	props.loadMusic && getRecordingList()
	// }, [props.loadMusic])

	useEffect(() => {
		if (
			searchString === "" ||
			props.activeMenu ||
			laodMusicDetails ||
			props.loadMusic ||
			getNewList
		) {
			getRecordingList();
			if (searchString === "") {
				setSearchMode(false);
			}
			if (getNewList) {
				setGetNewList(false);
			}
		}
	}, [
		searchString,
		props.activeMenu,
		laodMusicDetails,
		props.loadMusic,
		getNewList,
	]);

	// useEffect(() => {
	// 	if(getNewList){
	// 		console.log('get New list',getNewList)
	// 	getRecordingList();
	// 	setGetNewList(false);
	// 	}
	// }, [getNewList])

	// useEffect(() => {
	// 	getRecordingList();
	// }, [props.activeMenu]);
	// useEffect(() => {
	// 	laodMusicDetails && getRecordingList();
	// }, [laodMusicDetails]);

	interface HeadCell {
		disablePadding: boolean;
		id: keyof RecordingInterface;
		label: string;
		numeric: boolean;
		width?: string;
	}
	const headCells: readonly (HeadCell | any)[] = [
		{
			id: "title",
			numeric: false,
			disablePadding: false,
			label: "Music Title",
			width: "25%",
		},
		{
			id: "audioPlayer",
			numeric: false,
			disablePadding: false,
			label: "",
			width: "20%",
		},
		{
			id: "musicDuration",
			numeric: false,
			disablePadding: false,
			label: "Time",
			width: "10%",
		},
		props.activeMenu === "sharedMusic" && {
			id: "sharedBy",
			numeric: false,
			disablePadding: false,
			label: "Shared BY",
			width: "10%",
		},
		{
			id: "fileSize",
			numeric: false,
			disablePadding: false,
			label: "Size",
			width: "10%",
		},
		{
			id: "musicDate",
			numeric: false,
			disablePadding: false,
			label: "Date",
			width: "10%",
		},
		{
			id: "actions",
			numeric: false,
			disablePadding: true,
			label: "Actions",
			width: "15%",
		},
	].filter(Boolean);

	interface EnhancedTableToolbarProps {
		numSelected: number;
		activeMenuTitle: string;
		menus: Array<any>;
		searchString: string;
		setSearchString: any;
	}

	const handleMultiSelectOptionClick = (optionId: string) => {
		if (optionId === "download") {
			setDownloadStatusMessage("Downloading files...");
			setIsLoading(true);
			const downloadPromises = selected.map((selectedId) => {
				const record = recordings.find((r) => r.id === selectedId);
		  
				if (record) {
				  const musicUrl =
					props.activeMenu === "Recording" || record.musicUrl.includes("https://")
					  ? record.musicUrl
					  : process.env.REACT_APP_PICTURE_URL + record.musicUrl;
				  const ext = record.fileName.split(".")[1] ?? "mp3";
		  
				  return fetch(musicUrl, {
					mode: "cors",
					headers: {
					  "Access-Control-Allow-Origin": "*",
					},
				  })
					.then((response) => {
					  if (!response.ok) {
						throw new Error(`Failed to fetch: ${record.fileName}`);
					  }
					  return response.blob();
					})
					.then((blob) => {
					  const url = window.URL.createObjectURL(blob);
					  const link = document.createElement("a");
					  link.href = url;
					  link.setAttribute(
						"download",
						!record.fileName
							? `recording.mp3`
							: record.fileName.includes(".mp3")
							? record.fileName
							: `${record.fileName}.mp3`
					);
					  document.body.appendChild(link);
					  link.click();
					  document.body.removeChild(link);
					});
				}
		  
				return Promise.resolve(); 
			  });
			
			  Promise.allSettled(downloadPromises)
			  .then((results) => {
				const failedDownloads = results.filter((result) => result.status === "rejected").length;
				const successfulDownloads = results.filter((result) => result.status === "fulfilled").length;
				if (failedDownloads > 0) {
				  setDownloadStatusMessage(
					`${successfulDownloads} file(s) downloaded successfully. ${failedDownloads} failed.`
				  );
				} else {
				  setDownloadStatusMessage("All downloads completed successfully!");
				}
			
				setTimeout(() => setDownloadStatusMessage(""), 3000);
			  })
			  .catch(() => {
				setDownloadStatusMessage("An unexpected error occurred during download.");
				setTimeout(() => setDownloadStatusMessage(""), 3000);
			  })
			  .finally(() => {
				setIsLoading(false); 
			  });
		
		} else if (optionId === "moveToProfilePlaylist") {
			let playlistIds: Array<any> = [];
			let existPlaylist: Array<any> = [];
			recordings.map((record) => {
				if (record.isProfilePlaylist) {
					playlistIds.push(record.id);
					existPlaylist.push(record);
				}
			});

			let newItemForPlaylist = selected.filter((item: any) => {
				return (
					!playlistIds.includes(item) ||
					recordings.find((record) => record.id === item) !== undefined
				);
				// if(!playlistIds.includes(item)){
				// }
			});

			if (playlistIds.length + newItemForPlaylist.length <= 10) {
				let itemsToAdd: Array<any> = [];
				recordings.map((record) => {
					if (newItemForPlaylist.includes(record.id)) {
						itemsToAdd.push(record);
					}
				});
				saveDataToProfilePlaylist([...existPlaylist, ...itemsToAdd]);
			} else {
				updateAlertStatus(
					true,
					false,
					"Profile playlist count exceeds. Maximum 10 allowed."
				);
			}
		} else if (optionId === "delete") {
			deleteMultiple();
		} else if (optionId === "remove") {
			if (props.activeMenu === "sharedMusic") {
				handleRemoveFromShared();
			} else {
				removeMultiple();
			}
		}
	};
	const fetchMoreData = () => {
		// setHasMore(true);
		const fieldValue =
			orderByField === "musicDuration"
				? "music_duration"
				: orderByField === "fileSize"
				? "file_size"
				: orderByField === "musicDate"
				? "music_date"
				: "file_name";
		// const nextPage = pageNumber + 1;
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/recordings/user?searchString=${searchString}&page=${pageNumber}&size=${itemPerPage}&folder=${props.activeMenu}&orderBy=${fieldValue}&inOrder=${fieldOrder}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					userUuid: user.userUuid,
				}),
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data.content.length === 0) {
					setHasMore(false);
				} else {
					if (pageNumber === 0) {
						setRecordings(data.content);
					} else {
						setRecordings((prevRecordings) => [
							...prevRecordings,
							...data.content,
						]);
					}
					setPageNumber(pageNumber + 1);
					setPreviousSearchString(searchString);
				}
			})
			.catch((err) => {
				setHasMore(false);
			});
	};

	function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
		const { numSelected, activeMenuTitle, searchString, setSearchString } =
			props;

		return (
			<Grid
				container
				// xs={12}
				// spacing={1}
				sx={{
					//   pl: { sm: 2 },
					//   pr: { xs: 1, sm: 1 },
					p: 1,
					gap: 1,
				}}
			>
				<Grid item xs={3}>
					{numSelected && numSelected > 0 ? (
						<Typography
							sx={{ flex: "1 1 100%" }}
							color="inherit"
							variant="subtitle1"
							component="div"
						>
							{numSelected} selected
						</Typography>
					) : (
						<Typography
							sx={{ flex: "1 1 100%" }}
							variant="h6"
							id="tableTitle"
							component="div"
						>
							{/* Recent Recordings */}
							{activeMenuTitle}
						</Typography>
					)}
				</Grid>

				{numSelected && numSelected > 0 ? (
					<Grid container xs={8} gap={2}>
						{multiselectOptions
							.filter((option) => option.id !== "remove")
							.map((option) => {
								return (
									<>
										<Button
											className={classes.optionButtons}
											size="small"
											variant="outlined"
											startIcon={<img src={option.icon} />}
											disabled={option.disabled}
											onClick={() => handleMultiSelectOptionClick(option.id)}
										>
											{option.label}
										</Button>
									</>
								);
							})}
					</Grid>
				) : (
					<>
						<Grid item xs={4} className={classes.searchField}>
							<FormControl fullWidth variant="outlined" size="small">
								<OutlinedInput
									id="outlined-adornment-password"
									type="text"
									value={searchString}
									onChange={(e) => setSearchString(e.target.value)}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleMusicSearch}
												edge="end"
											>
												<img src={Search} alt="Search" />
											</IconButton>
										</InputAdornment>
									}
									placeholder="Search file name"
								/>
							</FormControl>
						</Grid>
					</>
				)}
			</Grid>
		);
	}

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof RecordingInterface
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	interface EnhancedTableProps {
		numSelected: number;
		onRequestSort: (
			event: React.MouseEvent<unknown>,
			property: keyof RecordingInterface
		) => void;
		onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
		order: Order;
		orderBy: string;
		rowCount: number;
		activeMenu: any;
		profileMusic: any;
	}

	function EnhancedTableHead(props: EnhancedTableProps) {
		const {
			onSelectAllClick,
			order,
			orderBy,
			numSelected,
			rowCount,
			onRequestSort,
			activeMenu,
			profileMusic,
		} = props;

		const FetchSortedData = (field: any, newOrderBy: any) => {
			setHasMore(true);
			const nextPage = pageNumber + 1;
			const fieldValue =
				field === "musicDuration"
					? "music_duration"
					: field === "fileSize"
					? "file_size"
					: field === "musicDate"
					? "music_date"
					: "file_name";
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/recordings/user?searchString=${searchString}&page=0&size=${itemPerPage}&folder=${activeMenu}&orderBy=${fieldValue}&inOrder=${newOrderBy}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						userUuid: user.userUuid,
					}),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.content.length === 0) {
						setHasMore(false);
					} else {
						setRecordings(data.content);
						setPageNumber(nextPage);
						setPreviousSearchString(searchString);
					}
				})
				.catch((err) => {
					setHasMore(false);
				});
		};
		const handleSorting = (field: any) => {
			setOrderByField((prevValue: any) => {
				const newOrderByField = orderByField === field ? `${field}` : field;
				return newOrderByField;
			});
			setFieldOrder((prevValue: any) => {
				const newOrderByField =
					orderByField === field && fieldOrder === "asc" ? `desc` : "asc";
				return newOrderByField;
			});
			const newOrderBy =
				orderByField === field && fieldOrder === "asc" ? `desc` : "asc";

			FetchSortedData(field, newOrderBy);
		};
		const createSortHandler =
			(property: keyof RecordingInterface) =>
			(event: React.MouseEvent<unknown>) => {
				onRequestSort(event, property);
			};
		return (
			<TableHead>
				<TableRow>
					<TableCell
						padding="checkbox"
						sx={{ background: "#F8F8F8" }}
						style={{ width: "1% !important" }}
					>
						<Checkbox
							color="primary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{
								"aria-label": "select all desserts",
							}}
						/>
					</TableCell>
					{headCells.map((headCell) => (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							variant="head"
							padding={headCell.disablePadding ? "none" : "normal"}
							sortDirection={orderBy === headCell.id ? order : false}
							width={headCell.width}
							sx={{
								fontFamily: "Lexend",
								textTransform: "uppercase",
								background: "#F8F8F8",
								fontWeight: "600 !important",
								color: "#7A7985",
								padding: "8px",
							}}
						>
							{headCell.id !== "actions" && headCell.id !== "audioPlayer" ? (
								profileMusic ? (
									<Typography
										sx={{
											fontWeight: 500,
											fontSize: "14px !important",
											color: "#000",
											fontFamily: "Lexend",
										}}
										color="textPrimary"
									>
										{headCell.label}
									</Typography>
								) : (
									<TableSortLabel
										active={orderByField === headCell.id}
										direction={fieldOrder === "desc" ? "asc" : "desc"}
										onClick={() => handleSorting(headCell.id)}
									>
										{headCell.label}
										{orderBy === headCell.id ? (
											<Box component="span" sx={visuallyHidden}>
												{order === "desc"
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										) : null}
									</TableSortLabel>
								)
							) : (
								<Typography
									sx={{
										fontWeight: 500,
										fontSize: "14px !important",
										color: "#000",
										fontFamily: "Lexend",
									}}
									color="textPrimary"
								>
									{headCell.label}
								</Typography>
							)}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	}

	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	type Order = "asc" | "desc";

	const isSelected = (id: number) => selected.indexOf(id) !== -1;

	function getComparator<Key extends keyof any>(
		order: Order,
		orderBy: Key
	): (
		a: { [key in Key]: number | string },
		b: { [key in Key]: number | string }
	) => number {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	function stableSort<T>(
		array: Array<RecordingInterface>,
		comparator: (a: T, b: T) => number
	) {
		const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		// setRecordings(stabilizedThis)
		const sortedData = stabilizedThis.map((el) => el[0]);
		return sortedData;
	}

	useEffect(() => {
		let sortedData: any = stableSort(
			recordings,
			getComparator(order, orderBy)
		).slice(page * itemPerPage, page * itemPerPage + itemPerPage);
		setDisplayRecordings(sortedData);
	}, [order, orderBy, page, itemPerPage, recordings]);

	const handleSelectAllClick = (event: any) => {
		if (event.target.checked) {
			const newSelected = recordings.map(({ id }) => id);
			const newSelectedUUID = recordings.map(({ myMusicUuid }) => myMusicUuid);
			setSelected(newSelected);
			setSelectedUUID(newSelectedUUID);
			return;
		}
		setSelected([]);
		setSelectedUUID([]);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setItemPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleClick = (event: any, name: number, sessionUUID: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: readonly number[] = [];
		let newSelectedUUID: readonly string[] = [];
	
		if (selectedIndex === -1) {
			// Add the new item to the selected arrays
			newSelected = newSelected.concat(selected, name);
			newSelectedUUID = newSelectedUUID.concat(selectedUUID, sessionUUID);
		} else if (selectedIndex === 0) {
			// Remove the first item
			newSelected = newSelected.concat(selected.slice(1));
			newSelectedUUID = newSelectedUUID.concat(selectedUUID.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			// Remove the last item
			newSelected = newSelected.concat(selected.slice(0, -1));
			newSelectedUUID = newSelectedUUID.concat(selectedUUID.slice(0, -1));
		} else if (selectedIndex > 0) {
			// Remove the item from the middle
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
			newSelectedUUID = newSelectedUUID.concat(
				selectedUUID.slice(0, selectedIndex),
				selectedUUID.slice(selectedIndex + 1)
			);
		}
	
		// Update the state
		setSelected(newSelected);
		setSelectedUUID(newSelectedUUID);
	};	

	const handleMusicSearch = () => {
		if (searchString !== "") {
			setSearchMode(true);
			getRecordingList();
		}
	};
	const handleMusicReset = () => {
		setSearchString("");
		setSearchMode(false);
		// getRecordingList();
	};
	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" && searchString !== "") {
			handleMusicSearch();
			setSearchMode(true);
		}
	};
	const deleteMultiple = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/mymusic?musicDirectory=${
				props.activeMenuTitle === "Uploaded Music" ? "myMusic" : ""
			}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					idList: selectedUUID,
					// fileName: fileName
				}),
			}
		)
			.then((response) => {
				if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				setSelected([]);
				setSelectedUUID([]);
				if (props.reloadStorage) {
					props.reloadStorage();
									}
				getRecordingList();
			});
	};

	const removeMultiple = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/mymusic/profilePlaylist`, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(selectedUUID),
		})
			.then((response) => {
				if (response.status > 400) {
					throw new Error();
				} else {
					User.fetchUserInfo(() => {});
					if (props.reloadStorage) {
						props.reloadStorage();
					}
					return response.json();
				}
			})
			.then((result) => {
				setSelected([]);
				setSelectedUUID([]);
				getRecordingList();
			});
	};

	const handleRemoveFromShared = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/removesharedmusic`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				musicUUIDs: selectedUUID,
			}),
		})
			.then((response) => {
				if (response.status > 400) {
					throw new Error();
				} else {
					// 	setSelected([]);
					// getRecordingList();
					User.fetchUserInfo(() => {});
					if (props.reloadStorage) {
						props.reloadStorage();
					}
					return response.json();
				}
			})
			.then((result) => {
				setSelected([]);
				setSelectedUUID([]);
				getRecordingList();
			});
	};

	const setErrorMessage = (status: any) => {
		if (status <= 400) {
			return "Error! Please try again..";
		} else if (status > 400 && status < 500) {
			return "Something went wrong..";
		} else if (status === 500) {
			return "Network Error! Please try again..";
		} else if (status === 507) {
			return "Insufficient Storage..";
		}
	};

	const [subscriptions, setSubscriptions] = useState<any[]>([]);
	const [userStorageCapacity, setUserStorageCapacity] = useState();
	const [userStorageUsage, setUserStorageUsage] = useState();

	useEffect(() => {
		User.fetchUserInfo((userInfo: any) => {
			if (userInfo.error) {
				console.error("Failed to fetch user info:", userInfo.error);
			} else {
				setUserStorageCapacity(userInfo.storageCapacity);
				setUserStorageUsage(userInfo.storageUsage);

				setSubscriptions(userInfo.subscriptionInformationDTOs);
			}
		});
	}, []);
	const musicUpload = (e: any) => {
		const targetFiles = e.target.files[0];
		setMusicUploading(true);
		console.log(
			"userInfo location name " +
				userStorageCapacity +
				"  " +
				userStorageUsage +
				" fsdf  " +
				location.pathname
		);
		if (targetFiles) {
			const audioUrl = URL.createObjectURL(targetFiles);
			const audio = new Audio(audioUrl);
			audio.addEventListener("loadedmetadata", () => {
				let formData = new FormData();
				formData.append("files", targetFiles);

				// return false;
				let ext = targetFiles.type.split("/").pop();
				//Convert bytes to MB & Round to the nearest integer
				var roundedFileSizeInMB = Math.round(targetFiles.size / (1024 * 1024));
				// Convert MB back to bytes
				const roundedFileSizeInBytes = roundedFileSizeInMB * (1024 * 1024);
				const getStorageInNumber=(value:string)=>{
					return Number(value.split(' ')[0]);
				}

				if (roundedFileSizeInMB > 100) {
					updateAlertStatus(
						true,
						false,
						"File size limited to 100 MB per audio file. Please save in another format or divide into multiple files and try again."
					);
					return; // Stop further execution if file size is too large
				}
				const sUsage = userStorageUsage ? userStorageUsage : 0;
				const targetfileAfterupload = sUsage + roundedFileSizeInMB;
				const sCapacity = userStorageCapacity ? userStorageCapacity : 0;
				if (
					storeFull || 
					sCapacity < targetfileAfterupload || 
					roundedFileSizeInMB > (
					  parseFloat(storageAvailable.split(' ')[0]) * (storageAvailable.includes('GB') ? 1024 : 1) - 
					  parseFloat(storageConsumed.split(' ')[0]) * (storageConsumed.includes('GB') ? 1024 : 1)
					)
				  ) {
					updateAlertStatus(
						true,
						false,
						"Music size exceeds the maximum storage limit. Please select other file or buy storage."
					);
					// }
					//  else if (roundedFileSizeInBytes > (10 * 1024 * 1024)) {
					// 	updateAlertStatus(true, false, 'Music size exceeds the maximum allowed limit of 10Mb. Please try again.');
				} else if (ALLOWED_FILE_EXT.includes(ext)) {
					updateAlertStatus(true, true, "Music uploading is in process");
					setLoading(true);
					fetch(
						`${process.env.REACT_APP_BASE_URL}/api/upload-music?musicDuration=${audio.duration}`,
						{
							method: "POST",
							headers: {
								// "Content-Type": "multipart/form-data",
								Authorization: `Bearer ${token}`,
							},
							body: formData,
						}
					)
						.then((res) => {
							if (res.status !== 200) {
								setLoading(false);
								setMusicUploading(false);
								ErrorAlert(
									res.status,
									setAlertStatus,
									setShowAlert,
									setAlertMsg
								);
								const errorMes = setErrorMessage(res.status);
								updateAlertStatus(true, false, errorMes ? errorMes : "Error!");
								//setLoadMusicDetails(true);
							} else {
								setLoading(false);
								setMusicUploading(false);
								updateAlertStatus(true, true, "Music uploaded successfully");
								setLoadMusicDetails(true);
								setGetNewList(true);
								// UPDATE THE STORAGE CONSUMED IN USER CONTEXT WHENEVER ANY NEW SESSION IS SCHEDULED
								// User.fetchUserInfo((userInfo: any) => {
								// 	if (userInfo.error) {
								// 	  console.error("Failed to fetch user info:", userInfo.error);
								// 	} else {
								// 	  setUserStorageCapacity(userInfo.storageCapacity)
								// 	  setUserStorageUsage(userInfo.storageUsage)

								// 	  setSubscriptions(userInfo.subscriptionInformationDTOs);
								// 	}
								//   });
								if (props.reloadStorage) {
									props.reloadStorage();
								}
								// getAllMusicListing();
							}
						})
						.catch((err) => {
							// setShowAlert(true);
							// setAlertStatus(false);
							// setAlertMsg("Network Error! Please try again..");
							updateAlertStatus(
								true,
								false,
								"Something went wrong, please try again."
							);
						});
				} else {
					// setShowAlert(true);
					// setAlertStatus(false);
					// setAlertMsg("Incorrect audio file format. Please try again.");
					updateAlertStatus(
						true,
						false,
						"Incorrect audio file format. Please try again."
					);
					setMusicUploading(false);
				}
			});
		}
		if (inputFileRef.current) {
			inputFileRef.current.value = null; // Clear the input value
			const newInput = document.createElement("input");
			newInput.style.display = "none";
			newInput.type = "file";
			newInput.accept = "audio/*"; // Only accept audio files
			newInput.addEventListener("change", musicUpload);
			inputFileRef.current.parentNode.replaceChild(
				newInput,
				inputFileRef.current
			);
			inputFileRef.current = newInput;
		}
		// fileInputRef.current = null;
	};

	useEffect(() => {
		setSelected([]);
		setSelectedUUID([]);
	}, [props.activeMenu]);

	const [orderByField, setOrderByField] = useState("title");
	const [fieldOrder, setFieldOrder] = useState("asc");

	return (
		<Grid
			container={true}
			spacing={2}
			columns={{ xs: 12 }}
			paddingLeft={props.profileMusic ? 0 : 2}
			px={props.profileMusic && 2}
			sx={{ width: "100%" }}
		>
			<Grid item={true} xs={12}>
				{props.profileMusic ? (
					<>
						<Grid
							container
							xs={12}
							sx={{
								p: 1,
								pl: 0,
								gap: 1,
							}}
						>
							<Grid item xs={3}>
								{selected.length && selected.length > 0 ? (
									<Typography
										sx={{
											flex: "1 1 100%",
											display: "flex",
											alignItems: "center",
										}}
										color="inherit"
										variant="subtitle1"
										component="div"
									>
										{selected.length} selected
									</Typography>
								) : (
									<Typography
										sx={{ flex: "1 1 100%" }}
										variant="h5"
										id="tableTitle"
										component="div"
									>
										{props.activeMenuTitle}
									</Typography>
								)}
							</Grid>

							{selected.length && selected.length > 0 ? (
								<Grid container xs={8} gap={2}>
									{multiselectOptions
										.filter((option) => option.id === "remove")
										.map((option) => {
											return (
												<Button
													className={classes.optionButtons}
													size="small"
													variant="outlined"
													startIcon={<img src={option.icon} />}
													disabled={option.disabled}
													onClick={() =>
														handleMultiSelectOptionClick(option.id)
													}
												>
													{option.label}
												</Button>
											);
										})}
								</Grid>
							) : (
								""
							)}
						</Grid>

						{loading ? (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								sx={{ p: "16px" }}
							>
								<CircularProgress />
							</Grid>
						) : recordings.length === 0 ? (
							<Grid sx={{ p: "16px" }}>
								<Typography
									component="h1"
									className="typography-field-custom-font-size center-align"
								>
									{i18n.myMusic.noMusicMsg}
								</Typography>
							</Grid>
						) : (
							<TableContainer sx={{ width: "100%" }}>
								<Table
									aria-labelledby="tableTitle Profile tabel"
									size="medium"
									sx={{
										width: "1020px",
										overflowX: "hidden",
										overflow: "hidden",
									}}
								>
									<EnhancedTableHead
										numSelected={selected.length}
										order={order}
										orderBy={orderBy}
										onSelectAllClick={handleSelectAllClick}
										onRequestSort={handleRequestSort}
										rowCount={recordings.length}
										activeMenu={props.activeMenu}
										profileMusic={props.profileMusic}
									/>
									<TableBody>
										{recordings.length === 0 && (
											<TableRow>
												<TableCell
													align="center"
													colSpan={headCells.length + 1}
												>
													{i18n.myMusic.noMusicMsg}
												</TableCell>
											</TableRow>
										)}
										{recordings.map((recording) => (
											<MusicCmp
												id={recording.id}
												getRecordingList={getRecordingList}
												recording={recording}
												handleClick={handleClick}
												isSelected={isSelected}
												activeMenu={props.activeMenu}
												selected={selected}
												selectedUUID={selectedUUID}
												getUserDetails={props.getUserDetails}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</>
				) : (
					<>
						<Paper
							sx={{
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "18px !important",
							}}
						>
							<Grid
								container
								xs={12}
								sx={{
									p: 2,
									gap: 1,
								}}
							>
								<Grid item xs={5}>
									{selected.length && selected.length > 0 ? (
										<Typography
											sx={{ flex: "1 1 100%" }}
											color="inherit"
											variant="subtitle1"
											component="div"
										>
											{selected.length} selected
										</Typography>
									) : (
										<Typography
											sx={{ flex: "1 1 100%" }}
											variant="h5"
											id="tableTitle"
											component="div"
										>
											{props.activeMenuTitle}
										</Typography>
									)}
								</Grid>

								{downloadStatusMessage && (
									<div className="status-message">{downloadStatusMessage}</div>
								)}
								{selected.length && selected.length > 0 ? (
									<Grid container xs={8} gap={2}>
										{multiselectOptions
											.filter(
												(option: any) =>
													(props.activeMenu === "sharedMusic" &&
														(option.id === "remove" ||
															option.id === "download")) ||
													(props.activeMenu !== "sharedMusic" &&
														option.id !== "remove")
											)
											.map((option) => (
												<Button
													key={option.id}
													className={classes.optionButtons}
													size="small"
													variant="outlined"
													startIcon={<img src={option.icon} />}
													disabled={
														option.disabled ||
														(option.id === "download" && isLoading)
													}
													onClick={() =>
														handleMultiSelectOptionClick(option.id)
													}
												>
													{option.label}
												</Button>
											))}
									</Grid>
								) : (
									<>
										<Grid item xs={3} className={classes.searchField}>
											{recordings.length > 0 ||
											(recordings.length === 0 && searchString != "") ? (
												<FormControl fullWidth variant="outlined" size="small">
													<OutlinedInput
														id="outlined-adornment-password"
														type="text"
														value={searchString}
														onChange={(e) => setSearchString(e.target.value)}
														endAdornment={
															<>
																{searchString != "" &&
																searchString === previousSearchString ? (
																	<InputAdornment position="end">
																		<IconButton
																			aria-label="toggle password visibility"
																			onClick={handleMusicReset}
																			edge="end"
																		>
																			<img src={CloseIcon} alt="Reset Search" />
																		</IconButton>
																	</InputAdornment>
																) : (
																	<InputAdornment position="end">
																		<IconButton
																			aria-label="toggle password visibility"
																			onClick={handleMusicSearch}
																			edge="end"
																		>
																			<img src={Search} alt="Search" />
																		</IconButton>
																	</InputAdornment>
																)}
															</>
														}
														placeholder="Search file name"
														onKeyDown={handleKeyPress}
														autoComplete="off"
													/>
												</FormControl>
											) : (
												<></>
											)}
										</Grid>

										<Grid item xs={3} alignSelf="right">
											{props.activeMenu === "myMusic" ? (
												<label>
													<Box
														className={classes.recordingBtn}
														id="photo-upload"
														sx={{
															height: "28px",
															fontSize: "18px !important",
															fontWeight: "500",
															background:
																user.userAccountStatus === "ACTIVE" && canStore && !musicUploading
																	? "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)"
																	: "#0003",
															cursor:
																user.userAccountStatus === "ACTIVE" && canStore && !musicUploading
																	? "pointer"
																	: "not-allowed",
														}}
													>
														{musicUploading ? "Uploading..." : "Upload Music"}
													</Box> 
													{user.userAccountStatus === "ACTIVE" && canStore ? (
														<input
														id="photo-upload"
														ref={inputFileRef}
														type="file"
														accept=".mp3,.wav,.aac,audio/mpeg,audio/wav,audio/aac,.mp4,video/mp4"
														style={{ display: "none" }}
														onChange={musicUpload}
														disabled={loading}
													/>
												) : null}
												</label>
											) : (
												<></>
											)}
										</Grid>
									</>
								)}

								{recordings.length === 0 && searchString === "" ? (
									<Grid sx={{ p: "16px" }}>
										<Typography
											component="h1"
											className="typography-field-custom-font-size center-align"
										>
											{i18n.myMusic.noMusicMsg}
										</Typography>
									</Grid>
								) : (
									<Grid sx={{ width: "100%", overflowX: "auto" }}>
										<InfiniteScroll
											dataLength={recordings.length}
											next={fetchMoreData}
											hasMore={hasMore}
											loader={<Box className={classes.spinner}></Box>}
										>
											{/* <EnhancedTableToolbar numSelected={0}/> */}
											<TableContainer sx={{ width: "100%" }}>
												<Table
													aria-labelledby="tableTitleprofiletable"
													size="medium"
													sx={{
														width: "1020px",
														overflowX: "hidden",
														overflow: "hidden",
													}}
												>
													<EnhancedTableHead
														numSelected={selected.length}
														order={order}
														orderBy={orderBy}
														onSelectAllClick={handleSelectAllClick}
														onRequestSort={handleRequestSort}
														rowCount={recordings.length}
														activeMenu={props.activeMenu}
														profileMusic={props.profileMusic}
													/>
													<TableBody>
														{loading ? (
															<TableRow>
																<TableCell
																	align="center"
																	colSpan={headCells.length + 1}
																>
																	<Grid
																		container
																		justifyContent="center"
																		alignItems="center"
																		sx={{ p: "16px" }}
																	>
																		<CircularProgress />
																	</Grid>
																</TableCell>
															</TableRow>
														) : recordings.length === 0 ? (
															<TableRow>
																<TableCell
																	align="center"
																	colSpan={headCells.length + 1}
																>
																	{i18n.myMusic.noMusicMsg}
																</TableCell>
															</TableRow>
														) : (
															recordings.map((recording) => (
																<MusicCmp
																	id={recording.id}
																	getRecordingList={getRecordingList}
																	recording={recording}
																	handleClick={handleClick}
																	isSelected={isSelected}
																	activeMenu={props.activeMenu}
																	selectedItems={selected}
																	selectedUUID={selectedUUID}
																	selected={selected}
																	getUserDetails={props.getUserDetails}
																	reloadStorage={props.reloadStorage}
																/>
															))
														)}
													</TableBody>
												</Table>
											</TableContainer>

											{/* <TablePagination
									rowsPerPageOptions={[1, 5, 10, 25]}
									component="div"
									count={recordings.length}
									rowsPerPage={itemPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
										/> */}
										</InfiniteScroll>
									</Grid>
									// </Grid>
								)}
							</Grid>
						</Paper>
					</>
				)}
			</Grid>
		</Grid>
	);
};
export default memo(SessionRecordings);
