
import { useState, useEffect } from 'react'
import { Card, CardContent, Typography, IconButton, Switch, Avatar, Grid, Button, Box } from '@mui/material';
import Play from '../../images/Play.svg';
import Chart from '../../images/chart.svg';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from '../Error/ErrorAlertDisplay';
import { useUser } from "../../context/UserContext";
import { set } from "date-fns";
import { addMinutes, format, isBefore, differenceInDays } from "date-fns";
import Sessions from '../jam-session/Sessions';
import CalendarCmp from './CalendarCmp';
import { SocialUSerDetails } from '../../types/types';
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
interface Props {
    userDetails: SocialUSerDetails;
}

const MyMusicCalendar = (props: Props) => {

    const user = useUser();
    const token = localStorage.getItem("sessionId");
    const [calendar, setCalendar] = useState<any>([]);
    const [otherUserCalendar, setOtherUserCalendar] = useState<any>([]);
    const [visibility, setVisibility] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertStatus, setAlertStatus] = useState(false);
    const viewer = props.userDetails.loggedInUser?.ViewerStatus;
    const userProfileUUID = props.userDetails.loggedInUser?.userProfileUUID;
    const otherUser = props.userDetails.privacySettings?.userProfile;
    const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
    const handleAlertClose = () => {
        setShowAlert(false);
    };

    const handleVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVisibility(event.target.checked);
    }

    const sDate = set(new Date(), {
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const eDate = new Date(
        sDate.getFullYear(),
        sDate.getMonth(),
        sDate.getDate(),
        23,
        59,
        59
    );

    const fetchCalendarInfo = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/get-sessions`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                userProfileUUID: userProfileUUID,
                groupBy: 'agenda',
                startDate: sDate,
                endDate: eDate,
            }),
        })
            .then((res) => {
                if (res.status !== 200) {
                    ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
                } else {
                    return res.json();
                }
            })
            .then((data) => {

                setCalendar(data)
            })
            .catch((err) => {
                // setShowAlert(true);
                // setAlertStatus(false);
                // setAlertMsg("Network Error! Please try again..");
            });
    };

    const fetchCalendarInfoForOtherUser = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/getPublicSessions/${otherUser?.user?.userUuid}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
                } else {
                    return res.json();
                }
            })
            .then((data) => {

                setOtherUserCalendar(data)
            })
            .catch((err) => {
                // setShowAlert(true);
                // setAlertStatus(false);
                // setAlertMsg("Network Error! Please try again..");
            });
    };
    const setAlertOnEditSession = (
        _showAlert: boolean,
        _alertStatus: boolean,
        _alertMsg: string
    ) => {
        setShowAlert(_showAlert);
        setAlertStatus(_alertStatus);
        setAlertMsg(_alertMsg);
    };
    useEffect(() => {
        if (viewer === 'SELF') {
            fetchCalendarInfo();
        } else {
            fetchCalendarInfoForOtherUser();
        }
    }, [])

    return (
        <>
            <ErrorAlertDisplay alertMsg={alertMsg} alertStatus={alertStatus} showAlert={showAlert} handleAlertClose={handleAlertClose} />
            <CardContent sx={{ alignItems: 'center', gap: 1 , marginBottom: 1}}>
                <Grid container columns={{ xs: 6, sm: 12, md: 12 }}>
                    <Grid item xs={7}>
                    {!isAdmin && viewer === 'SELF' ?
                                <Typography variant="h6" sx={{ fontWeight: 500, fontSize:'24px' }}>
                                    My Music Calendar
                                </Typography>
                                :
                                <Typography variant="h6" sx={{ fontWeight: 500, fontSize:'24px' }}>
                                    Music Calendar
                                </Typography>
                            }
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container  >
                        {isAdmin && viewer !== 'SELF' ?
                                <Typography >
                                    
                                </Typography>
                                :
                                <Typography  variant="body2" sx={{ mt: 1.5, color: '#5D5D65', mr: 3, ml: 'auto' }}>
                                    Your recent Jam sessions
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>

            </CardContent>
            {/* <Sessions
                    refresh={true}
                    reqStartdate={new Date()}
                    filter={"agenda"}
                    setAlertOnEditSession={setAlertOnEditSession}
                    displayEdit={false}
                /> */}

				{viewer === "SELF" ? (
					calendar.length > 0 ? (
						calendar.map((session: any, index: any) => (
							<>
								{/* <Sessions
                    refresh={true}
                    reqStartdate={new Date()}
                    filter={"agenda"}
                    setAlertOnEditSession={setAlertOnEditSession}
                    displayEdit={false}
                /> */}
								<CalendarCmp session={session} viewer={viewer} />
								{/* <Card sx={{ minWidth: 300, flexGrow: 1, minHeight: '30%', boxShadow: '0px 1px 16px 1px #D9E7FFC4' }}>
                    <CardContent sx={{ alignItems: 'center', gap: 1, }} >
                        <Grid container columns={{ xs: 6, sm: 12, md: 12 }}>
                            <Grid item xs={2}>
                                <Typography variant="body3" sx={{ fontWeight: 'bold', color: 'black', backgroundColor: '#E1F5DE', px: 2, borderRadius: '10px' }}>
                                    Live Streaming Now
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="body3" sx={{ fontWeight: 'bold', color: 'black', backgroundColor: '#DFEBFF', px: 2, borderRadius: '10px' }}>
                                    Scheduled
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body3" sx={{ fontWeight: 'bold', color: 'black' }}>
                                    {`${format(
                                        new Date(session.dateTime),
                                        "eeee, LLLL dd yyyy   hh:mm aaa"
                                    )} - ${format(
                                        addMinutes(
                                            new Date(session.dateTime),
                                            session.duration
                                        ),
                                        "hh:mm aaa"
                                    )}`}
                                </Typography>
                            </Grid>
                        </Grid>


                        <Typography variant="body3" sx={{ fontWeight: 'bold', color: 'black', backgroundColor: '#E1F5DE', px: 2, borderRadius: '10px' }}>
                            Live Streaming Now
                        </Typography>
                        <Typography variant="body3" sx={{ fontWeight: 'bold', color: 'black', backgroundColor: '#DFEBFF', px: 2, borderRadius: '10px' }}>
                            Scheduled
                        </Typography>
                        <Typography variant="body3" sx={{ fontWeight: 'bold', color: 'black' }}>
                            {`${format(
                                new Date(session.dateTime),
                                "eeee, LLLL dd yyyy   hh:mm aaa"
                            )} - ${format(
                                addMinutes(
                                    new Date(session.dateTime),
                                    session.duration
                                ),
                                "hh:mm aaa"
                            )}`}
                        </Typography>
                        <Switch size="small" sx={{ ml: 'auto' }} checked={visibility} onChange={handleVisibility} />
                        <Typography variant="body3">{visibility ? 'Live Streaming' : 'Private'}</Typography>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap', ml: 'auto' }}>
                            <Button className='colorStylingButton' size="small" variant="contained">
                                ($) Tip the band
                            </Button>
                            <Button className='colorStylingButtonOnHover' size="small" variant="outlined" sx={{ borderRadius: '20px', color: '#FF702A', borderColor: '#FF702A' }}>
                                Open in new window
                            </Button>
                            <Button className='colorStylingButton' size="small" variant="contained" >
                                Add to my calendar
                            </Button>
                        </Box>
                    </CardContent>

                    <Typography variant="body2" sx={{ fontWeight: 'bold', px: 2 }}>
                        {session.sessionName}
                    </Typography>

                    <Typography variant="body3" sx={{ px: 2 }} >
                        {session.sessionDescription}
                    </Typography>

                    <Grid container columnGap={1} sx={{ px: 2 }}>
                        {session.attendees.map((attendees: any, index: any) => (
                            <>
                                <Avatar
                                    alt="Remy Sharp"
                                    src="/static/images/avatar/1.jpg"
                                    sx={{ width: 20, height: 20 }} />
                                <Typography variant="body3" sx={{ mt: 0.5, color: '#3F77D7', }}>
                                    {attendees.firstName} {attendees.lastName}
                                </Typography>
                            </>
                        ))}
                    </Grid>

                    <Card sx={{ minWidth: 300, flexGrow: 1, minHeight: '30%', backgroundColor: '#F7F8FB' }}>

                        <CardContent sx={{ alignItems: 'center', gap: 1 }}>
                            <Grid container columns={{ xs: 6, sm: 12, md: 12 }}>

                                <Grid item xs={1}>
                                    <IconButton aria-label="Play" size='small' >
                                        <img src={Play} alt="Play" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', pt: 2 }}>
                                        Welcome to my musics
                                    </Typography>
                                </Grid>

                                <Grid item xs={4} sx={{ textAlign: 'right', pt: 1, pr: 1 }}>
                                    <img src={Chart} alt="Play" style={{ marginLeft: 'auto', height: '17px', width: '17px' }} />
                                    <Typography variant="body3" sx={{ fontWeight: 'bold', color: 'black' }}>2.5k</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton color="primary" size="small"  >
                                        <ThumbUpIcon sx={{ color: '#3F77D7' }} />
                                        <Typography variant="body3" sx={{ fontWeight: 'bold', color: 'black' }}>101</Typography>
                                    </IconButton>
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>
                </Card> */}
							</>
						))
					) : (
						<Grid xs={12} sx={{ textAlign: "center" }}>
							<Typography
								variant="subtitle1"
								fontSize="lg"
								sx={{ fontSize: "16px", fontWeight: 500 }}
							>
								No records available
							</Typography>
						</Grid>
					)
				) : viewer === "UNBLOCK" ? (
					<Grid xs={12} sx={{ textAlign: "center", py: 3 }}>
						<Typography
							variant="subtitle1"
							fontSize="lg"
							sx={{ fontSize: "16px", fontWeight: 500 }}
						>
							No records available
						</Typography>
					</Grid>
				) : otherUserCalendar.length > 0 ? (
					otherUserCalendar.map((session: any, index: any) => (
						<CalendarCmp session={session} viewer={viewer} />
					))
				) : (
					<Grid xs={12} sx={{ textAlign: "center", py: 3 }}>
						<Typography
							variant="subtitle1"
							fontSize="lg"
							sx={{ fontSize: "16px", fontWeight: 500 }}
						>
							No records available
						</Typography>
					</Grid>
				)}
			</>
		);
}

export default MyMusicCalendar