import {
	Box,
	Typography,
	Divider,
	Grid,
	Button,
	FormHelperText,
	TextField,
	CardMedia,
	Collapse,
	IconButton,
	CardActionArea,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Card,
	CardActions,
	CardContent,
	Dialog,
	DialogActions,
} from "@mui/material";
import i18n from "../../l10n/strings.json";
import { useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import e from "express";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import send from "../../images/send.svg";
import { Link, useNavigate } from "react-router-dom";
import { Client } from "@twilio/conversations";
import { addConversation, getToken } from "../Messages/api";
import { bindActionCreators } from "redux";
import { useAlertStatus } from "../../context/AlertStatusContext";
import { actionCreators } from "../../store";
import { useDispatch } from "react-redux";

const ConnectRequestDialog = (props: any) => {
	const user = useUser();
	const SelfDetails = useUser();
	const dispatch = useDispatch();
	const token = localStorage.getItem("sessionId");
	const [connnectionRequestNote, setConnectionRequestNote] =
		useState<string>("");
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const navigate = useNavigate();
	const [profileDetails, setProfileDetails] = useState(props.user);
	const [isLoading, setIsLoading] = useState(false);
	const { updateCurrentConversation, addNotifications, updateParticipants } =
		bindActionCreators(actionCreators, dispatch);
	const { updateAlertStatus } = useAlertStatus();

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const sendMessage = async () => {
		setIsLoading(true);
		var conversationToken = localStorage.getItem("conversationToken");
		if (conversationToken === null || conversationToken === "") {
			conversationToken = await getToken(user.email, "");
			localStorage.setItem("conversationToken", conversationToken);
		}
		const client = new Client(conversationToken as string);
		let title = profileDetails.nickname;
		let uniqueName = [props.user.user.email].sort().join("-");
		let uniqueNameForCheck = [user.email, props.user.user.email]
			.sort()
			.join("-");
		let defaultMessage = "Hi there, I would like to connect with you.";

		// Assign a default message if the connection request note is blank
		let messageToSend =
			connnectionRequestNote.trim() === ""
				? defaultMessage
				: connnectionRequestNote;

		// Before you use the client, subscribe to the 'initialized' event.
		client.on("initialized", async () => {
			try {
				const conversationDetails = await client.getConversationByUniqueName(
					uniqueNameForCheck
				);
				// Conversation exists, send the note message
				await conversationDetails.sendMessage(messageToSend);
				updateAlertStatus(true, true, "Connection request sent successfully");
				updateCurrentConversation(conversationDetails.sid);
			} catch (error) {
				if (error instanceof Error) {
					if (error.message === "Not Found") {
						// Conversation not found, create a new one
						try {
							const convo = await addConversation(
								title,
								uniqueName,
								updateParticipants,
								client,
								addNotifications
							);
							if (convo.sid) {
								const conversationDetails = await client.getConversationBySid(
									convo.sid
								);
								updateCurrentConversation(conversationDetails.sid);

								await conversationDetails.sendMessage(messageToSend);
								updateAlertStatus(
									true,
									true,
									"Connection request sent successfully"
								);
							}
						} catch (createError) {
							updateAlertStatus(
								true,
								false,
								"Failed to create conversation or send message"
							);
						}
					} else {
						updateAlertStatus(true, false, "Something went wrong");
					}
				} else {
					updateAlertStatus(true, false, "An unexpected error occurred");
				}
			} finally {
				setIsLoading(false);
			}
		});
	};

	const sendConnectionRequest = (
		requestTo: number,
		requestStatus: string,
		requestToUUID: string
	) => {
		if (connnectionRequestNote.length < 255) {
			try {
				setIsDisable(true);
				props.setLoading(true);
				fetch(
					`${process.env.REACT_APP_BASE_URL}/api/user-connection-requests`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({
							requestFromUUID: SelfDetails?.userProfileUuid,
							requestToUUID: requestToUUID,
							connectionStatus: requestStatus,
							note: connnectionRequestNote,
						}),
					}
				)
					.then((res) => {
						if (res.status === 502) {
							navigate("/defaultPage");
						} else if (res.status !== 200 && res.status !== 201) {
							setIsDisable(false);
							ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
						} else {
							setIsDisable(false);
							return res.json();
						}
					})
					.then((data) => {
						sendMessage();
						if (props.post) {
							props.updateConnectionStatus(
								props.userProfileId,
								data.connectionStatus,
								data.requestFrom.id,
								data.requestTo.id
							);
						} else {
							props.getUserDetails && props.getUserDetails();
							props.getReloadedArtists && props.getReloadedArtists();
						}
						props.handleClose();
					})
					.catch((err) => {
						setIsDisable(false);
					})
					.finally(() => {
						setIsDisable(false);
						props.setLoading(false);
					});
			} catch (error) {
				props.setLoading(false);
			}
		} else {
			setShowAlert(true);
			setAlertStatus(false);
			setAlertMsg("Maximum 150 characters allowed");
		}
	};

	useEffect(() => {
		setTimeout(() => {
			const inputField = document.getElementById(
				"myInput"
			) as HTMLInputElement | null;
			if (inputField) {
				inputField.focus();
			}
		}, 500);
	});

	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="sm"
				open={props.open}
			>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							className="dialog-title-custom-size"
							sx={{
								fontWeight: 500,
								color: "#000000",
							}}
						>
							{i18n.musicians.connection.title}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Grid
						container
						// columnSpacing={2}
					>
						<Grid item={true} xs={12}>
							<FormHelperText className="jamsession-label">
								{i18n.musicians.connection.label}
							</FormHelperText>
							<TextField
								id="myInput"
								inputProps={{
									style: {
										fontStyle: "normal",
										fontSize: "1.125rem",
										lineHeight: "25px",
										fontWeight: 900,
										color: "#000000",
									},
								}}
								sx={{ background: "#F8F8F8" }}
								fullWidth
								// multiline
								variant="outlined"
								value={connnectionRequestNote}
								multiline
								rows={2}
								maxRows={10}
								onChange={(event) =>
									setConnectionRequestNote(event.target.value.trimStart())
								}
								error={connnectionRequestNote.length > 255}
							></TextField>
							<FormHelperText
								sx={{
									fontSize: "0.8125rem",
									color: "#FD4B24",
									textAlign: "right",
								}}
							>
								{connnectionRequestNote?.length > 255
									? "Enter maximum 255 characters"
									: ""}
							</FormHelperText>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						columnSpacing={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<Grid item={true}>
							<Button
								className="cancel-button"
								size="large"
								// endIcon={<img src={RightAarrow} alt="Right Arrow" />}
								variant="outlined"
								onClick={props.handleClose}
							>
								<Typography>{i18n.dialog.cancel}</Typography>
							</Button>
						</Grid>
						<Grid item={true}>
							<Button
								className="submit-button"
								size="large"
								variant="contained"
								// disabled={serialNumber === "" || purchaseDate === null || (purchaseDate !== null && purchaseDate >= new Date())}
								onClick={() =>
									sendConnectionRequest(
										props.userProfileId,
										"PENDING",
										props?.userProfileUUID
									)
								}
								disabled={connnectionRequestNote.length > 255 || isDisable}
								endIcon={<img src={send} alt="Send" />}
							>
								<Typography>
									{isDisable ? "Sending" : i18n.musicians.send}
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ConnectRequestDialog;
