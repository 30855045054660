import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';

const PresetDialogBox = (props: any) => {
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [inputValue, setInputValue] = useState<string | undefined>('');
    
    useEffect(() => {
        // console.log("check",props.selectedPreset, inputValue);
        if(props.selectedPreset !== 'new'){
            setInputValue(props.selectedPreset)
        }else{
            setInputValue('')
        }
    },[props.key]);

    const handleClose = () => {
        if (props.selectedPreset !== 'new') {
            setInputValue(props.selectedPreset)
        } else {
            setInputValue('')
        }
        props.handlePresetDialogClose();
        
    };

    const handleInputChange = (e: any) => {
        setInputValue(e.target.value);
    }

   
    const handleSubmit = (event: any) => {
        event.preventDefault();
        props.handleSubmitPreset(inputValue);
        handleClose();
    };

    const getTitle = () => {
        if(props.type === "new"){
            return "Create New Preset";
        } else if(props.type === "update") {
            return "Update Preset";
        } else if(props.type === "delete") {
            return "Delete Preset";
        }
    }

    const getSaveTitle = () => {
        if(props.type === "new")
            return "Create";
        else if(props.type === "update")
            return "Update";
        else if(props.type === "delete")
            return "Delete";
    }

    const getcontentTitle = () => {
        if(props.type === "new")
            return "Preset Name";
        else if (props.type === "update")
            return "Edit Name (optional)"
    }




    useEffect(() => {
        if(inputValue === undefined || inputValue.length === 0){
            setIsSaveDisabled(true);
        } else {
            setIsSaveDisabled(false);
        }
    },[inputValue, props.type]);

    useEffect(() => {
        if(props.type === "delete" && props.selectedPreset){
            setIsSaveDisabled(false);
        }
    },[props.type, props.selectedPreset]);

    useEffect(() => {
        if(props.type === "update" && props.selectedPreset) {
            setInputValue(props.selectedPreset);
        }
    },[props.type, props.selectedPreset]);

  return (
      <Dialog
          sx={{
              border: "1px solid #888888",
              boxShadow: "customColor.dropShadow",
              borderRadius: "8px",
          }}
          fullWidth
          maxWidth="xs"
          open={props.openPresetDialogBox}
      >
          <DialogTitle
              className="edit-heading-name"
              sx={{
                  display: "flex",
                  flexDirection: "column",
              }}
          >
              <Box
                  sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                  }}
              >
                  <Typography
                      sx={{ fontWeight: 500, color: "#000000", fontSize: "20px" }}
                  >
                      {getTitle()}
                  </Typography>
              </Box>
          </DialogTitle>
          <form onSubmit={(e) => handleSubmit(e)}>
              <DialogContent sx={{ mx: 3 }}>
                {(props.type === "new" || props.type === "update") && (
                      <Box sx={{display: "flex", flexDirection: "column", textAlign: "left", alignItems: "center", justifyContent: "center"}}>
                          {props.type === "update" && (
                              <Typography variant="subtitle1" sx={{ textAlign: "center", padding: "0px 0px 20px 0px", color: "#7A7985" }}>
                                      Do you want to Update this preset with new values of each channel?
                                </Typography>
                            )}
                        <Grid>
                              <Typography variant="subtitle1" sx={{ textAlign: "start", color: "#7A7985" }}>{getcontentTitle()}</Typography>

                              <input
                                  type="text"
                                  className='preset-input'
                                  value={inputValue}
                                  onChange={(e) => handleInputChange(e)}
                                  maxLength={14}  // Optional: limits input to 14 characters
                              />

                              <Typography
                                  variant="caption"
                                  style={{ marginTop: '4px', display: 'block', color: "#4D4C4F" }}
                              >
                                  14 characters
                              </Typography>
                        </Grid>

                      </Box>
                )}

                {
                    props.type === "delete" && (
                          <Box sx={{ display: "flex", flexDirection: "column", textAlign: "left", alignItems: "center", justifyContent: "center" }}>
                          
                              <Typography variant="subtitle1" sx={{ textAlign: "center", padding: "0px 0px 20px 0px", color: "#7A7985" }}>
                                  Do you want to delete this preset?
                            </Typography>

                              <Typography variant="subtitle1" sx={{ textAlign: "center", fontWeight: "500", color: "black"}}>
                                  {props.selectedPreset}
                            </Typography>

                          </Box>
                    )
                }
              </DialogContent>

              <DialogActions>
                  <Grid
                      container
                      columnSpacing={1}
                      style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                      }}
                  >
                      <Grid item={true}>
                          <Button
                              className="cancel-edit-button"
                              size="large"
                              variant="outlined"
                              onClick={handleClose}
                          >
                             Cancel
                          </Button>
                      </Grid>

                      <Grid item={true}>
                          <Button
                              className="edit-button"
                              size="large"
                              variant="contained"
                              type="submit"
                            //   onClick={savePresetChange}
                              disabled={isSaveDisabled}
                          >
                             {getSaveTitle()}
                          </Button>
                      </Grid>
                  </Grid>
              </DialogActions>
          </form>
      </Dialog>
  )
}

export default PresetDialogBox