import {
	Card,
	CardContent,
	FormControlLabel,
	FormGroup,
	Grid,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import React, { useEffect } from "react";
import { useUser } from "../context/UserContext";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0273E6",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

export const NotificationSettings = () => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const [state, setState] = React.useState<any>({
		newConnectionRequest: false,
		doesLocalArtistsJoins: false,
		doesSomeoneCommentedOrLiked: false,
		isGigOpportunityinCity: false,
		isNewEventInCity: false,
		doesSomeoneMessagedToMe: false,
		isweeklysummary: true,
	});

	useEffect(() => {
		getDefaultConfiguration();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getDefaultConfiguration = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/get_user-notification-settings`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setState({
					...state,
					newConnectionRequest: data.connectionReq,
					doesSomeoneCommentedOrLiked: data.postActivities,
					doesLocalArtistsJoins: data.localArtistJoins,
					isGigOpportunityinCity: data.newGigInCity,
					isNewEventInCity: data.newEventNearMe,
					doesSomeoneMessagedToMe: data.message,
					isweeklysummary: data.weeklySummary,
				});
			})
			.catch((err) => {});
	};

	const handleConnectionReqChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		notificationType: string,
		stateKey: string
	) => {
		setState({
			...state,
			[stateKey]: !state[stateKey],
		});

		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-notification-settings/UpdateAllNotificationRequest`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					[notificationType]: state[stateKey] ? false : true,
				}),
			}
		).then((response) => {
			if (response.status >= 400) {
				throw new Error();
			}
		});
	};

	// const handleArtistsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	setState({
	// 		...state,
	// 		doesLocalArtistsJoins: !state.doesLocalArtistsJoins,
	// 	});
	// };

	return (
		<>
			<Grid container sx={{ marginBottom: "20px" }}>
				<Grid item={true} xs={9} className="centerPanelWidth">
					<Card>
						<CardContent>
							<Typography
								sx={{
									fontSize: "24px",
									display: "flex",
									alignItems: "center",
									fontWeight: "500",
									fontFamily: "Lexend",
									lineHeight: "30px",
									marginBottom: 2,
								}}
								color="#000000"
							>
								Notifications
							</Typography>
							<FormGroup sx={{ marginBottom: 2 }}>
								<FormControlLabel
									control={
										<IOSSwitch
											checked={state.newConnectionRequest}
											onChange={(e: any) =>
												handleConnectionReqChange(
													e,
													"connectionReq",
													"newConnectionRequest"
												)
											}
											sx={{ m: 1 }}
										/>
									}
									label={
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											New Connection Requests
										</Typography>
									}
								/>
								<FormControlLabel
									control={
										<IOSSwitch
											sx={{ m: 1 }}
											checked={state.doesLocalArtistsJoins}
											onChange={(e: any) =>
												handleConnectionReqChange(
													e,
													"localArtistJoins",
													"doesLocalArtistsJoins"
												)
											}
										/>
									}
									label={
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											Local Artists Join
										</Typography>
									}
								/>
								<FormControlLabel
									control={
										<IOSSwitch
											sx={{ m: 1 }}
											checked={state.doesSomeoneCommentedOrLiked}
											onChange={(e: any) =>
												handleConnectionReqChange(
													e,
													"postActivities",
													"doesSomeoneCommentedOrLiked"
												)
											}
										/>
									}
									label={
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											New Comments or Likes
										</Typography>
									}
								/>
								<FormControlLabel
									control={<IOSSwitch sx={{ m: 1 }} />}
									checked={state.isGigOpportunityinCity}
									onChange={(e: any) =>
										handleConnectionReqChange(
											e,
											"newGigInCity",
											"isGigOpportunityinCity"
										)
									}
									label={
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											New Gig in My City
										</Typography>
									}
								/>
								<FormControlLabel
									control={<IOSSwitch sx={{ m: 1 }} />}
									checked={state.isNewEventInCity}
									onChange={(e: any) =>
										handleConnectionReqChange(
											e,
											"newEventNearMe",
											"isNewEventInCity"
										)
									}
									label={
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											New Event Near Me
										</Typography>
									}
								/>
								<FormControlLabel
									control={<IOSSwitch sx={{ m: 1 }} />}
									checked={state.doesSomeoneMessagedToMe}
									onChange={(e: any) =>
										handleConnectionReqChange(
											e,
											"message",
											"doesSomeoneMessagedToMe"
										)
									}
									label={
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											New Message
										</Typography>
									}
								/>
							</FormGroup>
						</CardContent>
						<CardContent>
							<Grid container>
								<Grid item={true} xs={10}>
									<Typography
										sx={{
											fontSize: "18px",
											fontWeight: 500,
											lineHeight: "30px",
											fontFamily: "Lexend",
											color: "#000000",
											marginBottom: "5px",
										}}
									>
										Weekly Email Summary
									</Typography>
								</Grid>
								<Grid item={true} xs={2}>
									<FormControlLabel
										control={<IOSSwitch sx={{ m: 1 }} />}
										checked={state.isweeklysummary}
										onChange={(e: any) =>
											handleConnectionReqChange(
												e,
												"weeklySummary",
												"isweeklysummary"
											)
										}
										label=""
									/>
								</Grid>
							</Grid>
							<Card sx={{ marginBottom: "20px" }}>
								<CardContent>
									<FormGroup sx={{ marginBottom: 1 }}>
										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
												marginBottom: "15px",
											}}
										>
											Like/Comments on Posts
										</Typography>
										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
												marginBottom: "15px",
											}}
										>
											Connection Request
										</Typography>
										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
												marginBottom: "15px",
											}}
										>
											Local Artists Join
										</Typography>

										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
												marginBottom: "15px",
											}}
										>
											New Gig in My City
										</Typography>

										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											New Event Near Me
										</Typography>
									</FormGroup>
								</CardContent>
							</Card>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};
