import {
    Box,
    Typography,
    Grid,
    Button,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogActions,
    Switch,
    FormControlLabel,
} from "@mui/material";
import i18n from "../../l10n/strings.json";
import { useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import TickCircle from '../../images/tick-circle-white.svg';
import AddCircle from '../../images/add-circle.svg';
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from "react-router-dom";

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 34,
    height: 22,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 20,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0px 1px 14px 0px rgba(217, 231, 255, 0.77)',
        width: 16.89,
        height: 16.89,
        borderRadius: 9.21,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const ReportBlockUser = (props: any) => {

    const SelfDetails = useUser();
    const token = localStorage.getItem("sessionId");
    const [reasons, setReasons] = useState<any>('false');
    const [selectedButtons, setSelectedButtons] = useState<any>([]);
    const [isReport, setIsReport] = useState(false)
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertStatus, setAlertStatus] = useState(false);
    const navigate = useNavigate();
    

    useEffect(() => {
        const isObjectEmpty = (obj: any) => {
            return Object.keys(obj).length !== 0;
        }
        const selectedViolations = Object.keys(props.loggedInUsersReportsOnViewingUserProfile).filter((key: any) => props.loggedInUsersReportsOnViewingUserProfile[key])
        isObjectEmpty(props.loggedInUsersReportsOnViewingUserProfile) && setSelectedButtons(selectedViolations)
    }, [props])

    const handleReason = (event: any) => {
        setIsReport(event.target.checked);
    }

    const getViolations = () => {
        try {
            fetch(
                `${process.env.REACT_APP_BASE_URL}/api/master-violations/get-all`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((res) => {
                    if(res.status === 502){
                        navigate('/defaultPage')
                    } else if (res.status >= 400) {
                        ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
                        throw new Error("api_error");
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    setReasons(data);
                })
                .catch((err) => {
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    }
    useEffect(() => {
        props.open && getViolations()
    }, [props])
    const handleClick = (index: any) => {
        // Check if the button is already selected
        if (selectedButtons.includes(index)) {
            // If it's already selected, remove it from the array
            setSelectedButtons(selectedButtons.filter((item: any) => item !== index));
        } else {
            // If it's not selected, add it to the array
            setSelectedButtons([...selectedButtons, index]);
        }
    };
    const reportUser = (offenderUserProfileUUID: string) => {
        try {
            props.setLoading(true);
            fetch(
                `${process.env.REACT_APP_BASE_URL}/api/moderations/report`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        offenderUserProfileUUID: offenderUserProfileUUID,
                        postUUID: null,  // give post Id during reporting on post
                        violationTypes: selectedButtons,
                        commentId: null
                    }),
                }
            )
                .then((res) => {
                    if(res.status === 502){
                        navigate('/defaultPage')
                    } else if (res.status !== 200 && res.status !== 201) {
                        ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
                    } else {
                        props.handleClose()
                        setSelectedButtons([])
                        return res.json();
                    }
                })
                .then((data) => {
                })
                .catch((err) => {
                })
                .finally(() => {
                    props.setLoading(false);
                });
        } catch (error) {
            props.setLoading(false);
        }
    }

    const sendConnectionRequest = (requestTo: number, requestStatus: string, requestToUUID: string) => {
        try {
            props.setLoading(true);
            fetch(
                `${process.env.REACT_APP_BASE_URL}/api/user-connection-requests`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        requestFromUUID: SelfDetails?.userProfileUuid,		
                        requestToUUID: requestToUUID,
                        connectionStatus: requestStatus,
                        note: selectedButtons.join(',')
                    }),
                }
            )
                .then((res) => {
                    if(res.status === 502){
                        navigate('/defaultPage')
                    } else if (res.status > 400) {
                        throw new Error("api_error");
                    } else {
                        if (isReport) {
                            reportUser(requestToUUID)
                        }
                        props.getUserDetails();
                        return res.json();
                    }
                })
                .then((data) => {
                    props.getUserDetails();
                })
                .catch((err) => {
                    props.getUserDetails();
                })
                .finally(() => {
                    props.setLoading(false);
                });
        } catch (error) {
            props.getUserDetails();
            props.setLoading(false);
        }
    }

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    return (
        <>
            <ErrorAlertDisplay alertMsg={alertMsg} alertStatus={alertStatus} showAlert={showAlert} handleAlertClose={handleAlertClose} />
            <Dialog
                sx={{
                    border: "1px solid #888888",
                    boxShadow: "customColor.dropShadow",
                    borderRadius: "8px"
                }}
                fullWidth
                maxWidth="sm"
                // onClose={props.handleClose()}
                open={props.open}
            >
                <DialogTitle
                    //className="edit-heading-name"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}
                    >
                        <Typography className="card-title-custom-size"
                            sx={{
                                fontWeight: 500,
                                color: "#000000",
                            }}
                        >
                            Report/Block User
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container
                        xs={12}
                    >
                        <Grid item={true} xs={12}>
                            <Typography >
                                {props.userName} will no longer be able to:
                            </Typography>

                            <ul>
                                <li style={{ color: '#5D5D65' }}>See your posts on your timeline</li>
                                <li style={{ color: '#5D5D65' }}>Tag you</li>
                                <li style={{ color: '#5D5D65' }}>Invite you to Jam sessions</li>
                                <li style={{ color: '#5D5D65' }}>Message you</li>
                                <li style={{ color: '#5D5D65' }}>Add you as a friend</li>
                            </ul>

                            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#5D5D65', }, ml: "1px" }}
                                control={<AntSwitch checked={isReport} onChange={handleReason} />}
                                label="Also report the user for not following community guidelines" />

                            {isReport &&
                                <Box sx={{ backgroundColor: '#F8F8F8', my: 1, borderRadius: '6px', border: '1px solid #D7D7D7' }}>
                                    <Grid container columnGap={2} rowGap={1} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ py: 1, px: 4, }}>

                                        {reasons && reasons.map((item: any) =>
                                            <Button sx={{ border: '1px solid #0273E6', color: selectedButtons.includes(item.violationType) ? 'white' : '#0273E6', backgroundColor: selectedButtons.includes(item.violationType) ? '#0273E6' : '', '&:hover': { background: "#EEF6FF" } }}
                                                onClick={() => handleClick(item.violationType)}>
                                                <img src={selectedButtons.includes(item.violationType) ? TickCircle : AddCircle}
                                                    alt={selectedButtons.includes(item.violationType) ? 'TickCircle' : 'AddCircle'} height={'20px'} />&nbsp;
                                                {item.violationType}
                                            </Button>
                                        )}
                                        {/* {reasonsArray.map((item:any) =>
                                        <ReasonSelect item={item}  setReasonsList={setReasonsList} reasonsList={reasonsList} reasonsArray={reasonsArray} />
                                    )} */}
                                    </Grid>
                                </Box>
                            }

                            <Typography sx={{ color: '#999999', fontSize: '14px', fontWeight: 400, }}>
                                This user will be listed in your blocked user list in settings
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container
                        columnSpacing={1}
                        style={{
                            display: "flex", flexDirection: "row", justifyContent: "flex-end",
                        }}
                    >
                        <Grid item={true}>
                            <Button
                                className="cancel-button"
                                size="large"
                                variant="outlined"
                                onClick={props.handleClose}
                            >
                                {i18n.dialog.cancel}
                            </Button>
                        </Grid>
                        <Grid item={true}>
                            <Button
                                className="submit-button"
                                size="large"
                                variant="contained"
                                onClick={() => sendConnectionRequest(props.userProfileId, 'BLOCKED', props?.userProfileUUID)}
                            >
                                Block
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ReportBlockUser;