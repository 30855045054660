import { memo, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import MixerSlider from "../MixerSlider/MixerSlider";
import { useFullscreenModeContext } from "../../context/FullscreenModeContext";
import { useParticipantProperty } from "@daily-co/daily-react";
import { useDailyUtils } from "../Daily/Hooks/useDailyUtils";
import Pagination from '../Daily/Pagination/Pagination';
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import { normalizeAudioControls, syncAudioControls } from "./utils";
import { useDailyAppRoom } from "../Daily/Hooks/useDailyGlobalRoom";
import { isEqual } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		innerContainer: {
			background: "#093969",
			border: "1px solid #014D78",
			borderRadius: "12px",
			minWidth: "180px",
		},
		pagination: {
			"& .MuiPaginationItem-root": {
				color: "white",
				background: "grey",
				borderRadius: "20px",
			},
			"& :hover": {
				"& .Mui-selected": {
					color: "black",
					background: "white",
				},
			},
			"& .MuiPaginationItem-previousNext": {
				background: "black",
			},
		},
	})
);

const Mixer = (props: any) => {
	const classes = useStyles();
	const [checked, setChecked] =  useState(false);
	const { isFullscreenMode, isTabHidden } = useFullscreenModeContext();
	const { currentPageData, getUserByEmail, totalData } = useDailyUtils();
	const { sessionParticipantsVolumeDetails, setsessionParticipantsVolumeDetails} = useDailyAppRoom();
	const participantsCount = totalData?.length || 0;
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	const [userSessions, setUserSessions] = useState(
		JSON.parse(localStorage.getItem("userSession") ?? "")
	);

	useEffect(() => {
		syncAudioControls(twilioSessionId);
		WebsocketClientUSerStatus.on("syncAudioControls", onSyncAudioControlsOfAllUsersRecieved);
		return () => {
			WebsocketClientUSerStatus.off("syncAudioControls", onSyncAudioControlsOfAllUsersRecieved);
		}
	}, []);

	useEffect(()=>{
		props.mixer && syncAudioControls(twilioSessionId);
	}, [props.mixer])
	
	const RenderMixerSlider = memo((props: any) => {
		const { participantId } = props;
		const participant = getUserByEmail(useParticipantProperty(participantId, 'user_name'), false);
		const participantAudioDetails = sessionParticipantsVolumeDetails?.find((p: any) => p.email === participant?.email);
		// console.log("RenderMixerSlider", { participantId, participant, sessionParticipantsVolumeDetails, participantAudioDetails })
		return (
			<div className={classes.innerContainer} key={participant?.email}>
				<MixerSlider participant={participant} participantId={participantId} participantAudioDetails={participantAudioDetails} />
			</div>
		)
	});

	const onSyncAudioControlsOfAllUsersRecieved = (payloadData: any) => {
		const participantDetail = normalizeAudioControls(payloadData);
		// check if sessionParticipantsVolumeDetails is not same as participantDetail before saving
		// if (!isEqual(sessionParticipantsVolumeDetails, participantDetail)) {
			// console.log(`onAudioControlDetailsOfAllUsersRecieved and update from mixer for ${ userSessions.createdBy}`, { payloadData, participantDetail, sessionParticipantsVolumeDetails, time: new Date().toISOString() });
			setsessionParticipantsVolumeDetails(participantDetail);
			localStorage.setItem("participantVolumeDetails", JSON.stringify(participantDetail));
		// }
	}

	return (
		<div
			style={{
				display: props.mixer ? "flex" : "none",
				flexDirection: "column",
				width: "100%",
				height:
					isFullscreenMode || isTabHidden
						? `calc(100% - 70px)`
						: `calc(100% - 140px)`,
				top: isFullscreenMode || isTabHidden ? "70px" : "140px",
				zIndex: "10",
				left: 0,
				position: "fixed",
				backgroundColor: "#000000",
			}}
		>
			<div className="mixer-container">
				{currentPageData.map((participantId: string) => (
					<RenderMixerSlider key={participantId} participantId={participantId} />
				))}
			</div>
			<div className="mixer-footer">
				<Pagination participantsCount={participantsCount} />
			</div>
		</div>
	);
};

export default memo(Mixer);
