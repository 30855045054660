import { StatusType, ThresholdType } from "./Types/dailyTypes";
import WarningIcon from "../../images/alert_filled_warning.svg";
import ErrorIcon from "../../images/Alert-filled.svg";
import SuccessIcon from "../../images/green-profile.svg"

const drawerWidth = 320;


// State constants
const STATE_IDLE = 'STATE_IDLE';
const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_LEAVING = 'STATE_LEAVING';
const STATE_ERROR = 'STATE_ERROR';
const STATE_LEFT = 'STATE_LEFT';

const EMOJI_MAP: { [key: string]: string } = {
  fire: '🔥',
  squid: '🦑',
  laugh: '🤣',
  clap:"👏",
  heart: "❤️",
  smiling: "😍",
  wow: "😮",
  thumsup: "👍"
};

const dailyQualityInitialStatus = {
  call: { inProgress: true, status: '', icon: '', message: '' },
  network: { inProgress: true, status: '', icon: '', message: ''  },
  ws: { inProgress: true, status: '', icon: '', message: ''  },
  currentStep: 1
};


const iconMap: Record<ThresholdType, string> = {
  'low': WarningIcon,
  'warning': WarningIcon,
  'failed': ErrorIcon,
  'bad': ErrorIcon,
  'very-low': ErrorIcon,
  'good': SuccessIcon
};

const stringIconMap: Record<StatusType, string> = {
  'failed': "❌",
  'aborted': "❌",
  'bad': "🔴",
  'warning': "⚠️",
  'good': "✅",
  'passed': "✅"
};

const colorNameMap: Record<StatusType, string> = {
  'failed': "red",
  'aborted': "red",
  'bad': "red",
  'warning': "yellow",
  'good': "green",
  'passed': "green"
};


export {
  STATE_IDLE,
  STATE_JOINING,
  STATE_JOINED,
  STATE_LEAVING,
  STATE_ERROR,
  STATE_LEFT,
  drawerWidth,
  EMOJI_MAP,
  dailyQualityInitialStatus,
  iconMap,
  stringIconMap,
  colorNameMap
}

