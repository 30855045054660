import { useState, useEffect, useRef } from "react";
import {
	Outlet,
	useParams,
	useNavigate,
	useSearchParams,
	useLocation,
	Link,
} from "react-router-dom";
import {
	Box,
	Typography,
	Divider,
	Grid,
	Button,
	FormHelperText,
	TextField,
	CardMedia,
	Collapse,
	IconButton,
	CardActionArea,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Card,
	CardActions,
	CardContent,
	Dialog,
	DialogActions,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, styled } from "@mui/material/styles";
// contexts
import { useUser } from "../../context/UserContext";
import { useMusicianCounts } from "./MusicProvider/useMusicianCounts";
import SettingsAlert from "../../pages/SettingsAlert";
import i18n from "../../l10n/strings.json";

import ProfileAdd from "../../images/profile-add.svg";
import Peoples from "../../images/peoples.svg";
import Message from "../../images/message-text-blue.svg";
import PeopleRemove from "../../images/profile-remove-blue.svg";
import DefaultProfilePicture from "../../images/User square 198.png";
import Unblock from "../../images/profile-delete-blue.svg";
// import CloseIcon from "../images/close.svg";
import VerifyAccount from "../../dialogBox/VerifyAccount";
import { useMusiciansFilter } from "./MusicProvider/useMusicianFilter";
import send from "../../images/send.svg";
import RightArrow from "../../images/right arrow.svg";
import RemoveConnection from "../../images/close-circle-black.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Client } from "@twilio/conversations";
import { useAlertStatus } from "../../context/AlertStatusContext";
import {
	addConversation,
	checkUserByUniqueName,
	getToken,
	onlyCreateConversation,
} from "../Messages/api";
import { bindActionCreators } from "redux";
import { updateCurrentConversation } from "../../store/action-creators";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../store";
import CenterCircularProgress from "../CenterCircularProgress";

type RouteParams = {
	menuId: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		userCard: {
			margin: "1%",
			textAlign: "center",
			background: "#f8f8f8 !important",
			borderRadius: "10px",
			width: "198px",
			border: "1px solid #CFD9DF",
		},
		connectBtn: {
			fontWeight: "200 !important",
			color: "#0273E6 !important",
		},
		cardButtons: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
		},
	})
);

const UserCardShort = (props: any) => {
	const user = useUser();
	const textFieldRef = useRef<HTMLInputElement | null>(null);
	const { resetMusicianCount } = useMusicianCounts();
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { updateAlertStatus } = useAlertStatus();
	const { resetMusicianFilter, musicianFilter, setMusicianFilter } =
		useMusiciansFilter();
	const token = localStorage.getItem("sessionId");
	const [loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [requestLoading, setRequestLoading] = useState(false);
	const [connnectionRequestNote, setConnectionRequestNote] =
		useState<string>("");
	const [connnectionRequestPopup, setConnectionRequestPopup] =
		useState<boolean>(false);
	const [openUnblockDialog, setOpenUnblockDialog] = useState(false);
	const [dirtyPostText, setDirtyPosText] = useState(false);
	const [profileDetails, setProfileDetails] = useState(props.user);
	const [hideCardAfterAction, setHideCardAfterAction] =
		useState<string>("flex");
	const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);
	const { updateCurrentConversation, addNotifications, updateParticipants } =
		bindActionCreators(actionCreators, dispatch);
	const [isDisable, setIsDisable] = useState(false);
	const is1024To1224 = useMediaQuery(
		"(min-width: 800px) and (max-width:1024px)"
	);
	const isAbove1224 = useMediaQuery("(min-width:1024px)");

	// Determine the xs value based on custom media queries
	let xsValue = is1024To1224 ? 4 : isAbove1224 ? 3 : 12;

	if (
		(window.innerWidth === 1024 && window.innerHeight === 1366) ||
		(window.innerWidth === 1024 && window.innerHeight === 1259)
	) {
		xsValue = 4;
	} else {
		xsValue = 3;
	}
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [windowSize]);

	const handleClose = () => {
		setConnectionRequestNote("");
		setConnectionRequestPopup(false);
		setOpenUnblockDialog(false);
	};

	const toggleCardButtons = (
		requestFrom: number,
		requestTo: number,
		requestStatus: string
	) => {
		setConnectionRequestPopup(false);
		if (requestStatus === "PENDING") {
			setProfileDetails({
				...profileDetails,
				invited: true,
				requestFromId: requestFrom,
			});
		} else if (requestStatus === "WITHDRAWN") {
			setProfileDetails({
				...profileDetails,
				invited: false,
				requestFromId: null,
			});
			if (props.hideCardAfterAction) {
				setHideCardAfterAction("none");
			}
		} else if (requestStatus === "REMOVED") {
			setHideCardAfterAction("none");
			setRemoveConfirmation(false);
		} else if (requestStatus === "ACCEPTED") {
			if (props.hideCardAfterAction) {
				setHideCardAfterAction('none');
			}
			// if (props.isNotification) {
				setProfileDetails({
					...profileDetails,
					rejected: false,
					connected: true,
				});
			// }
		} else if (requestStatus === "REJECTED") {
			if (props.hideCardAfterAction) {
				setHideCardAfterAction("none");
			}
			// if (props.isNotification) {
				setProfileDetails({
					...profileDetails,
					rejected: true,
					connected: false,
					canSendConnection: true,
					invited:false,
				});
			// }
		}
	};

	// setMusicians({recentJoiners: userCardDetails, mostActive: userCardDetails, recommended: userCardDetails});
	const sendConnectionRequest = (
		requestFrom: number,
		requestTo: number,
		requestStatus: string,
		requestFromUUID: string | undefined,
		requestToUUID: string | undefined
	) => {
         
		try {
			setIsDisable(true);
			setRequestLoading(true);
			fetch(`${process.env.REACT_APP_BASE_URL}/api/user-connection-requests`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					requestFromUUID: requestFromUUID,
					requestToUUID: requestToUUID,
					connectionStatus: requestStatus,
					note: connnectionRequestNote,
				}),
			})
				.then((res) => {
					if (res.status === 502) {
						navigate("/defaultPage");
					} else if (res.status > 400) {
						setIsDisable(false);
						throw new Error("api_error");
					} else {
						setIsDisable(false);
						return res.json();
					}
				})
				.then((data) => {
					// props.refreshList();
					toggleCardButtons(requestFrom, requestTo, requestStatus);
				})
				.catch((err) => {})
				.finally(() => {
					setRequestLoading(false);
					resetMusicianCount();
					setIsDisable(false);
				});
		} catch (error) {
			setRequestLoading(false);
		}
	};

	const viewUserProfile = () => {
		// resetMusicianFilter();
		setMusicianFilter({
			...musicianFilter,
			isGlobalSearch: false,
			firstName: "",
			lastName: "",
			nickName: "",
		});
		const encodedNickName = encodeURIComponent(profileDetails.nickname);

		navigate(`/socialHome/` + encodedNickName);
	};

	const maxBioLength = 30;
	const maxname = 15;

	const formatNameWithMaxLength = (
		name: string | undefined,
		maxChars: number
	): string => {
		return name && name.length > maxChars
			? name.substring(0, maxChars) + "..."
			: name || "";
	};

	const sendMessage = async () => {
		setIsLoading(true);
		var conversationToken = localStorage.getItem('conversationToken');
        if (conversationToken === null || conversationToken === "") {
            conversationToken = await getToken(user.email, "");
            localStorage.setItem("conversationToken", conversationToken);
        }
		const client = new Client(conversationToken as string);
		let title = profileDetails.name;
		let uniqueName = [profileDetails.userEmail].sort().join("-");
		let uniqueNameForCheck = [profileDetails.userEmail, user.email]
			.sort()
			.join("-");

		// Before you use the client, subscribe to the `'initialized'` event.
		client.on("initialized", async () => {
			// const checkConversationExists = await client.getConversationByUniqueName(uniqueName);
			client
				.getConversationByUniqueName(uniqueNameForCheck)
				.then((conversationDetails) => {
					updateCurrentConversation(conversationDetails.sid);
					setIsLoading(false);
					navigate("/messages");
					// conversationDetails.sendMessage(gigMessage)
					// .then(async (convo) => {
					// 	updateAlertStatus(
					// 		true,
					// 		true,
					// 		"Applied for Gig successfully"
					// 	);
					// 	// setGigMessage("");
					// 	// setOpenSendMessage(false);
					// }).catch((error:any) => {
					// 	console.error('Error sending message:', error);
					// 	updateAlertStatus(
					// 		true,
					// 		false,
					// 		"Something went wrong"
					// 	);
					// });
				})
				.catch(async (error: any) => {
					if (error.message === "Not Found") {
						const convo = await onlyCreateConversation(
							title,
							uniqueName,
							updateParticipants,
							client,
							addNotifications,
							navigate,
							updateCurrentConversation
						);

						if (convo.sid) {
							const conversationDetails = await client.getConversationBySid(
								convo.sid
							);

							updateCurrentConversation(conversationDetails.sid);
							setIsLoading(false);
							navigate("/messages");

							// conversationDetails.sendMessage(gigMessage).then(() => {
							// 	updateAlertStatus(
							// 		true,
							// 		true,
							// 		"Applied for Gig successfully"
							// 	);
							// 	// setGigMessage("");
							// 	// setOpenSendMessage(false)
							// }).catch((error:any) => {
							// 	console.error('Error sending message:', error);
							// });
						}
					} else {
						updateAlertStatus(true, false, "Something went wrong");
					}
				});
		});
	};

	useEffect(() => {
		if (connnectionRequestPopup) {
			setTimeout(() => {
				const inputField = document.getElementById(
					"myInput"
				) as HTMLInputElement | null;
				if (inputField) {
					inputField.focus();
				}
			}, 500);
		}
	}, [connnectionRequestPopup]);

	// if (isLoading) {
	// 	return <CenterCircularProgress />
	// }

	return (
		<>
			{loading ? (
				<Grid container item justifyContent="center" sx={{ p: "16px" }}>
					<CircularProgress /> {/* The loading spinner */}
				</Grid>
			) : (
				<>
					{/* Please check every alignment in the testing */}

					<Grid
						item
						xs={xsValue}
						sx={{
							marginBottom: "12px",
							justifyContent: "left",
							display: hideCardAfterAction,
						}}
					>
						<Grid className={`${classes.userCard} userCardShorts`}>
							{/* PROFILE IMAGE & USERNAME & BIO */}
							<Grid container sx={{ height: "75%", width: 198 }}>
								<CardActionArea
									sx={{
										flex: 1,
										borderTopLeftRadius: "10px",
										borderTopRightRadius: "10px",
									}}
								>
									<Grid item sx={{ height: "70%", position: "relative" }}>
										<CardMedia
											component="img"
											height="100%"
											image={
												profileDetails.profilePictureUrl !== null &&
												profileDetails.profilePictureUrl !== ""
													? process.env.REACT_APP_PICTURE_URL +
													  profileDetails.profilePictureUrl
													: DefaultProfilePicture
											}
											alt={profileDetails.name}
											sx={{
												borderTopLeftRadius: "10px",
												borderTopRightRadius: "10px",
												"&.MuiCardMedia-root": {
													height: "198px",
													objectFit: "unset",
												},
											}}
											onClick={viewUserProfile}
										/>
										{!profileDetails.isBlockedByMe &&
											profileDetails.connected === true && (
												<button
													style={{
														position: "absolute",
														top: 0,
														right: 0,
														display: "flex",
														alignItems: "center",
														padding: "5px",
														border: "none",
														background: "transparent",
														// zIndex:"9999",
														cursor: "pointer",
													}}
													title="Remove Connection"
													onClick={() => setRemoveConfirmation(true)}
													disabled={user.userAccountStatus != "ACTIVE"}
												>
													<img
														src={RemoveConnection}
														style={{
															marginRight: "5px",
															width: "24px",
															height: "24px",
														}}
														alt=""
													/>
												</button>
											)}
									</Grid>

									{/* UserName & Bio */}
									<Grid item sx={{ height: "30%" }} onClick={viewUserProfile}>
										<CardContent
											style={{
												flex: 1,
												marginTop: "0.1rem",
												padding: "7px 11px 16px 15px",
											}}
										>
											<Typography variant="h6" component="b">
												{/* {profileDetails.name && profileDetails.name?.length > maxname
                                                ? profileDetails.name?.substring(0, maxname) + "..."
                                                : profileDetails.name} */}
												{profileDetails?.userProfileId === user?.userProfileId
													? formatNameWithMaxLength(
															profileDetails?.name,
															maxname
													  )
													: profileDetails?.userPrivacySettingsDTO
															?.identityPermission ===
													  "Name is not visible. Instead just show nickname"
													? formatNameWithMaxLength(
															`@` + profileDetails?.nickname,
															maxname
													  )
													: profileDetails?.userPrivacySettingsDTO
															?.identityPermission === "Name is visible to all"
													? formatNameWithMaxLength(
															profileDetails?.name,
															maxname
													  )
													: profileDetails?.userPrivacySettingsDTO
															?.identityPermission ===
													  "Name is visible only to my connections. Other see nickname"
													? profileDetails?.connected
														? formatNameWithMaxLength(
																profileDetails?.name,
																maxname
														  )
														: formatNameWithMaxLength(
																`@` + profileDetails?.nickname,
																maxname
														  )
													: formatNameWithMaxLength(
															profileDetails?.name,
															maxname
													  )}
											</Typography>

											<Typography
												variant="body2"
												color="text.secondary"
												sx={{
													maxWidth: "90%",
													overflow: "hidden",
													display: "-webkit-box",
													WebkitBoxOrient: "vertical",
													WebkitLineClamp: 2,
													height: "36px",
													marginTop: "5px",
													marginBottom: "0px",
												}}
											>
												{profileDetails.profileTitle &&
													profileDetails.profileTitle}
												{/* {profileDetails.profileTitle && profileDetails.profileTitle?.length > maxBioLength
                                                ? profileDetails.profileTitle?.substring(0, maxBioLength) + "..."
                                                : profileDetails.profile} */}
											</Typography>
										</CardContent>
									</Grid>
								</CardActionArea>
							</Grid>

							{/* Connection Actions */}
							<Grid
								item
								sx={{ height: "25%", alignItems: "center", marginTop: "10px" }}
							>
								{!profileDetails.isBlockedByMe &&
								profileDetails.connected === true ? (
									<Link to={`mutuals/${profileDetails.userProfileId}`}>
										<Typography>
											<img
												src={Peoples}
												alt="Mutual Connections"
												style={{
													verticalAlign: "bottom",
													margin: "0 5px",
													padding: "0",
													width: "18px",
													height: "18px",
												}}
											/>
											<span style={{ fontSize: "0.8rem" }}>
												{profileDetails.noOfMutualConnections} mutual
											</span>
										</Typography>
									</Link>
								) : (
									<Typography>
										<img
											src={Peoples}
											alt="Connections"
											style={{
												verticalAlign: "bottom",
												margin: "0 5px",
												padding: "0",
												width: "18px",
												height: "18px",
											}}
										/>
										<span style={{ fontSize: "0.8rem" }}>
											{profileDetails.totalConnections} Connections
										</span>
									</Typography>
								)}
								<CardActions>
									<Grid container xs={12}>
										{requestLoading ? (
											<>
												<Grid xs={12} container item justifyContent="center">
													{/* <Button className={classes.connectBtn} startIcon={<img src={PeopleRemove} alt="Connect" />} onClick={() => sendConnectionRequest(user.userProfileId, profileDetails.userProfileId, 'WITHDRAWN')} >
                                                        <span style={{ fontSize: "1rem" }}>Withdraw</span>
                                                    </Button> */}
													<CircularProgress />
												</Grid>
											</>
										) : !profileDetails.isBlockedByMe &&
										  profileDetails.connected === true ? (
											<Grid container item xs={12}>
												{profileDetails.userPrivacySettingsDTO
													.messagePermission !==
													"No one can send me messages" && (
													<Button
														sx={{
															border: "1.5px solid #0273E6",
															padding: "0",
															margin: "4px  0",
															// filter: "brightness(0.1)",
															height: "28px",
															color: "#0273E6",
															width: "100%",
															backgroundColor: "#fff",
														}}
														// size="small"
														onClick={sendMessage}
														disabled={user.userAccountStatus != "ACTIVE"}
													>
                                                    {isLoading ? (
                                                        <CircularProgress size={18} />
                                                        ) : (
                                                        <>
                                                            <img src={Message} alt="Message" style={{ width: "18px" }} />
                                                            <span style={{ fontSize: "0.8rem" }}>Message</span>
                                                            </>
                                                    )}
													</Button>
												)}

												{/* <Grid item xs={6}>
                                                        <Button className={classes.connectBtn} size="small" startIcon={<img src={PeopleRemove} alt="Remove" />} onClick={() => setRemoveConfirmation(true) } disabled={user.userAccountStatus != "ACTIVE" }>
                                                            <span style={{ fontSize: "0.8rem" }}>Remove</span>
                                                        </Button>

                                                    </Grid> */}
											</Grid>
										) : !profileDetails.isBlockedByMe &&
										  profileDetails.canSendConnection &&
										  //   profileDetails.rejected === false &&
										  profileDetails.connected === false &&
										  profileDetails.invited === false &&
										  profileDetails.userProfileId !== user.userProfileId ? (
											<Grid container item xs={12}>
												{/* <Grid item xs={12} md={6} sx={{ padding: '0' }}> */}
												<Button
													className={classes.connectBtn}
													sx={{
														border: "1.5px solid #0273E6",
														padding: "0",
														margin: "4px  0",
														// filter: "brightness(0.1)",
														height: "28px",
														color: "#0273E6",
														width: "100%",
														backgroundColor: "#fff",
													}}
													startIcon={
														<img
															src={ProfileAdd}
															alt="Connect"
															style={{ width: "18px" }}
														/>
													}
													onClick={() => setConnectionRequestPopup(true)}
													disabled={
														!profileDetails.canSendConnection ||
														user.userAccountStatus != "ACTIVE"
													}
												>
													<span style={{ fontSize: "0.8rem" }}>Connect</span>
												</Button>
												{/* </Grid> */}

												{/* <Grid item xs={12} md={6} sx={{ padding: '0' }}>
                                                            {
                                                                // (profileDetails.noOfMutualConnections && profileDetails.noOfMutualConnections > 0) ?
                                                                <Link to={`mutuals/${profileDetails.userProfileId}`}>
                                                                    <Typography align="right">
                                                                        <img src={Peoples} alt="Mutual Connections" style={{ verticalAlign: "bottom", margin: "0 5px", padding: '0' }} />
                                                                        <span style={{ fontSize: "1rem" }}>{profileDetails.noOfMutualConnections}</span>
                                                                    </Typography>
                                                                </Link>
                                                                // : ""
                                                            }
                                                        </Grid> */}
											</Grid>
										) : !profileDetails.isBlockedByMe &&
										  profileDetails.invited === true &&
										  profileDetails.requestFromId === user.userProfileId ? (
											<>
												<Grid item xs={12}>
													<Button
														className={classes.connectBtn}
														sx={{
															border: "1.5px solid #0273E6",
															padding: "0",
															margin: "4px  0",
															// filter: "brightness(0.1)",
															height: "28px",
															color: "#0273E6",
															width: "100%",
															backgroundColor: "#fff",
														}}
														startIcon={
															<img
																src={PeopleRemove}
																alt="Withdraw"
																style={{ width: "18px" }}
															/>
														}
														onClick={() =>
															sendConnectionRequest(
																user.userProfileId,
																profileDetails.userProfileId,
																"WITHDRAWN",
																user.userProfileUuid,
																profileDetails.userProfileUUID
															)
														}
														disabled={
															user.userAccountStatus !== "ACTIVE" || isDisable
														}
													>
														<span style={{ fontSize: "0.8rem" }}>Withdraw</span>
													</Button>
												</Grid>

												{/* <Grid item xs={6}>
                                                    {
                                                        (profileDetails.noOfMutualConnections && profileDetails.noOfMutualConnections > 0) ?
                                                            <Typography align="right">
                                                                <img src={Peoples} alt="Mutual Connections" style={{verticalAlign: "bottom", margin: "0 5px"}}/>
                                                                <span style={{fontSize: "80%"}}>{profileDetails.noOfMutualConnections}</span>
                                                            </Typography>
                                                        : ""
                                                    }
                                                </Grid> */}
											</>
										) : !profileDetails.isBlockedByMe &&
										  profileDetails.invited === true &&
										  profileDetails.requestFromId !== user.userProfileId ? (
											<Grid container sx={{ alignItems: "center" }}>
												<Grid item xs={6}>
													<Button
														className={classes.connectBtn}
														sx={{
															border: "1.5px solid #0273E6",
															padding: "0",
															margin: "4px  0",
															// filter: "brightness(0.1)",
															height: "28px",
															color: "#0273E6",
															width: "95%",
															backgroundColor: "#fff",
														}}
														startIcon={
															<img
																src={ProfileAdd}
																alt="Accept"
																style={{ width: "18px" }}
															/>
														}
														onClick={() =>
															sendConnectionRequest(
																profileDetails?.userProfileId,
																user?.userProfileId,
																"ACCEPTED",
																profileDetails?.userProfileUUID,
																user?.userProfileUuid
															)
														}
														disabled={
															user.userAccountStatus !== "ACTIVE" || isDisable
														}
													>
														<span style={{ fontSize: "0.8rem" }}>Accept</span>
													</Button>
												</Grid>

												<Grid item xs={6}>
													<Button
														className={classes.connectBtn}
														sx={{
															border: "1.5px solid #0273E6",
															padding: "0",
															margin: "4px  0",
															// filter: "brightness(0.1)",
															height: "28px",
															color: "#0273E6",
															width: "95%",
															backgroundColor: "#fff",
														}}
														startIcon={
															<img
																src={ProfileAdd}
																alt="Reject"
																style={{ width: "18px" }}
															/>
														}
														onClick={() =>
															sendConnectionRequest(
																profileDetails?.userProfileId,
																user?.userProfileId,
																"REJECTED",
																profileDetails?.userProfileUUID,
																user?.userProfileUuid
															)
														}
														disabled={
															user.userAccountStatus !== "ACTIVE" || isDisable
														}
													>
														<span style={{ fontSize: "0.8rem" }}>Reject</span>
													</Button>
												</Grid>
											</Grid>
										) : profileDetails.isBlockedByMe ? (
											<>
												{/* <Grid item xs={6}>
                                                </Grid> */}

												<Grid item xs={12}>
													{/* <Button variant="contained" startIcon={<img src={PeopleRemove} alt="Reject" />} onClick={() => setOpenUnblockDialog(true)} >
                                                        <span>Unblock</span>
                                                    </Button> */}

													<Button
														className={classes.connectBtn}
														startIcon={<img src={Unblock} alt="Unblock" />}
														onClick={() => setOpenUnblockDialog(true)}
														disabled={user.userAccountStatus !== "ACTIVE"}
													>
														<span style={{ fontSize: "1rem" }}>Unblock</span>
													</Button>
												</Grid>

												<VerifyAccount
													open={openUnblockDialog}
													handleClose={handleClose}
													unblock={true}
													toUnblockUser={{
														userProfile: { id: user.userProfileId },
														blockedUser: profileDetails.userProfileId,
                                                        blockedUserUUID: profileDetails.userProfileUUID
													}}
													refreshList={props.refreshList}
													source="musicianList"
												/>

												{/* <Grid item xs={6}>
                                                    <Button className="submit-button" variant="contained" startIcon={<img src={TickCircleOrange} alt="Connect" />} onClick={() => sendConnectionRequest(profileDetails.userProfileId, 'ACCEPTED')} >
                                                        <span >Accept</span>
                                                    </Button>
                                                </Grid> */}
											</>
										) : (
											<></>
										)}
									</Grid>
								</CardActions>
							</Grid>
						</Grid>
					</Grid>

					<Dialog
						sx={{
							border: "1px solid #888888",
							boxShadow: "customColor.dropShadow",
							borderRadius: "8px",
						}}
						fullWidth
						maxWidth="sm"
						onClose={() => setConnectionRequestPopup(false)}
						open={connnectionRequestPopup}
					>
						<DialogTitle
							className="edit-heading-name"
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							{/* <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IconButton
                                    aria-label="close dialogbox"
                                    onClick={props.onClose}
                                    onMouseDown={(
                                        event: React.MouseEvent<HTMLButtonElement>
                                    ) => event.preventDefault()}
                                    edge="end"
                                    sx={{ marginLeft: "90%" }}>
                                    <img
                                        src={CloseIcon}
                                        alt="close"
                                    />
                                </IconButton>
                            </Box> */}
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
								}}
							>
								<Typography
									className="dialog-title-custom-size"
									sx={{
										fontWeight: 500,
										color: "#000000",
									}}
								>
									{i18n.musicians.connection.title}
								</Typography>
							</Box>
						</DialogTitle>
						<DialogContent>
							<Grid
								container
								xs={12}
								// columnSpacing={2}
							>
								<Grid item={true} xs={12}>
									<FormHelperText className="jamsession-label">
										{i18n.musicians.connection.label}
									</FormHelperText>
									<TextField
										id="myInput"
										className="social-textField"
										inputProps={{
											style: {
												fontStyle: "normal",
												fontSize: "1.125rem",
												lineHeight: "25px",
												fontWeight: 900,
												color: "#000000",
											},
										}}
										sx={{ background: "#F8F8F8" }}
										fullWidth
										// multiline
										variant="outlined"
										value={connnectionRequestNote}
										multiline
										rows={2}
										maxRows={10}
										onChange={(event) =>
											setConnectionRequestNote(event.target.value.trimStart())
										}
										error={connnectionRequestNote.length > 255}
										onFocus={() => setDirtyPosText(true)}
									></TextField>
									<FormHelperText
										sx={{
											fontSize: "0.8125rem",
											color: "#FD4B24",
											textAlign: "right",
										}}
									>
										{connnectionRequestNote?.length > 255
											? "Enter maximum 255 characters"
											: ""}
									</FormHelperText>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Grid
								container
								columnSpacing={1}
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-end",
								}}
							>
								<Grid item={true}>
									<Button
										className="cancel-button"
										size="large"
										// endIcon={<img src={RightAarrow} alt="Right Arrow" />}
										variant="outlined"
										onClick={handleClose}
									>
										{i18n.dialog.cancel}
									</Button>
								</Grid>
								<Grid item={true}>
									<Button
										className="submit-button"
										size="large"
										variant="contained"
										// disabled={serialNumber === "" || purchaseDate === null || (purchaseDate !== null && purchaseDate >= new Date())}
										onClick={() =>
											sendConnectionRequest(
												user.userProfileId,
												profileDetails.userProfileId,
												"PENDING",
												user.userProfileUuid,
												profileDetails.userProfileUUID
											)
										}
										disabled={connnectionRequestNote.length > 255 || isDisable}
										endIcon={<img src={send} alt="Send" />}
									>
										<Typography>
											{isDisable ? "Sending" : i18n.musicians.send}
										</Typography>
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Dialog>

					<Dialog
						sx={{
							border: "1px solid #888888",
							boxShadow: "customColor.dropShadow",
							borderRadius: "8px",
						}}
						fullWidth
						maxWidth="xs"
						onClose={() => setRemoveConfirmation(false)}
						open={removeConfirmation}
					>
						<DialogTitle
							className="edit-heading-name"
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
								}}
							>
								<Typography
									className="dialog-title-custom-size"
									sx={{
										fontWeight: 500,
										color: "#000000",
										fontSize: "24px !important",
									}}
								>
									{i18n.musicians.connection.removeConnectionTitle}working
								</Typography>
							</Box>
						</DialogTitle>
						<DialogContent>
							<Grid
								container
								xs={12}
								// columnSpacing={2}
							>
								<Typography
									className="dialog-title-custom-sizea"
									variant="h4"
									sx={{
										fontWeight: 500,
										color: "#000000",
										fontSize: "16px !important",
										textAlign: "center",
									}}
								>
									{/* {i18n.musicians.connection.removeConfirmationTitle}  {profileDetails?.userProfileId === user?.userProfileId
                                                    ? formatNameWithMaxLength(profileDetails?.name, maxname)
                                                    : profileDetails?.userPrivacySettingsDTO?.identityPermission ===
                                                    'Name is not visible. Instead just show nickname'
                                                    ? formatNameWithMaxLength(`@`+profileDetails?.nickname, maxname)
                                                    : profileDetails?.userPrivacySettingsDTO?.identityPermission ===
                                                    'Name is visible to all'
                                                    ? formatNameWithMaxLength(profileDetails?.name, maxname)
                                                    : profileDetails?.userPrivacySettingsDTO?.identityPermission ===
                                                    'Name is visible only to my connections. Other see nickname'
                                                    ? profileDetails?.connected
                                                    ? formatNameWithMaxLength(profileDetails?.name, maxname)
                                                    : formatNameWithMaxLength(`@`+profileDetails?.nickname, maxname)
                                                    :  formatNameWithMaxLength(profileDetails?.name, maxname)} ? */}
									{i18n.musicians.connection.removeConfirmationTitle}{" "}
									{profileDetails?.userProfileId === user?.userProfileId
										? profileDetails?.name
										: profileDetails?.userPrivacySettingsDTO
												?.identityPermission ===
										  "Name is not visible. Instead just show nickname"
										? `@` + profileDetails?.nickname
										: profileDetails?.userPrivacySettingsDTO
												?.identityPermission === "Name is visible to all"
										? profileDetails?.name
										: profileDetails?.userPrivacySettingsDTO
												?.identityPermission ===
										  "Name is visible only to my connections. Other see nickname"
										? profileDetails?.connected
											? profileDetails?.name
											: `@` + profileDetails?.nickname
										: profileDetails?.name}{" "}
									?
								</Typography>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Grid
								container
								columnSpacing={1}
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
								}}
							>
								<Grid item={true}>
									<Button
										className="cancel-button"
										size="medium"
										sx={{
											fontSize: "18px",
										}}
										// endIcon={<img src={RightAarrow} alt="Right Arrow" />}
										variant="outlined"
										onClick={() => setRemoveConfirmation(false)}
									>
										{i18n.dialog.cancel}
									</Button>
								</Grid>
								<Grid item={true}>
									<Button
										className="submit-button"
										// sx={{
										//     fontSize: "18px",
										// }}
										size="small"
										variant="contained"
										// disabled={serialNumber === "" || purchaseDate === null || (purchaseDate !== null && purchaseDate >= new Date())}
										onClick={() =>
											sendConnectionRequest(
												user.userProfileId,
												profileDetails.userProfileId,
												"REMOVED",
												user.userProfileUuid,
												profileDetails.userProfileUUID
											)
										}
										// disabled={connnectionRequestNote.length > 255}
										// endIcon={<img src={RightArrow} alt="Down Arrow" />}
									>
										<Typography sx={{ marginRight: "0px !important " }}>
											{i18n.musicians.confirm}
										</Typography>
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Dialog>
				</>
			)}
		</>
	);
};

export default UserCardShort;
