import { useState, useEffect, MouseEventHandler } from "react";
import * as React from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	ListItem,
	List,
	Grid,
	CircularProgress,
	FormControlLabel,
	FormControl,
	Modal,
	Typography,
	Divider,
	Box,
	Tabs,
	Radio,
	RadioGroup,
	Autocomplete,
	TextField,
	InputAdornment,
} from "@mui/material";
import Tab, { tabClasses } from "@mui/material/Tab";
import ErrorAlert from "../Error/ErrorAlert";
import { useUser } from "../../context/UserContext";
import { RecordingInterface } from "../../types/types";
import SearchIcon from "@mui/icons-material/Search";

import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import MusicList from "./musicList";
import { useAlertStatus } from "../../context/AlertStatusContext";
import { error } from "console";
import User from "../../services/user";

const SetProfileMusicDialog = (props: any) => {
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const [music, setMusic] = useState(props.musicTemp);
	const [musicUUID, setMusicUUID] = useState("");

	const [selectedTab, setSelectedTab] = useState(0);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [musicList, setMusicList] = useState([]);

	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [isMusicLoading, setIsMusicLoading] = useState(false);
	const [isProfileMusicLoading, setIsProfileMusicLoading] = useState(false);
	const [isMusicUploaded, setIsMusicUploaded] = useState(false);

	const inputFileRef = React.useRef<any>(null);
	const { updateAlertStatus } = useAlertStatus();
	const ALLOWED_FILE_EXT = ["mp3", "wav", "mpeg"];
	const [isLoading, setIsLoading] = useState(false);
	const [duration, setDuration] = useState<any>("");

	const handleChangeMusic = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMusic(event.target.value);
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleClose = () => {
		setMusic(initialSelectedValue);
		setMusicUUID(initialSelectedUUIDValue)
		props.handleClose(false);
	};

	const handleSaveMusic = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/mymusic/profileMusic`,
			{
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					myMusicUuid: musicUUID,
				}),
			}
		).then((res) => {
			if (res.status !== 200) {
				ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				handleClose();
			}
			if (res.status === 500) {
				props.getUserDetails();
				handleClose();
				return res.json();
			} else {
				props.getUserDetails();
				//handleClose()
				return res.json();
			}
		});

		// props.setMusicTemp(music.musicUrl)
	};
	const handleChange = (tabId: any) => {
		if (initialSelectedValue) {
			if (initialSelectedValue === tabId) {
				setMusic("");
			} else {
				setInitialSelectedValue(tabId);
				setMusic(tabId);
			}
		} else {
			setInitialSelectedValue(tabId);
			setMusic(tabId);
		}
		// setSelectedTab(tabId);
	};

	const handleChangeuuid = (uuid: any) => {
		if (initialSelectedUUIDValue) {
			if (initialSelectedUUIDValue === uuid) {
				setMusicUUID("");
			} else {
				setInitialSelectedUUIDValue(uuid);
				setMusicUUID(uuid);
			}
		} else {
			setInitialSelectedUUIDValue(uuid);
			setMusicUUID(uuid);
		}
		// setSelectedTab(tabId);
	};

	const handleClickMusic =
		(id: any, uuid:any): MouseEventHandler<HTMLLabelElement> =>
		(event: any) => {
			handleChange(id)
			handleChangeuuid(uuid);
		};

	const fetchMusicList = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/getAllMusic/filter`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				folderName: selectedTab === 0 ? "MyMusic" : "Recording",
			}),
		})
			.then((res) => {
				if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setMusicList(data);
				// setIsLoading(false);
			})
			.catch((err) => {
				// setShowAlert(true);
				// setAlertStatus(false);
				// setAlertMsg("Network Error! Please try again..");
				// setIsLoading(false);
			});
	};

	useEffect(() => {
		props.musicOpen && fetchMusicList();
	}, [props, selectedTab, music, musicUUID]);

	const [searchText, setSearchText] = useState("");
	const filteredItems =
		musicList &&
		musicList.length > 0 &&
		musicList.filter((item: any) =>
			item.fileName.toLowerCase().includes(searchText.toLowerCase())
		);

	const handleSearchChange = (event: any) => {
		setSearchText(event.target.value);
	};
	//const initialSelectedValue = props.selectedMusicId;
	const [initialSelectedValue, setInitialSelectedValue] = useState(
		props.selectedMusicId
	);

	const [initialSelectedUUIDValue, setInitialSelectedUUIDValue] = useState(
		props.selectedMusicUUID
	);

	useEffect(() => {
		// Set the initial value when the component mounts
		setMusic(initialSelectedValue);
		setMusicUUID(initialSelectedUUIDValue)
	}, []);
	const [subscriptions, setSubscriptions] = useState<any[]>([]);
	const [userStorageCapacity, setUserStorageCapacity] = useState();
	const [userStorageUsage, setUserStorageUsage] = useState();

	const handleOpenEditor = (e: any) => {
		e.preventDefault();

		const targetFiles = e.target.files[0];

		if (targetFiles.type.startsWith("audio/")) {
			if (targetFiles) {
				const audioUrl = URL.createObjectURL(targetFiles);
				const audio = new Audio(audioUrl);

				audio.addEventListener("loadedmetadata", () => {
					let formData = new FormData();
					formData.append("files", targetFiles);

					// return false;
					let ext = targetFiles.type.split("/").pop();
					//Convert bytes to MB & Round to the nearest integer
					var roundedFileSizeInMB = Math.round(
						targetFiles.size / (1024 * 1024)
					);
					// Convert MB back to bytes
					const roundedFileSizeInBytes = roundedFileSizeInMB * (1024 * 1024);
					if (roundedFileSizeInMB > 100) {
						updateAlertStatus(
							true,
							false,
							"File size limited to 100 MB per audio file. Please save in another format or divide into multiple files and try again."
						);
						return; // Stop further execution if file size is too large
					}
					const sUsage = userStorageUsage ? userStorageUsage : 0;
					const targetfileAfterupload = sUsage + roundedFileSizeInMB;
					const sCapacity = userStorageCapacity ? userStorageCapacity : 0;

					if (storeFull || sCapacity < targetfileAfterupload) {
						updateAlertStatus(
							true,
							false,
							"Music size exceeds the maximum storage limit. Please select other file or buy storage."
						);
					}
					// else  if (roundedFileSizeInBytes > (10 * 1024 * 1024)) {
					//     updateAlertStatus(true, false, 'Music size exceeds the maximum allowed limit of 10Mb. Please try again.');
					// }
					else if (ALLOWED_FILE_EXT.includes(ext)) {
						setIsLoading(true);
						updateAlertStatus(true, true, "Music uploading is in process");

						fetch(
							`${process.env.REACT_APP_BASE_URL}/api/upload-music?musicDuration=${audio.duration}`,
							{
								method: "POST",
								headers: {
									// "Content-Type": "multipart/form-data",
									Authorization: `Bearer ${token}`,
								},
								body: formData,
							}
						)
							.then((res) => {
								if (res.status !== 200 && res.status !== 201) {
									ErrorAlert(
										res.status,
										setAlertStatus,
										setShowAlert,
										setAlertMsg
									);
									setIsLoading(false);
								} else {
									return res.json();
								}
							})
							.then((data) => {
								if (data) {
									// handleSaveMusic()
									// saveAsProfile(data && data.id)
									//saveAsProfile(data)
									// props.setLoadMusic(true)

									setIsMusicUploaded(true);
									//handleClose();
									setIsLoading(false);
									updateAlertStatus(true, true, "Music uploaded successfully");
								}
							})
							.catch((err: any) => {
								// updateAlertStatus(true, false, 'Something went wrong, please try again.');
								setIsLoading(false);
							});
					} else {
						updateAlertStatus(
							true,
							false,
							"Incorrect audio file format. Please try again."
						);
						setIsLoading(false);
					}
				});
			}

			if (inputFileRef.current) {
				inputFileRef.current.value = null; // Clear the input value
				const newInput = document.createElement("input");
				newInput.type = "file";
				newInput.style.display = "none";
				newInput.addEventListener("change", handleOpenEditor);
				inputFileRef.current.parentNode.replaceChild(
					newInput,
					inputFileRef.current
				);
				inputFileRef.current = newInput;
			}
		} else {
			updateAlertStatus(true, false, "Invalid file type");
		}
	};

	const [storageConsumedPerc, setStorageConsumedPerc] = useState(0);
	const [storageConsumed, setStorageConsumed] = useState(0);
	const [storageAvailable, setStorageAvailable] = useState(0);

	type Units = "B" | "KB" | "MB" | "GB" | "TB" | "PB" | "EB" | "ZB" | "YB";

	const getReadableFileSizeString = (fileSize: number, unit: Units = "B") => {
		if (fileSize) {
			let i = -1;
			const byteUnits = [
				" KB",
				" MB",
				" GB",
				" TB",
				" PB",
				" EB",
				" ZB",
				" YB",
			];

			const unitsMap: Record<Units, number> = {
				B: 0,
				KB: 1,
				MB: 2,
				GB: 3,
				TB: 4,
				PB: 5,
				EB: 6,
				ZB: 7,
				YB: 8,
			};

			const upperUnit = unit.toUpperCase() as Units;
			if (upperUnit in unitsMap) {
				i = unitsMap[upperUnit] - 1;
				fileSize = fileSize * Math.pow(1024, i);

				i--; // Adjust to start with the correct unit for the conversion loop
			}

			do {
				fileSize = fileSize / 1024;
				i++;
			} while (fileSize > 1024 && i < byteUnits.length - 1);

			return Math.max(fileSize, 0.1).toFixed(1) + byteUnits[i];
		} else {
			return "0 MB";
		}
	};

	useEffect(() => {
		User.fetchUserInfo((userInfo: any) => {
			if (userInfo.error) {
				console.error("Failed to fetch user info:", userInfo.error);
			} else {
				setUserStorageCapacity(userInfo.storageCapacity);
				setUserStorageUsage(userInfo.storageUsage);
				if (user.storageCapacity != null) {
					let converterSize: any = getReadableFileSizeString(
						userInfo.storageUsage,
						"MB"
					);
					setStorageConsumed(converterSize);
					// Set Storage as 50 GB default untill avaialble from backend
					let totalSize: any = getReadableFileSizeString(
						userInfo.storageCapacity as any,
						"MB"
					);
					setStorageAvailable(totalSize);

					let consumptionPercent =
						(userInfo.storageUsage / (userInfo.storageCapacity as any)) * 100;
					setStorageConsumedPerc(consumptionPercent);
				}
			}
		});
	}, []);

	const canStore = Number(storageConsumedPerc) < 99.99;
	const storeFull = Number(storageConsumedPerc) === 100;

	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>

			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="xs"
				open={props.musicOpen}
			>
				<Typography
					id="variant-modal-title"
					variant="body1"
					sx={{ alignSelf: "center", fontWeight: 600, mt: 1 }}
				>
					Set your default profile music
				</Typography>

				{/* <Autocomplete
                    // startDecorator={<img src={SearchName} alt="Search" style={{ height: '17px', width: '17px' }} />}
                    options={recordings}
                    renderInput={(params) => <TextField {...params} label="Search by name" />}
                    getOptionLabel={(option) => option.fileName}
                    sx={{ width: 330, backgroundColor: '#F3F3F3', color: '#888888', border: 'none', borderRadius: '20px', alignSelf: 'center', m: 1, borderColor: 'white' }}
                /> */}

				<TextField
					variant="standard"
					fullWidth
					value={searchText}
					placeholder="Search by file name"
					onChange={handleSearchChange}
					sx={{
						"& .css-dnxhuh-MuiInputBase-root-MuiInput-root:before": {
							borderBottom: "none",
						},
						"& .css-1515x7o:before": {
							borderBottom: "none",
						},
						"& .css-dnxhuh-MuiInputBase-root-MuiInput-root:after": {
							borderBottom: "none",
						},
						"& .css-dnxhuh-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled,.Mui-error):before":
							{
								borderBottom: "none",
							},
						"& .css-1515x7o:after": {
							borderBottom: "none",
						},
						"& .css-1515x7o:hover:not(.Mui-disabled,.Mui-error):before": {
							borderBottom: "none",
						},
						height: "43px",
						fontFamily: "Lexend",
						fontWeight: 500,
						fontSize: "14px",
						width: 330,
						backgroundColor: "#F3F3F3",
						color: "#888888",
						border: "none",
						borderRadius: "20px",
						alignSelf: "center",
						m: 1,
						borderColor: "white",
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start" sx={{ pl: 2 }}>
								<SearchIcon />
								{/* <img src={SearchIcon} alt="Search" style={{ height: '17px', width: '17px' }} /> */}
							</InputAdornment>
						),
					}}
				/>

				<Tabs
					value={selectedTab}
					onChange={(event, value) => handleChange(value)}
					aria-label="Plain tabs"
					sx={{
						alignSelf: "center",
						"& .Mui-selected": {
							color: "#1D2D53 !important",
						},
						"& .MuiTabs-indicator": {
							backgroundColor: "#1D2D53 !important",
						},
					}}
				>
					<Tab
						key={0}
						sx={{
							textTransform: "none !important",
							fontWeight: 500,
							fontSize: "14px",
							color: "#1D2D53",
							fontFamily: "Lexend",
						}}
						label="Uploaded Music"
					/>
					{/* Hiding for Social-Launch */}
					{/* <Tab key={1}
                        sx={{ textTransform: 'none !important', fontWeight: 500, fontSize: '14px', color: '#1D2D53', fontFamily: 'Lexend', }}
                        label="Jam Recordings"
                    /> */}
				</Tabs>

				<Divider variant="middle" light sx={{ my: 1 }} />

				<FormControl>
					<RadioGroup
						aria-labelledby="demo-controlled-radio-buttons-group"
						name="controlled-radio-buttons-group"
						value={music}
						sx={{ mx: 2 }}
					>
						<List component="div" sx={{ height: 320, overflowY: "auto" }}>
							{filteredItems && filteredItems.length > 0 ? (
								filteredItems.map((value: any, index: any) => (
									<React.Fragment key={value.id}>
										<ListItem>
											<FormControlLabel
												control={<Radio />}
												value={value.id}
												label={value.fileName}
												onClick={handleClickMusic(value.id, value.myMusicUuid)}
												// checked={ value.musicUrl === props.profileMusic}
											/>
										</ListItem>
									</React.Fragment>
								))
							) : (
								<Typography sx={{ textAlignLast: "center" }}>
									No records available
								</Typography>
							)}
						</List>
					</RadioGroup>
				</FormControl>

                <Divider variant="middle" light sx={{ my: 1 }} />
                
                {/* { canStore ? 
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexWrap: 'wrap', pt: 1, mx: 2, mb: 2 }}>
                    <label style={{ alignSelf: 'center', cursor: 'pointer' }}>
                        <Typography sx={{ fontSize: '0.9rem', color: '#0273E6' }}>Browse Music</Typography>
                        <input
                            type="file"
                            accept=".mp3,.wav,.aac,audio/mpeg,audio/wav,audio/aac"
                            style={{ display: 'none' }}
                            onChange={handleOpenEditor}
                        />
                    </label>
                </Box> : ""} */}

				{canStore ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							flexWrap: "wrap",
							pt: 1,
							mx: 2,
							mb: 2,
						}}
					>
						<label style={{ alignSelf: "center", cursor: "pointer" }}>
							<Typography sx={{ fontSize: "0.9rem", color: "#0273E6" }}>
								Browse Music
							</Typography>
							<input
								type="file"
								accept="audio/*,.mp3,.wav,.mpeg"
								style={{ display: "none" }}
								onChange={handleOpenEditor}
							/>
						</label>
					</Box>
				) : (
					""
				)}

				<Box
					sx={{
						display: "flex",
						gap: 2,
						flexWrap: "wrap",
						pt: 1,
						flexDirection: "row-reverse",
						mx: 2,
						mb: 2,
					}}
				>
					<Button
						sx={{ borderRadius: "17px" }}
						size="small"
						variant="outlined"
						onClick={handleClose}
					>
						Cancel
					</Button>
					<Button
						size="small"
						variant="contained"
						sx={{ borderRadius: "17px", background: "#FF702A", px: 2 }}
						onClick={handleSaveMusic}
						//  disabled={!music ? true : false }
					>
						{" "}
						Save
					</Button>
					<Typography
						id="variant-modal-description"
						sx={{ color: "#FD4B24", marginRight: "auto" }}
					>
						Only 1 allowed
					</Typography>
				</Box>
			</Dialog>
		</>
	);
};

export default SetProfileMusicDialog;
