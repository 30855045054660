import { useParams } from "react-router";
import { useEffect, useState } from "react";
import Post from "./Post";
import { Alert, AlertTitle, Grid, Snackbar, Typography } from "@mui/material";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import PostsHome from "./PostsHome";
// import AdsComponent from "../commonComponents/AdsComponent";
import HomeSideBar from "../commonComponents/HomeSideBar";
import LeftPanel from "../commonComponents/LeftPanel";
import { useSearchParams } from "react-router-dom";
import SettingsAlert from "../../pages/SettingsAlert";
import { useAlertStatus } from "../../context/AlertStatusContext";
import WarningIcon from "../../images/alert_filled_warning.svg";
import { useUser } from "../../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../commonComponents/Loader";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

const SinglePostView = () => {
	let [searchParams] = useSearchParams();
	const postId = searchParams.get("postId");
	const nickName = searchParams.get("nickName");
	const [postDetails, setPostDetails] = useState<any>([]);
	const token = localStorage.getItem("sessionId");
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userData, setUserData] = useState<any>({});
	const { updateAlertStatus } = useAlertStatus();
	const [showWarning, setShowWarning] = useState(false);
	const [viewPost, setViewPost] = useState(false);
	const [isPostVisible, setIsPostVisible] = useState(false);
	const user = useUser();
	const navigate = useNavigate();
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	// useEffect(() => {
	//     postDetails && postDetails.map((post: any) => {
	//         if (post.deletedBy === 'USER') {
	//             setShowWarning(true)
	//             setViewPost(true)
	//         }
	//     })
	// }, [postDetails])

	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if ((!userDetails.firstName || !userDetails.lastName || !userDetails.location) && !isAdmin) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	}, [navigate]);

	useEffect(() => {
		// Check for deleted posts during the initial rendering
		const deletedPostExists =
			postDetails && postDetails.some((post: any) => post.deletedBy === "USER");
		if (deletedPostExists) {
			setShowWarning(true);
			setViewPost(true);
		}
	}, [postDetails]);

	const fetchUserInfo = () => {
		setLoading(true);
		if (nickName !== null) { // Null check
			fetch(`${process.env.REACT_APP_BASE_URL}/api/view-user-profile/${encodeURIComponent(nickName)}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
				.then((response) => {
					if (response.status === 502) {
						navigate('/defaultPage');
					} else if (response.status > 400) {
						throw new Error();
					} else {
						return response.json();
					}
				})
				.then((result) => {
					setLoading(false);
					if (result !== null) {
						setUserData(result);
					}
				})
				.catch((err) => {
					setLoading(false);
				});
		} else {
			setLoading(false); // Stop loading if nickName is null
		}
	};
	

	const getPostDetails = () => {
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/getSinglePost/${postId}?sort=RECENT`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
				.then((res) => {
					if (res.status >= 400) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					setPostDetails([data]);
					setIsPostVisible(
						(data.postVisibility === "PRIVATE" && data.connected) ||
							data.userProfileId === user.userProfileId
							? true
							: data.postVisibility === "PUBLIC"
							? true
							: false
					);
				})
				.catch((err) => {
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

    useEffect(() => {
        fetchUserInfo()
        getPostDetails()
    }, [postId, nickName])

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const isObjectEmpty = (obj: any) => {
		return Object.keys(obj).length === 0;
	};
	const updateConnectionStatus = (
		id: any,
		newValue: any,
		requestFrom: any,
		requestTo: any
	) => {
		const newData =
			postDetails &&
			postDetails.map((obj: any) => {
				if (obj.userProfileId === id) {
					return {
						...obj,
						conneStatus: newValue,
						conneRequestFrom: requestFrom,
						conneRequestTo: requestTo,
						canSendConnection:
							(newValue === "REJECTED" ||
								newValue === "WITHDRAWN" ||
								newValue === "REMOVED") &&
							true,
					};
				}
				return obj;
			});
		setPostDetails(newData);
	};
	const handleCloseWarning = () => {
		setShowWarning(false);
	};
	return (
		<>
			<Grid
				className="center-align"
				style={{ padding: "10px", position: "relative" }}
				id="home-alert-title-bar"
			>
				{showWarning && (
					<Snackbar
						sx={{
							"&.MuiSnackbar-anchorOriginTopCenter": {
								top: "80px",
							},
						}}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showWarning}
						autoHideDuration={6000}
						onClose={() => handleCloseWarning()}
					>
						<Alert
							onClose={() => handleCloseWarning()}
							icon={
								<img
									src={WarningIcon}
									alt="error-icon"
									style={{ marginRight: "10px" }}
								/>
							}
							sx={{
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "6px",
								fontWeight: "500",
								color: "black",
							}}
						>
							<AlertTitle
								sx={{
									fontWeight: "500",
									color: "#000000",
									display: "flex",
									justifyContent: "center !important",
								}}
							>
								Opps! Looks like this post was deleted
							</AlertTitle>
						</Alert>
					</Snackbar>
				)}
			</Grid>
			<SettingsAlert />
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>

			<div
				className="mainPanel"
				style={{
					margin: "auto",
				}}
			>
				<Grid container columns={{ xs: 12, sm: 12, md: 12 }} sx={{ mt: 4 }}>
					<Grid item xs={3} className="leftPanelWidth">
						<HomeSideBar userDetails={userData} />
					</Grid>

					<Grid container item xs={6} rowGap={1} className="centerPanelWidth">
						{isPostVisible ? (
							<Grid item sx={{ width: "600px" }}>
								{viewPost ? (
									""
								) : postDetails && postDetails.length > 0 ? (
									postDetails &&
									postDetails.map((post: any) => {
										return (
											<Post
												post={post}
												singlePostRefresh={getPostDetails}
												isSinglePostView={true}
												postDetails={postDetails}
												setPostDetails={setPostDetails}
												updateConnectionStatus={updateConnectionStatus}
											/>
										);
									})
								) : (
									<Typography sx={{ textAlign: "center" }}>
										Post not Found
									</Typography>
								)}
							</Grid>
						) : (
							<Grid item sx={{ width: "600px" }}>
								<Typography sx={{ textAlign: "center" }}>
									{/* Unable to access private post */}
									<Loader></Loader>
								</Typography>
							</Grid>
						)}

						{/* <Grid item xs={12} lg={12}>
							<AdsComponent dataAdSlot="4022680804" />
                        </Grid> */}
					</Grid>

					<Grid
						item
						container
						direction={"column"}
						xs={3}
						sx={{ alignItems: "end" }}
						rowGap={2}
						className="rightPanelWidth"
					>
						<LeftPanel />
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default SinglePostView;
