import {
	Avatar,
	Card,
	CardContent,
	Grid,
	IconButton,
	Typography,
	Button,
	Slider,
	Menu,
	MenuItem,
	Divider,
	Dialog,
	DialogTitle,
	Box,
	DialogContent,
	DialogContentText,
	DialogActions,
	ListItemIcon,
	Tooltip,
} from "@mui/material";
import ConnectIcon from "../../images/Posts/connect-icon.svg";
import MoreIcon from "../../images/Posts/menu dots verticle.svg";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Like from "../../images/like.svg";
import LikeDisabled from "../../images/Admin/like.svg";
import MessageIcon from "../../images/Posts/message-text.svg";
import MessageIconDisabled from "../../images/Posts/message-icon-disabled.svg";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { useUser } from "../../context/UserContext";
import ErrorAlert from "../Error/ErrorAlert";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ShareIcon from "../../images/Posts/share.svg";
import FlagIcon from "../../images/Posts/flag.svg";
import RemoveConnectionIcon from "../../images/Posts/profile-remove.svg";
import ConnectRequestDialog from "../commonComponents/connectRequestDialog";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import PeopleRemove from "../../images/profile-remove-blue.svg";
import CloseCircled from "../../images/close-circle-blue.svg";
import TickCircleOrange from "../../images/tick-circle-blue.svg";
import ReportPost from "./ReportPost";
import DeletePostIcon from "../../images/Posts/delete-post.svg";
import EditPostIcon from "../../images/Posts/edit-post.svg";
import CopyLinkToShareIcon from "../../images/Posts/copy-link-to-share.svg";
import CommentList from "./CommentList";
import SettingsAlert from "../../pages/SettingsAlert";
import { useAlertStatus } from "../../context/AlertStatusContext";
import { Link, useNavigate } from "react-router-dom";
import CreatePostNew from "./CreatePostNew";
import CloseIcon from "../../images/close.svg";
import ViewImage from "../commonComponents/ViewImage";
import Calender from "../../images/calendar.svg";
import Arrow from "../../images/arrow-right-black.svg";
import i18n from "../../l10n/strings.json";
import RightArrow from "../../images/right arrow.svg";
import React from "react";
import Linkify from "react-linkify";
import ViewLikeUsers from "../commonComponents/ViewLikeUsers";
import { CleaningServices } from "@mui/icons-material";
import { log } from "console";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import InfoIcon from "@mui/icons-material/InfoRounded";
import theme from "../../theme";

const Post = ({
	post,
	selectedTab,
	viewer,
	userName,
	resetPostDetails,
	postDetails,
	setPostDetails,
	profileUrl,
	isSinglePostView,
	singlePostRefresh,
	updateConnectionStatus,
}: any) => {
	const audioRef = useRef(document.createElement("audio"));
	const user = useUser();
	const { updateAlertStatus } = useAlertStatus();
	const token = localStorage.getItem("sessionId");
	const [likeStatus, setLikeStatus] = useState(
		post.userReaction ? post.userReaction : false
	);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	// const audioRef = useRef<any>(
	// 	post.mediaUrl
	// 		? new Audio(`${process.env.REACT_APP_PICTURE_URL}${post.mediaUrl}`)
	// 		: null
	// );
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [openMenu, setOpenMenu] = useState(false);
	const buttonRef = useRef(null);
	const [openConnectDialog, setOpenConnectDialog] = useState(false);
	const [openReportPost, setOpenReportPost] = useState(false);
	const [viewComment, setViewComment] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isLoading, setIsLoading] = useState(false);
	const [openEditPost, setOpenEditPost] = useState(false);
	const [totalLikeCount, setTotalLikeCount] = useState(post.likeCount);
	const [totalCommentCount, setTotalCommentCount] = useState(
		post.totalCommentCountOnPost
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [connectionStatus, setConnectionStatus] = useState("");
	const navigate = useNavigate();
	const [commentDto, setCommentDto] = useState<any>([]);
	const [likedDto, setLikedDto] = useState<any>([]);
	const [isReported, setIsReported] = useState(false);
	const [postDeletedBy, setPostDeletedBy] = useState("");
	const [openDeleteDialog, setOPenDeleteDialog] = useState(false);
	const [selectedViolations, setSelectedViolations] = useState<any>([]);
	const [isModalOpen, setModalOpen] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const [showFullText, setShowFullText] = useState(false);
	const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);
	const [isCommentHovered, setIsCommentHovered] = useState(false);

	const [isViewUserModalOpen, setViewUserModalOpen] = useState(false);
	const [isLoadingLiked, setIsLoadingLiked] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [itemPerPage, setItemPerPage] = useState(10);
	const [hasMore, setHasMore] = useState(true);
	const [change, setChange] = useState(false);
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	const authorities =
		user.authorities &&
		user.authorities.map((authority: { name: string }) => authority.name);
	const handleClick = () => {
		setModalOpen(true);
	};

	useEffect(() => {
		const isObjectEmpty = (obj: any) => {
			return Object.keys(obj).length !== 0;
		};
		//getUserPrivacySetting(post.userProfileId);
		setTotalLikeCount(post.likeCount);
		setTotalCommentCount(post.totalCommentCountOnPost);
		setConnectionStatus(post.conneStatus);
		setIsReported(post.IsReported);
		setPostDeletedBy(post.deletedBy);
		isObjectEmpty(post.usersReportForPostMap) &&
			setSelectedViolations(
				Object.keys(post.usersReportForPostMap).filter(
					(key: any) => post.usersReportForPostMap[key]
				)
			);
	}, []);

	const handleCloseReportPost = () => {
		setOpenReportPost(false);
	};

	const handleCloseDeleteDialog = () => {
		setOPenDeleteDialog(false);
	};

	const handleCloseConnectDialog = () => {
		setOpenConnectDialog(false);
	};

	const handleCloseMenu = () => {
		setOpenMenu(false);
	};

	// const getUserPrivacySetting = (id: number) => {
	//     fetch(`${process.env.REACT_APP_BASE_URL}/api/get-user-privacy-settings/${id}`, {
	//         method: "GET",
	//         headers: {
	//             "Content-Type": "application/json",
	//             Authorization: `Bearer ${token}`,
	//         },
	//     })
	//         .then((res) => {
	//             if (res.status !== 200) {
	//                 ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
	//             } else {
	//                 return res.json();
	//             }
	//         })
	//         .then((data) => {
	//             // setIdentity(data.identityPermission)
	//             // setViewProfile(data.viewProfilePermission)
	//             // setConnectionRequest(data.connectionRequestPermission)
	//             // setMessage(data.messagePermission)
	//             // setOnlineStatus(data.onlineStatusPermission)
	//             // setShareMyProfile(data.shareMyProfilePermission)
	//             // setUpdateId(data.id)
	//             // setLoading(false);
	//         })
	//         .catch((err) => {
	//             // setShowAlert(true);
	//             //     setAlertStatus(false);
	//             //     setAlertMsg("Network Error! Please try again..");
	//             //setLoading(false);
	//         });
	// };

	const sendConnectionRequest = (
		requestTo: number | undefined,
		requestStatus: string,
		requestToUUID: String | undefined
	) => {
		try {
			setIsDisable(true);
			const isRequestFinalized =
				requestStatus === "REJECTED" || requestStatus === "ACCEPTED";
			fetch(`${process.env.REACT_APP_BASE_URL}/api/user-connection-requests`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					requestFromUUID: isRequestFinalized
						? requestToUUID
						: user.userProfileUuid,
					requestToUUID: isRequestFinalized
						? user.userProfileUuid
						: requestToUUID,
					connectionStatus: requestStatus,
				}),
			})
				.then((res) => {
					if (res.status === 502) {
						navigate("/defaultPage");
					} else if (res.status > 400) {
						setIsDisable(false);
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
					} else {
						setIsDisable(false);
						return res.json();
					}
				})
				.then((data) => {
					setRemoveConfirmation(false);

					updateConnectionStatus(
						post.userProfileId,
						data.connectionStatus,
						data.requestFrom?.id,
						data.requestTo?.id
					);
					// setConnectionStatus(data.connectionStatus)
					// props.getUserDetails();
				});
		} catch (error) {
			setShowAlert(true);
			setAlertStatus(false);
			setAlertMsg("Network Error! Please try again..");
		}
		setOpenMenu(false);
	};

	// const togglePlay = () => {
	// 	if(audioRef){
	// 	if (isPlaying) {
	// 		audioRef.current.pause();
	// 	} else {
	// 		audioRef.current.play();
	// 	}
	// }
	// 	setIsPlaying(!isPlaying);
	// };
	const pauseAudioPlayer = (postId: string) => {
		// Pause all playing audio elements except the one with the matching post ID
		const audioPlayers = document.querySelectorAll("audio");
		audioPlayers.forEach((audioPlayer) => {
			const audioElement = audioPlayer as HTMLAudioElement;
			// Assuming each audio element has an id matching the post ID
			if (!audioElement.paused && audioElement.id !== postId) {
				audioElement.pause();
			}
		});
	};

	const togglePlay = () => {
		if (audioRef && audioRef.current) {
			if (isPlaying) {
				audioRef.current.pause();
			} else {
				pauseAudioPlayer(post.id);
				audioRef.current.play();
			}
		}
		setIsPlaying(!isPlaying);
	};

	const handleTimeUpdate = () => {
		const duration = audioRef.current?.duration;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const progressPercent = (currentTime / duration) * 100;
		setDuration(duration);
		setCurrentTime(audioRef.current.currentTime);
		if (duration === currentTime) {
			// setCurrentTime(0);
			setIsPlaying(!isPlaying);
		}
	};

	const handleSliderChange = (
		event: Event | SyntheticEvent,
		newValue: number
	) => {
		// Pause the audio before seeking

		// Ensure the audio file is loaded
		if (!isNaN(audioRef.current.duration)) {
			// Set the new current time
			audioRef.current.currentTime = newValue;

			// Update the state to reflect the new time
			setCurrentTime(newValue);

			// Resume playing the audio only if it was playing before
			// if (isPlaying) {
			// 	audioRef.current.play();
			// 	console.log("Resuming play from:", newValue);
			// }
		} else {
			console.log("Audio not loaded yet.");
		}
	};

	// added to display music time
	const handleMetadata = (e: any) => {
		setDuration(audioRef.current.duration);
	};

	useEffect(() => {
		// Update the audio duration once the audio reference is loaded
		setTimeout(() => {
			const duration = audioRef.current?.duration;
			setDuration(duration);
		}, 1000);
	}, []);

	const formatDuration = (duration: number) => {
		const minutes = Math.floor(duration / 60);
		const seconds = Math.floor(duration % 60);
		return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	};

	const handleLike = (status: any) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/post-reaction/react`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				post: {
					postUuid: post?.postUUID,
				},
			}),
		})
			.then((res) => {
				if (res.status === 502) {
					navigate("/defaultPage");
				} else if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					status
						? setTotalLikeCount(totalLikeCount + 1)
						: setTotalLikeCount(totalLikeCount - 1);
					setLikeStatus(status);
				}
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};
	const handleAlertClose = () => {
		setShowAlert(false);
	};
	const handleCopyLink = () => {
		navigator.clipboard.writeText(
			`${process.env.REACT_APP_BASE_URL}/api/public/post-view?postId=${post?.id}&nickName=${post.profileNickName}`
		);
		// navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL_FRONTEND}/postView/${post?.id}/${post.userProfileId}`);
		updateAlertStatus(true, true, "Link copied to clipboard");
		setOpenMenu(!openMenu);
		// setTimeout(() => {
		//     setShowTooltip(false);
		// }, 2000)
	};

	const formatTimeDifference = (dateTime: string) => {
		const currentDateTime: Date = new Date();
		const notificationDateTime = new Date(dateTime);
		const timeDiffInMilliseconds =
			currentDateTime.getTime() - notificationDateTime.getTime();

		const minutes = Math.floor(timeDiffInMilliseconds / (1000 * 60));
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		const months = Math.floor(days / 30);
		const years = Math.floor(months / 12);

		if (minutes === 0) {
			return "now";
		} else if (minutes === -1) {
			return "now";
		} else if (minutes < 60) {
			return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
		} else if (hours < 24) {
			return `${hours} hour${hours === 1 ? "" : "s"} ago`;
		} else if (days < 30) {
			return `${days} day${days === 1 ? "" : "s"} ago`;
		} else if (months < 12) {
			return `${months} month${months === 1 ? "" : "s"} ago`;
		} else {
			return `${years} year${years === 1 ? "" : "s"} ago`;
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const convertTime = (createdAt: any) => {
		const originalTimestamp = new Date(createdAt);
		const year = originalTimestamp.getFullYear();
		const month = String(originalTimestamp.getMonth() + 1).padStart(2, "0");
		const day = String(originalTimestamp.getDate()).padStart(2, "0");
		const hours = originalTimestamp.getHours();
		const minutes = originalTimestamp.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		const formattedHours = hours % 12 || 12;
		const NewTime = `${formattedHours}:${String(minutes).padStart(
			2,
			"0"
		)} ${ampm} ${day}-${month}-${year}`;
		return NewTime;
	};

	const updatePostAfterDelete = () => {
		let newArray = postDetails.filter((obj: any) => obj?.id !== post?.id);
		setPostDetails(newArray);
		setCommentDto([]);
		setLikedDto([]);
		setViewComment(false);
		if (newArray.length < 5) {
			resetPostDetails();
		}
	};

	const updatePostAfterDeleteByAdmin = () => {
		setPostDeletedBy("ADMIN");
	};

	const removeBlockedUsersPosts = () => {
		let newArray = postDetails.filter(
			(obj: any) => obj.userProfileId !== post.userProfileId
		);
		setPostDetails(newArray);
	};

	const handleDeletePost = () => {
		let userRole = authorities?.includes("ROLE_ADMIN") ? "ADMIN" : "USER";
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/deletePosts?deletedBy=${userRole}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					postUuid: post?.postUUID,
				}),
			}
		)
			.then((res) => {
				if (res.status === 502) {
					navigate("/defaultPage");
				} else if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					setOPenDeleteDialog(false);
					updateAlertStatus(true, true, "Post Deleted Successfully");
					if (authorities?.includes("ROLE_ADMIN")) {
						updatePostAfterDeleteByAdmin();
					} else {
						updatePostAfterDelete();
					}
					if (isSinglePostView) {
						singlePostRefresh();
					}
					// resetPostDetails()
					setOpenMenu(!openMenu);
					return res.json();
				}
			})
			.catch((err) => {
				// setShowAlert(true);
				// setAlertStatus(false);
				// setAlertMsg("Network Error! Please try again..");
			});
		setOpenMenu(!openMenu);
	};

	const handleEditPostClose = () => {
		setOpenEditPost(false);
	};
	const handleOpenEdit = () => {
		setOpenEditPost(true);
		handleCloseMenu();
	};
	const handleReportPost = () => {
		setOpenReportPost(true);
		handleCloseMenu();
	};
	const viewUserProfile = () => {
		const encodedNickName = encodeURIComponent(post.profileNickName);

		navigate("/socialHome/" + encodedNickName);
	};

	const viewTaggedUserProf = (nickname: string) => {
		const encodedNickName = encodeURIComponent(nickname);

		navigate("/socialHome/" + encodedNickName);
	};
	const viewCommentsList = () => {
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/comments/allComments/tree?sortBy=RECENT&page=0&size=2`,
				// `${process.env.REACT_APP_BASE_URL}/api/getAllPosts`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						postUUID: post?.postUUID,
					}),
				}
			)
				.then((res) => {
					if (res.status === 502) {
						navigate("/defaultPage");
					} else if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length > 0) {
						setCommentDto(data);
					}
				})
				.catch((err) => {})
				.finally(() => {});
		} catch (error) {}
		setViewComment(!viewComment);
	};

	const handleShowFullText = () => {
		setShowFullText((prevShowFullText) => !prevShowFullText);
	};

	const lineCount = post.feedText?.split("\n").length || 0;

	const viewLikeUserList = () => {
		//likedDto?.length === 0 &&
		setViewUserModalOpen(true);

		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/likedprofile?page=${pageNumber}&size=${itemPerPage}`,
				// `${process.env.REACT_APP_BASE_URL}/api/getAllPosts`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ postUuid: post?.postUUID }),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						//setViewUserModalOpen(false);
						throw new Error("api_error");
					} else {
						//setViewUserModalOpen(true);
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
					} else {
						// setLikedDto(data);
						setLikedDto(
							data.sort(
								(a: any, b: any) =>
									new Date(b.createdAt).getTime() -
									new Date(a.createdAt).getTime()
							)
						);
						if (pageNumber === 0) {
							// setLikedDto(data);
							setLikedDto(
								data.sort(
									(a: any, b: any) =>
										new Date(b.createdAt).getTime() -
										new Date(a.createdAt).getTime()
								)
							);
						} else {
							setLikedDto((prev: any) =>
								[...prev, ...data].sort(
									(a, b) =>
										new Date(b.createdAt).getTime() -
										new Date(a.createdAt).getTime()
								)
							);
						}
					}

					if (data.length > 0) {
						setLikedDto(data);
					}

					setPageNumber(pageNumber + 1);
					// setViewUserModalOpen(false);
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {});
		} catch (error) {
			//	setViewUserModalOpen(false);
		}
		//setViewComment(!viewComment);
		//setViewUserModalOpen(true);
	};
	const handleCloseLikeDialog = () => {
		setLikedDto(null);
		setPageNumber(0);
		setHasMore(true);
		setViewUserModalOpen(false);
	};
	const renderFormattedText = () => {
		const lines: string[] = post.feedText?.split("\n") || [];

		const TaggedUser = (tagRecordsDto: any, identifier: string) => {
			if (!tagRecordsDto) {
				return null;
			}
			return tagRecordsDto.find((item: any) => {
				const fullName = `${item?.taggedUserProfile?.user.firstName || ""} ${item?.taggedUserProfile?.user.lastName || ""}`.trim();
				const nickname = item?.taggedUserProfile?.nickname;

				// Match identifier with either full name or nickname
				return fullName === identifier || nickname === identifier;
			});
		};

		const taggedUserColor = (nickname: string) => {
			const taggedUser = TaggedUser(post?.tagRecordsDto, nickname);
			if (taggedUser?.approved === true) {
				return true;
			} else {
				return false;
			}
		};

		const taggedUserNickname = (identifier: string) => {
			const taggedUserProfile = TaggedUser(post?.tagRecordsDto, identifier);

			if (taggedUserProfile) {
				const fullName = `${taggedUserProfile.taggedUserProfile.user.firstName || ""} ${taggedUserProfile.taggedUserProfile.user.lastName || ""
					}`.trim();
				const nickname = taggedUserProfile?.taggedUserProfile?.nickname;
				return nickname || fullName;
			}
			return identifier;
		};

		const getTaggedUserProfileName = (identifier:any) => {
			if (!post?.tagRecordsDto) return identifier;
		  
			const normalizedIdentifier = identifier.toLowerCase();
			const firstWordOfIdentifier = identifier.split(" ")[0].toLowerCase();
		  
			const taggedUserProfile = post?.tagRecordsDto.find((item:any) => {
			  const user = item?.taggedUserProfile?.user;
			  const fullName = `${user?.firstName || ""} ${user?.lastName || ""}`.trim().toLowerCase();
			  const nickname = item?.taggedUserProfile?.nickname?.toLowerCase();
		  
			  return (
				fullName === normalizedIdentifier || 
				(nickname && nickname.startsWith(firstWordOfIdentifier))
			  );
			});
		  
			if (taggedUserProfile) {
			  const user = taggedUserProfile.taggedUserProfile.user;
			  const fullName = `${user?.firstName || ""} ${user?.lastName || ""}`.trim();
			  const nickname = taggedUserProfile?.taggedUserProfile?.nickname;
		  
			  return fullName.toLowerCase() === normalizedIdentifier
				? fullName
				: nickname || fullName || identifier;
			}
		  
			return identifier;
		};

		const fullNameArray: string[] = [];
		const TaggedUserList = post?.tagRecordsDto;

		if (TaggedUserList) {
			TaggedUserList.forEach((record: any) => {
				const firstName = record.taggedUserProfile.user.firstName || "";
				const lastName = record.taggedUserProfile.user.lastName || "";
				const fullName = `${firstName} ${lastName}`.trim();
				const nickname = record.taggedUserProfile?.nickname;

				if (fullName) {
					fullNameArray.push(fullName);
				}
				if (nickname) {
					fullNameArray.push(nickname);
				}
			});
		}

		return lines.map((line, index) => (
			<React.Fragment key={index}>
				{(() => {
					const regex = /(@[^\s@]+(?:\s[^\s@]+)?)/g;

					const parts = line.split(regex);

					return parts.map((part, partIndex) => {
						if (part.startsWith("@")) {
							const identifier = part.substring(1).trim();
							const profileName = getTaggedUserProfileName(identifier);

							if (identifier !== profileName) {
								const splitParts = part.split(" "); // Split by space
								const adjustedParts = splitParts.map((splitPart, splitPartIndex) => {
									if (splitPart.startsWith("@")) {
										const identifier = splitPart.substring(1).trim();
										const profileName = getTaggedUserProfileName(identifier);
										const isPartInFullNameArray = fullNameArray.some((item) => item === profileName);

										if (isPartInFullNameArray) {
											const isApproved = taggedUserColor(identifier);
											const nickName = taggedUserNickname(identifier);

											return (
												<span key={splitPartIndex}>
													<a
														onClick={() => {
															if (isApproved) {
																viewTaggedUserProf(nickName);
															}
														}}
														style={{
															color: isApproved ? "#0273E6" : "black",
															cursor: isApproved ? "pointer" : "default",
															fontWeight: isApproved ? "600" : "600",
														}}
													>
														{splitPart.substring(1)} {/* Display the part without the '@' */}
													</a>
												</span>
											);
										} else {
											return <span key={splitPartIndex}>{splitPart}</span>;
										}
									} else {
										return (
											<span style={{ fontWeight: "500" }} key={splitPartIndex}>
												{splitPart}
											</span>
										);
									}
								});

								return adjustedParts;
							}

							const isPartInFullNameArray = fullNameArray.some((item) => item === profileName);

							if (isPartInFullNameArray) {
								const isApproved = taggedUserColor(identifier);
								const nickName = taggedUserNickname(identifier);

								return (
									<span key={partIndex}>
										<a
											onClick={() => {
												if (isApproved) {
													viewTaggedUserProf(nickName);
												}
											}}
											style={{
												color: isApproved ? "#0273E6" : "black",
												cursor: isApproved ? "pointer" : "default",
												fontWeight: isApproved ? "600" : "600",
											}}
										>
											{part.substring(1)} {/* Display the part without the '@' */}
										</a>
									</span>
								);
							} else {
								return <span key={partIndex}>{part}</span>;
							}
						} else {
							return (
								<span style={{ fontWeight: "500" }} key={partIndex}>
									{part}
								</span>
							);
						}
					});
				})()}
				{index < lines.length - 1 && <br />}
			</React.Fragment>
		));
	};

	const [isHovered, setIsHovered] = useState(false);

	// Function to handle mouse enter event
	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	// Function to handle mouse leave event
	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const isEventAndGigDeleted = (tooltipTitle: string, usersPost: any) => {
		return (
			<Grid
				item={true}
				xs={1}
				sx={{
					display: "flex",
					alignSelf: "center",
					marginTop: "2vh",
				}}
			>
				{usersPost.eventDto?.deleted ? (
					<Tooltip title={tooltipTitle} arrow>
						<InfoIcon
							sx={{
								color: theme.palette.error.light,
								marginLeft: 1,
								cursor: "pointer",
								display: "flex",
								alignItems: "center",
								"&:hover": {
									color: theme.palette.error.main,
								},
								pointerEvents: usersPost.eventDto?.deleted ? "auto" : "none",
							}}
						/>
					</Tooltip>
				) : (
					<img src={Arrow} alt="arrow" />
				)}
			</Grid>
		);
	};

	const handleConnect = () => {
		setOpenConnectDialog(true);
	};
	return (
		<>
			<SettingsAlert />
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<Card
				sx={{
					minWidth: 300,
					flexGrow: 1,
					minHeight: "18%",
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					mb: 3,
					borderRadius: "12px",
					px: 2,
					py: 2,
				}}
			>
				{selectedTab === "Comments" || selectedTab === "Reactions" ? (
					<CardContent sx={{ alignItems: "center", gap: 1 }}>
						<Grid container rowGap={1} columns={{ xs: 12, sm: 6, md: 12 }}>
							{/* <Grid item xs={2}> */}
							<Avatar
								alt={post.name}
								src={
									profileUrl
										? `${process.env.REACT_APP_PICTURE_URL}${profileUrl}`
										: ""
								}
								sx={{ height: "26px", width: "26px", ml: 1 }}
							/>

							<Typography
								sx={{ color: "#000000", fontSize: "14px", marginTop: "2px" }}
							>
								&nbsp;&nbsp;{viewer === "SELF" ? "You" : userName}&nbsp;
							</Typography>
							<Typography
								sx={{ color: "#999999", fontSize: "14px", marginTop: "2px" }}
							>
								{selectedTab === "Comments"
									? " commented on this"
									: " liked on this"}
							</Typography>
							{/* </Grid> */}
						</Grid>
						<Divider sx={{ color: "#C2C2C3", mt: 1 }} />
					</CardContent>
				) : (
					""
				)}

				<CardContent sx={{ alignItems: "center", padding: "0" }}>
					<Grid container rowGap={1} columns={{ xs: 12, sm: 6, md: 12 }}>
						<Grid
							item
							xs={1.5}
							onClick={viewUserProfile}
							sx={{ cursor: "pointer" }}
						>
							<Avatar
								alt={post.name}
								src={
									post.profilePicture
										? `${process.env.REACT_APP_PICTURE_URL}${post.profilePicture}`
										: ""
								}
								sx={{ height: "50px", width: "50px" }}
							/>
						</Grid>
						<Grid container item xs={5.5} direction={"column"}>
							<Grid item onClick={viewUserProfile} sx={{ cursor: "pointer" }}>
								{/* <Tooltip title={`${post.name}`}> */}
								<Typography
									variant="body1"
									sx={{ fontWeight: "bold", fontSize: "16px" }}
								>
									{/*name to change*/}{" "}
									{`${
										post.name && post.name?.length > 25
											? post.name?.substring(0, 25) + "..."
											: post.name
									}`}
								</Typography>
								{/* </Tooltip> */}
							</Grid>
							{post.profileTitle !== null ? (
								<Grid item>
									<Typography
										variant="caption"
										sx={{ color: "#888888", fontSize: "12px" }}
									>
										{`${
											post.profileTitle && post.profileTitle?.length > 35
												? post.profileTitle?.substring(0, 35) + "..."
												: post.profileTitle
										}`}
									</Typography>
								</Grid>
							) : (
								""
							)}
							<Grid item>
								<Typography
									variant="caption"
									sx={{ color: "#888888", fontSize: "12px" }}
								>
									{formatTimeDifference(post.createdAt)}
								</Typography>
							</Grid>
						</Grid>

						{!authorities?.includes("ROLE_ADMIN") ? (
							user.userAccountStatus === "SUSPEND" ? (
								<Grid item xs={4.5} sx={{ textAlign: "right" }}></Grid>
							) : (
								<>
									{/* start of buttons */}
									<Grid item xs={4.5} sx={{ textAlign: "right" }}>
										{/* {(post.canSendConnection && !post.connected && ((post.userProfileId !== user.userProfileId) || connectionStatus === 'REMOVED' || connectionStatus === 'WITHDRAWN' || connectionStatus === 'REJECTED')) ? */}
										{post.canSendConnection &&
										!post.connected &&
										post.userProfileId !== user.userProfileId &&
										(post.conneStatus === null ||
											post.conneStatus === "REMOVED" ||
											post.conneStatus === "WITHDRAWN" ||
											post.conneStatus === "REJECTED") ? (
											<Button
												disabled={isAdmin}
												variant="outlined"
												color="info"
												sx={{
													minWidth: "117px",
													fontSize: "16px",
													color: "#0273E6",
													borderColor: "#0273E6",
													marginLeft: "auto",
													height: "30px",
													fontFamily: "Lexend",
													fontWeight: 400,
												}}
												onClick={handleConnect}
											>
												<img
													src={ConnectIcon}
													alt=""
													style={{ height: "18px", width: "18px" }}
												/>
												Connect
											</Button>
										) : post.conneRequestFrom === user.userProfileId &&
										  post.conneStatus === "PENDING" ? (
											<Button
												variant="outlined"
												color="info"
												sx={{
													minWidth: "117px",
													width: "117px",
													fontSize: "16px",
													color: "#0273E6",
													borderColor: "#0273E6",
													marginLeft: "auto",
													height: "30px",
													fontFamily: "Lexend",
													fontWeight: 400,
												}}
												onClick={() => {
													sendConnectionRequest(
														post.userProfileId,
														"WITHDRAWN",
														post?.userProfileUUID
													);
												}}
												disabled={isDisable || isAdmin}
											>
												<img
													src={PeopleRemove}
													alt=""
													style={{ height: "18px", width: "18px" }}
												/>
												Withdraw
											</Button>
										) : post.conneRequestTo === user.userProfileId &&
										  post.conneStatus === "PENDING" ? (
											<>
												<Grid container>
													<Grid item xs={6}>
														<Button
															variant="outlined"
															color="info"
															sx={{
																color: "#0273E6",
																fontSize: "16px",
																borderColor: "#0273E6",
																mb: 1,
																height: "30px",
																maxWidth: "97px",
																ml: "auto",
																fontFamily: "Lexend",
																fontWeight: 400,
															}}
															onClick={() =>
																sendConnectionRequest(
																	post.userProfileId,
																	"ACCEPTED",
																	post?.userProfileUUID
																)
															}
															disabled={isDisable}
														>
															<img
																src={TickCircleOrange}
																alt=""
																style={{ height: "18px", width: "18px" }}
															/>
															Accept
														</Button>
													</Grid>
													<Grid item xs={6}>
														<Button
															variant="outlined"
															color="info"
															sx={{
																color: "#0273E6",
																fontSize: "16px",
																borderColor: "#0273E6",
																marginLeft: "auto",
																height: "30px",
																maxWidth: "97px",
																fontFamily: "Lexend",
																fontWeight: 400,
															}}
															onClick={() =>
																sendConnectionRequest(
																	post.userProfileId,
																	"REJECTED",
																	post?.userProfileUUID
																)
															}
															disabled={isDisable}
														>
															<img
																src={CloseCircled}
																alt=""
																style={{ height: "18px", width: "18px" }}
															/>
															Reject
														</Button>
													</Grid>
												</Grid>
											</>
										) : (
											""
										)}
									</Grid>
									{/* end of buttons */}
								</>
							)
						) : (
							<Grid item xs={4.5}></Grid>
						)}

						<Grid item xs={0.5}>
							<IconButton
								ref={buttonRef}
								aria-label="close"
								sx={{ pt: "2px" }}
								aria-expanded={openMenu ? "true" : undefined}
								onClick={() => {
									setOpenMenu(!openMenu);
								}}
								disabled={user.userAccountStatus === "SUSPEND"}
							>
								<img
									src={MoreIcon}
									alt="close"
									style={{ height: "24px", width: "24px" }}
								/>
							</IconButton>
							<Menu
								id="basic-menu"
								anchorEl={buttonRef.current}
								open={openMenu}
								onClose={handleCloseMenu}
								aria-labelledby="basic-demo-button"
							>
								<MenuItem onClick={() => handleCopyLink()}>
									<IconButton>
										<img src={CopyLinkToShareIcon} alt="" />
									</IconButton>
									Copy link to post
								</MenuItem>

								{post.userProfileId === user.userProfileId ||
								(authorities?.includes("ROLE_ADMIN") &&
									postDeletedBy !== "ADMIN") ? (
									<MenuItem onClick={() => setOPenDeleteDialog(true)}>
										<IconButton>
											<img src={DeletePostIcon} alt="" />
										</IconButton>
										Delete Post
									</MenuItem>
								) : (
									""
								)}
								{post.userProfileId === user.userProfileId ? (
									<MenuItem onClick={() => handleOpenEdit()}>
										<ListItemIcon>
											<IconButton>
												<img src={EditPostIcon} alt="edit-post-icon" />
											</IconButton>
										</ListItemIcon>
										Edit Post
									</MenuItem>
								) : isReported && authorities?.includes("ROLE_ADMIN") ? (
									""
								) : (
									<MenuItem onClick={() => handleReportPost()}>
										<IconButton>
											<img src={FlagIcon} alt="" />
										</IconButton>
										Report Post
									</MenuItem>
								)}
								{post.connected || post.conneStatus === "ACCEPTED" ? (
									<MenuItem
										// onClick={() =>
										// 	sendConnectionRequest(post.userProfileId, "REMOVED")
										// }
										onClick={() => setRemoveConfirmation(true)}
									>
										<IconButton>
											<img src={RemoveConnectionIcon} alt="" />
										</IconButton>
										Remove Connection
									</MenuItem>
								) : (
									""
								)}
							</Menu>
						</Grid>
						{post.isHidden ? (
							<Grid
								item
								xs={12}
								sx={{
									backgroundColor: "#F5F5F5",
									height: "90px",
									borderRadius: "12px",
									textAlign: "center",
									py: "35px",
								}}
							>
								<Typography
									sx={{ color: "#888888", fontWeight: 400, fontSize: "16px" }}
								>
									This post is hidden by admin as it violated our terms &
									conditions
								</Typography>
							</Grid>
						) : postDeletedBy === "ADMIN" ? (
							<Grid
								item
								xs={12}
								sx={{
									backgroundColor: "#F5F5F5",
									height: "90px",
									borderRadius: "12px",
									textAlign: "center",
									py: "35px",
								}}
							>
								<Typography
									sx={{ color: "#888888", fontWeight: 400, fontSize: "16px" }}
								>
									This post is deleted by Admin
								</Typography>
							</Grid>
						) : (
							<>
								{post.feedText !== "undefined" ? (
									// 	<Grid item xs={12}>
									// 	<Typography
									// 		sx={{
									// 			color: "#888888",
									// 			fontSize: "16px",
									// 			overflowWrap: "anywhere",
									// 		}}
									// 		dangerouslySetInnerHTML={{ __html: post.feedText }}
									// 	/>
									// 	{/* {post.feedText} */}
									// 	{/* </Typography> */}
									// </Grid>
									<Grid item xs={12}>
										<Typography
											sx={{
												color: "#888888",
												fontSize: "16px",
												overflow: showFullText ? "visible" : "hidden",
												whiteSpace: "pre-line",
												height: showFullText ? "auto" : "51px", //Adjust the height as needed
											}}
										>
											{/* { post.feedText} */}
											<Linkify
												componentDecorator={(
													decoratedHref,
													decoratedText,
													key
												) => (
													<a
														href={decoratedHref}
														key={key}
														target="_blank"
														rel="noopener noreferrer"
														style={{ color: "#0273E6" }}
													>
														{decoratedText}
													</a>
												)}
											>
												{renderFormattedText()}
												{/* igyugyu */}
											</Linkify>
										</Typography>
										{(post.feedText?.length > 100 || lineCount > 3) && (
											<Typography
												onClick={handleShowFullText}
												sx={{
													color: "#0273e6",
													cursor: "pointer",
													textAlign: "right",
												}}
											>
												{showFullText ? "see less" : "see more..."}
											</Typography>
										)}
									</Grid>
								) : (
									""
								)}

								{/* images */}
								{post.postType === "PHOTO" ? (
									<Grid item xs={12}>
										<img
											src={`${process.env.REACT_APP_PICTURE_URL}${post.mediaUrl}`}
											id="upload-button"
											alt="Upload"
											style={{
												objectFit: "fill",
												borderRadius: "unset",
												width: "100%",
												height: "100%",
												cursor: "pointer",
											}}
											onClick={handleClick}
										/>
									</Grid>
								) : (
									""
								)}
								{/* music */}
								{post.postType === "MUSIC" ? (
									<Grid
										item
										container
										xs={12}
										columnGap={1}
										sx={{
											background: post?.musicDeleted ? "#f5ecea" : "#F7F8FB",
											border: "none",
											borderRadius: "20px",
											padding: "12px",
											cursor: post?.musicDeleted ? "not-allowed" : "default",
											pointerEvents: post?.musicDeleted ? "none" : "auto",
										}}
									>
										<Grid item xs={1.5}>
											<IconButton onClick={togglePlay}>
												{!audioRef.current?.paused ? (
													<StopCircleIcon
														sx={{ width: 48, height: 48, color: "#1D2D53" }}
													/>
												) : (
													<PlayCircleIcon
														sx={{ width: 48, height: 48, color: "#1D2D53" }}
													/>
												)}
											</IconButton>
										</Grid>

										<Grid container direction="column" item xs={10}>
											<Grid container>
												{/* Title and Icon (if music is deleted) */}
												<Grid item xs={post?.musicDeleted ? 9 : 10}>
													<Typography
														sx={{
															fontSize: "1.125rem",
															fontWeight: "500 !important",
															whiteSpace: "normal",
															wordBreak: "break-word", // Prevents long words from overflowing
														}}
													>
														{post.musicTitle}
													</Typography>
												</Grid>
												{/* Icon for deleted music */}
												{post?.musicDeleted && (
													<Grid
														item
														xs={post?.musicDeleted ? 1 : 0}
														sx={{ display: "flex", justifyContent: "flex-end" }}
													>
														<Tooltip
															title={"This song is no longer accessible"}
															arrow
														>
															<InfoIcon
																sx={{
																	color: theme.palette.error.light,
																	marginLeft: 1,
																	cursor: "pointer",
																	display: "flex",
																	alignItems: "center",
																	"&:hover": {
																		color: theme.palette.error.main,
																	},
																	pointerEvents: post?.musicDeleted
																		? "auto"
																		: "none",
																}}
															/>
														</Tooltip>
													</Grid>
												)}
											</Grid>

											{/* Audio and Slider */}
											<Grid container item sx={{ mt: 1 }}>
												<Grid item xs={9}>
													<audio
														ref={audioRef}
														onTimeUpdate={handleTimeUpdate}
														onLoadedMetadata={handleMetadata}
														preload="auto"
														crossOrigin="anonymous"
														id={post.id}
													>
														<source
															src={post?.musicUrl.includes('https://') ? post?.musicUrl: process.env.REACT_APP_PICTURE_URL + post?.musicUrl}
															type="audio/mpeg"
														/>
													</audio>
													<Slider
														sx={{
															height: "8px",
															color: "#5D5D65",
															"& .MuiSlider-thumb": {
																height: "24px !important",
																width: "24px !important",
																backgroundColor: "#fff !important",
																border: "2px solid currentColor !important",
																"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible":
																	{
																		boxShadow: "inherit !important",
																	},
																"&:before": {
																	display: "none !important",
																},
															},
														}}
														size="small"
														step={0.01}
														value={currentTime}
														aria-label="Small"
														valueLabelDisplay="off"
														min={0}
														max={audioRef.current?.duration}
														onChangeCommitted={(
															event: Event | SyntheticEvent,
															newValue: number | number[]
														) => {
															if (typeof newValue === "number")
																handleSliderChange(event, newValue);
														}}
													/>
												</Grid>

												{/* Duration display */}
												<Grid
													item
													xs={3}
													sx={{ paddingLeft: "18px", paddingTop: "4px" }}
												>
													<Typography
														sx={{
															fontSize: "14px",
															fontWeight: "500 !important",
															color: "#7A7985",
														}}
													>
														{formatDuration(duration) === "NaN:NaN"
															? ""
															: formatDuration(currentTime) +
															  " / " +
															  formatDuration(duration)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								) : (
									""
								)}

								{/* events */}
								{post.postType === "EVENT" ? (
									<>
										<Link
											to={
												!post?.eventDto?.deleted
													? `/events/eventDetails/${post?.eventDto?.eventsUuid}`
													: "#"
											}
											style={{
												textDecoration: "none",
												width: "100%",
												borderRadius: "12px",
												border:
													post.eventDto?.bannerUrl !== null &&
													post.eventDto?.bannerUrl !== ""
														? "none"
														: "2px solid #999999",
												pointerEvents: post.eventDto?.deleted ? "none" : "auto",
											}}
										>
											<Grid item={true} xs={12}>
												<img
													alt=""
													src={
														post.eventDto?.bannerUrl !== null &&
														post.eventDto?.bannerUrl !== ""
															? `${process.env.REACT_APP_PICTURE_URL}${post.eventDto?.bannerUrl}`
															: `${process.env.REACT_APP_PICTURE_URL}/defaultimages/event-banner.png`
													}
													style={{
														height: "100%",
														//border: (post.eventDto?.bannerUrl !== null && post.eventDto?.bannerUrl !== "") ? "none" : "2px solid #FF702A",
														borderTopLeftRadius: "12px",
														width: "100%",
														borderTopRightRadius: "12px",
													}}
												/>
											</Grid>
											<Grid
												item={true}
												xs={12}
												sx={{
													background: post?.eventDto?.deleted
														? "#f5ecea"
														: "#F5F5F5",
													//border: (post.eventDto?.bannerUrl !== null && post.eventDto?.bannerUrl !== "") ? "none" : "2px solid #FF702A",
													paddingBottom: "3vh",
													borderBottomLeftRadius: "12px",
													borderBottomRightRadius: "12px",
													marginTop: "-1vh",
												}}
											>
												<Grid container={true}>
													<Grid
														item={true}
														xs={1.5}
														sx={{ display: "flex", alignSelf: "center" }}
													>
														<img
															src={Calender}
															alt="calender-icon"
															style={{ marginLeft: "3vh", marginTop: "2vh" }}
														/>
													</Grid>
													<Grid item={true} xs={9.5} sx={{ marginTop: "2vh" }}>
														<Grid container={true}>
															<Typography
																style={{
																	fontFamily: "Lexend",
																	fontSize: "16px",
																	fontWeight: 500,
																	lineHeight: "20px",
																	letterSpacing: "0em",
																	textAlign: "left",
																	color: "#1D2D53",
																	overflow: "hidden",
																	whiteSpace: "nowrap",
																	textOverflow: "ellipsis",
																}}
															>
																EVENT :{" "}
																{post?.eventDto?.title?.substring(0, 20)}
															</Typography>
														</Grid>
														<Grid item={true} xs={12}>
															<Typography
																style={{
																	fontFamily: "Lexend",
																	fontSize: "14px",
																	fontWeight: 400,
																	lineHeight: "18px",
																	textAlign: "left",
																	color: "#5B5B65",
																	overflow: "hidden",
																	whiteSpace: "nowrap",
																	textOverflow: "ellipsis",
																}}
															>
																{post.eventDto?.description !== "null" &&
																post.eventDto?.description !== null
																	? post.eventDto?.description.length > 30
																		? `${post.eventDto?.description.substring(
																				0,
																				30
																		  )}...`
																		: post.eventDto?.description
																	: ""}
															</Typography>
														</Grid>
													</Grid>
													{isEventAndGigDeleted("This event is deleted", post)}
												</Grid>
												<Grid item container={true} xs={12}>
													<Grid item={true} xs={6}>
														{post.postType === "EVENT" ? (
															<Typography
																variant="body1"
																style={{
																	fontFamily: "Lexend",
																	fontSize: "14px",
																	fontWeight: 500,
																	lineHeight: "20px",
																	letterSpacing: "0em",
																	textAlign: "left",
																	color: "#5B5B65",
																	justifySelf: "center",
																	marginLeft: "20px",
																	marginTop: "2vh",
																	width: "100%",
																}}
															>
																{(() => {
																	const startDateTimeString: string =
																		post.eventDto?.startDateTime;
																	const startDate: Date = new Date(
																		startDateTimeString
																	);
																	const durationInMinutes: number =
																		post.eventDto?.duration;

																	const hours: number = Math.floor(
																		durationInMinutes / 60
																	);
																	const minutes: number =
																		durationInMinutes % 60;
																	const formattedDuration: string = `${hours} Hr ${minutes} Min`;

																	const options: Intl.DateTimeFormatOptions = {
																		month: "short",
																		day: "numeric",
																		year: "numeric",
																		hour: "numeric",
																		minute: "numeric",
																		hour12: true,
																	};

																	const formattedDate: string =
																		startDate.toLocaleDateString(
																			"en-US",
																			options
																		);

																	return `${formattedDate} | ${formattedDuration}`;
																})()}
															</Typography>
														) : null}
													</Grid>
													<Grid item={true} xs={6}>
														{post.eventDto?.venue !== null &&
														post.eventDto?.venue !== "" ? (
															<Typography
																variant="body1"
																style={{
																	fontFamily: "Lexend",
																	fontSize: "14px",
																	fontWeight: 500,
																	lineHeight: "20px",
																	letterSpacing: "0em",
																	textAlign: "left",
																	color: "#5B5B65",
																	justifySelf: "center",

																	marginTop: "2vh",
																}}
															>
																{`${
																	(
																		post.eventDto?.venue?.replace(/#/g, "") +
																		", " +
																		post.eventDto?.cityCountry
																	).length > 30
																		? (
																				post.eventDto?.venue?.replace(
																					/#/g,
																					""
																				) +
																				", " +
																				post.eventDto?.cityCountry
																		  ).substring(0, 30) + "..."
																		: post.eventDto?.venue?.replace(/#/g, "") +
																		  ", " +
																		  post.eventDto?.cityCountry
																}`}
															</Typography>
														) : (
															<Typography
																variant="body1"
																style={{
																	fontFamily: "Lexend",
																	fontSize: "14px",
																	fontWeight: 500,
																	lineHeight: "20px",
																	letterSpacing: "0em",
																	textAlign: "left",
																	color: "#5B5B65",
																	justifySelf: "center",
																	marginTop: "2vh",
																}}
															>
																{`${
																	post.eventDto?.cityCountry.length > 30
																		? post.eventDto?.cityCountry.substring(
																				0,
																				30
																		  ) + "..."
																		: post.eventDto?.cityCountry
																}`}
															</Typography>
														)}
													</Grid>
												</Grid>
											</Grid>
										</Link>
									</>
								) : (
									""
								)}

								{/* gigs */}
								{post.postType === "GIG" ? (
									<>
										<Link
											to={
												!post?.eventDto?.deleted
													? `/gigs/gigDetails/${post?.eventDto?.eventsUuid}`
													: "#"
											}
											style={{
												textDecoration: "none",
												width: "100%",
												pointerEvents: post.eventDto?.deleted ? "none" : "auto",
											}}
										>
											<Grid item={true} xs={12}>
												<img
													alt=""
													src={
														post?.eventDto?.bannerUrl !== null &&
														post?.eventDto?.bannerUrl !== ""
															? `${process.env.REACT_APP_PICTURE_URL}${post?.eventDto?.bannerUrl}`
															: `${process.env.REACT_APP_PICTURE_URL}/defaultimages/gig-banner.png`
													}
													style={{
														borderTopLeftRadius: "12px",
														width: "100%",
														borderTopRightRadius: "12px",
													}}
												/>
											</Grid>
											<Grid
												item={true}
												xs={12}
												sx={{
													background: post?.eventDto?.deleted
														? "#f5ecea"
														: "#F5F5F5",
													paddingBottom: "3vh",
													borderBottomLeftRadius: "12px",
													borderBottomRightRadius: "12px",
													marginTop: "-1vh",
												}}
											>
												<Grid container={true} item xs={12}>
													<Grid
														item={true}
														xs={1.5}
														sx={{ display: "flex", alignSelf: "center" }}
													>
														<img
															src={Calender}
															alt="calender-icon"
															style={{ marginLeft: "3vh", marginTop: "2vh" }}
														/>
													</Grid>
													<Grid item={true} xs={9.5} sx={{ marginTop: "2vh" }}>
														<Grid container={true}>
															<Typography
																style={{
																	fontFamily: "Lexend",
																	fontSize: "16px",
																	fontWeight: 500,
																	lineHeight: "20px",
																	letterSpacing: "0em",
																	textAlign: "left",
																	color: "#1D2D53",
																	overflow: "hidden",
																	whiteSpace: "nowrap",
																	textOverflow: "ellipsis",
																}}
															>
																GIG : {post?.eventDto?.title?.substring(0, 20)}
															</Typography>
														</Grid>
														<Grid item={true} xs={12}>
															<Typography
																style={{
																	fontFamily: "Lexend",
																	fontSize: "14px",
																	fontWeight: 400,
																	lineHeight: "18px",
																	textAlign: "left",
																	color: "#5B5B65",
																	overflow: "hidden",
																	whiteSpace: "nowrap",
																	textOverflow: "ellipsis",
																}}
															>
																{post?.eventDto?.requirements !== "undefined" &&
																post?.eventDto?.requirements !== null
																	? post?.eventDto?.requirements.length > 30
																		? `${post?.eventDto?.requirements.substring(
																				0,
																				30
																		  )}...`
																		: post?.eventDto?.requirements
																	: ""}
															</Typography>
														</Grid>
													</Grid>
													{isEventAndGigDeleted("This gig is deleted", post)}
												</Grid>
												<Grid container={true} xs={12}>
													<Grid item={true} xs={6}>
														{post.postType === "GIG" ? (
															<Typography
																variant="body1"
																style={{
																	fontFamily: "Lexend",
																	fontSize: "14px",
																	fontWeight: 500,
																	lineHeight: "20px",
																	letterSpacing: "0em",
																	textAlign: "left",
																	color: "#5B5B65",
																	justifySelf: "center",
																	marginLeft: "20px",
																	marginTop: "2vh",
																	width: "100%",
																}}
															>
																{(() => {
																	const startDateTimeString: string =
																		post?.eventDto?.startDateTime;
																	const startDate: Date = new Date(
																		startDateTimeString
																	);
																	const durationInMinutes: number =
																		post?.eventDto?.duration;

																	const hours: number = Math.floor(
																		durationInMinutes / 60
																	);
																	const minutes: number =
																		durationInMinutes % 60;
																	const formattedDuration: string = `${hours} Hr ${minutes} Min`;

																	const options: Intl.DateTimeFormatOptions = {
																		month: "short",
																		day: "numeric",
																		year: "numeric",
																		hour: "numeric",
																		minute: "numeric",
																		hour12: true,
																	};

																	const formattedDate: string =
																		startDate.toLocaleDateString(
																			"en-US",
																			options
																		);

																	return `${formattedDate} | ${formattedDuration}`;
																})()}
															</Typography>
														) : null}
													</Grid>
													<Grid item={true} xs={6}>
														{post?.eventDto?.venue !== null &&
														post?.eventDto?.venue !== "" ? (
															<Typography
																variant="body1"
																style={{
																	fontFamily: "Lexend",
																	fontSize: "14px",
																	fontWeight: 500,
																	lineHeight: "20px",
																	letterSpacing: "0em",
																	textAlign: "left",
																	color: "#5B5B65",
																	justifySelf: "center",
																	marginLeft: "10vh",
																	marginTop: "2vh",
																}}
															>
																{`${
																	(
																		post.eventDto?.venue?.replace(/#/g, "") +
																		", " +
																		post?.eventDto?.cityCountry
																	).length > 30
																		? (
																				post.eventDto?.venue?.replace(
																					/#/g,
																					""
																				) +
																				", " +
																				post?.eventDto?.cityCountry
																		  ).substring(0, 30) + "..."
																		: post.eventDto?.venue?.replace(/#/g, "") +
																		  ", " +
																		  post?.eventDto?.cityCountry
																}`}
															</Typography>
														) : (
															<Typography
																variant="body1"
																style={{
																	fontFamily: "Lexend",
																	fontSize: "14px",
																	fontWeight: 500,
																	lineHeight: "20px",
																	letterSpacing: "0em",
																	textAlign: "left",
																	color: "#5B5B65",
																	justifySelf: "center",
																	marginTop: "2vh",
																}}
															>
																{`${
																	post?.eventDto?.cityCountry?.length > 30
																		? post?.eventDto?.cityCountry?.substring(
																				0,
																				30
																		  ) + "..."
																		: post?.eventDto?.cityCountry
																}`}
															</Typography>
														)}
													</Grid>
												</Grid>
											</Grid>
										</Link>
									</>
								) : (
									""
								)}
							</>
						)}

						<Grid item xs={2}>
							{likeStatus ? (
								<IconButton
									color="primary"
									size="small"
									disabled={
										user.userAccountStatus === "SUSPEND" ||
										postDeletedBy === "ADMIN" ||
										// user.authorities?.[0].name === "ROLE_ADMIN" ||
										isAdmin ||
										post.isHidden
									}
									onClick={() => handleLike(false)}
								>
									<ThumbUpIcon
										sx={{ color: "#3F77D7", height: "24px", width: "24px" }}
									/>
									<Typography
										variant="body2"
										sx={{
											color: "#5D5D65",
											fontSize: "14px",
											marginLeft: "5px",
										}}
									>
										{/* {totalLikeCount} */}
										{/* {totalLikeCount <= 1 ? " Like" : " Likes"} */}
										Like
									</Typography>
								</IconButton>
							) : (
								<IconButton
									color="primary"
									size="small"
									disabled={
										user.userAccountStatus === "SUSPEND" ||
										postDeletedBy === "ADMIN" ||
										// user.authorities?.[0].name === "ROLE_ADMIN" ||
										isAdmin ||
										post.isHidden
									}
									onClick={() => handleLike(true)}
								>
									<img
										src={
											user.userAccountStatus === "SUSPEND" ||
											postDeletedBy === "ADMIN" ||
											// user.authorities?.[0].name === "ROLE_ADMIN" ||
											post.isHidden
												? LikeDisabled
												: Like
										}
										alt="Like"
										style={{ height: "24px", width: "24px" }}
									/>
									<Typography
										variant="body2"
										sx={{
											color: "#5D5D65",
											fontSize: "14px",
											marginLeft: "5px",
										}}
									>
										{/* {totalLikeCount} */}
										{/* {totalLikeCount <= 1 ? " Like" : " Likes"} */}
										Like
									</Typography>
								</IconButton>
							)}
						</Grid>
						<Grid item xs={3}>
							<IconButton
								color="primary"
								size="small"
								disabled={postDeletedBy === "ADMIN" || post.isHidden || isAdmin}
								onClick={() => viewCommentsList()}
								disableRipple
								sx={{
									"&:hover": {
										backgroundColor: isCommentHovered
											? "#F5F5F5"
											: "transparent",
									},
									"& .MuiButtonBase-root.MuiIconButton-root.Mui-disabled": {
										backgroundColor: "#F5F5F5",
									},
									"&:active": {
										color: "#F5F5F5",
									},
									borderRadius: "8px",
									marginBottom: "3px",
								}}
							>
								<img
									src={
										postDeletedBy === "ADMIN" || post.isHidden
											? MessageIconDisabled
											: MessageIcon
									}
									alt="Comment"
									style={{ height: "24px", width: "24px" }}
								/>
								&nbsp;
								<Typography
									variant="body2"
									sx={{
										color:
											postDeletedBy === "ADMIN" || post.isHidden
												? "#888888"
												: "#5D5D65",
										fontSize: "14px",
										padding: isCommentHovered ? "4px 8px" : "4px 8px",
										display: "inline-flex",
										alignItems: "center",
										cursor: "pointer",
									}}
									onMouseEnter={() => setIsCommentHovered(true)}
									onMouseLeave={() => setIsCommentHovered(false)}
								>
									Comment
								</Typography>
							</IconButton>
						</Grid>

						<Grid
							item
							xs={3}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
							}}
						>
							{totalLikeCount > 0 ? (
								<div
									onMouseEnter={handleMouseEnter}
									onMouseLeave={handleMouseLeave}
								>
									<Typography
										variant="body2"
										sx={{
											color: isHovered ? "#0273E6" : "#5D5D65", // Change color when hovered
											borderBottom: isHovered ? "2px solid #0273E6" : "none", // Change color when hovered
											lineHeight: isHovered ? "17.6px" : "19.6px",
											fontSize: "14px",
											fontWeight: "500",
											marginLeft: "5px",
											marginRight: "20px",
											cursor: "pointer", // Optionally change cursor to pointer when hovered
										}}
										onClick={viewLikeUserList}
									>
										{totalLikeCount}
										{totalLikeCount <= 1 ? " Like" : " Likes"}
									</Typography>{" "}
								</div>
							) : (
								""
							)}
						</Grid>
						<Grid
							item
							xs={3}
							sx={{ display: "flex", alignSelf: "center", textAlign: "left" }}
						>
							{totalCommentCount ? (
								<Typography
									variant="body2"
									sx={{
										color: "#5D5D65",
										fontSize: "14px",
										lineHeight: "19.6px",
										fontWeight: "500",
										cursor: "pointer", // Optionally change cursor to pointer when hovered
										"&:hover": {
											color: "#0273E6",
											borderBottom: "2px solid #0273E6", // Change color when hovered
											lineHeight: "17.6px",
										},
									}}
									onClick={() => viewCommentsList()}
								>
									{totalCommentCount}{" "}
									{totalCommentCount === 1 ? "Comment" : "Comments"}
								</Typography>
							) : (
								""
							)}
						</Grid>
						<Grid
							item
							xs={1}
							sx={{ alignSelf: "center", textAlign: "left" }}
						></Grid>
					</Grid>
					{viewComment && (
						<CommentList
							commentDto={commentDto}
							postId={post?.id}
							postUUID={post?.postUUID}
							totalCommentCount={totalCommentCount}
							setTotalCommentCount={setTotalCommentCount}
							postCreateBy={post.userProfileId}
						/>
					)}
				</CardContent>
			</Card>

			{isModalOpen && (
				<ViewImage
					open={isModalOpen}
					handleClose={() => setModalOpen(false)}
					ImageUrl={`${process.env.REACT_APP_PICTURE_URL}${post.mediaUrl}`}
				/>
			)}

			{isViewUserModalOpen && (
				<ViewLikeUsers
					open={isViewUserModalOpen}
					likedDto={likedDto}
					handleClose={() => {
						handleCloseLikeDialog();
					}}
					//ImageUrl={`${process.env.REACT_APP_PICTURE_URL}${post.mediaUrl}`}
					postId={post?.id}
					post={post}
					profileUrl={profileUrl}
				/>
			)}
			{openDeleteDialog && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteDialog}
					onClose={() => handleCloseDeleteDialog()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeleteDialog()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Delete Post</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure you want to remove post?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={() => handleCloseDeleteDialog()}
						>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => handleDeletePost()}>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}

			<ConnectRequestDialog
				open={openConnectDialog}
				user={user}
				handleClose={handleCloseConnectDialog}
				setLoading={setIsLoading}
				userProfileId={post.userProfileId}
				userProfileUUID={post?.userProfileUUID}
				setConnectionStatus={setConnectionStatus}
				post={true}
				updateConnectionStatus={updateConnectionStatus}
			/>
			<ReportPost
				open={openReportPost}
				handleClose={handleCloseReportPost}
				userProfileId={post.userProfileId}
				userProfileUUID={post?.userProfileUUID}
				postId={post?.id}
				setLoading={setIsLoading}
				updatePostAfterDelete={updatePostAfterDelete}
				setIsReported={setIsReported}
				setConnectionStatus={setConnectionStatus}
				removeBlockedUsersPosts={removeBlockedUsersPosts}
				usersReportForPostMap={selectedViolations}
				setSelectedViolations={setSelectedViolations}
				postUUID={post?.postUUID}
				musicUUID={post?.myMusicUUID}
				offenderUserProfileUUID={post?.userProfileUUID}
			/>
			<CreatePostNew
				open={openEditPost}
				postType={post.postType}
				mediaUrl={
					post.mediaUrl
						? `${process.env.REACT_APP_PICTURE_URL}${post.mediaUrl}`
						: ""
				}
				handleClose={handleEditPostClose}
				resetPostDetails={resetPostDetails}
				propPostText={post.feedText}
				postVisibility={post.postVisibility}
				editPost={true}
				postId={post?.id}
				postUUID={post?.postUUID}
				musicId={post.musicId}
				musicUUID={post.myMusicUUID}
				eventPostDto={post.eventDto}
				tagRecordsDto={post.tagRecordsDto}
			/>

			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="xs"
				onClose={() => setRemoveConfirmation(false)}
				open={removeConfirmation}
			>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							className="dialog-title-custom-size"
							sx={{
								fontWeight: 500,
								color: "#000000",
								fontSize: "24px !important",
							}}
						>
							{i18n.musicians.connection.removeConnectionTitle}
						</Typography>
					</Box>
				</DialogTitle>

				<DialogContent>
					<Grid
						container
						// columnSpacing={2}
					>
						<Typography
							className="dialog-title-custom-sizea"
							variant="h4"
							sx={{
								fontWeight: 500,
								color: "#000000",
								fontSize: "16px !important",
								textAlign: "center",
							}}
						>
							{i18n.musicians.connection.removeConfirmationTitle} {post.name}
						</Typography>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						columnSpacing={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Grid item={true}>
							<Button
								className="cancel-button"
								size="medium"
								sx={{
									fontSize: "18px",
								}}
								// endIcon={<img src={RightAarrow} alt="Right Arrow" />}
								variant="outlined"
								onClick={() => setRemoveConfirmation(false)}
							>
								{i18n.dialog.cancel}
							</Button>
						</Grid>
						<Grid item={true}>
							<Button
								className="submit-button"
								size="small"
								variant="contained"
								// disabled={serialNumber === "" || purchaseDate === null || (purchaseDate !== null && purchaseDate >= new Date())}
								onClick={() =>
									sendConnectionRequest(
										post?.userProfileId,
										"REMOVED",
										post?.userProfileUUID
									)
								}
								// disabled={connnectionRequestNote.length > 255}
								// endIcon={<img src={RightArrow} alt="Down Arrow" />}
							>
								<Typography sx={{ marginRight: "0px !important " }}>
									{i18n.musicians.confirm}
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Post;
