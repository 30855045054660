import { Button, Typography } from "@mui/material";
import ActionDialog from "../../reusable-components/ActionDialog";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDailyAppRoom } from "../Hooks/useDailyGlobalRoom";

type StartLiveStreamProps = {
    twilioSessionId: string;
    endLiveStream: () => void;
    openEndLiveStreamDialog: boolean;
    handleCloseEndLiveStreamDialog: () => void;
}

export const Heading = styled(Typography)(({ theme }) => ({
    fontFamily: "Lexend",
    color: "#7A7985",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "20px",
}));


const EndLiveStream: React.FC<StartLiveStreamProps> = (props) => {
    const { allParticipantsCount } = useDailyAppRoom();
    const userSession = localStorage.getItem("userSession") ?? "";
    const userSessions = JSON.parse(userSession);
    const [liveStreamDuration, setLiveStreamDuration] = useState("");

    useEffect(() => {
        if (userSessions?.dailyLiveStreamStartTime) {
            const liveStreamTimeDiff = Date.now() - new Date(userSessions?.dailyLiveStreamStartTime).getTime();
            setLiveStreamDuration(formatLiveStreamTimer(liveStreamTimeDiff));
        }
    }, [userSessions?.dailyLiveStreamStartTime, props.openEndLiveStreamDialog]);



    function formatLiveStreamTimer(recordingTimer: number): string {
        // Convert time difference to hours and minutes
        const totalMinutes = Math.floor(recordingTimer / (1000 * 60));
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        // Return formatted string based on totalMinutes
        if (totalMinutes >= 60) {
            return `${hours}:${minutes.toString().padStart(2, '0')} hrs`;
        } else {
            return `${totalMinutes} min`;
        }
    }


    return (
        <ActionDialog
            open={props.openEndLiveStreamDialog}
            handleClose={props.handleCloseEndLiveStreamDialog}
            showCloseIcon={false}
            dialogWidth="37.5rem"
            title="Do you want to end Live Streaming?"
            titleStyle={{
                color: "#000",
                fontSize: "1.375rem",
                fontWeight: "500",
            }}
            titleAlignment="left"
            buttonsAlignment="right"
            actionButtons={
                <>
                    <Button
                        sx={{
                            width: "7.812rem",
                            height: "2.25rem",
                            borderRadius: "18px",
                        }}
                        className="cancel-button"
                        size="large"
                        variant="outlined"
                        onClick={props.handleCloseEndLiveStreamDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            width: "15.562rem",
                            height: "2.25rem",
                            borderRadius: "18px",
                            backgroundColor: "#EB2A00"
                        }}
                        className="submit-button"
                        size="large"
                        variant="contained"
                        onClick={() => {
                            props.endLiveStream();
                            props.handleCloseEndLiveStreamDialog();
                        }}
                    >
                        End Live Streaming
                    </Button>
                </>
            }
        >
            <>
                <Heading sx={{ mb: 2 }}>
                    Streaming Duration: <span style={{ fontWeight: 400, color: "#000", fontSize: "18px" }}>{liveStreamDuration}</span>
                </Heading>
                <Heading>
                    Live audience: <span style={{ fontWeight: 400, color: "#000", fontSize: "18px" }}>{allParticipantsCount?.hidden}</span>
                </Heading>
            </>
        </ActionDialog>
    );
}

export default EndLiveStream;