import { IconButton, Snackbar } from '@mui/material'
import React from 'react'
import CloseIcon from "../../../images/none.svg";
import { useDailyAppRoom } from '../Hooks/useDailyGlobalRoom';
import { getUserAvatar } from '../../MixerSlider/MixerSlider';
import { NewMessageWrapper, NewMessageText } from './ChatStyled';
import { getUserInfo } from './helper';

export default function ChatMessageAlert() {
	const { dailyChatMessages, showNewMessageNotification, setShowNewMessageNotification} = useDailyAppRoom();
	const lastMessage = dailyChatMessages[dailyChatMessages.length - 1];

	const message = () => {
		return (
			<NewMessageWrapper>
				{getUserAvatar(getUserInfo(lastMessage?.name), true)}
				<NewMessageText> {lastMessage?.msg}</NewMessageText>
			</NewMessageWrapper>
		)
	}
	return (
		<Snackbar
			sx={{
				"&.MuiSnackbar-anchorOriginTopRight": {
					// top: isFullscreenMode ? "70px" : "140px",
					top: "140px",
					"& .MuiSnackbarContent-root": {
						background: "#000",
						border: "1px solid #5C5C5C",
						borderRadius: "6px",
						color: "#C5C5C5",
						alignItems: "flex-start"
					},
				},
			}}
			open={showNewMessageNotification}
			onClose={() => setShowNewMessageNotification(false)}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			message={message()}
			autoHideDuration={5000}
			action={
				<IconButton onClick={() => setShowNewMessageNotification(false)}>
					<img src={CloseIcon} alt="close" />
				</IconButton>
			}
		/>
		

	)
}
