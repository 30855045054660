import { useState, useEffect, useRef } from "react";
import { Alert, Autocomplete, TextField } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const GoogleLocationService = (props: any) => {
	const { placePredictions, getPlacePredictions } = usePlacesService({
		apiKey: process.env.REACT_APP_GOOOGLE_MAPS_API_KEY,
	});

	const timeoutIdRef = useRef<any>(undefined);

	const [location, setLocation] = useState(
		props.defaultValue
			? { description: props.defaultValue }
			: { description: "" }
	);
	const [inputValue, setInputValue] = useState(props.defaultValue || ""); // Tracking input changes separately
	const [showAlert, setShowAlert] = useState(false);

	useEffect(() => {
		if (props.defaultValue !== location.description) {
			setLocation({ description: props.defaultValue });
			setInputValue(props.defaultValue || "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.defaultValue]);

	const handleBlur = () => {
		// Check if the input value matches any prediction
		const isValid = placePredictions.some(
			(prediction) => prediction.description === inputValue
		);

		if (!isValid && inputValue.trim()) {
			setShowAlert(true);
			setLocation({ description: "" }); // Reset invalid location
			props.onLocationChange(""); 
		} else {
			setShowAlert(false);
		}
	};

	const handleAutocompleteChange = (event: any, item: any) => {
		if (item?.description) {
			setLocation(item);
			setInputValue(item.description);
			setShowAlert(false);
			props.onLocationChange(item.description);
		} else {
			setLocation({ description: "" });
			setInputValue("");
			props.onLocationChange("");
		}
	};

	const handleInputChange = (event: any, newInput: string) => {
		setInputValue(newInput); // Tracking current input
		clearTimeout(timeoutIdRef.current);
		timeoutIdRef.current = setTimeout(() => {
			if (newInput?.length > 2) {
				getPlacePredictions({ input: newInput });
			}
		}, 200);
		setShowAlert(false);
	};

	const cityPredictions = placePredictions.filter((prediction) =>
		prediction.types.includes("locality")
	);

	return (
		<>
			<Autocomplete
				options={cityPredictions}
				freeSolo
				getOptionLabel={(option) =>
					typeof option === "string" ? option : option.description
				}
				onChange={handleAutocompleteChange}
				renderInput={(params) => (
					<TextField
						{...params}
						autoFocus={false}
						error={props.isError}
						onBlur={handleBlur}
					/>
				)}
				onInputChange={(event, newInput) => handleInputChange(event, newInput)}
				value={location.description || ""}
				inputValue={inputValue} 
				style={{ background: "#F8F8F8", width: "100%" }}
			/>
			{showAlert && (
				<Alert severity="error" style={{ marginTop: "8px", color: "red" }}>
					Please select a city from the dropdown menu.
				</Alert>
			)}
		</>
	);
};

export default GoogleLocationService;
