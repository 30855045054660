import React, { createContext, useContext, useState, useCallback, ReactNode, useEffect } from 'react';
import { DailyCall as DailyCallType, DailyEventObjectParticipant } from '@daily-co/daily-js';
import { DailyChatMessage } from '../Types/dailyTypes';
import {isEqual, throttle} from 'lodash';
import WebsocketClientUSerStatus from '../../../services/WebsocketClientUSerStatus';
import { normalizeAudioControls } from '../../Mixer/utils';
import { dailyQualityInitialStatus } from '../Constants';

export type DailyQualityStatus = {
  call: { status: string; message: string, icon: any,  inProgress: boolean };
  network: { status: string; message: string, icon: any,  inProgress: boolean };
  ws: { status: string; message: string, icon: any,  inProgress: boolean };
  networkStats?: Object;
  currentStep: number
};

type DailyAppRoomContextType = {
  startLeavingDailyCall: (callback?: () => void) => void;
  dailyAppCallObject: DailyCallType | null;
  setDailyAppCallObject: React.Dispatch<React.SetStateAction<DailyCallType | null>>;
  isStreamingView:  boolean,
  setIsStreamingView: Function,
  streamingViewType: "soloFocus" | "soloRotate" | "ensemble" | "initial",
  setStreamingViewType: Function,
  allParticipantsCount: { present: number; hidden: number },
  isChatOpen: boolean,
  setIsChatOpen: Function,
  isParticipantsOpen: boolean, 
  setParticipantsOpen: Function,
  isMixerSliderOpen: boolean, 
  setIsMixerSliderOpen: Function,
  isMixerOpen: boolean, 
  setIsMixerOpen: Function,
  dailyChatMessages: DailyChatMessage[];
  setDailyChatMessages: Function;
  hasNewChatMessage: boolean,
  setHasNewChatMessage: Function,
  showNewMessageNotification: boolean,
  setShowNewMessageNotification: Function,
  isScreenSharing: boolean,
  setIsScreenSharing: Function,
  toggleSendingAudioFromDevice: (params: {userId: number, dailySessionId: string, cmd: string }) => void;
  sessionParticipantsVolumeDetails: any[];
  setsessionParticipantsVolumeDetails: Function;
  dailyQualityStatus: DailyQualityStatus,
  setDailyQualityStatus: Function;
  sessionParticipantsNetworkDetails:any[];
  setsessionParticipantsNetworkDetails: Function;
};

interface DailyRoomProviderProps {
  children: ReactNode;
}

const DailyAppRoomContext = createContext<DailyAppRoomContextType | undefined>(undefined);

export const useDailyAppRoom = () => {
  const context = useContext(DailyAppRoomContext);
  if (!context) {
    throw new Error('useDailyAppRoom must be used within a DailyAppRoomProvider');
  }
  return context;
};

export const DailyAppRoomProvider: React.FC<DailyRoomProviderProps> = ({ children }) => {
  const [dailyChatMessages, setDailyChatMessages] = useState<DailyChatMessage[]>([])
  const [hasNewChatMessage, setHasNewChatMessage] = useState(false);
  const [showNewMessageNotification, setShowNewMessageNotification] = useState(false);
  const [dailyAppCallObject, setDailyAppCallObject] = useState<DailyCallType | null>(null);
  const [isStreamingView, setIsStreamingView] = useState(false);
  const [streamingViewType, setStreamingViewType] = useState<"soloFocus" | "soloRotate" | "ensemble" | "initial">("initial");
  const [allParticipantsCount, setAllParticipantsCount] = useState<{ present: number; hidden: number }>({ present: 0, hidden: 0 });
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isParticipantsOpen, setParticipantsOpen] = useState(false);
  const [isMixerSliderOpen, setIsMixerSliderOpen] = useState(false);
  const [isMixerOpen, setIsMixerOpen] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [isAudioBeingSentToDaily, setIsAudioBeingSentToDaily] = useState(false);
  const [sessionParticipantsVolumeDetails, setsessionParticipantsVolumeDetails] = useState([]);
  const [dailyQualityStatus, setDailyQualityStatus] = useState(dailyQualityInitialStatus);
  const [sessionParticipantsNetworkDetails, setsessionParticipantsNetworkDetails] = useState([]);
  

  const startLeavingDailyCall = useCallback(
    (callback?: () => void) => {
      if (!dailyAppCallObject) return;

      dailyAppCallObject
        .leave()
        .then(() => {
          setDailyAppCallObject(null);
          setDailyQualityStatus(dailyQualityInitialStatus);
          if (callback) callback();
        })
        .catch((error) => {
          console.error('Error leaving the call:', error);
        });
    },
    [dailyAppCallObject]
  );

  // This needs to be improved and store the data on server for proper sync. I'll update this soon.
  useEffect(() => {
    if (!dailyAppCallObject) return;

    // Reset Chat and Messages
    setDailyChatMessages([]);
    setHasNewChatMessage(false);
    setShowNewMessageNotification(false);

    const handleParticipantJoined = (event: DailyEventObjectParticipant) => {
      // console.log('Participant joined:', event.participant, allParticipantsCount);
      setAllParticipantsCount(dailyAppCallObject.participantCounts());
      // setAllParticipants(prevParticipants => [...prevParticipants, event]);
    };

    const handleParticipantLeft = (event: any) => {
      // console.log('Participant left:', event.participant, allParticipantsCount);
      setAllParticipantsCount(dailyAppCallObject.participantCounts());
      // setAllParticipants(prevParticipants => 
      //   prevParticipants.filter(p => p.participant?.user_name !== event.user_name)
      // );
    };

    const handleParticipantUpdated = (event: DailyEventObjectParticipant) => {
      // console.log('Participant updated:', event.participant, allParticipantsCount);
      setAllParticipantsCount(dailyAppCallObject.participantCounts());
      // setAllParticipants(prevParticipants => 
      //   prevParticipants.map(p => 
      //     p.participant.user_name === event.participant.user_name ? event : p
      //   )
      // );
    };

    dailyAppCallObject.on('participant-joined', handleParticipantJoined);
    dailyAppCallObject.on('participant-left', handleParticipantLeft);
    dailyAppCallObject.on('participant-updated', handleParticipantUpdated);

    // Initialize participants
    const initialParticipants = dailyAppCallObject?.participantCounts()
    setAllParticipantsCount(initialParticipants);

    // MessageHandler
    dailyAppCallObject.on('app-message', receiveMessage);


    const validateParticipants = throttle(() => {
      setAllParticipantsCount(dailyAppCallObject.participantCounts());
    }, 5000);
    const intervalId = setInterval(validateParticipants, 5000);

    // 
    WebsocketClientUSerStatus.on("sessionBroadcasting", onMessageReceivedSessionBroadcasting)
    WebsocketClientUSerStatus.on("subcribed", onStartSessionMessageReceived);

    return () => {
      dailyAppCallObject.off('participant-joined', handleParticipantJoined);
      dailyAppCallObject.off('participant-left', handleParticipantLeft);
      dailyAppCallObject.off('participant-updated', handleParticipantUpdated);
      dailyAppCallObject.off('app-message', receiveMessage);
      WebsocketClientUSerStatus.off("sessionBroadcasting", onMessageReceivedSessionBroadcasting)
      WebsocketClientUSerStatus.off("subcribed", onStartSessionMessageReceived);
      clearInterval(intervalId)
    };
  }, [dailyAppCallObject]);


  useEffect(()=> {
    if(isChatOpen){
      // Reset the new message status
      setHasNewChatMessage(false);
      setShowNewMessageNotification(false);
    }
  },[isChatOpen])

  const receiveMessage =  (e: any ) => {
    // console.log("Message ======", e.data)
    handleMessage(e);
  };

  const handleMessage = (e: any) => {
    if(!e.data.msg){
      return;
    }else{
      setDailyChatMessages((existingMessages) => {
        // Check if the message already exists
        const isMessageExist = existingMessages.some(item => item.messageId === e.data.messageId);
        // Only add the message if it doesn't exist
        if (!isMessageExist) {
          return [...existingMessages, e.data];
        }
        // Return the existing messages without adding the duplicate
        return existingMessages;
      });
      setHasNewChatMessage(true);
      setShowNewMessageNotification(true);
    }
  }
  

  const onStartSessionMessageReceived = (payload: any) => {
    let payloadData: any = JSON.parse(payload.body);
    if (payloadData.action === "start_session") {

      if ( payloadData && payloadData.metadata.session_status === "SESSION_STARTED") {
        let participantDetailList = JSON.parse(
          localStorage.getItem("participantVolumeDetails") ?? ""
        );
        participantDetailList = normalizeAudioControls(payloadData);
        if (!isEqual(sessionParticipantsVolumeDetails, participantDetailList)) {
          // console.log(`DailyAppRoomProvider | onAudioControlDetailsOfAllUsersRecieved and update from videoSession`, { payloadData, participantDetailList, sessionParticipantsVolumeDetails, time: new Date().toISOString() });
          setsessionParticipantsVolumeDetails(participantDetailList);
          localStorage.setItem("participantVolumeDetails", JSON.stringify(participantDetailList));
        }
      }
    }
  }

  const onMessageReceivedSessionBroadcasting = (responseMsg: any) => {
		// console.log('onMessageReceivedSessionBroadcasting', responseMsg)
    const status = responseMsg?.metadata?.status;
    const isStarted = status === "STARTED";
    setIsAudioBeingSentToDaily(isStarted);
	}

  const toggleSendingAudioFromDevice = ({userId, dailySessionId, cmd } : {userId: number, dailySessionId: string, cmd: string }) => {
    let requestMsgForDevice = {
      action: "session_broadcasting",
      timestamp: new Date(),
      session_id: dailySessionId,
      created_by: userId,
      broadcastingStatus: isAudioBeingSentToDaily ? "STOP" : "START",
    };
    // console.log("cleanupAndLeaveSession Live Stream message", requestMsgForDevice, isAudioBeingSentToDaily);
    if(cmd === 'START' && !isAudioBeingSentToDaily){
      WebsocketClientUSerStatus.handleBroadcasting(requestMsgForDevice);
    }
    if(cmd === 'STOP' && isAudioBeingSentToDaily){
      WebsocketClientUSerStatus.handleBroadcasting(requestMsgForDevice);
    }
  }


  return (
    <DailyAppRoomContext.Provider
      value={{
        startLeavingDailyCall,
        dailyAppCallObject,
        setDailyAppCallObject,
        isStreamingView,
        setIsStreamingView,
        streamingViewType,
        setStreamingViewType,
        allParticipantsCount,
        isChatOpen,
        setIsChatOpen,
        isParticipantsOpen, 
        setParticipantsOpen,
        isMixerSliderOpen, 
        setIsMixerSliderOpen,
        isMixerOpen,
        setIsMixerOpen,
        dailyChatMessages,
        setDailyChatMessages,
        hasNewChatMessage,
        setHasNewChatMessage,
        showNewMessageNotification,
        setShowNewMessageNotification,
        isScreenSharing,
        setIsScreenSharing,
        toggleSendingAudioFromDevice,
        sessionParticipantsVolumeDetails,
        setsessionParticipantsVolumeDetails,
        dailyQualityStatus,
        setDailyQualityStatus,
        sessionParticipantsNetworkDetails,
        setsessionParticipantsNetworkDetails
      }}
    >
      {children}
    </DailyAppRoomContext.Provider>
  );
};
