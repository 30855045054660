import Location from "../../../images/location.svg";
import ProfileAddWhite from "../../../images/profile-add-blue.svg";
import MutualConnection from "../../../images/peoples.svg";
import Archive from "../../../images/archive-add.svg";
import { Button, Grid, Stack, Typography, Avatar } from "@mui/material";
import { useUser } from "../../../context/UserContext";
import ConnectRequestDialog from "../connectRequestDialog";
import { useState } from "react";
import ErrorAlert from "../../Error/ErrorAlert";
import { useNavigate } from "react-router";
import { format, utcToZonedTime } from "date-fns-tz";
import useAdminAuthorized from "../../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
interface Artist {
	nickname: any;
	profilePictureUrl: string;
	name: string;
	location: string;
	profileTitle: string;
	userProfileId: number;
	userProfileUUID: string;
	canSendConnection: boolean;
	connected: boolean;
	connectionStatus: string;
	conneRequestFrom: number;
	conneRequestTo: number;
	userPrivacySettingsDTO: any;
	userInstrumentsList: any;
	noOfMutualConnections: any;
}

interface Event {
	id: number;
	profilePictureUrl: string;
	title: string;
	cityCountry: string;
	venue: string;
	description: string;
	bannerUrl: string;
	thumbnailUrl: string;
	startDateTime: Date;
	endDateTime: Date;
	eventsUuid: string;
}

interface RecommendationTemplateProps {
	artist?: Artist;
	event?: Event;
	getSimilarArtists?: any;
}

export const ReccomendationTemplate = ({
	artist,
	event,
	getSimilarArtists,
}: RecommendationTemplateProps) => {
	const user = useUser();
	const navigate = useNavigate();
	const token = localStorage.getItem("sessionId");
	const [openConnectDialog, setOpenConnectDialog] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [connectionStatus, setConnectionStatus] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showAlert, setShowAlert] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertMsg, setAlertMsg] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertStatus, setAlertStatus] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isLoading, setIsLoading] = useState(false);
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	const handleCloseConnectDialog = () => {
		setOpenConnectDialog(false);
	};

	const sendConnectionRequest = (
		requestTo: number | undefined,
		requestStatus: string,
		requestToUUID: string | undefined
	) => {
		try {
			fetch(`${process.env.REACT_APP_BASE_URL}/api/user-connection-requests`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					requestFromUUID: user?.userProfileUuid,
					requestToUUID: requestToUUID,
					connectionStatus: requestStatus,
				}),
			})
				.then((res) => {
					if (res.status === 502) {
						navigate("/defaultPage");
					} else if (res.status > 400) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
					} else {
						return res.json();
					}
				})
				.then((data) => {
					// updateConnectionStatus(post.userProfileId, data.connectionStatus, data.requestFrom.id, data.requestTo.id)
					// setConnectionStatus(data.connectionStatus)
					// props.getUserDetails();
				});
		} catch (error) {
			setShowAlert(true);
			setAlertStatus(false);
			setAlertMsg("Network Error! Please try again..");
		}
		// setOpenMenu(false)
	};

	const viewSocialProfile = (nickname: any) => {
		if (artist) {
			const encodedNickName = encodeURIComponent(nickname);
			navigate("/socialHome/" + encodedNickName);
		}
	};

	const handleAttendClick = (eventsUuid: string) => {
		navigate(`/events/eventDetails/${eventsUuid}`);
	};

	const extracttStateCity = (location: string) => {
		const updatedLocation =
			location && location?.split(",").slice(-3, -1).join(", ");
		return updatedLocation;
	};

	const time: Date | undefined = event?.startDateTime;
	let finalFormattedDate = "";
	if (time) {
		const newTime = new Date(time);
		finalFormattedDate = format(newTime, "MMM dd, hh:mm a");
	}

	return (
		<>
			<Grid
				container
				columnGap={1}
				sx={{ mb: 2 }}
				className="musicEventContainer"
			>
				<Grid item xs={3} className="musicEventGrid">
					{/* <img src={artist?.profilePictureUrl ? `${process.env.REACT_APP_PICTURE_URL}${artist?.profilePictureUrl}` : ''} alt="profile" /> */}

					{artist && (
						<Avatar
							alt={"artist?.name"}
							onClick={() => viewSocialProfile(artist?.nickname)}
							src={
								artist?.profilePictureUrl
									? `${process.env.REACT_APP_PICTURE_URL}${artist?.profilePictureUrl}`
									: ""
							}
							sx={{ height: "58px", width: "58px", ml: 1, cursor: "pointer" }}
						/>
					)}

					{event && (
						<img
							alt=""
							height="60"
							width="60"
							style={{ borderRadius: "8px", cursor: "pointer" }}
							onClick={() => handleAttendClick(event.eventsUuid)}
							src={
								event.thumbnailUrl &&
								event.thumbnailUrl !== "" &&
								event.thumbnailUrl !== "null" &&
								event.thumbnailUrl !== null
									? `${process.env.REACT_APP_PICTURE_URL}${event?.thumbnailUrl}`
									: `${process.env.REACT_APP_PICTURE_URL}/defaultimages/event-thumbnail.svg`
							}
						/>
					)}
					{event?.startDateTime && (
						<Typography
							sx={{
								fontSize: "14px",
								fontWeight: "500",
								fontFamily: "Lexend",
								color: "#000",
							}}
							variant="body1"
						>
							{finalFormattedDate}
						</Typography>
					)}
				</Grid>
				<Grid item xs={8.5}>
					<Stack rowGap={1}>
						<div
							style={{ cursor: (artist || event) && "pointer" }}
							onClick={() => {
								if (artist) {
									viewSocialProfile(artist?.nickname);
								}
								if (event) {
									handleAttendClick(event?.eventsUuid);
								}
							}}
						>
							<Typography
								variant="body1"
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									fontFamily: "Lexend",
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
								}}
							>
								{/* {artist &&
								artist.userPrivacySettingsDTO?.identityPermission ===
									"Name is not visible. Instead just show nickname"
									? artist.userInstrumentsList[0]?.userProfile?.nickname
									: artist && artist.name} */}

								{artist?.userProfileId === user?.userProfileId
									? artist?.name
									: artist?.userPrivacySettingsDTO?.identityPermission ===
									  "Name is not visible. Instead just show nickname"
									? `@` + artist?.userInstrumentsList[0]?.userProfile.nickname
									: artist?.userPrivacySettingsDTO?.identityPermission ===
									  "Name is visible to all"
									? artist?.name
									: artist?.userPrivacySettingsDTO?.identityPermission ===
									  "Name is visible only to my connections. Other see nickname"
									? artist?.connected
										? artist?.name
										: `@` + artist?.userInstrumentsList[0]?.userProfile.nickname
									: artist?.name}

								{event && (
									<>
										{event.title.length > 15 ? (
											<>{event.title.substring(0, 15)}...</>
										) : (
											event.title
										)}
									</>
								)}
							</Typography>
						</div>

						{event && (
							<Typography sx={{ display: "flex", fontSize: "12px" }}>
								<div style={{ marginRight: 5 }}>
									<img src={Location} alt="location" />
								</div>
								<Typography
									sx={{
										overflow: "hidden",
										display: "-webkit-box",
										WebkitBoxOrient: "vertical",
										WebkitLineClamp: 2,
										letterSpacing: "-0.14px",
										lineHeight: "normal",
									}}
								>
									{/* Folsom, California */}
									{/* {artist && extracttStateCity(artist.location)} */}
									{event &&
										(event.venue ? event.venue + ", " : "") +
											extracttStateCity(event.cityCountry)}
								</Typography>
							</Typography>
						)}
						{(artist?.profileTitle || event?.description) && (
							<div style={{ maxWidth: "100%", wordBreak: "break-word" }}>
								<Typography
									sx={{
										fontSize: "14px",
										fontWeight: "400",
										color: "#5B5B65",
										overflow: "hidden",
										display: "-webkit-box",
										WebkitBoxOrient: "vertical",
										WebkitLineClamp: 2,
										letterSpacing: "-0.14px",
										lineHeight: "normal",
										mb: artist || event ? "18px" : 0,
									}}
								>
									{artist && artist.profileTitle}
									{/* {event && event.description} */}
									{event &&
										event.description !== null &&
										event.description !== "null" && <>{event.description}</>}
								</Typography>
							</div>
						)}

						{artist && (
							<Typography
								variant="body1"
								sx={{
									display: "flex",
									alignItems: "center",
									fontSize: "12px",
									fontWeight: "400",
									color: "#7A7985",
								}}
							>
								<img
									src={MutualConnection}
									alt="mutual-connection"
									style={{ marginRight: "4px" }}
								/>
								{artist?.noOfMutualConnections} mutual
							</Typography>
						)}

						{
							artist &&
								user.userAccountStatus !== "SUSPEND" &&
								(artist.canSendConnection &&
								!artist.connected &&
								artist.userProfileId !== user.userProfileId &&
								(artist.connectionStatus === null ||
									artist.connectionStatus === "REMOVED" ||
									artist.connectionStatus === "WITHDRAWN" ||
									artist.connectionStatus === "REJECTED") ? (
									<Button
										color="info"
										sx={{
											width: "100%",
											border: "1.5px solid #0273E6",
											borderRadius: "15px",
											marginTop: 1,
										}}
										disabled={isAdmin}
										onClick={() => setOpenConnectDialog(true)}
										variant="outlined"
										startIcon={<img src={ProfileAddWhite} alt="Connect" />}
									>
										<Typography
											variant="h6"
											component="span"
											sx={{
												fontSize: "16px",
												fontWeight: "400",
												color: "#0273E6",
											}}
										>
											Connect
										</Typography>
									</Button>
								) : artist?.conneRequestFrom === user.userProfileId &&
								  artist.connectionStatus === "PENDING" ? (
									<Button
										variant="outlined"
										color="info"
										sx={{
											minWidth: "117px",
											width: "117px",
											fontSize: "16px",
											color: "#0273E6",
											borderColor: "#0273E6",
											marginLeft: "auto",
											height: "30px",
										}}
										onClick={() =>
											sendConnectionRequest(
												artist.userProfileId,
												"WITHDRAWN",
												artist?.userProfileUUID
											)
										}
									>
										{/* <img src={PeopleRemove} alt='' style={{ height: '18px', width: '18px' }} /> */}
										Withdraw
									</Button>
								) : artist?.conneRequestTo === user.userProfileId &&
								  artist.connectionStatus === "PENDING" ? (
									<>
										<Grid container>
											<Button
												variant="outlined"
												color="info"
												sx={{
													color: "#0273E6",
													fontSize: "16px",
													borderColor: "#0273E6",
													mb: 1,
													height: "30px",
													maxWidth: "100px",
													ml: "auto",
												}}
												onClick={() =>
													sendConnectionRequest(
														artist.userProfileId,
														"ACCEPTED",
														artist?.userProfileUUID
													)
												}
											>
												{/* <img src={TickCircleOrange} alt='' style={{ height: '18px', width: '18px' }} /> */}
												Accept
											</Button>
											<Button
												variant="outlined"
												color="info"
												sx={{
													color: "#0273E6",
													fontSize: "16px",
													borderColor: "#0273E6",
													marginLeft: "auto",
													height: "30px",
													maxWidth: "100px",
												}}
												onClick={() =>
													sendConnectionRequest(
														artist.userProfileId,
														"REJECTED",
														artist?.userProfileUUID
													)
												}
											>
												{/* <img src={CloseCircled} alt='' style={{ height: '18px', width: '18px' }} /> */}
												Reject
											</Button>
										</Grid>
									</>
								) : (
									""
								))

							// artist.canSendConnection && !artist.connected && artist.userProfileId !== user.userProfileId && (artist.connectionStatus === null || artist.connectionStatus === 'REMOVED' || artist.connectionStatus === "WITHDRAWN" || artist.connectionStatus === 'REJECTED') ?
							//                 <Button variant="outlined" color='info' sx={{ minWidth: '117px', fontSize: '16px', color: '#0273E6', borderColor: '#0273E6', marginLeft: 'auto', height: '30px' }}
							//                     onClick={() => setOpenConnectDialog(true)} >
							//                     {/* <img src={ConnectIcon} alt='' style={{ height: '18px', width: '18px' }} /> */}
							//                     Connect
							//                 </Button>
						}

						{event && user.userAccountStatus !== "SUSPEND" && (
							<Button
								color="info"
								sx={{
									width: "100%",
									border: "1.5px solid #0273E6",
									borderRadius: "15px",
									marginTop: 1,
								}}
								variant="outlined"
								startIcon={<img src={Archive} alt="Connect" />}
								onClick={() => handleAttendClick(event.eventsUuid)}
							>
								<Typography
									variant="h6"
									component="span"
									sx={{
										fontSize: "1rem",
										fontWeight: "400",
										color: "#0273E6",
									}}
								>
									Attend
								</Typography>
							</Button>
						)}
					</Stack>
				</Grid>
			</Grid>

			<ConnectRequestDialog
				open={openConnectDialog}
				user={user}
				handleClose={handleCloseConnectDialog}
				userProfileId={artist?.userProfileId}
				userProfileUUID={artist?.userProfileUUID}
				setLoading={setIsLoading}
				setConnectionStatus={setConnectionStatus}
				post={false}
				getReloadedArtists={getSimilarArtists}
			/>
		</>
	);
};
