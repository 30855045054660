import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { Button, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useUser } from "../../../context/UserContext";
import Leave from "../../../images/phone-leave.svg";
import { useFullscreenModeContext } from "../../../context/FullscreenModeContext";
import WebsocketClientUSerStatus from "../../../services/WebsocketClientUSerStatus";
import { RecordingDetails, useBroadcastingStatusContext } from "../../../context/BroadcastingStatusContext";
import { useDaily } from "@daily-co/daily-react";
import WebsocketMetricsClient from "../../../services/WebsocketMetricsClient";
import { useAlertStatus } from "../../../context/AlertStatusContext";
import { Constants } from "../../../services/constants";
import ActionDialog from "../../reusable-components/ActionDialog";
import { Heading } from "../../Daily/LiveStreamingDialog/EndLiveStreamDialog";
import { useDailyAppRoom } from "../../Daily/Hooks/useDailyGlobalRoom";
import i18n from "../../../l10n/strings.json";
import { cleanUpSession } from "../../Mixer/utils";

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            background: "#E11E1E !important",
            color: "white",
            "&:hover": {
                background: "#E11E1E !important",
            },
            padding: "5px 15px",
            borderRadius: "6px",
        },
    })
);

type cleanupAndLeaveSessionProps = {
    recordingDetails?: RecordingDetails;
    user: any;
    setIsFullscreenMode: (value: boolean) => void;
    setIsTabHidden: (value: boolean) => void;
    onLeaveCall?: Function
    dailyRoomObj?: any;
    toggleSendingAudioFromDevice: (params: { userId: number, dailySessionId: string, cmd: string }) => void;
    navigate: (path: string) => void;
};

const cleanupAndLeaveSession = ({
    recordingDetails,
    user,
    setIsFullscreenMode,
    setIsTabHidden,
    onLeaveCall,
    dailyRoomObj,
    toggleSendingAudioFromDevice,
    navigate
}: cleanupAndLeaveSessionProps) => {
    
    // Live stream is handled on backend and audio is being stop from toggleSendingAudioFromDevice
    const twilioSessionId = localStorage.getItem("twilioSessionId") || "";
    const storedUserSession = localStorage.getItem("userSession");
    const userSession = storedUserSession ? JSON.parse(storedUserSession) : null;
    const { recordingStatus, recordingStartedBy } = userSession;
    var sessionStopMessage = {
        action: "session_stop",
        timestamp: new Date().toISOString(),
        session_id: twilioSessionId,
        created_by: user.id,
        unsubscribe:
            dailyRoomObj && Object.keys(dailyRoomObj?.participants()).length === 0
                ? true
                : false,
    };
    const recordingStopMessage = {
        action: "audio_recording",
        timestamp: new Date().toISOString(),
        session_id: twilioSessionId,
        created_by: user.id,
        recordingStatus: Constants.RecordingCmd.STOP,
    };
    
    const compressionMessage = {
        action: "compression",
        session_id: twilioSessionId,
        created_by: user.id,
        timestamp: new Date().toISOString(),
        compressionAudio: "off",
    };

    if (recordingDetails?.recordingType === "onlyaudio" && recordingStartedBy === user.email && recordingStatus) {
        // console.log("cleanupAndLeaveSession Recording message", recordingStopMessage);
        WebsocketClientUSerStatus.handleRecordingControl(recordingStopMessage);
    }

    WebsocketClientUSerStatus.handleAudioCompressionControl(compressionMessage);

    toggleSendingAudioFromDevice({ userId: user.id, dailySessionId: twilioSessionId, cmd: "STOP" });
    cleanUpSession(twilioSessionId, user.id);
    WebsocketClientUSerStatus.handleLeaveSession(sessionStopMessage);
    WebsocketClientUSerStatus.onLeaveSession(twilioSessionId, user);
    if (document.fullscreenElement) {
        setIsFullscreenMode(false);
        document.exitFullscreen()
    }

    setIsTabHidden(false);
    localStorage.setItem("checked", JSON.stringify(false));
    localStorage.removeItem("userCheckedMap");
    onLeaveCall && onLeaveCall();
    navigate("/ratejamsession");
};



function EndCallButton(props: {
    className?: string,
    broadcastResponse?: any,
    streamStartedBy?: any,
    onLeaveCall?: Function,
    isRecordingStarted?: boolean,
    isLiveStreaming?: boolean
}) {
    const twilioSessionId = localStorage.getItem("twilioSessionId") || "";
    const {updateAlertStatus} = useAlertStatus();
    const storedUserSession = localStorage.getItem("userSession");
    const userSession = storedUserSession ? JSON.parse(storedUserSession) : null;
    const isRedirection = Boolean(localStorage.getItem("isRedirection"));
    const { recordingStatus } = userSession;
    const navigate = useNavigate();
    const user = useUser();
    const dailyRoomObj = useDaily();
    const { toggleSendingAudioFromDevice, allParticipantsCount } = useDailyAppRoom();
    const classes = useStyles();
    const { isFullscreenMode, setIsFullscreenMode, setIsTabHidden, isTabHidden } = useFullscreenModeContext();
    const { liveStreamingStartedBy, recordingDetails, setIsLiveStreaming } = useBroadcastingStatusContext();
    const [open, setOpen] = useState(false);
    const recordingStartedByUser = recordingDetails?.recordingStarted && (recordingDetails.recordingStartedBy === user?.email ||
        recordingDetails.recordingStartedBy === user?.id
    );

    const [leaveSessionDialogContent, setLeaveSessionDialogContent] = useState({
        heading: "",
        subHeading1: "",
        subHeading2: "",
        buttonText: "",
        type: ""
    });


    useEffect(() => {
        const onMessageReceived = (payloadData: any) => {
            if (props?.streamStartedBy === user?.email) {
                WebsocketClientUSerStatus.disconnectWebsocketConnection(payloadData, user);
                WebsocketClientUSerStatus.disconnectWebsocket();
            }
        };
        WebsocketClientUSerStatus.on("leaveSession", onMessageReceived);
        return () => {
            WebsocketClientUSerStatus.off("leaveSession", onMessageReceived);
        };
    }, []);
    const handleLeaveClick = () => {
        if (recordingStartedByUser || liveStreamingStartedBy === user?.id || isRedirection) {
            setOpen(true);
        } else {
            if (isFullscreenMode) {
                document.exitFullscreen();
                setIsFullscreenMode(false);
            }
            cleanupAndLeaveSession({
                recordingDetails,
                user,
                setIsFullscreenMode,
                setIsTabHidden,
                onLeaveCall: props?.onLeaveCall,
                dailyRoomObj,
                toggleSendingAudioFromDevice,
                navigate
            })
            // leaveRoom();
        }
    };

    const handleConfirmLeave = () => {
        setOpen(false);
        if (isFullscreenMode) {
            document.exitFullscreen();
            setIsFullscreenMode(false);
        }
        cleanupAndLeaveSession({
            recordingDetails,
            user,
            setIsFullscreenMode,
            setIsTabHidden,
            onLeaveCall: props?.onLeaveCall,
            dailyRoomObj,
            toggleSendingAudioFromDevice,
            navigate,
        })
        localStorage.removeItem("isRedirection");
        // leaveRoom(); 
    };

    const handleCancelLeave = () => {
        localStorage.removeItem("isRedirection");
        setOpen(false);
    };

    useEffect(() => {
        if (recordingStartedByUser && props?.isLiveStreaming && liveStreamingStartedBy === user?.id) {
            setLeaveSessionDialogContent((prevContent) => {
                return {
                    ...prevContent,
                    heading: i18n.jamSession.dialog.liveSteramAndRecording.heading,
                    subHeading1: i18n.jamSession.dialog.liveSteramAndRecording.subHeading1,
                    subHeading2: i18n.jamSession.dialog.liveSteramAndRecording.subHeading2,
                    buttonText: i18n.jamSession.dialog.liveSteramAndRecording.buttonText,
                    type: i18n.jamSession.dialog.liveSteramAndRecording.type
                }
            });
        } else if (recordingStartedByUser) {
            setLeaveSessionDialogContent((prevContent) => {
                return {
                    ...prevContent,
                    heading: i18n.jamSession.dialog.recording.heading,
                    subHeading1: i18n.jamSession.dialog.recording.subHeading1,
                    subHeading2: i18n.jamSession.dialog.recording.subHeading2,
                    buttonText: i18n.jamSession.dialog.recording.buttonText,
                    type: i18n.jamSession.dialog.recording.type
                }
            });
        } else if (props?.isLiveStreaming && liveStreamingStartedBy === user?.id) {
            setLeaveSessionDialogContent((prevContent) => {
                return {
                    ...prevContent,
                    heading: i18n.jamSession.dialog.liveStream.heading,
                    subHeading1: i18n.jamSession.dialog.liveStream.subHeading1,
                    subHeading2: i18n.jamSession.dialog.liveStream.subHeading2,
                    buttonText: i18n.jamSession.dialog.liveStream.buttonText,
                    type: i18n.jamSession.dialog.liveStream.type
                }
            });
        } else {
            setLeaveSessionDialogContent((prevContent) => {
                return {
                    ...prevContent,
                    heading: i18n.jamSession.dialog.default.heading,
                    subHeading1: "",
                    subHeading2: i18n.jamSession.dialog.default.subHeading2,
                    buttonText: i18n.jamSession.dialog.default.buttonText,
                    type: i18n.jamSession.dialog.default.type
                }
            });
        }
    }, [open]);

    const lastSavedPresetFunctionality = () => {
        // Retrieve user session from local storage
        const userSessions = JSON.parse(localStorage.getItem("userSession") ?? "{}");
        const participantVolumeDetails = JSON.parse(localStorage.getItem("participantVolumeDetails") ?? "[]");
      
        if (!user?.email) {
          console.error("User email not found.");
          return;
        }
      
        // Find attendee matching the logged-in user
        const userAttendee = userSessions?.attendees?.find(
          (attendee: any) => attendee?.login === user.email
        );
      
        // Declare presets as a Record<string, any> to include dynamic keys like "Default"
        let presets: Record<string, any> = {};
        const presetsString = userAttendee?.presetNames ?? "{}";
        if (presetsString.trim()) {
          try {
            presets = JSON.parse(presetsString);
          } catch (error) {
            console.error("Error parsing presets:", error);
          }
        }
      
        // Find volume details for the logged-in user
        const requiredVolumeDetails = participantVolumeDetails.find(
          (participant: any) => participant?.email === user.email
        );
        if (!requiredVolumeDetails?.audio) {
          console.error("Audio settings not found for the user.");
          return;
        }
      
        // Merge existing presets with the updated Default preset audio details
        const updatedPresets = {
          ...((typeof presets === 'object' && presets !== null) ? presets : {}), 
            "Default": {
                ...(presets?.["Default"] || {}), 
            audio: requiredVolumeDetails.audio,
          },
        };
      
        console.log("Updated Presets:", updatedPresets);
      
        // Prepare request payload
        const requestMessage = {
          action: "PRESET_NAMES",
          timestamp: new Date().toISOString(),
          session_id: twilioSessionId,
          created_by: userSessions.createdBy,
          created_for: user.email,
          presetName: JSON.stringify(updatedPresets),
          currentPreset: "Default"
        };
      
        console.log("Request Message:", requestMessage);
      
        // WebSocket check and sending message
        if (!WebsocketClientUSerStatus.isWebsocketClientConnected()) {
          updateAlertStatus(true, false, "WebSocket Connection Lost. Please Try Again Later.");
          return;
        }
      
        WebsocketClientUSerStatus.handleSavePresetChange(requestMessage);
      };
      
    const leaveRoom = () => {
        handleRecording();
        if (isTabHidden === false) {
            setIsTabHidden(isTabHidden)
        }
        else {
            setIsTabHidden(false);
        }
        isFullscreenMode && setIsFullscreenMode(false);
        localStorage.setItem("checked", JSON.stringify(false));
        localStorage.removeItem("userCheckedMap");
        if (document.fullscreenElement) {
            document.exitFullscreen().catch((err) => {
                console.error("Failed to exit full-screen mode:", err);
            });
        }
        if (props?.streamStartedBy === user?.id) {
            setIsFullscreenMode(false);
            // setIsTabHidden(false);
            // localStorage.removeItem("session_attendees");
            // const sessionAttendees = JSON.parse(localStorage.getItem("session_attendees") || "");
            // const storedAttendees = localStorage.getItem("session_attendees");
            var sessionStopMessage = {
                action: "session_stop",
                timestamp: new Date().toISOString(),
                session_id: twilioSessionId,
                created_by: user.id,
                unsubscribe:
                    dailyRoomObj && Object.keys(dailyRoomObj?.participants()).length === 0
                        ? true
                        : false,
            };

            let liveStreamMsg = {
                action: "live_stream_status",
                timestamp: new Date(),
                session_id: twilioSessionId,
                created_by: user.id,
                dailyLiveStreamStatus: "STOP",
            };
            userSession.dailyLiveStreamStatus = false;
            WebsocketMetricsClient.disconnectWebsocketConnection();
            // WebsocketMetricsClient.disconnectWebsocketConnection();
            toggleSendingAudioFromDevice({ userId: user.id, dailySessionId: twilioSessionId, cmd: "STOP" });
            WebsocketClientUSerStatus.handleLiveStream(liveStreamMsg);
            WebsocketClientUSerStatus.handleLeaveSession(sessionStopMessage);
            WebsocketClientUSerStatus.onLeaveSession(twilioSessionId, user);
            setIsLiveStreaming(false);
        } else {
            const sessionStopMessage = {
                action: "session_stop",
                timestamp: new Date().toISOString(),
                session_id: twilioSessionId,
                created_by: user.id,
                unsubscribe:
                    dailyRoomObj && Object.keys(dailyRoomObj?.participants()).length === 0
                        ? true
                        : false,
            };
            userSession.dailyLiveStreamStatus = true;
            WebsocketClientUSerStatus.onLeaveSession(twilioSessionId, user);
            WebsocketClientUSerStatus.handleLeaveSession(sessionStopMessage);
        }
        setIsFullscreenMode(false);
        setIsTabHidden(false);
        if (isFullscreenMode) {
            document.exitFullscreen()
        }
        props.onLeaveCall && props.onLeaveCall();
        navigate("/ratejamsession");
    };

    const handleRecording = () => {
        if (recordingDetails?.recordingType === "onlyaudio" && recordingDetails?.recordingStartedBy === user.email && recordingStatus) {
            let message = {
                action: "audio_recording",
                timestamp: new Date().toISOString(),
                session_id: twilioSessionId,
                created_by: user.id,
                recordingStatus: Constants.RecordingCmd.STOP,
            };

            WebsocketClientUSerStatus.handleRecordingControl(message);
        }
    }

    return (
        <>
            <Tooltip title="Leave Session">
                <Button
                    id="endCallButton"
                    onClick={handleLeaveClick}
                    className={clsx(classes.button, props.className)}
                    data-cy-disconnect
                    startIcon={<img src={Leave} alt="leave session" />}
                >
                    Leave
                </Button>
            </Tooltip>

            <ActionDialog
                open={open}
                buttonsAlignment="right"
                showCloseIcon={false}
                handleClose={handleCancelLeave}
                dialogWidth={(props?.isRecordingStarted || props?.isLiveStreaming) ? "600px" : "480px"}
                title={leaveSessionDialogContent?.heading}
                titleStyle={{
                    color: "#000",
                    fontSize: "1.375rem",
                    fontWeight: "500",
                }}
                titleAlignment={leaveSessionDialogContent?.type === "default" ? "center" : "left"}
                actionButtons={
                    <>
                        <Button
                            sx={{
                                width: "7.8125rem",
                                height: "2.5rem",
                                borderRadius: "2.5rem",
                            }}
                            className="cancel-button"
                            size="large"
                            variant="outlined"
                            onClick={handleCancelLeave}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{
                                width: (props?.isRecordingStarted || props?.isLiveStreaming) ? "12.5625rem" : "7.8125rem",
                                height: "2.5rem",
                                borderRadius: "1.25rem",
                                backgroundColor: "rgba(235, 42, 0, 1)"
                            }}
                            className="submit-button"
                            size="large"
                            variant="contained"
                            onClick={handleConfirmLeave}
                        >
                            {leaveSessionDialogContent?.buttonText}
                        </Button>
                    </>
                }
            >
                <Typography sx={{ fontSize: "1rem", fontWeight: "400", color: "#FD4B24" }}>
                    {leaveSessionDialogContent?.subHeading1}
                </Typography>
                <Typography sx={{ fontSize: "1rem", fontWeight: "400", color: "#4D4C4F" }}>
                    {leaveSessionDialogContent?.subHeading2}
                </Typography>
                {(leaveSessionDialogContent.type === "liveStreaming" || leaveSessionDialogContent.type === "liveStreamingAndRecording") && (
                    <Heading sx={{ mt: 2 }}>
                        Live audience: <span style={{ fontWeight: 400, color: "#000", fontSize: "1.125rem" }}>{allParticipantsCount?.hidden}</span>
                    </Heading>
                )}
            </ActionDialog>
        </>
    );
}

export default memo(EndCallButton);