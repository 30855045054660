import { DailyUserName } from '../DailyStyled';
import { useParticipantProperty } from '@daily-co/daily-react';
import { DailyUserNameType } from '../Types/dailyTypes';
import { useDailyUtils } from '../Hooks/useDailyUtils';
import { useEffect, useState } from 'react';
// export default function Username({ id, isLocal, isStreamingView }: DailyUserNameType) {

//   const {getUserByEmail} = useDailyUtils();
//   const username = getUserByEmail(useParticipantProperty(id, 'user_name'), true);
//   const maskedId = username?.split('@')[0].replace('.', ' ') || id;

//   return (
//     <DailyUserName isStreamingView={isStreamingView}> {username || isStreamingView ? maskedId :  id} {isLocal && '(you)'} </DailyUserName>
//   );
// }


export default function Username({ id, isLocal, isStreamingView }: DailyUserNameType) {

  const {getUserByEmail} = useDailyUtils();
  const userNameProperty = useParticipantProperty(id, 'user_name');
  const [username, setUsername] = useState<string | undefined>(undefined);
  const maskedId = username?.split('@')[0].replace('.', ' ') || id;

  useEffect(() => {
    // set username with a delay of 4 seconds to avoid flickering experience
    if (!username) {
      const fetchedUsername = getUserByEmail(userNameProperty, true);
      setUsername(fetchedUsername);
    }

    const timer = setTimeout(() => {
      const fetchedUsername = getUserByEmail(userNameProperty, true);
      setUsername(fetchedUsername);
    }, 2000); 

    return () => clearTimeout(timer); 
  }, [id, userNameProperty]); 
  

  return (
    <DailyUserName isStreamingView={isStreamingView}> {username || isStreamingView ? maskedId :  id} {isLocal && '(you)'} </DailyUserName>
  );
}
