import React, { useEffect, useState, useCallback, memo, useRef } from 'react';
import DailyIframe, { DailyCall as DailyCallType, DailyEvent } from '@daily-co/daily-js';
import { DailyAudio, DailyProvider, DailyVideo, useLocalSessionId } from '@daily-co/daily-react';
import DailyCall from './Gallery/Gallery';
import { STATE_ERROR, STATE_IDLE, STATE_JOINED, STATE_JOINING, STATE_LEAVING } from './Constants';
import { DailyRoomType } from './Types/dailyTypes';
import DailyMenuBar from './Tray/MenuBar';
import EmojisOverlay from './EmojiReaction/EmojiReaction';
import { isEqual } from 'lodash';
import { useDailyAppRoom } from './Hooks/useDailyGlobalRoom';
import { CenteredDailyWrapper, FixedDailyWrapper, TestItem, TestVideoInfo, TestVideoWrapper, TestWrapper } from './DailyStyled';
import StreamingMenuBar from './Tray/StreamingMenuBar';
import { removeTestEvents, runAllTests } from './Utils/dailyUtils';
import InfoIcon from '../../icons/InfoIcon';


function DailyRoom({ dailyUrl, setCallObjectInVideoSession, setDailyRoomState }: DailyRoomType) {
  const [appState, setAppState] = useState(STATE_IDLE);
  const [callObject, setCallObject] = useState<DailyCallType | null>(null);
  const prevUrlRef = useRef<string | undefined>(undefined);
  const { setDailyAppCallObject, startLeavingDailyCall, isStreamingView, setIsChatOpen, setParticipantsOpen, isMixerOpen, setIsMixerOpen, dailyQualityStatus, setDailyQualityStatus } = useDailyAppRoom();

  const initDailyAndJoinCall = useCallback(async (url = dailyUrl) => {
    // console.log('initDailyAndJoinCall', {url, prevUrlRef: prevUrlRef.current});
    let dailyCallInstance = DailyIframe.getCallInstance();
    if (!dailyCallInstance) {
      dailyCallInstance = DailyIframe.createCallObject();
    }
    if (dailyCallInstance) {
      const newCallObject = dailyCallInstance;
      setCallObject(newCallObject);
      setDailyAppCallObject(newCallObject);  // setting this for app level context
      setCallObjectInVideoSession && setCallObjectInVideoSession(newCallObject); // this is for video session component
      const [meetingUrl, token] = url.split('?t=');
      if (newCallObject?.meetingState() === 'new') {
        if (token) {
          await newCallObject.preAuth({ url: meetingUrl, token });
        } else {
          await newCallObject.preAuth({ url });
        }
        await newCallObject.startCamera();
        // await runAllTests(newCallObject, setDailyQualityStatus);
        await newCallObject.join({ url: meetingUrl || url });
      }
    } else {
      console.log('Call object already exists. Using the existing instance.');
    }
  }, [callObject, setCallObjectInVideoSession]);

  const localSessionId = useLocalSessionId();

  /**
   * End the current call.
  */
  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    // If we're in the error state, we've already "left", so just clean up
    if (appState === STATE_ERROR) {
      startLeavingDailyCall(() => {
        removeTestEvents(callObject);
        setCallObject(null);
        setDailyRoomState && setDailyRoomState(STATE_IDLE);
        setCallObjectInVideoSession && setCallObjectInVideoSession(null);
      });
    } else {
      /* This will trigger a `left-meeting` event, which in turn will trigger
      the full clean-up as seen in handleNewMeetingState() below. */
      startLeavingDailyCall(() => {
        removeTestEvents(callObject);
        setDailyRoomState && setDailyRoomState(STATE_LEAVING)
      });
    }
  }, [callObject, appState, setCallObjectInVideoSession]);

  const chatToggle = useCallback((value: boolean) => {
    setIsChatOpen(value)
  }, []);

  const participantsToggle = useCallback((value: boolean) => {
    setParticipantsOpen(value)
  }, []);

  const mixerToggle = useCallback((value: boolean) => {
    setIsMixerOpen(value)
  }, []);

  /**
   * Update app state based on reported meeting state changes.
   */
  useEffect(() => {
    if (!callObject) return;

    const events: DailyEvent[] = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    // Use initial state
    handleNewMeetingState();

    events.forEach((event) => callObject.on(event, handleNewMeetingState));
    // Stop listening for changes in state
    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
    };
  }, [callObject]);

  // Handle page reload or exit
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      prevUrlRef.current = undefined;
      if (appState === STATE_JOINED || appState === STATE_JOINING) {
        startLeavingCall();
      }
      // Optionally show a confirmation dialog (modern browsers often ignore this). I'll  remove this soon.
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [appState, startLeavingCall]);

  useEffect(() => {
    if (dailyUrl && (!prevUrlRef.current || dailyUrl !== prevUrlRef.current)) {
      prevUrlRef.current = dailyUrl;
      // console.log('URL has changed, preparing to leave and join a new call', { dailyUrl, prevUrlRef: prevUrlRef.current, isEqualUrl: isEqual(dailyUrl, prevUrlRef.current) });
      if (appState === STATE_JOINED || appState === STATE_JOINING || callObject) {
        if (callObject && callObject.meetingState() === 'joined-meeting') {
          // console.log('Leaving current call before joining new call');
          startLeavingCall();
        }
      } else {
        initDailyAndJoinCall(dailyUrl);
      }
    }
  }, [dailyUrl, appState, initDailyAndJoinCall, callObject, startLeavingCall]);


  function handleNewMeetingState() {
    switch (callObject?.meetingState()) {
      case 'joined-meeting':
        setAppState(STATE_JOINED);
        setDailyRoomState && setDailyRoomState(STATE_JOINED)
        break;
      case 'left-meeting':
        callObject.destroy().then(() => {
          setCallObject(null);
          setDailyRoomState && setDailyRoomState(STATE_IDLE);
          setCallObjectInVideoSession && setCallObjectInVideoSession(null);
          setAppState(STATE_IDLE);
        });
        break;
      case 'error':
        setAppState(STATE_ERROR);
        setDailyRoomState && setDailyRoomState(STATE_ERROR)
        break;
      default:
        break;
    }
  }

  /**
   * Show the call UI if we're either joining, already joined, or have encountered
   * an error that is _not_ a room API error.
   */
  const showCall = [STATE_JOINING, STATE_JOINED, STATE_ERROR].includes(appState);

  const jamSessionRoom = () => (
    <>
      {isStreamingView ?
        <StreamingMenuBar leaveCall={startLeavingCall} /> :
        <DailyMenuBar
          leaveCall={startLeavingCall}
          chatToggle={chatToggle}
          participantsToggle={participantsToggle}
          mixerToggle={mixerToggle}
          isMixerOpen={isMixerOpen}
        />
      }
      <DailyCall />
      <EmojisOverlay />
      {isStreamingView && <DailyAudio />}
    </>
  );

  const renderApp = () => {
    if (showCall) {
      if (setCallObjectInVideoSession) {
        return jamSessionRoom();
      } else {
        return (
          <FixedDailyWrapper isStreamingView={isStreamingView}>
            <DailyProvider callObject={callObject}>
              {jamSessionRoom()}
            </DailyProvider>
          </FixedDailyWrapper>
        );
      }
    }

    const NetworkTest = () => {
      return (
        <TestWrapper>
          <TestVideoWrapper>
            <DailyVideo automirror sessionId={localSessionId} type={"video"} />
          </TestVideoWrapper>
          <TestVideoInfo> <InfoIcon /> <span>  Others won't see your video while network is being checked.</span></TestVideoInfo>
          {dailyQualityStatus.currentStep === 1 && (
            <TestItem className='fade-out slide-up'>
              <b>1/3 - Checking session connectivity: </b>
              {dailyQualityStatus.call.inProgress ? <div className='spinner'></div> : <span id="connection-test-results">{dailyQualityStatus.call.icon}</span>}
            </TestItem>
          )}
          {dailyQualityStatus.currentStep === 2 && (
            <TestItem className='fade-out slide-up'>
              <b>2/3 - Checking network connectivity: </b>
              {dailyQualityStatus.network.inProgress ? <div className='spinner'></div> : <span id="connectivity-test-results">{dailyQualityStatus.network.icon}</span>}
            </TestItem>
          )}
          {dailyQualityStatus.currentStep === 3 && (
            <TestItem className='fade-out slide-up'>
              <b>3/3 - Checking webSocket connectivity: </b>
              {dailyQualityStatus.ws.inProgress ? <div className='spinner'></div> : <span id="websocket-test-results">{dailyQualityStatus.ws.icon}</span>}
            </TestItem>
          )}
        </TestWrapper>)
    }

    return (
      <CenteredDailyWrapper isStreamingView={isStreamingView}>
        {dailyUrl ?
          <p>
            {/* <NetworkTest /> */}
            Joining... </p> :
          'Could not find daily session URL. Please provide the correct URL.'}
      </CenteredDailyWrapper>
    )
  };

  return renderApp();
}

export default memo(DailyRoom);