import React, { useEffect, useState } from 'react'
import InfoIcon from '../../../icons/InfoIcon'
import { useDailyAppRoom } from '../Hooks/useDailyGlobalRoom';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { ArrowDownward, ArrowUpward, ExpandLess, ExpandMore } from '@mui/icons-material';
import { TestText } from '../DailyStyled';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Box } from '@mui/system';
import { NetworkIcon, PacketLossIcon, VideoReceivingIcon, VideoSendingIcon, WebSocketIcon } from '../../../icons/NetworkIcon';
import { colorNameMap, iconMap } from '../Constants';
import { StatusType, ThresholdType } from '../Types/dailyTypes';

export default function NetworkStats({ email }: { email: any }) {
  const { sessionParticipantsNetworkDetails } = useDailyAppRoom();
  const networkQualityData = sessionParticipantsNetworkDetails.find((p: any) => p.email === email)?.networkQuality;
  const { networkStats, call, ws } = networkQualityData || {}
  const { threshold, stats } = networkStats || {};
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isGridVisible, setIsGridVisible] = useState(false);
  const [isDebug, setIsDebug] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setIsGridVisible(false);
  };

  const toggleGridVisibility = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsGridVisible(!isGridVisible);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'D') {
        setIsDebug(prevIsDebug => !prevIsDebug);
        handleClickAway();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        onClick={handleClick}
        style={{ zIndex: 9, position: 'absolute', right: 10, top: 10 }}
      >
        {isDebug ? <InfoIcon /> :
          threshold !== 'good' && <img src={iconMap[threshold as ThresholdType]} alt={threshold} style={{ width: '18px', marginRight: '4px', marginTop: '2px' }} />
        }
        <Popover
          id="networkQualityPopover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          disableRestoreFocus={false}
        >
          <Box sx={{ p: 1, background: '#000', color: "#fff", minWidth: '300px' }}>
            <div style={{ display: "flex" }}>
              <div style={{
                flex: 1,
                alignItems: 'center',
                // justifyContent: 'space-between',
                display: 'flex',
              }}>
                <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block' }} title={threshold || call?.message}>
                  {/* Network stats is {stringIconMap[threshold as StatusType || call?.status as StatusType]} {threshold} */}
                </Typography>
                <div style={{
                  display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '5px',
                  // fontSize: '12px',
                  marginBottom: isGridVisible ? '10px': 0,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  {/* <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block' }} title={threshold || call?.message}>
                    Network stats is {stringIconMap[threshold as StatusType || call?.status as StatusType]} {threshold}
                  </Typography> */}
                  <span title="Netowork Status"><NetworkIcon fill={colorNameMap[threshold as StatusType || call?.status as StatusType]}/></span>
                  {/* <WebSocketIcon fill={colorNameMap[threshold as StatusType || ws?.status as StatusType]} /> */}

                  <span title="Video Out" style={{ margin: '0 5px', display: 'flex', alignItems: 'center' }}>
                    <VideoSendingIcon />  {stats?.latest?.videoSendBitsPerSecond !== undefined
                      ? `${Math.floor(stats.latest.videoSendBitsPerSecond / 1000)} kb/s`
                      : 'N/A'}
                  </span>
                  <span title="Video In" style={{ margin: '0 5px', display: 'flex', alignItems: 'center' }}>
                    <VideoReceivingIcon /> {stats?.latest?.videoRecvBitsPerSecond !== undefined
                      ? `${Math.floor(stats.latest.videoRecvBitsPerSecond / 1000)} kb/s`
                      : 'N/A'}
                  </span>
                  <span title="Packet loss" style={{ margin: '0 5px', display: 'flex', alignItems: 'center' }}>
                    <PacketLossIcon /> {stats?.worstVideoRecvPacketLoss !== undefined
                    ? `${Math.floor(stats.worstVideoRecvPacketLoss * 100)}%`
                    : 'N/A'}
                  </span>
                </div>
              </div>
              <button onClick={toggleGridVisibility} style={{ marginBottom: '0px', background: 'transparent', color: ' #fff', boxShadow: 'none', border: 'none', cursor: 'pointer' }}>
                {isGridVisible ? <ExpandLess /> : <ExpandMore />}
              </button>
            </div>

            {isGridVisible && (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '10px' }}>
                <TestText ><ArrowUpward sx={{ color: '#FF702A', fontSize: 16 }} />Video :
                  {stats?.latest?.videoSendBitsPerSecond !== undefined
                    ? `${Math.floor(stats.latest.videoSendBitsPerSecond / 1000)} kb/s`
                    : 'N/A'}
                </TestText>

                <TestText ><ArrowUpward sx={{ color: '#FF702A', fontSize: 16 }} />Audio :
                  {stats?.latest?.audioSendBitsPerSecond !== undefined
                    ? `${Math.floor(stats.latest.audioSendBitsPerSecond / 1000)} kb/s`
                    : 'N/A'}
                </TestText>

                <TestText >
                  <ArrowUpward sx={{ color: '#FF702A', fontSize: 16 }} />Packet Loss  :
                  {stats?.worstVideoSendPacketLoss !== undefined
                    ? `${Math.floor(stats.worstVideoSendPacketLoss * 100)}%`
                    : 'N/A'}
                </TestText>

                <TestText >
                  <ArrowDownward sx={{ color: '#FF702A', fontSize: 16 }} />Video :
                  {stats?.latest?.videoRecvBitsPerSecond !== undefined
                    ? `${Math.floor(stats.latest.videoRecvBitsPerSecond / 1000)} kb/s`
                    : 'N/A'}
                </TestText>

                <TestText>
                  <ArrowDownward sx={{ color: '#FF702A', fontSize: 16 }} />Audio :
                  {stats?.latest?.audioRecvBitsPerSecond !== undefined
                    ? `${Math.floor(stats.latest.audioRecvBitsPerSecond / 1000)} kb/s`
                    : 'N/A'}
                </TestText>
                <TestText >
                  <ArrowDownward sx={{ color: '#FF702A', fontSize: 16 }} />Packet Loss :
                  {stats?.worstVideoRecvPacketLoss !== undefined
                    ? `${Math.floor(stats.worstVideoRecvPacketLoss * 100)}%`
                    : 'N/A'}
                </TestText>
                {/* {JSON.stringify(networkQualityData, undefined, 2)} */}
              </div>
            )}
          </Box>
        </Popover>
      </div>
    </ClickAwayListener>
  )
}
