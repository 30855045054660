import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	Button,
	FormHelperText,
	TextField,
	Stack,
	Card,
	CardActions,
	CardContent,
	ImageListItem,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
// contexts
import { useUser } from "../../context/UserContext";
import { useMusicianCounts } from "./MusicProvider/useMusicianCounts";
import i18n from "../../l10n/strings.json";

import ProfileAddWhite from "../../images/profile-add-white.svg";
import ProfileRemoveWhite from "../../images/profile-remove-white.svg";
import Peoples from "../../images/peoples.svg";
import Message from "../../images/message-text.svg";
import ArrowCircleRight from "../../images/arrow-circle-right.svg";
import ArrowCircleUp from "../../images/arrow-circle-up.svg";
import MutualGroups from "../../images/mutual-groups.svg";
import Location from "../../images/location.svg";
import CloseCircled from "../../images/close-circle.svg";
import ClosedCircled from "../../images/close-circle-blue.svg";
import TickCircleOrange from "../../images/tick-circle-white-16.svg";
import InstrumentSkillLevel from "./InstrumentSkills";
import CustomAudioPlayer from "../../pages/CustomAudioPlayer";
import DefaultProfilePicture from "../../images/user square 160.png";
import PeopleRemove from "../../images/profile-remove.svg";
import Trash from "../../images/trash-orange.svg";
import VerifyAccount from "../../dialogBox/VerifyAccount";
import ProfileWhite from "../../images/user-octagon-white.svg";
import { useMusiciansFilter } from "./MusicProvider/useMusicianFilter";
import send from "../../images/send.svg";
import RightArrow from "../../images/right arrow.svg";

import { Client } from "@twilio/conversations";
import { useAlertStatus } from "../../context/AlertStatusContext";
import { addConversation, getToken } from "../Messages/api";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../store";
import CenterCircularProgress from "../CenterCircularProgress";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		userCard: {
			maxWidth: "100% !important ",
			width: "100% !important",
			// textAlign: "center",
			// background: "#f8f8f8 !important"
		},
		connectBtn: {
			width: "100%",
			// fontWeight: "500 !important", color: "#0273E6 !important"
		},
		toggleBtn: {
			width: "100%",
			fontWeight: "400 !important",
			color: "#0273E6 !important",
			// border: "1px solid #0273E6 !important"
		},
		toggleBtnText: {
			color: "#0273E6 !important",
			paddingRight: "23px !important",
		},
		rejectBtn: {
			border: "1px solid #FF702A !important",
			color: "#FF702A !important",
			borderRadius: "20px !important",
		},
		acceptBtn: {
			// border: "1px solid #FF702A",
			color: "#FFF",
			background: "#FF702A !important",
			borderRadius: "20px !important",
		},
		withdrawBtn: {
			// border: "1px solid #FF702A",
			color: "#FFF",
			background: "#FF702A !important",
			borderRadius: "20px !important",
		},
		skillStack: {
			padding: "1%",
			margin: "1% 0",
			backgroundColor: "#FAFAFA !important",
			borderRadius: "15px",
		},
	})
);

const UserCardDetailed = (props: any) => {
	const user = useUser();
	const dispatch = useDispatch();
	const { updateAlertStatus } = useAlertStatus();
	const textFieldRef = useRef<HTMLInputElement | null>(null);
	const { resetMusicianCount } = useMusicianCounts();
	const classes = useStyles();
	const token = localStorage.getItem("sessionId");
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { resetMusicianFilter, setMusicianFilter, musicianFilter } =
		useMusiciansFilter();

	const { updateCurrentConversation, addNotifications, updateParticipants } =
		bindActionCreators(actionCreators, dispatch);

	const [isNotificationRead, setIsNotificationRead] = useState(
		props.user?.readStatus || false
	);
	const [isDisable, setIsDisable] = useState(false);

	const handleCardClick = () => {
		setIsNotificationRead(true);
	};

	useEffect(() => {
		if (props.user?.readStatus) {
			setIsNotificationRead(true);
		}
	}, [props.user?.readStatus]);

	const [profileDetails, setProfileDetails] = useState(props.user);
	const [loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [openUnblockDialog, setOpenUnblockDialog] = useState(false);
	const currentPath = window.location.hash;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	var instrumentKeys = profileDetails.instrumentSkills
		? Object.keys(profileDetails.instrumentSkills)
		: [];
	const [instrumentForDisplay, setInstrumentForDisplay] = useState([]);
	const [noteForDisplay, setNoteForDisplay] = useState("");
	const [expanded, setExpanded] = useState(false);
	const [connnectionRequestNote, setConnectionRequestNote] =
		useState<string>("");
	const [connnectionRequestPopup, setConnectionRequestPopup] =
		useState<boolean>(false);
	const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);
	const [hideCardAfterAction, setHideCardAfterAction] = useState<string>("");
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	let nameOfUser = profileDetails.name;
	const handleExpandClick = () => {
		setExpanded(!expanded);
		if (!expanded) {
			setInstrumentForDisplay(profileDetails.userInstrumentsList);
			setNoteForDisplay(profileDetails?.note);
		} else {
			setInstrumentForDisplay(profileDetails.userInstrumentsList.slice(0, 3));
			setNoteForDisplay(profileDetails?.note?.substring(0, 70));
		}
	};

	const toggleCardButtons = (
		requestFrom: number,
		requestTo: number,
		requestStatus: string
	) => {
		if (requestStatus === "PENDING") {
			setProfileDetails({
				...profileDetails,
				invited: true,
				requestFromId: requestFrom,
				requestToId: requestTo,
				rejected: false,
				connected: false,
			});
		} else if (requestStatus === "WITHDRAWN") {
			setProfileDetails({
				...profileDetails,
				invited: false,
				requestFromId: null,
			});
			if (props.hideCardAfterAction) {
				setHideCardAfterAction("none");
			}
		} else if (requestStatus === "ACCEPTED") {
			//if (props.isNotification) {
			setProfileDetails({
				...profileDetails,
				rejected: false,
				connected: true,
			});
			//}
			if (props.hideCardAfterAction) {
				setHideCardAfterAction("none");
			}
		} else if (requestStatus === "REJECTED") {
			//if (props.isNotification) {
			setProfileDetails({
				...profileDetails,
				rejected: true,
				connected: false,
				requestFromId: null,
				invited: false,
			});
			//}
			if (props.hideCardAfterAction) {
				setHideCardAfterAction("none");
			}
		} else if (requestStatus === "REMOVED") {
			//setHideCardAfterAction('none');
			setRemoveConfirmation(false);
			setProfileDetails({
				...profileDetails,
				rejected: true,
				connected: false,
				requestFromId: null,
				invited: false,
			});
		}
	};

	const sendConnectionRequest = (
		requestFrom: number,
		requestTo: number,
		requestStatus: string,
		requestFromUUID: string | undefined,
		requestToUUID: string | undefined
	) => {
		 
		try {
			setIsDisable(true);
			// setLoading(true);
			fetch(`${process.env.REACT_APP_BASE_URL}/api/user-connection-requests`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					requestFromUUID: requestFromUUID,
					requestToUUID: requestToUUID,
					connectionStatus: requestStatus,
					note: connnectionRequestNote,
				}),
			})
				.then((res) => {
					if (res.status === 502) {
						navigate("/defaultPage");
					} else if (res.status > 400) {
						setIsDisable(false);
						throw new Error("api_error");
					} else {
						// PLEASE KEEP THIS COMMENTED SO THAT THE LIST WON"T FLICKER THE WHOLE PAGE
						// props.refreshList();
						setIsDisable(false);
						toggleCardButtons(requestFrom, requestTo, requestStatus);
						return res.json();
					}
				})
				.then((data) => {
					// PLEASE KEEP THIS COMMENTED SO THAT THE LIST WON"T FLICKER THE WHOLE PAGE
					// props.refreshList();
					toggleCardButtons(requestFrom, requestTo, requestStatus);
				})
				.catch((err) => {})
				.finally(() => {
					setConnectionRequestNote("");
					setConnectionRequestPopup(false);
					setLoading(false);
					resetMusicianCount();
					setIsDisable(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	const deleteConnectionRequest = () => {
		try {
			setLoading(true);
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-connection-requests/rejected`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						requestFromUUID: profileDetails.userProfileUUID,
						deleteAllRejectedReq: false,
					}),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						props.refreshList();
						return res.json();
					}
				})
				.then((data) => {
					props.refreshList();
				})
				.catch((err) => {})
				.finally(() => {
					setLoading(false);
					resetMusicianCount();
				});
		} catch (error) {
			setLoading(false);
		}
	};

	console.log("Username-->", nameOfUser);

	const viewUserProfile = () => {
		// resetMusicianFilter();
		// setMusicianFilter({...musicianFilter,
		// 	isGlobalSearch: false,
		// 	firstName: "",
		// 	lastName: "",
		// 	nickName: ""})
		const encodedNickName = encodeURIComponent(profileDetails.nickname);

		navigate(`/socialHome/` + encodedNickName);
	};

	const handleClose = () => {
		setConnectionRequestNote("");
		setConnectionRequestPopup(false);
		setOpenUnblockDialog(false);
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const loadMoreInstrument = () => {
		setExpanded(true);
		if (instrumentForDisplay.length === 3) {
			setInstrumentForDisplay(profileDetails.userInstrumentsList.slice(0, 6));
		} else if (instrumentForDisplay.length > 3) {
			viewUserProfile();
		}
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const revertLoadMoreInstrument = () => {
		setExpanded(false);
		setInstrumentForDisplay(profileDetails.userInstrumentsList.slice(0, 3));
	};

	const insertLineBreaks = (text: string, maxLength: number): string => {
		const regex = new RegExp(`.{1,${maxLength}}`, "g");
		return text.match(regex)?.join("\n") || "";
	};

	const extractLocationInfo = (location: string | null): string => {
		if (location === null) {
			return ""; // or handle it in a way that makes sense for your application
		}
		const parts: string[] = location.split(",").map((part) => part.trim());
		const city: string = parts.slice(-3).join(", ");
		return city;
	};

	const sendMessage = async () => {
		setIsLoading(true);
		var conversationToken = localStorage.getItem("conversationToken");
		if (conversationToken === null || conversationToken === "") {
			conversationToken = await getToken(user.email, "");
			localStorage.setItem("conversationToken", conversationToken);
		}
		const client = new Client(conversationToken as string);
		let title = profileDetails.name;
		let uniqueName = [profileDetails.userEmail].sort().join("-");
		let uniqueNameForCheck = [profileDetails.userEmail, user.email]
			.sort()
			.join("-");

		// Before you use the client, subscribe to the `'initialized'` event.
		client.on("initialized", async () => {
			// const checkConversationExists = await client.getConversationByUniqueName(uniqueName);
			client
				.getConversationByUniqueName(uniqueNameForCheck)
				.then((conversationDetails) => {
					updateCurrentConversation(conversationDetails.sid);
					navigate("/messages");
					// conversationDetails.sendMessage(gigMessage)
					// .then(async (convo) => {
					// 	updateAlertStatus(
					// 		true,
					// 		true,
					// 		"Applied for Gig successfully"
					// 	);
					// 	// setGigMessage("");
					// 	// setOpenSendMessage(false);
					// }).catch((error:any) => {
					// 	console.error('Error sending message:', error);
					// 	updateAlertStatus(
					// 		true,
					// 		false,
					// 		"Something went wrong"
					// 	);
					// });
				})
				.catch(async (error: any) => {
					if (error.message === "Not Found") {
						const convo = await addConversation(
							title,
							uniqueName,
							updateParticipants,
							client,
							addNotifications
						);

						if (convo.sid) {
							const conversationDetails = await client.getConversationBySid(
								convo.sid
							);
							updateCurrentConversation(conversationDetails.sid);
							navigate("/messages");

							// conversationDetails.sendMessage(gigMessage).then(() => {
							// 	updateAlertStatus(
							// 		true,
							// 		true,
							// 		"Applied for Gig successfully"
							// 	);
							// 	// setGigMessage("");
							// 	// setOpenSendMessage(false)
							// }).catch((error:any) => {
							// 	console.error('Error sending message:', error);
							// });
						}
					} else {
						updateAlertStatus(true, false, "Something went wrong");
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
		});
	};

	useEffect(() => {
		setInstrumentForDisplay(profileDetails.userInstrumentsList.slice(0, 3));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		textFieldRef.current?.focus();
	}, [connnectionRequestPopup]);

	useEffect(() => {
		if (connnectionRequestPopup) {
			setTimeout(() => {
				const inputField = document.getElementById(
					"myInput"
				) as HTMLInputElement | null;
				if (inputField) {
					inputField.focus();
				}
			}, 500);
		}
	}, [connnectionRequestPopup]);

	if (isLoading) {
		return <CenterCircularProgress />;
	}
	const handlePlay = (musicId: any) => {
		props.onItemClick(musicId);
	};
	return (
		<>
			{/* {loading ? ( */}
			{/* // <Grid container item justifyContent="center" sx={{ p: "16px" }}> */}
			{/* // <CircularProgress /> The loading spinner */}
			{/* </Grid> */}
			{/* // ) : ( */}
			{/* // )} */}
			<Grid
				xs={12}
				sx={{ display: hideCardAfterAction, zIndex: 100 }}
				container
			>
				<Card
					elevation={0}
					className={classes.userCard}
					sx={{
						mb: 1.5,
						borderRadius: "12px",
						background:
							!isNotificationRead || currentPath !== "#/notifications"
								? "#fff"
								: "#F9F9FA",
						border:
							!isNotificationRead || currentPath !== "#/notifications"
								? "none"
								: "1px solid #E2E2E2",
						boxShadow:
							!isNotificationRead || currentPath !== "#/notifications"
								? "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
								: "none",
					}}
					onClick={handleCardClick}
				>
					{/* <CardMedia
                                component="img"
                                // height="200"
                                image={profileDetails.profilePicture}
                                alt={profileDetails.name}
                            /> */}
					<CardContent sx={{ padding: "8px" }}>
						<Grid container={true} xs={12}>
							{/* Profile Picture */}
							<Grid
								item
								xs={2}
								onClick={viewUserProfile}
								className="profilePic"
							>
								{props.notification && (
									<Typography
										sx={{
											backgroundColor: "#F5ECFF",
											borderRadius: "8px",
											textAlign: "center",
											display: "inline-block",
											padding: "0 10px",
											color: "#000",
											fontSize: "1rem",
											fontWeight: "500",
											marginBottom: 1,
										}}
										variant="subtitle2"
									>
										Connection Request
									</Typography>
								)}
								{/* <img src={profileDetails.profilePicture} alt="Profile Picture" style={{maxWidth: "100%", maxHeight: "100%"}} /> */}
								<ImageListItem>
									<img
										src={
											profileDetails.profilePictureUrl !== null &&
											profileDetails.profilePictureUrl !== ""
												? process.env.REACT_APP_PICTURE_URL +
												  profileDetails.profilePictureUrl
												: DefaultProfilePicture
										}
										alt=""
										loading="lazy"
										style={{ borderRadius: "12px", cursor: "pointer" }}
										className="musicCardProf"
									/>
									{/* <ImageListItemBar
                                                position="bottom"
                                                subtitle={<><img src={Guitar} alt="Guitar" /> <img src={Drums} alt="Drums" /></>}
                                                actionIcon={
                                                <IconButton
                                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                    // aria-label={`info about ${item.title}`}
                                                >
                                                </IconButton>
                                                }
                                                sx={{background: "none"}}
                                            /> */}
								</ImageListItem>
							</Grid>

							{/* Musicians Details  */}
							<Grid item xs={6} className="musicianDetails">
								<Stack marginX={2} rowGap={1}>
									<Typography
										variant="h6"
										sx={{
											fontWeight: 600,
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											cursor: "pointer",
										}}
										onClick={viewUserProfile}
									>
										{/* isNotification */}
										{profileDetails?.userProfileId === user?.userProfileId
											? profileDetails?.name
											: profileDetails?.userPrivacySettingsDTO
													?.identityPermission ===
											  "Name is not visible. Instead just show nickname"
											? `@` + profileDetails?.nickname
											: profileDetails?.userPrivacySettingsDTO
													?.identityPermission === "Name is visible to all"
											? profileDetails?.name
											: profileDetails?.userPrivacySettingsDTO
													?.identityPermission ===
											  "Name is visible only to my connections. Other see nickname"
											? profileDetails?.connected
												? profileDetails?.name
												: `@` + profileDetails?.nickname
											: profileDetails?.name}
									</Typography>
									<Typography variant="body1">
										{profileDetails.description}
									</Typography>
									<Typography
										variant="body1"
										sx={{
											fontSize: "1rem",
											fontWeight: "400",
											color: "#313131",
										}}
									>
										{profileDetails.profileTitle}
									</Typography>
									<Typography
										variant="body2"
										className="verticallyCenterUserCode"
									>
										<img src={Location} alt="Location" />
										{/* <span>{profileDetails.location}</span> */}
										<span>{extractLocationInfo(profileDetails?.location)}</span>
									</Typography>

									<Grid container>
										<Grid item xs={8}>
											<Typography
												variant="body2"
												className="verticallyCenterUserCode"
											>
												<img src={Peoples} alt="Peoples" />
												{profileDetails.totalConnections
													? profileDetails.totalConnections
													: 0}{" "}
												connections
											</Typography>
										</Grid>
										<Grid item xs={8}>
											{profileDetails.noOfMutualConnections ? (
												<Link
													to={`/musicians/mutuals/${profileDetails.userProfileId}`}
												>
													<Typography
														variant="body2"
														className="verticallyCenterUserCode"
													>
														<div
															style={{
																display: "flex",
																alignItems: "center",
																marginRight: "4%",
															}}
														>
															{profileDetails.mutualConnectionProfilePhotos
																?.slice(0, 3)
																.map(
																	(profilePhotoUrl: string, index: number) => (
																		<img
																			key={index}
																			src={
																				profilePhotoUrl
																					? `${process.env.REACT_APP_PICTURE_URL}${profilePhotoUrl}`
																					: DefaultProfilePicture
																			}
																			alt={`Mutual connection ${index + 1}`}
																			style={{
																				width: "22px",
																				height: "22px",
																				borderRadius: "50%",
																				objectFit: "cover",
																				border: "2px solid white",
																				marginLeft: index === 0 ? "0" : "-10px",
																				zIndex: index,
																			}}
																		/>
																	)
																)}
														</div>
														{profileDetails.noOfMutualConnections
															? profileDetails.noOfMutualConnections
															: 0}{" "}
														mutual connections
													</Typography>
												</Link>
											) : (
												<></>
											)}
										</Grid>
									</Grid>
									{profileDetails.profileMusic ? (
										<Grid container>
											{/* <CustomAudioPlayer
												audioUrl={ profileDetails.profileMusic.includes('https://') ? profileDetails.profileMusic :
													`${process.env.REACT_APP_PICTURE_URL}` +
													profileDetails.profileMusic
												}
												displayDirectly={true}
											/> */}
											<audio
												id={profileDetails.profileMusic}
												crossOrigin="anonymous"
												controls
												controlsList="nodownload noremoteplayback noplaybackrate"
												onPlay={() =>
													props.onItemClick(profileDetails.profileMusic)
												}
											>
												<source
													src={
														profileDetails.profileMusic.includes("https://")
															? profileDetails.profileMusic
															: `${process.env.REACT_APP_PICTURE_URL}${profileDetails.profileMusic}`
													}
													type="audio/mpeg"
												/>
											</audio>
										</Grid>
									) : (
										<></>
									)}
								</Stack>
							</Grid>

							{/* Action buttons & Instrument Skill levels */}
							<Grid item xs={4} className="instruSkill">
								<Grid container xs={12}>
									{/* {
                                                (profileDetails.requestedBy && profileDetails.requestedBy == user.id) ?
                                                    <Grid container xs={12} justifyContent="flex-end" gap={1}>
                                                        <Button className={classes.withdrawBtn} startIcon={<img src={ProfileRemoveWhite} alt="Reject" />}>
                                                        </Button>
                                                            <span>Withdraw</span>
                                                    </Grid>
                                                :
                                                <Grid container xs={12} justifyContent="flex-end" gap={1}>
                                                    <Button variant="outlined" className={classes.rejectBtn} startIcon={<img src={CloseCircled} alt="Reject" />}>
                                                        <span>Reject</span>
                                                    </Button>
                                                    <Button variant="contained" className={classes.acceptBtn} startIcon={<img src={TickCircleOrange} alt="Accept" />}>
                                                        <span>Accept</span>
                                                    </Button>
                                                </Grid>

                                            } */}

									{loading ? (
										<Grid container xs={12}>
											<Grid item xs={6}></Grid>
											<Grid item xs={6}>
												<CircularProgress />
											</Grid>
										</Grid>
									) : !profileDetails.isBlockedByMe &&
									  profileDetails.rejected === false &&
									  profileDetails.connected === true &&
									  props.isNotification === true ? (
										<Grid container xs={12}>
											<Grid item xs={6}></Grid>
											<Grid item xs={6}>
												<Button
													className={classes.connectBtn}
													disabled
													variant="contained"
													size="small"
												>
													<span>Accepted</span>
												</Button>
											</Grid>
										</Grid>
									) : !props.user.isBlockedByMe &&
									  props.user.rejected === true &&
									  props.user.connected === false &&
									  props.isNotification === true ? (
										<Grid container xs={12}>
											<Grid item xs={6}></Grid>
											<Grid item xs={6}>
												<Button
													className={classes.connectBtn}
													disabled
													variant="contained"
													size="small"
												>
													<span>Rejected</span>
												</Button>
											</Grid>
										</Grid>
									) : !profileDetails.isBlockedByMe &&
									  profileDetails.rejected === false &&
									  profileDetails.connected === true ? (
										<Grid container spacing={1}>
											<Grid item xs={6}>
												<Button
													className={classes.connectBtn}
													variant="outlined"
													size="small"
													startIcon={<img src={ClosedCircled} alt="Close" />}
													//startIcon={<img src={PeopleRemove} alt="Remove" />}
													// onClick={() =>
													// 	sendConnectionRequest(
													// 		user.userProfileId,
													// 		profileDetails.userProfileId,
													// 		"REMOVED"
													// 	)
													// }
													onClick={() => setRemoveConfirmation(true)}
													disabled={
														user.userAccountStatus !== "ACTIVE" || isDisable
													}
													style={{
														color: "#0273E6",
														border: "1.5px Solid #0273EB",
														width: "118px",
														//height: "30px",
														padding: "0px, 20px, 0px, 20px",
														marginLeft: "20px",
													}}
												>
													<span>Remove</span>
												</Button>
											</Grid>

											<Grid item xs={6}>
												{profileDetails.userPrivacySettingsDTO
													.messagePermission !==
													"No one can send me messages" && (
													<Button
														className={classes.connectBtn}
														variant="contained"
														size="small"
														startIcon={<img src={Message} alt="Message" />}
														onClick={sendMessage}
														disabled={user.userAccountStatus !== "ACTIVE"}
													>
														<span>Message</span>
													</Button>
												)}
											</Grid>
										</Grid>
									) : // : ((!profileDetails.isBlockedByMe && profileDetails.canSendConnection && profileDetails.rejected === false && profileDetails.connected === false && profileDetails.invited === false && profileDetails.userProfileId != user.userProfileId) || (profileDetails.rejected && profileDetails.userProfileId != user.userProfileId )) ?
									!profileDetails.isBlockedByMe &&
									  profileDetails.canSendConnection &&
									  //   profileDetails.rejected === false &&
									  profileDetails.connected === false &&
									  profileDetails.invited === false &&
									  profileDetails.userProfileId !== user.userProfileId ? (
										<>
											{/* <Grid item xs={6}>
                                                        </Grid> */}
											<Grid item xs={6}></Grid>
											<Grid item xs={6}>
												<Button
													sx={{ width: "100%" }}
													variant="contained"
													startIcon={
														<img src={ProfileAddWhite} alt="Connect" />
													}
													onClick={() => setConnectionRequestPopup(true)}
													disabled={
														user.userAccountStatus !== "ACTIVE" || isAdmin
													}
												>
													<Typography
														variant="h6"
														component="span"
														sx={{ fontSize: "1rem" }}
													>
														Connect
													</Typography>
												</Button>
											</Grid>

											{/* <Grid item xs={6}>
                                                            {
                                                                // (profileDetails.noOfMutualConnections && profileDetails.noOfMutualConnections > 0) ?
                                                                    <Typography align="right">
                                                                        <img src={Peoples} alt="Mutual Connections" style={{verticalAlign: "bottom", margin: "0 5px"}}/>
                                                                        <span style={{fontSize: "80%"}}>{profileDetails.noOfMutualConnections}</span>
                                                                    </Typography>
                                                                // : ""
                                                            }
                                                        </Grid> */}
										</>
									) : (!profileDetails.isBlockedByMe &&
											profileDetails.canSendConnection &&
											profileDetails.rejected === false &&
											profileDetails.connected === false &&
											profileDetails.invited === false &&
											profileDetails.userProfileId === user.userProfileId) ||
									  (profileDetails.rejected &&
											profileDetails.userProfileId === user.userProfileId) ? (
										<>
											{/* <Grid item xs={6}>
                                                    </Grid> */}
											<Grid item xs={5}></Grid>
											<Grid item xs={7}>
												<Button
													variant="contained"
													startIcon={
														<img src={ProfileWhite} alt="View Profile" />
													}
													sx={{ width: "100%", padding: "6px 0 6px 3px" }}
													onClick={viewUserProfile}
												>
													<Typography
														variant="h6"
														component="span"
														sx={{ fontSize: "1rem" }}
													>
														View Profile
													</Typography>
												</Button>
											</Grid>

											{/* <Grid item xs={6}>
                                                        {
                                                            // (profileDetails.noOfMutualConnections && profileDetails.noOfMutualConnections > 0) ?
                                                                <Typography align="right">
                                                                    <img src={Peoples} alt="Mutual Connections" style={{verticalAlign: "bottom", margin: "0 5px"}}/>
                                                                    <span style={{fontSize: "80%"}}>{profileDetails.noOfMutualConnections}</span>
                                                                </Typography>
                                                            // : ""
                                                        }
                                                    </Grid> */}
										</>
									) : !profileDetails.isBlockedByMe &&
									  profileDetails.requestFromId &&
									  profileDetails.rejected === false &&
									  profileDetails.invited === true &&
									  profileDetails.requestFromId === user.userProfileId ? (
										<>
											<Grid xs={6}></Grid>
											<Grid xs={6}>
												<Button
													sx={{ width: "100%" }}
													variant="contained"
													startIcon={
														<img src={ProfileRemoveWhite} alt="Withdraw" />
													}
													onClick={() =>
														sendConnectionRequest(
															user?.userProfileId,
															profileDetails?.userProfileId,
															"WITHDRAWN",
															user?.userProfileUuid,
															profileDetails?.userProfileUUID,
														)
													}
													disabled={
														user.userAccountStatus !== "ACTIVE" || isDisable
													}
												>
													{/* <span>Withdraw</span> */}
													<Typography variant="h6" component="span">
														Withdraw
													</Typography>
												</Button>
											</Grid>

											{/* <Grid item xs={6}>
                                                            {
                                                                (profileDetails.noOfMutualConnections && profileDetails.noOfMutualConnections > 0) ?
                                                                    <Typography align="right">
                                                                        <img src={Peoples} alt="Mutual Connections" style={{verticalAlign: "bottom", margin: "0 5px"}}/>
                                                                        <span style={{fontSize: "80%"}}>{profileDetails.noOfMutualConnections}</span>
                                                                    </Typography>
                                                                : ""
                                                            }
                                                        </Grid> */}
										</>
									) : !profileDetails.isBlockedByMe &&
									  profileDetails.requestFromId &&
									  profileDetails.rejected === false &&
									  profileDetails.invited === true &&
									  profileDetails.requestFromId !== user.userProfileId ? (
										<>
											<Grid container spacing={1}>
												<Grid xs={2}></Grid>
												<Grid item xs={5}>
													<Button
														className={classes.connectBtn}
														variant="outlined"
														startIcon={<img src={CloseCircled} alt="Reject" />}
														onClick={() =>
															sendConnectionRequest(
																profileDetails.userProfileId,
																user.userProfileId,
																"REJECTED",
																profileDetails?.userProfileUUID,
																user?.userProfileUuid,
															)
														}
														disabled={user.userAccountStatus !== "ACTIVE"}
													>
														<span>Reject</span>
													</Button>
												</Grid>

												<Grid item xs={5}>
													<Button
														className={classes.connectBtn}
														variant="contained"
														startIcon={
															<img src={TickCircleOrange} alt="Connect" />
														}
														onClick={() =>
															sendConnectionRequest(
																profileDetails.userProfileId,
																user.userProfileId,
																"ACCEPTED",
																profileDetails?.userProfileUUID,
																user?.userProfileUuid,
															)
														}
														disabled={
															user.userAccountStatus !== "ACTIVE" || isDisable
														}
													>
														<span>Accept</span>
													</Button>
												</Grid>
											</Grid>
										</>
									) : profileDetails.rejected === true &&
									  profileDetails.requestFromId !== user.userProfileId &&
									  window.location.hash === "#/musicians/invitations" ? (
										!props.notification ? (
											<>
												<Grid item xs={6}></Grid>

												<Grid item xs={6}>
													<Button
														variant="outlined"
														startIcon={<img src={Trash} alt="Reject" />}
														onClick={() => deleteConnectionRequest()}
														disabled={
															user.userAccountStatus !== "ACTIVE" || isDisable
														}
													>
														<span>Delete</span>
													</Button>
												</Grid>

												{/* <Grid item xs={6}>
                                                            <Button className="submit-button" variant="contained" startIcon={<img src={TickCircleOrange} alt="Connect" />} onClick={() => sendConnectionRequest(profileDetails.userProfileId, 'ACCEPTED')} >
                                                                <span >Accept</span>
                                                            </Button>
                                                        </Grid> */}
											</>
										) : (
											<>
												<Grid item xs={6}></Grid>

												<Grid item xs={6}>
													<Button
														className={classes.connectBtn}
														disabled
														variant="contained"
														size="small"
													>
														<span>Rejected</span>
													</Button>
												</Grid>
											</>
										)
									) : profileDetails.isBlockedByMe ? (
										<>
											<Grid item xs={6}></Grid>

											<Grid item xs={6}>
												<Button
													variant="contained"
													startIcon={
														<img src={ProfileRemoveWhite} alt="Reject" />
													}
													onClick={() => setOpenUnblockDialog(true)}
													disabled={
														user.userAccountStatus !== "ACTIVE" || isDisable
													}
												>
													<span>Unblock</span>
												</Button>
											</Grid>

											<VerifyAccount
												open={openUnblockDialog}
												handleClose={handleClose}
												unblock={true}
												toUnblockUser={{
													userProfile: { id: user.userProfileId },
													blockedUser: profileDetails.userProfileId,
													blockedUserUUID: profileDetails.userProfileUUID
												}}
												refreshList={props.refreshList}
												source="musicianList"
											/>

											{/* <Grid item xs={6}>
                                                            <Button className="submit-button" variant="contained" startIcon={<img src={TickCircleOrange} alt="Connect" />} onClick={() => sendConnectionRequest(profileDetails.userProfileId, 'ACCEPTED')} >
                                                                <span >Accept</span>
                                                            </Button>
                                                        </Grid> */}
										</>
									) : (
										<></>
									)}
								</Grid>
								<Grid
									container
									xs={12}
									marginTop={1}
									//onClick={viewUserProfile}
								>
									{profileDetails.userInstrumentsList &&
									instrumentForDisplay.length > 0 ? (
										instrumentForDisplay.map((instrument: any) => {
											return (
												<InstrumentSkillLevel
													instrument={instrument.instrument.id}
													instrumentLabel={instrument.instrument.instrumentName}
													skillLevel={instrument.skillLevel}
													editable={false}
												/>
											);
										})
									) : (
										<></>
									)}

									{/* {(profileDetails.userInstrumentsList.length > 3) ? <> */}
									{/* {'+ '+ (profileDetails.userInstrumentsList.length-3) + 'more' }  */}
									{instrumentForDisplay.length <
									profileDetails.userInstrumentsList.length ? (
										<>
											{/* <span onClick={() => loadMoreInstrument()}> */}
											<span>
												{/* + { (profileDetails.userInstrumentsList.length > 5 &&instrumentForDisplay.length === 3) ? '3 ' : (profileDetails.userInstrumentsList.length - instrumentForDisplay.length)} more  */}
												{profileDetails.userInstrumentsList.length > 3
													? "+" +
													  (profileDetails.userInstrumentsList.length - 3) +
													  " more"
													: ""}
											</span>
										</>
									) : (
										<></>
									)}

									{/* {
                                                instrumentForDisplay.length > 3 &&
                                                <span onClick={() => revertLoadMoreInstrument()} >Show Less</span>
                                            } */}
								</Grid>
							</Grid>
						</Grid>
					</CardContent>

					{/* {profileDetails.note &&  */}
					<CardActions
						style={{
							borderTop: profileDetails.note ? "1px solid #CFD9DF" : "none",
						}}
					>
						<Grid container xs={12}>
							<Grid item xs={10}>
								{profileDetails.note ? (
									profileDetails.note.length < 70 ? (
										<Typography variant="subtitle2" component="span">
											<b>Note: </b>
											{profileDetails.note}
										</Typography>
									) : (
										<Typography variant="subtitle2" component="span">
											<b>Note: </b>
											{expanded === false ? (
												<Typography>
													{profileDetails.note.substring(0, 70)} ...
												</Typography>
											) : (
												<Typography>
													{insertLineBreaks(noteForDisplay, 70)}
												</Typography>
											)}
										</Typography>
									)
								) : (
									<></>
								)}
							</Grid>

							<Grid item xs={2} sx={{ mt: profileDetails?.note ? "none" : -5 }}>
								{profileDetails.userInstrumentsList.length > 3 ||
								(profileDetails.note && profileDetails.note.length >= 70) ? (
									<>
										{expanded ? (
											<Button
												className={classes.toggleBtn}
												size="small"
												endIcon={<img src={ArrowCircleUp} alt="Hide" />}
												onClick={handleExpandClick}
												disableTouchRipple={true}
												sx={{ whiteSpace: "nowrap", mt: 3.3 }}
											>
												<Typography className={classes.toggleBtnText}>
													Hide
												</Typography>
											</Button>
										) : (
											<Button
												className={classes.toggleBtn}
												size="small"
												endIcon={<img src={ArrowCircleRight} alt="View All" />}
												onClick={handleExpandClick}
												disableTouchRipple={true}
												sx={{
													whiteSpace: "nowrap",
													marginLeft: "-15px",
													mt: 1,
												}}
											>
												<Typography className={classes.toggleBtnText}>
													Show more
												</Typography>
											</Button>
										)}
									</>
								) : (
									<>
										{expanded && (
											<Button
												className={classes.toggleBtn}
												size="small"
												endIcon={<img src={ArrowCircleUp} alt="Hide" />}
												onClick={handleExpandClick}
												disableTouchRipple={true}
											>
												<Typography className={classes.toggleBtnText}>
													Hide
												</Typography>
											</Button>
										)}
									</>
								)}
							</Grid>
						</Grid>
					</CardActions>
					{/* } */}
					{/* <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                            <Typography variant="subtitle2" component="span"><b>Note: </b>I would like to connect with you as I need to also practice Guitar and we can also meet. I would like to connect with you as I need to also practice Guitar and we can also meet. I would like to connect with you as I need to also practice Guitar and we can also meet</Typography>
                            </CardContent>
                        </Collapse> */}
				</Card>
			</Grid>

			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="sm"
				onClose={() => setConnectionRequestPopup(false)}
				open={connnectionRequestPopup}
			>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					{/* <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                        }}
                    >
                        <IconButton
                            aria-label="close dialogbox"
                            onClick={props.onClose}
                            onMouseDown={(
                                event: React.MouseEvent<HTMLButtonElement>
                            ) => event.preventDefault()}
                            edge="end"
                            sx={{ marginLeft: "90%" }}>
                            <img
                                src={CloseIcon}
                                alt="close"
                            />
                        </IconButton>
                    </Box> */}
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							className="dialog-title-custom-size"
							sx={{
								fontWeight: 500,
								color: "#000000",
							}}
						>
							{i18n.musicians.connection.title}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Grid
						container
						xs={12}
						// columnSpacing={2}
					>
						<Grid item={true} xs={12}>
							<FormHelperText className="jamsession-label">
								{i18n.musicians.connection.label}
							</FormHelperText>
							<TextField
								id="myInput"
								inputProps={{
									style: {
										fontFamily: "Lexend !important",
										fontStyle: "normal",
										fontSize: "16px !important",
										lineHeight: "25px",
										fontWeight: 500,
										color: "#000000",
									},
								}}
								sx={{ background: "#F8F8F8" }}
								fullWidth
								// multiline
								variant="outlined"
								value={connnectionRequestNote}
								multiline
								rows={2}
								maxRows={10}
								onChange={(event) =>
									setConnectionRequestNote(event.target.value.trimStart())
								}
								error={connnectionRequestNote.length > 255}
								inputRef={textFieldRef}
							></TextField>
							<FormHelperText
								sx={{
									fontSize: "0.8125rem",
									color: "#FD4B24",
									textAlign: "right",
								}}
							>
								{connnectionRequestNote?.length > 255
									? "Enter maximum 255 characters"
									: ""}
							</FormHelperText>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						columnSpacing={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<Grid item={true}>
							<Button
								className="cancel-button"
								size="large"
								// endIcon={<img src={RightAarrow} alt="Right Arrow" />}
								variant="outlined"
								onClick={handleClose}
							>
								{i18n.dialog.cancel}
							</Button>
						</Grid>
						<Grid item={true}>
							<Button
								className="submit-button"
								size="large"
								variant="contained"
								onClick={() =>
									sendConnectionRequest(
										user.userProfileId,
										profileDetails.userProfileId,
										"PENDING",
										user.userProfileUuid,
										profileDetails.userProfileUUID,
									)
								}
								disabled={connnectionRequestNote.length > 255 || isDisable}
								endIcon={<img src={send} alt="Send" />}
							>
								<Typography>
									{isDisable ? "Sending..." : i18n.musicians.send}
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>

			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="xs"
				onClose={() => setRemoveConfirmation(false)}
				open={removeConfirmation}
			>
				{/* <DialogTitle
                            className="edit-heading-name"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography className="dialog-title-custom-size"
                                    sx={{
                                        fontWeight: 500,
                                        color: "#000000",
                                    }}
                                >
                                    {i18n.musicians.connection.removeConfirmationTitle}
                                </Typography>
                            </Box>
                        </DialogTitle> */}
				<DialogContent>
					<Grid
						container
						xs={12}
						// columnSpacing={2}
					>
						<Typography
							className="dialog-title-custom-sizea"
							variant="h4"
							sx={{
								fontWeight: 500,
								color: "#000000",
							}}
						>
							{i18n.musicians.connection.removeConfirmationTitle} {nameOfUser}
						</Typography>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						columnSpacing={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<Grid item={true}>
							<Button
								className="cancel-button"
								size="medium"
								// endIcon={<img src={RightAarrow} alt="Right Arrow" />}
								variant="outlined"
								onClick={() => setRemoveConfirmation(false)}
							>
								{i18n.dialog.cancel}
							</Button>
						</Grid>
						<Grid item={true}>
							<Button
								className="submit-button"
								size="small"
								variant="contained"
								// disabled={serialNumber === "" || purchaseDate === null || (purchaseDate !== null && purchaseDate >= new Date())}
								onClick={() =>
									sendConnectionRequest(
										user.userProfileId,
										profileDetails.userProfileId,
										"REMOVED",
										user.userProfileUuid,
										profileDetails.userProfileUUID,
									)
								}
								disabled={isDisable}
								endIcon={<img src={RightArrow} alt="Down Arrow" />}
							>
								<Typography>{i18n.musicians.confirm}</Typography>
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default UserCardDetailed;
