import React, { useCallback, useEffect, useState } from 'react';
import {
  useParticipantProperty,
  useLocalSessionId,
  useDaily,
  useRoom,
} from '@daily-co/daily-react';
import { DailyDrawerCloseButton, DailyDrawerHeader, DailyDrawerTitle, DailyDrawerWrapper, DrawerContent } from '../Chat/ChatStyled';
import CloseIcon from '../../../icons/CloseIcon';
import { DailyParticipanysType, ParticipantRowType } from '../Types/dailyTypes';
import { ProfileIcon } from "../../../icons/icons";
import { PeopleActionItem, PeopleActions, PeopleDetails, PeopleTitle, PeopleWrapper } from './ParticipantsStyled';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';
import { useDailyUtils } from '../Hooks/useDailyUtils';
import WebsocketClientUSerStatus from '../../../services/WebsocketClientUSerStatus';
import { useUser } from '../../../context/UserContext';
import { useFullscreenModeContext } from '../../../context/FullscreenModeContext';
import { useDailyAppRoom } from '../Hooks/useDailyGlobalRoom';
import { syncAudioControls } from '../../Mixer/utils';

const ParticipantRow: React.FC<ParticipantRowType> = ({ id, isLocal = false, sessionParticipantsVolumeDetails }) => {
  const user = useUser();
  const { getUserByEmail } = useDailyUtils();
  const [username, audioState] = useParticipantProperty(id, [
    'user_name',
    'tracks.audio.state'
  ]) as [string | undefined, string | undefined];

  const callObject = useDaily();
  // console.log(callObject);

  const twilioSessionId = localStorage.getItem("twilioSessionId");

  const audioDetails = sessionParticipantsVolumeDetails.find(
    (p: any) => p.email === username
  );

  const [participantAudio, setParticipantAudio] = useState(
    audioDetails.audio.master.audio_status === "mute" ? false : true
  );

  const [volume, setVolume] = useState(audioDetails.audio.master.audio_level);

  useEffect(() => {
    if (audioDetails) {
      setParticipantAudio(audioDetails.audio.master.audio_status === "mute" ? false : true);
    }
  }, [audioDetails]);

  useEffect(() => {
    WebsocketClientUSerStatus.on("audioControl", onMessageReceived);
    return () => {
      WebsocketClientUSerStatus.off("audioControl", onMessageReceived);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMessageReceived = (data: any) => {
    if (data.metadata.created_by === username) {
      if (data.metadata.audio.master.audio_status === "mute") {
        setParticipantAudio(false);
      } else {
        setParticipantAudio(true);
      }
    }
  };



  const participantAudioControl = () => {
    let audioStatus =
			audioDetails.audio.master.audio_status === "mute" ? "unmute" : "mute";
		let message = {
			action: "audio_control",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			created_for: username,
			// audio_status: audioStatus,
			// audio_level: volume ?? 50
			audio: {
				updated_for: "master",
				master: {
					audio_status: audioStatus,
					audio_level: volume ?? 50
				},
				channel_1: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.channel_1.audio_level,
				},
				channel_2: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.channel_2.audio_level,
				},
				mic: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.mic.audio_level,
				},
			},
		};
		WebsocketClientUSerStatus.handleAudioControl(message);
    syncAudioControls(twilioSessionId)
  };


  const muteParticipant =  () => {
      const isUserOnMute = audioDetails.audio.master.audio_status === "mute"
      const hasPermission = user.email === username;
      if (!callObject) return;
      if (hasPermission || (!hasPermission && !isUserOnMute)) {
        participantAudioControl();
      }
  }

  const peopleName = `${username ? getUserByEmail(username, true) : 'Guest'} ${isLocal ? '(You)' : ''}`;
  return (
    <PeopleWrapper>
      <PeopleDetails>
        <ProfileIcon />
        <PeopleTitle title={peopleName}>{peopleName}</PeopleTitle>
      </PeopleDetails>
      <PeopleActions>
        <PeopleActionItem onClick={() => muteParticipant()}>{participantAudio ? <MicIcon /> : <MicOffIcon />}</PeopleActionItem>
      </PeopleActions>
    </PeopleWrapper>
  );
};

const Participants = ({ toggleParticipants, remoteParticipantIds }: DailyParticipanysType) => {

  const { isFullscreenMode, isTabHidden } = useFullscreenModeContext();
  const localSessionId = useLocalSessionId();
  const user = useUser();
  const {  sessionParticipantsVolumeDetails } = useDailyAppRoom();
  const participantIds = [localSessionId, ...remoteParticipantIds];
  const twilioSessionId = localStorage.getItem("twilioSessionId");

  console.log(sessionParticipantsVolumeDetails, "======>>>>>> Sessio participant details...");

  const participantDetailList = sessionParticipantsVolumeDetails || JSON.parse(
    localStorage.getItem("participantVolumeDetails") ?? ""
  );

  const [connectedPeoplesList, setConnectedPeoplesList] = useState<any>([]);
  const [participantNotConnected, setParticipantNotConnected] = useState<any>([]);

  const userSession = localStorage.getItem("userSession") ?? "";
  const userSessions = JSON.parse(userSession);


  const callObject = useDaily();
  console.log(callObject);

  useEffect(() => {
    if (callObject) {
      syncAudioControls(twilioSessionId)
      const updateNotConnectedParticipants = () => {
        const participantsList = callObject.participants();
        const joinedEmails = Object.values(participantsList).map((p) => p.user_name);

        const notConnected = userSessions.attendees.filter(
          (item: any) => !joinedEmails.includes(item.email)
        );

        setParticipantNotConnected(notConnected);
      };

      // Initial check and subscribe to participant events to keep it updated
      updateNotConnectedParticipants();
      callObject.on("participant-joined", updateNotConnectedParticipants);
      callObject.on("participant-left", updateNotConnectedParticipants);

      // Clean up event listeners on component unmount
      return () => {
        callObject.off("participant-joined", updateNotConnectedParticipants);
        callObject.off("participant-left", updateNotConnectedParticipants);
      };
    }
  }, [callObject]);


  const muteAll = useCallback(
    (deviceType = 'audio') => {
      if (callObject) {
        let updatedParticipantList: any = {};
        const newSetting =
          deviceType === 'video' ? { setVideo: false } : { setAudio: false };
        let connectedPeopleCallObjects = [];
        for (let id in callObject?.participants()) {
          // Do not update the local participant's device
          if (id === 'local') continue;
          updatedParticipantList[id] = newSetting;
          connectedPeopleCallObjects.push(callObject?.participants()[id]);
        }
        setConnectedPeoplesList(connectedPeopleCallObjects);
        // Update all participants at once
        callObject.updateParticipants(updatedParticipantList);

        audioControl(connectedPeopleCallObjects);

      }
    },
    [callObject]
  );

  const audioControl = (connectedPeoplesList: any[]) => {
    let wantedParticipants: any[] = [];

    // Loop through connectedPeopleList and filter out the wanted participants
    connectedPeoplesList.forEach((connectedParticipant: any) => {
      const foundParticipant = participantDetailList.find(
        (connParticipant: any) => connectedParticipant.user_name === connParticipant.email
      );

      //  // Only add if it's not already in the array to prevent duplicates
      if (foundParticipant && !wantedParticipants.some((p: any) => p.email === foundParticipant.email)) {
        wantedParticipants.push(foundParticipant);
      }
    });

    wantedParticipants.forEach((participant: any) => {
      let audioStatus = "mute";

      const audioDetails = participantDetailList.find(
        (p: any) => p.email === participant.email
      );

      // if (participant.email !== room!.localParticipant.identity) {
      let message = {
        action: "audio_control",
        timestamp: new Date().toISOString(),
        session_id: twilioSessionId,
        created_by: user.id,
        created_for: participant.email,
        audio: {
          updated_for: "master",
          master: {
            audio_status: audioStatus,
            audio_level: 0,
          },
          channel_1: {
            audio_status: audioStatus,
            audio_level: audioDetails.audio.channel_1.audio_level,
          },
          channel_2: {
            audio_status: audioStatus,
            audio_level: audioDetails.audio.channel_2.audio_level,
          },
          mic: {
            audio_status: audioStatus,
            audio_level: audioDetails.audio.mic.audio_level,
          },
        },
      };
      console.log("audioControl", {participantDetailList, audioDetails,message});
      WebsocketClientUSerStatus.handleAudioControl(message);
    }
    );
		syncAudioControls(twilioSessionId)
    
    wantedParticipants = [];

  };


  return (
    <DailyDrawerWrapper isFullscreenMode={isFullscreenMode || isTabHidden}>
      <DailyDrawerHeader>
        <DailyDrawerTitle>People</DailyDrawerTitle>
        <DailyDrawerCloseButton onClick={() => toggleParticipants(false)} type="button">
          <CloseIcon />
        </DailyDrawerCloseButton>
      </DailyDrawerHeader>
      <DrawerContent>
        <PeopleWrapper>
          <PeopleTitle>In Session  ({participantIds?.length})</PeopleTitle>
          <PeopleActions>
            <PeopleActionItem onClick={() => muteAll()}><MicOffIcon /> <PeopleTitle>Mute All </PeopleTitle></PeopleActionItem>
          </PeopleActions>
        </PeopleWrapper>
        {participantIds.map((id) => (
          <ParticipantRow key={id} id={id} isLocal={id === localSessionId} sessionParticipantsVolumeDetails={participantDetailList}/>
        ))}

        <PeopleWrapper>
          <PeopleTitle>Not Joined ({participantNotConnected?.length})</PeopleTitle>
        </PeopleWrapper>
        {participantNotConnected.map((participant: any) => {
          return (
            <PeopleWrapper>
              <PeopleDetails>
                <ProfileIcon />
                <PeopleTitle title={`${participant.firstName} ${participant.lastName}`}>
                  {`${participant.firstName} ${participant.lastName}`}
                </PeopleTitle>
              </PeopleDetails>
            </PeopleWrapper>
          )
        })}

      </DrawerContent>
    </DailyDrawerWrapper>


  );
};

export default Participants;
