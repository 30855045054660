import {
	Box,
	Typography,
	Grid,
	Button,
	FormHelperText,
	TextField,
	DialogContent,
	DialogTitle,
	Dialog,
	DialogActions,
} from "@mui/material";
import { useState } from "react";
import { useAlertStatus } from "../../context/AlertStatusContext";
import ArrowLeft from "../../images/arrow-left-orange.svg";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { useUser } from "../../context/UserContext";

const CommentReplyDialog = (props: any) => {
	const [replyMessage, setReplyMessage] = useState<string>("");
	const token = localStorage.getItem("sessionId");
	const { updateAlertStatus } = useAlertStatus();
	const [loading, setLoading] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertStatus, setAlertStatus] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
	
	
	const [disableSendButton, setDisableSendButton] = useState(true);

	const user = useUser();

	const sendReply = () => {
		
		setLoading(true);
		setDisableSendButton(true);
		
		if (replyMessage.length > 600) {
		  setShowAlert(true);
		  setAlertStatus(false);
		  setAlertMsg("Max 600 characters allowed!");
		  
		  setLoading(false);
		  setDisableSendButton(false);
		  
		} else {
		  var formData = new FormData();
		  formData.append('commentType', 'TEXT')
		  formData.append("parentCommentUUID", props.notification.parentCommentUUID);
		  formData.append("postUUID", props.notification.postUUID);
		  formData.append("commentOnPost", replyMessage);
		  formData.append('musicUUID',  '');
		  formData.append("file", '');
		  console.log('FormData contents:');
		  for (let [key, value] of formData.entries()) {
			console.log(`${key}: ${value}`);
		  }		  // formData.append("totalReactions", '');
		  // formData.append("userReaction", '');
		  // formData.append("childComments", '');
		  fetch(`${process.env.REACT_APP_BASE_URL}/api/comments/save`, {
			method: "POST",
			headers: {
			  Authorization: `Bearer ${token}`,
			},
			body: formData
		  }).then((res) => {
			if(res.status === 204) {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Comment has been deleted by user");
			}
			else if (res.status >= 400) {
			  ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
			} else {
				setReplyMessage("");
				props.closeCommentReplyDialog();
			}
			setLoading(false);
			setDisableSendButton(false);
			
		  }).catch((err) => {
			setShowAlert(true);
			setAlertStatus(false);
			setAlertMsg("Network Error! Please try again..");
			setLoading(false);
			setDisableSendButton(false);
		  });
		}
	  }
    
    const handleAlertClose = () => {
        setShowAlert(false);
    };

	return (
        <>
            <ErrorAlertDisplay
                alertMsg={alertMsg}
                alertStatus={alertStatus}
                showAlert={showAlert}
                handleAlertClose={handleAlertClose}
            />
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="md"
				open={props.open}
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							sx={{
								fontWeight: 500,
								fontSize: "1.875rem !important",
								color: "#000000",
							}}
						>
							Reply to comment
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Grid container xs={12}>
						<Grid item={true} xs={12}>
							<FormHelperText className="jamsession-label">
								Reply to <strong>
									{props.notification.userPrivacySettingsDTO?.identityPermission === "Name is not visible. Instead just show nickname"
										? props.notification.nickname
										: props.notification.userPrivacySettingsDTO?.identityPermission === "Name is visible only to my connections. Other see nickname"
											? props.notification.connected
												? props.notification.name
												: props.notification.nickname
											: props.notification.name
									}
								</strong>'s Comment
							</FormHelperText>
							
							
							<TextField
								id="myInput"
								autoFocus={true}
								inputProps={{
									style: {
										fontStyle: "normal",
										fontSize: "1.125rem",
										lineHeight: "25px",
										fontWeight: 900,
										color: "#000000",
									},
								}}
								sx={{ background: "#F8F8F8", mt: 1 }}
								fullWidth
								// multiline
								variant="outlined"
								value={replyMessage}
								multiline
								rows={4}
								maxRows={10}
								onChange={(event) => {
									const inputValue = event.target.value.trimStart();
									if (inputValue.length <= 600) {
										setReplyMessage(inputValue);
										setShowWarning(false);
										
										setDisableSendButton(inputValue.trim() === '');    // Disable if empty or contains only spaces
									} else {
										setShowWarning(true);
										setDisableSendButton(true);
									}
								}}
							></TextField>
							{showWarning && (
								<FormHelperText
									sx={{
										display: "flex",
										justifyContent: "flex-end",
										fontSize: "0.8125rem",
										color: "#FD4B24",
									}}
								>
                                    Enter maximum 600 characters
								</FormHelperText>
							)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						xs={12}
						display="flex"
						sx={{ mt: 9 }}
						justifyContent="flex-end"
						alignItems="center"
						columnSpacing={1}
					>
						{/* <Grid item xs={2}> */}
						<Button
							// endIcon={<img src={RightAarrow} alt="Right Arrow" />}
							variant="outlined"
							onClick={props.closeCommentReplyDialog}
							sx={{ width: "8.75rem" }}
							startIcon={<img src={ArrowLeft} alt="Arrow Left" />}
						>
							<Typography
								sx={{ fontSize: "18px", fontWeight: "500", color: "#FF702A" }}
							>
								Back
							</Typography>
						</Button>
						
						<Button
						    disabled={disableSendButton || loading}    // Use disableSendButton state
							//disabled={loading}
							variant="contained"
							size="large"
							sx={{ width: "8.75rem", marginLeft: "1rem" }}
							onClick={() => sendReply()}
						>
							<Typography
								sx={{ fontSize: "18px", fontWeight: "500", color: "#fff" }}
							>
								{/* Send */}
								{loading ? "Sending..." : "Send"}
							</Typography>
						</Button>
						{/* </Grid> */}
					</Grid>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CommentReplyDialog;
