import {
	Typography,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Grid,
	Box,
	Stack,
	Divider,
} from "@mui/material";
import { format } from "date-fns";
import ActionDialog from "../../reusable-components/ActionDialog";
import { useEffect, useState } from "react";
import CopyIcon from "../../../images/copy.svg";
import Profile from "../../../images/profile-blue.svg";
import useSnackbarAlert from "../../../hooks/useSnackbarAlert/useSnackbarAlert";
import { UserInterface } from "../../../types/types";
import { styled } from "@mui/material/styles";
import { useDailyAppRoom } from "../Hooks/useDailyGlobalRoom";
import WebsocketClientUSerStatus from "../../../services/WebsocketClientUSerStatus";

type StartLiveStreamProps = {
	twilioSessionId: string;
	isBroadcastingStarted: boolean;
	sessionInformationDialogBoxOpen: boolean;
	closeSessionInformationDialogBox: () => void;
};

export const Heading = styled(Typography)(({ theme }) => ({
	fontFamily: "Lexend",
	color: "#7A7985",
	fontSize: "1rem",
	fontWeight: 400,
	lineHeight: "20px",
}));

const Content = styled(Typography)(({ theme }) => ({
	fontFamily: "Lexend",
	fontSize: "1rem",
	fontWeight: "500",
	color: "#000",
	lineHeight: "20px",
}));

const SessionInformation: React.FC<StartLiveStreamProps> = (props) => {
	const [isCopied, setIsCopied] = useState(false);
	const _userSession = localStorage.getItem("userSession") ?? "";
	const userSession = JSON.parse(_userSession);
	const { SnackbarAlert, handleAlertOpen } = useSnackbarAlert();
	const { allParticipantsCount } = useDailyAppRoom();
	const [liveStreamStartTime, setLiveStreamStartTime] = useState<string | null>(
		null
	);

	const url =
		`${process.env.REACT_APP_BASE_URL_FRONTEND}/liveStreamDetails?id=` +
		props.twilioSessionId;

	async function copyTextToClipboard() {
		if ("clipboard" in navigator) {
			return await navigator.clipboard.writeText(url);
		} else {
			return document.execCommand("copy", true, url);
		}
	}

	useEffect(() => {
		WebsocketClientUSerStatus.on(
			"liveStreaming",
			onMessageReceivedLiveStreaming
		);

		return () => {
			WebsocketClientUSerStatus.off(
				"liveStreaming",
				onMessageReceivedLiveStreaming
			);
		};
	}, []);

	const onMessageReceivedLiveStreaming = (response: any) => {
		if (response?.timestamp) {
			const formattedDate = format(
				new Date(response.timestamp),
				"MM/dd/yyyy h:mm a"
			);
			setLiveStreamStartTime(formattedDate);
			console.log("Formatted Live Stream Start Time:", formattedDate);
		}
	};

	// onClick handler function for the copy button
	const handleCopyClick = () => {
		// Asynchronously call copyTextToClipboard
		copyTextToClipboard()
			.then(() => {
				// If successful, update the isCopied state value
				setIsCopied(true);
				handleAlertOpen("Live Streaming Link Copied", true);
				setTimeout(() => {
					setIsCopied(false);
				}, 10000);
			})
			.catch((err) => {});
	};

	return (
		<ActionDialog
			open={props.sessionInformationDialogBoxOpen}
			handleClose={props.closeSessionInformationDialogBox}
			dialogWidth="37.5rem"
			title="Session Information"
			titleStyle={{
				color: "#000",
				fontSize: "1.375rem",
				fontWeight: "500",
			}}
			titleAlignment="left"
			buttonsAlignment="right"
		>
			<Grid container xs={12} display="flex" justifyContent="left">
				<Stack sx={{ width: "542px", margin: "0 auto" }}>
					{props.isBroadcastingStarted && (
						<Box sx={{ mb: 3 }}>
							<Typography
								sx={{
									fontFamily: "Lexend",
									fontSize: "16px",
									fontWeight: "400",
									lineHeight: "20px",
									letterSpacing: "0em",
									textAlign: "left",
									marginBottom: "10px",
								}}
							>
								Streaming URL
							</Typography>

							<OutlinedInput
								type={"text"}
								sx={{
									width: "100%",
									background:
										"linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #D7D7D7, #D7D7D7)",
									height: "40px",
									borderRadius: "6px",
									border: "1px solid",
								}}
								disabled
								defaultValue={props.isBroadcastingStarted ? url : ""}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleCopyClick}
											edge="end"
											disabled={!props.isBroadcastingStarted}
										>
											<img src={CopyIcon} alt="Eye Slash" />
										</IconButton>
									</InputAdornment>
								}
							/>
						</Box>
					)}

					<Box sx={{ mb: 2, py: 1 }}>
						<Heading>Title</Heading>
						<Content>{userSession.sessionName}</Content>
						<Divider sx={{ py: 1 }} />
					</Box>

					<Box sx={{ mb: 2, py: 1 }}>
						<Heading>Description</Heading>
						<Content>{userSession.sessionDescription}</Content>
						<Divider sx={{ py: 1 }} />
					</Box>

					<Box sx={{ mb: 2, py: 1 }}>
						<Heading>Attendees</Heading>

						<Grid container={true} justifyContent="normal" columns={{ xs: 12 }}>
							{userSession?.attendees?.map(
								(attendee: UserInterface, index: any) => (
									<div
										key={attendee?.id + index}
										style={{
											display: "flex",
											flexDirection: "row",
											marginRight: "15px",
										}}
									>
										{" "}
										<img
											src={Profile}
											alt="user-icon"
											className="music-logo-custom-height"
										/>{" "}
										<Typography
											className="attendee-field-custom-font-size"
											sx={{ color: "#5B5B65" }}
										>
											{" "}
											{`${attendee?.firstName} ${attendee?.lastName}`}{" "}
										</Typography>{" "}
									</div>
								)
							)}
						</Grid>
						<Divider sx={{ py: 1 }} />
					</Box>

					{props.isBroadcastingStarted && (
						<Grid container xs={12} spacing={2}>
							<Grid item xs={6}>
								<Heading>Start Time</Heading>
								<Content>{liveStreamStartTime || ""}</Content>
							</Grid>
							<Grid item xs={6}>
								<Heading>Live audience</Heading>
								<Content>{allParticipantsCount?.hidden}</Content>
							</Grid>
						</Grid>
					)}
				</Stack>
			</Grid>
			<SnackbarAlert />
		</ActionDialog>
	);
};

export default SessionInformation;
