import {
	Box,
	Typography,
	Grid,
	Button,
	FormHelperText,
	TextField,
	DialogContent,
	DialogTitle,
	Dialog,
	DialogActions,
} from "@mui/material";
import { useState } from "react";
import { useAlertStatus } from "../../context/AlertStatusContext";
import ArrowLeft from "../../images/arrow-left-orange.svg";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { Link, useNavigate } from "react-router-dom";

const CancelSessionDialog = (props: any) => {
	const [cancelSessionNote, setCancelSessionNote] = useState<string>("");
	const token = localStorage.getItem("sessionId");
	const { updateAlertStatus } = useAlertStatus();
	const [loading, setLoading] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertStatus, setAlertStatus] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
	const navigate = useNavigate();

	const cancelSession = (note: string) => {
		try {
			setLoading(true);
			fetch(`${process.env.REACT_APP_BASE_URL}/api/cancel-session`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					sessionUuid: props.sessionUuid,
					cancelMessage: note ? note : "",
				}),
			})
				.then((res) => {
					if(res.status === 502){
						navigate('/defaultPage')
					} else if (res.status !== 200) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
						setLoading(false);
                    } else {
                        updateAlertStatus(true, true, "Jam Session Cancelled Successfully");
                        props.refreshList();
                        props.handleCloseSessionDialog();
                        setLoading(false);
                    }
				})
				.catch((err) => {
					ErrorAlert(err.status, setAlertStatus, setShowAlert, setAlertMsg);
					setLoading(false);
					props.handleCloseSessionDialog();
				})
				.finally(() => {});
		} catch (error) {
		}
    };
    
    const handleAlertClose = () => {
        setShowAlert(false);
    };

	return (
        <>
            <ErrorAlertDisplay
                alertMsg={alertMsg}
                alertStatus={alertStatus}
                showAlert={showAlert}
                handleAlertClose={handleAlertClose}
            />
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="md"
				open={props.open}
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							sx={{
								fontWeight: 500,
								fontSize: "1.875rem !important",
								color: "#000000",
							}}
						>
							Cancel Event
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Grid container xs={12}>
						<Grid item={true} xs={12}>
							<TextField
								id="myInput"
								autoFocus={true}
								inputProps={{
									style: {
										fontStyle: "normal",
										fontSize: "1.125rem",
										lineHeight: "25px",
										fontWeight: 900,
										color: "#000000",
									},
								}}
								sx={{ background: "#F8F8F8", mt: 1 }}
								fullWidth
								// multiline
								variant="outlined"
								value={cancelSessionNote}
								multiline
								rows={4}
								maxRows={10}
								onChange={(event) => {
									const inputValue = event.target.value.trimStart();
									if (inputValue.length < 256) {
										setCancelSessionNote(inputValue);
										setShowWarning(false);
									} else {
										setShowWarning(true);
									}
								}}
							></TextField>
							{showWarning && (
								<FormHelperText
									sx={{
										display: "flex",
										justifyContent: "flex-end",
										fontSize: "0.8125rem",
										color: "#FD4B24",
									}}
								>
                                    Enter maximum 256 characters
								</FormHelperText>
							)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						xs={12}
						display="flex"
						sx={{ mt: 9 }}
						justifyContent="flex-end"
						// alignItems="flex-end"
						columnSpacing={1}
					>
						{/* <Grid item xs={2}> */}
						<Button
							// endIcon={<img src={RightAarrow} alt="Right Arrow" />}
							variant="outlined"
							onClick={props.handleCloseSessionDialog}
							sx={{ width: "8.75rem", mr: "5px" }}
							startIcon={<img src={ArrowLeft} alt="Arrow Left" />}
						>
							<Typography
								sx={{ fontSize: "18px", fontWeight: "500", color: "#FF702A" }}
							>
								Back
							</Typography>
						</Button>
						{/* </Grid> */}
						{/* <Grid item xs={4}> */}
						{/* <Button
							disabled={loading}
							variant="outlined"
							sx={{ width: "18.625rem" }}
							onClick={() => cancelSession("")}
						>
							<Typography
								sx={{ fontSize: "18px", fontWeight: "500", color: "#FF702A" }}
							>
								Cancel without message
							</Typography>
						</Button> */}
						{/* </Grid> */}
						{/* <Grid item xs={5}> */}
						<Button
							disabled={loading}
							variant="contained"
							size="large"
							sx={{ width: "16rem" }}
							onClick={() => cancelSession(cancelSessionNote)}
						>
							<Typography
								sx={{ fontSize: "18px", fontWeight: "500", color: "#fff" }}
							>
								Cancel & Send Message
							</Typography>
						</Button>
						{/* </Grid> */}
					</Grid>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CancelSessionDialog;
