import { DailyQualityStatus } from "../Hooks/useDailyGlobalRoom";

const tryStartingCamera = (callObject: any) => {
  try {
    callObject.startCamera();
  } catch (e) {
    console.error('Failed to start camera', e);
  }
}

const removeTestEvents = (callObject: any) => {
  callObject.abortTestNetworkConnectivity();
  callObject.abortTestWebsocketConnectivity();
  callObject.stopTestConnectionQuality();
}

const runAllTests = async (newCallObject: any, setDailyQualityStatus: Function) => {
  newCallObject?.setLocalVideo(true);
  const localParticipant = newCallObject.participants().local;
  const videoTrack = localParticipant?.tracks?.video?.persistentTrack;
  console.log("runAllTests localParticipant", { localParticipant, videoTrack });

  // Test call quality
  const callQualityResult = await testCallQuality(newCallObject);
  // Update call quality status with a delay
  setDailyQualityStatus((prevState: DailyQualityStatus) => ({
    ...prevState,
    call: { ...callQualityResult, inProgress: false }
  }));
  console.log("connectivity Test", { callQualityResult });

  // Delay before updating current step
  await new Promise(resolve => setTimeout(resolve, 1000));
  setDailyQualityStatus((prevState: DailyQualityStatus) => ({
    ...prevState,
    currentStep: 2
  }));

  // Test network connectivity
  const networkConnectivity = await testNetworkConnectivity(newCallObject);
  setDailyQualityStatus((prevState: DailyQualityStatus) => ({
    ...prevState,
    network: { ...networkConnectivity, inProgress: false }
  }));
  console.log("connectivity Test", { networkConnectivity });

  // Delay before updating current step
  await new Promise(resolve => setTimeout(resolve, 1000));
  setDailyQualityStatus((prevState: DailyQualityStatus) => ({
    ...prevState,
    currentStep: 3
  }));

  // Test WebSocket connectivity
  const wsConnectivity = await testWebSocketConnectivity(newCallObject);
  setDailyQualityStatus((prevState: DailyQualityStatus) => ({
    ...prevState,
    ws: { ...wsConnectivity, inProgress: false }
  }));
  console.log("connectivity Test", { wsConnectivity });

  newCallObject?.setLocalVideo(false);
  return {
    callQualityResult,
    networkConnectivity,
    wsConnectivity
  }
}

/**
* Runs Daily's call quality test and updates DOM with the result.
* @param {DailyCall} callObject
* @param {MediaStreamTrack} videoTrack
* @returns
*/
function testCallQuality(callObject: any) {
  console.log('testing network/call connectivity');
  const localParticipant = callObject.participants().local;
  const videoTrack = localParticipant?.tracks?.video?.persistentTrack;
  if (!videoTrack) {
    tryStartingCamera(callObject);
  }

  // return callObject
  // .testCallQuality({
  //   videoTrack,
  //   duration: 5, // In seconds
  // })
  return callObject
    .testConnectionQuality({
      videoTrack,
      duration: 5, // In seconds
    })
    .then((res: any) => {
      const testResult = res.result;
      let resultMsg = {
        message: "",
        status: "",
        icon: ""
      };
      switch (testResult) {
        case 'aborted':
          resultMsg.message = 'Test aborted before any data was gathered.';
          resultMsg.status = 'aborted';
          resultMsg.icon = "🚫";
          break;
        case 'failed':
          resultMsg.message = 'Unable to run test.';
          resultMsg.status = 'failed';
          resultMsg.icon = "❌";
          break;
        case 'bad':
          resultMsg.message =
            'Your internet connection is unstable, which may cause frequent disconnections and rejoining. Consider switching to a different network.';
          resultMsg.status = 'bad';
          resultMsg.icon = "🔴";
          break;
        case 'warning':
          resultMsg.message = 'Video and audio might be choppy.';
          resultMsg.status = 'warning';
          resultMsg.icon = "⚠️";
          break;
        case 'good':
          resultMsg.message = 'Your internet connection is good.';
          resultMsg.status = 'good';
          resultMsg.icon = "✅";
          break;
        default:
          resultMsg.message = `Unexpected connection test result: ${testResult}`;
          resultMsg.status = "unknown";
          resultMsg.icon = "❓";
      }

      return resultMsg;
    })
    .catch((e: any) => {
      console.error('Failed to test call quality:', e);
    });
}

/**
* Runs Daily's network connectivity test and updates DOM with the result.
* @param {DailyCall} callObject
* @param {MediaStreamTrack} videoTrack
* @returns
*/
function testNetworkConnectivity(callObject: any) {
  console.log('testing network connectivity');
  const localParticipant = callObject.participants().local;
  const videoTrack = localParticipant?.tracks?.video?.persistentTrack;
  if (!videoTrack) {
    tryStartingCamera(callObject);
  }
  return callObject
    .testNetworkConnectivity(videoTrack)
    .then((res: any) => {
      const testResult = res.result;
      let resultMsg = {
        message: "",
        status: "",
        icon: ""
      };
      switch (testResult) {
        case 'aborted':
          resultMsg.message = 'Test aborted.';
          resultMsg.status = 'aborted';
          resultMsg.icon = "🚫";
          break;
        case 'failed':
          resultMsg.message =
            'Connection attempt failed. Try a different network, or contact your network admin.';
          resultMsg.status = 'failed';
          resultMsg.icon = "❌";
          break;
        case 'passed':
          resultMsg.message = 'You are able to connect!';
          resultMsg.status = 'passed';
          resultMsg.icon = "✅";
          break;
        default:
          resultMsg.message = `Unexpected network connectivity test result: ${testResult}`;
          resultMsg.status = "unknown";
          resultMsg.icon = "❓";
      }
      return resultMsg;
    })
    .catch((e: any) => {
      console.error('Failed to test network connectivity.', e);
    });
}

/**
* Runs Daily's WebSocket connectivity test and updates DOM with the result.
* @param {DailyCall} callObject
* @returns
*/
function testWebSocketConnectivity(callObject: any) {
  console.log('testing WebSocket connectivity');
  return callObject
    .testWebsocketConnectivity()
    .then((res: any) => {
      const testResult = res.result;
      let resultMsg = {
        message: "",
        status: "",
        icon: ""
      };

      switch (testResult) {
        case 'warning':
          resultMsg.message = `You are not able to connect to some regions via WebSockets: ${res.failedRegions.toString()}`;
          resultMsg.status = 'warning';
          resultMsg.icon = "⚠️";
          break;
        case 'failed':
          resultMsg.message =
            'You are not able to connect to any region via WebSockets. Contact your network admin.';
          resultMsg.status = 'failed';
          resultMsg.icon = "❌";
          break;
        case 'passed':
          resultMsg.message = 'You are able to connect to WebSockets in all regions.';
          resultMsg.status = 'passed';
          resultMsg.icon = "✅";
          break;
        case 'aborted':
          resultMsg.message = 'The WebSocket connectivity test was aborted.';
          resultMsg.status = 'aborted';
          resultMsg.icon = "🚫";
          break;
        default:
          resultMsg.message = `Unexpected network connectivity test result: ${JSON.stringify(
            testResult
          )}`;
          resultMsg.status = "unknown";
          resultMsg.icon = "❓";
      }
      return resultMsg;
    })
    .catch((e: any) => {
      console.error('Failed to test WebSocket connectivity.', e);
    });
}

export {
  removeTestEvents,
  runAllTests,
  testCallQuality,
  testNetworkConnectivity,
  testWebSocketConnectivity
}