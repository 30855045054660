import { useState, useEffect } from "react";
import {
	Typography,
	Grid,
	Button,
	Divider,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	Card,
	CardContent,
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Alert,
	IconButton,
	Snackbar,
	LinearProgress,
	Stack,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import CenterCircularProgress from "../CenterCircularProgress";
import { DeviceInterface } from "../../types/types";
// contexts
import { useUser } from "../../context/UserContext";
import SettingsAlert from "../../pages/SettingsAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
//import Online from "../../images/online.svg";
import { useActiveSubscription } from "./SubscriptionProvider/useUserSubscription";
import DOMPurify from "dompurify";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import CloudStorageBlue from "../../images/cloud-storage-blue.svg"
import CheckCircleIcon from "@mui/icons-material/DoneOutlined"; // Import Material-UI Icon
import CloseIcon from "@mui/icons-material/Close"; // Optional: For features that are not included
import ErrorIcon from "../../images/danger.svg";
import User from "../../services/user";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import External from "../../images/external-link-line.svg";
import { useAlertStatus } from "../../context/AlertStatusContext";
import ErrorMark from "../../images/Alert-filled.svg";
import CheckCircleIconBtn from "@mui/icons-material/CheckCircle";
//import { differenceInCalendarDays } from "date-fns";
//import { isEmpty } from "lodash";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import PriorityHighRoundedIconRounded from '@mui/icons-material/PriorityHighRounded';


type SubscriptionPlan = {
	name: string;
	price: number;
	// Other properties if needed
};
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		linearGradBtn: {
			width: "100%",
			// margin: "0.5em 0 !important",
			background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
			borderRadius: "18px !important",
		},
		listing: {
			listStyleType: "none",
			"& li": {
				margin: "1em 0",
			},
		},
		link: {
			color: "#0A6BFE !important",
			textDecoration: "none",
		},
		container: {
			border: `1px solid ${theme.palette.grey[300]}`,
			borderRadius: theme.shape.borderRadius,
			padding: theme.spacing(2),
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column",
				alignItems: "stretch",
			},
		},
		input: {
			marginRight: theme.spacing(2),
			flex: 1,
			fontSize: "14px",

			width: "192px",
			[theme.breakpoints.down("sm")]: {
				marginBottom: theme.spacing(2),
				marginRight: 0,
			},
		},
		button: {
			width: "140px",
			whiteSpace: "nowrap",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
		progressBar: {
			height: "6px !important",
			borderRadius: "3px !important",
			backgroundColor: "#F0F0F0 !important",
		},
	})
);

const SubscriptionPlans = () => {
	const [loading, setLoading] = useState(true);
	const classes = useStyles();
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const [subscriptionPlans, setSubscriptionPlans] = useState([]);
	const {
		purchasedSubscriptions,
		isSubscribed,
		isDeviceAvailable,
		sealSubscriptionSellingPlans,
	} = useActiveSubscription();

	// Alert states
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	const navigate = useNavigate();
	const location = useLocation();
	const [userDeviceID, setUserDeviceId] = useState("");
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogDownOpen, setDialogDownOpen] = useState(false);
	const [dialogReactivateOpen, setDialogReactivateOpen] = useState(false);
	const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
	const [selectedPlan, setSelectedPlan] = useState([]);
	const [selectedPlanName, setSelectedPlanName] = useState("");
	const { updateAlertStatus } = useAlertStatus();
	const [deviceError, setDeviceError] = useState(false);
	const [reactiveCancel, setReactiveCancel] = useState(false);
	const [storageButtonText, setstorageButtonText] = useState("");
	const [dialogDownStorageCancelOpen, setDialogDownCancelOpen] =
		useState(false);
		const [storageConsumedPerc, setStorageConsumedPerc] = useState(0);
	const [storageConsumed, setStorageConsumed] = useState(0);
	const [storageAvailable, setStorageAvailable] = useState(0);
	const [showPremium, setShowPremium] = useState(false);
	const canStore = Number(storageConsumedPerc) < 99.99;

	const handleAddNow = (
		plan: any,
		event: React.MouseEvent<HTMLButtonElement>,
		action: any
	) => {
		const buttonText = event.currentTarget.textContent;
		setSelectedPlan(plan);
		setSelectedPlanName(plan.name);
		if (plan && plan.name?.toLowerCase() !== "storage+") {
			if (
				(subscriptionName === "premium" &&
					(buttonText === "Choose Standard" || buttonText === "Choose Free") &&
					(storageSubscriptionName !== "storage+" ||
						storageStatus === "paused")) ||
				(subscriptionName === "standard" &&
					buttonText === "Choose Free" &&
					(storageSubscriptionName !== "storage+" ||
						storageStatus === "paused"))
			) {
				setDialogDownOpen(true);
			} else if (
				(subscriptionName === "premium" &&
					buttonText === "Choose Standard" &&
					(storageSubscriptionName === "storage+" ||
						storageStatus === "paused")) ||
				(subscriptionName === "standard" &&
					buttonText === "Choose Free" &&
					(storageSubscriptionName !== "storage+" ||
						storageStatus === "paused"))
			) {
				setDialogDownOpen(true);
			} else if (
				(subscriptionName === "premium" &&
					buttonText === "Choose Free" &&
					storageSubscriptionName === "storage+" &&
					storageStatus === "active") ||
				(subscriptionName === "standard" &&
					buttonText === "Choose Free" &&
					storageSubscriptionName === "storage+" &&
					storageStatus === "active")
			) {
				setDialogDownCancelOpen(true);
			} else if (buttonText?.includes("Reactivate")) {
				setSelectedPlan(planSubscriptionPaused);
				// Call the other function if button text includes "Reactivate"
				setDialogReactivateOpen(true);
			} else {
				setDialogOpen(true);
			}
		} else {
			if (buttonText?.includes("Cancel")) {
				setDialogReactivateOpen(true);
				setstorageButtonText("pause");
			} else if (buttonText?.includes("Reactivate")) {
				setDialogReactivateOpen(true);
				setstorageButtonText("reactivate");
			} else {
				setDialogOpen(true);
			}
		}
	};

	const handleClose = () => {
		setDeviceError(false);
	};

	useEffect(() => {
		let user = localStorage.getItem("user");
		if (user) {
			try {
				const userDetails = JSON.parse(user);
				if (
					(!userDetails.firstName ||
						!userDetails.lastName ||
						!userDetails.location) &&
					!isAdmin
				) {
					navigate("/socialProfileNew", {
						state: {
							email: userDetails.email,
							password: "",
						},
					});
				}
			} catch (e) {
				console.error("Failed to parse user data:", e);
			}
		}
	}, [navigate]);


	useEffect(() => {
		setShowPremium(location.search === "?ref=namm");
		console.log("location", location);
	}, [location])

	const [subscriptions, setSubscriptions] = useState<any[]>([]);
	const [
		shopifySubscriptionPlanStatusDTOs,
		setShopifySubscriptionPlanStatusDTOs,
	] = useState<any[]>([]);
	//const sealToken: string = process.env.REACT_APP_SEAL_SUB || "";
	//const invoiceURL: string = process.env.REACT_APP_PLAN_INVOICE_URL || "";

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	const handleDialogDownClose = () => {
		setDialogDownOpen(false);
	};

	const handleDialogDownStorageCancelClose = () => {
		setDialogDownCancelOpen(false);
	};
	const handleDialogReactivateClose = () => {
		setDialogReactivateOpen(false);
	};
	// const handleDialogInfoOpen = () => {
	// 	setDialogInfoOpen(true);
	// };

	const handleDialogInfoClose = (event: any, reason: any) => {
		// Only close if the reason is not 'backdropClick' or 'escapeKeyDown'
		if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
			setDialogInfoOpen(false);
		}
	};

	const getSubscriptionPlans = async () => {
		setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/master-subscription-plans`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})

			.then((data) => {
				if (data.length > 0) {
					// Parse features if they are JSON strings
					const plansWithParsedFeatures = data.map((plan: any) => {
						if (typeof plan.features === "string") {
							try {
								plan.features = JSON.parse(plan.features);
							} catch (error) {
								console.error(
									"Failed to parse features for plan:",
									plan.name,
									error
								);
								plan.features = [];
							}
						} else if (!Array.isArray(plan.features)) {
							plan.features = [];
						}
						return plan;
					});
					setSubscriptionPlans(plansWithParsedFeatures);
				}
			})
			.catch((err) => {})
			.finally(() => {
				//setLoading(false);
			});
	};

	const getMagicLink = async () => {
		setLoading(true);
		const email = user.email;

		try {
			const response = await fetch(
				`${
					process.env.REACT_APP_BASE_URL
				}/api/public/getShopifyMagicLink?email=${encodeURIComponent(email)}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(
					`API error with status: ${response.status}, message: ${errorData.error}`
				);
			}
			const magicLink = await response.text();

			if (magicLink && isValidURL(magicLink)) {
				window.open(magicLink, "_blank");
			} else {
				throw new Error("Magic link is not valid or missing");
			}
		} catch (err) {
			console.error("Error fetching magic link:", err);
		} finally {
			setLoading(false);
		}
	};

	const isValidURL = (string: string) => {
		try {
			new URL(string);
			return true;
		} catch (_) {
			return false;
		}
	};

	const [disabledAll, setDisabledAll] = useState(false);

	function formatDate(setDate: any, m: any) {
		const dateObject = new Date(setDate);
		let dayOfMonth = dateObject.getDate();
		const month = dateObject.toLocaleString("default", { month: "short" }); // Get 3-letter month abbreviation
		//const year = dateObject.getFullYear();
		let daySuffix;
		if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
			daySuffix = `${dayOfMonth}st`;
		} else if (dayOfMonth === 2 || dayOfMonth === 22) {
			daySuffix = `${dayOfMonth}nd`;
		} else if (dayOfMonth === 3 || dayOfMonth === 23) {
			daySuffix = `${dayOfMonth}rd`;
		} else {
			daySuffix = `${dayOfMonth}th`;
		}

		if (m === "m") {
			return `${month}, ${dayOfMonth} `;
		} else {
			return `${daySuffix}`;
		}
	}

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const linkifyMessage = (errorMessage: string) => {
		const contactUsText = "contact us";
		const contactUsLink = `<a href="${process.env.REACT_APP_BASE_URL_FRONTEND}/helpAndSupport" target="_blank" rel="noopener noreferrer" style="color: blue;">contact us</a>`; // Update with your contact link
		if (errorMessage.includes(contactUsText)) {
			return errorMessage.replace(contactUsText, contactUsLink);
		}
		return errorMessage;
	};

	const downgradePlan = async (
		plan: any,
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		if (loading) return; // Prevent API call if already loading
		setReactiveCancel(true);
		setLoading(true); // Set loading state to true
		let shopifyCheckoutItems = {
			variant_id: plan.shopifyVariantId,
			quantity: 1,
			selling_plan: plan.shopifySellingPlanId ? plan.shopifySellingPlanId : "",
			inventory_item_id: plan.inventoryItemId,
			product_id: plan.shopifyProductId,
			email: user.email,
		};

		try {
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/public/downgred-seal-subscription`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
						//"X-Seal-Token": sealToken,
					},
					body: JSON.stringify(shopifyCheckoutItems),
				}
			);

			if (res.status >= 400) {
				setDialogDownOpen(false);
				setDeviceError(true);
			} else if (res.status === 200) {
				setDialogDownOpen(false);
				setDialogInfoOpen(true);
			} else {
				const data = await res.json();
				// Handle response data if needed
			}
		} catch (err) {
			setDeviceError(true);
		} finally {
			setLoading(false); // Reset loading state
		}
	};

	const reactivateCancelPlan = (
		plan: any,
		event: React.MouseEvent<HTMLButtonElement>,
		actionBe: any
	) => {
		if (loading) return; // Prevent API call if already loading
		setLoading(true); // Reset loading state
		setReactiveCancel(true);
		let itemToSubscribe = {
			action: actionBe,
			subscription_id: plan.subscriptionId ? plan.subscriptionId : "",
			email: user.email,
		};
		fetch(`${process.env.REACT_APP_BASE_URL}/api/public/cancel-subscription`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				//"X-Seal-Token": sealToken,
			},
			body: JSON.stringify(itemToSubscribe),
		})
			.then((res) => {
				if (res.status >= 400) {
					setDialogReactivateOpen(false);
					setDeviceError(true);
				} else if (res.status === 200) {
					setDialogReactivateOpen(false);
					setDialogInfoOpen(true);
				} else {
					return res.json();
				}
			})
			.then((data) => {})
			.catch((err) => {
				//setDeviceError(true);
				setDialogInfoOpen(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const checkOut = (plan: any, event: React.MouseEvent<HTMLButtonElement>) => {
		if (loading) return; // Prevent API call if already loading
		setLoading(true); // Reset loading state
		let itemToSubscribe = {
			variant_id: plan.shopifyVariantId,
			quantity: 1,
			selling_plan: plan.shopifySellingPlanId ? plan.shopifySellingPlanId : "",
			inventory_item_id: plan.inventoryItemId, //fetch from master api
			product_id: plan.shopifyProductId,
		};

		fetch(`${process.env.REACT_APP_BASE_URL}/api/public/checkout`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				//"X-Seal-Token": sealToken,
			},
			body: JSON.stringify({
				action: "create",
				items: [itemToSubscribe],
				attributes: {
					checkout: {
						email: user.email,
						shipping_address: {
							first_name: user.firstName,
							last_name: user.lastName,
							address1: user.location ? user.location : "",
							phone: "",
							zip: "",
							city: "",
							province: "",
							country: "",
						},
					},
				},
			}),
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data.success) {
					// To Open in a new browsr window
					window.open(data.payload.quick_checkout_url, "_blank");
					setDialogOpen(false);
					setDialogInfoOpen(true);
				}
			})
			.catch((err) => {
				setDeviceError(true);
				setDialogInfoOpen(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		User.fetchUserInfo((userInfo: any) => {
			if (userInfo.error) {
				console.error("Failed to fetch user info:", userInfo.error);
			} else {
				setSubscriptions(userInfo.subscriptionInformationDTOs);
				setUserDeviceId(userInfo.deviceId);
				setShopifySubscriptionPlanStatusDTOs(
					userInfo.shopifySubscriptionPlanStatusDTOs
				);
				setLoading(false);
			}
		});

		getSubscriptionPlans();
		
	}, []);

	useEffect(() => {
		// Log the values and handle conditional logic based on the updated state
		if (
			shopifySubscriptionPlanStatusDTOs &&
			shopifySubscriptionPlanStatusDTOs.length > 0
		) {
			const subscriptionActivity =
				shopifySubscriptionPlanStatusDTOs[0]?.activity;
			if (subscriptionActivity) {
				if (
					subscriptionActivity?.toLowerCase() === "upgrade" ||
					subscriptionActivity?.toLowerCase() === "downgrade"
				) {
					setDisabledAll(true);
				}
			}
		}
	}, [shopifySubscriptionPlanStatusDTOs]); // Dependency array to run this effect when shopifySubscriptionPlanStatusDTOs changes

	
		const planSubscription = subscriptions?.find(
			(sub) =>
				sub.type === "Software" &&
				(sub.status?.toLowerCase() === "active" ||
					sub.status?.toLowerCase() === "paused") &&
				(sub.name?.toLowerCase() === "free" ||
					sub.name?.toLowerCase() === "standard" ||
					sub.name?.toLowerCase() === "premium")
		); // Example condition
		const subscriptionName = planSubscription
			? planSubscription.name?.toLowerCase()
			: null;
		const subscriptionEndDate = planSubscription
			? planSubscription.endDate
			: null;
	
		const storageSubscription = subscriptions?.find(
			(sub) => sub.type === "Storage" && sub.name?.toLowerCase() === "storage+"
		); // Example condition
		const storageSubscriptionName = storageSubscription
			? storageSubscription.name?.toLowerCase()
			: null;
		const storageStatus = storageSubscription
			? storageSubscription.status?.toLowerCase()
			: null;
		const storageSubscriptionEndDate = storageSubscription
			? storageSubscription.endDate
			: null;
	
		const planSubscriptionPaused = subscriptions?.find(
			(sub) =>
				sub.type === "Software" &&
				sub.status?.toLowerCase() === "paused" &&
				(sub.name?.toLowerCase() === "free" ||
					sub.name?.toLowerCase() === "standard" ||
					sub.name?.toLowerCase() === "premium")
		); // Example condition
		const pausedsubscriptionName = planSubscriptionPaused
			? planSubscriptionPaused.name?.toLowerCase()
			: null;
	
		const devicePurcased = subscriptions?.find(
			(sub) =>
				sub.type === "Electronics" && sub.name?.toLowerCase().includes("lutefish")
		); // Example condition
		const devicePurcasedName = devicePurcased
			? devicePurcased.name?.toLowerCase()
			: null;
	
		const activePlanSubscription = subscriptions?.find(
			(sub) =>
				sub.type === "Software" &&
				sub.status?.toLowerCase() === "active" &&
				(sub.name?.toLowerCase() === "free" ||
					sub.name?.toLowerCase() === "standard" ||
					sub.name?.toLowerCase() === "premium")
		); // Example condition
		const activePlanSubscriptionName = activePlanSubscription
			? activePlanSubscription.name?.toLowerCase()
			: null;
		const activePlanSubscriptionDate = activePlanSubscription
			? activePlanSubscription.endDate?.toLowerCase()
			: null;
	
		const activePendingPlanSubscription = subscriptions?.find(
			(sub) =>
				sub.type === "Software" &&
				sub.status?.toLowerCase() === "pending_active" &&
				(sub.name?.toLowerCase() === "free" ||
					sub.name?.toLowerCase() === "standard" ||
					sub.name?.toLowerCase() === "premium")
		); // Example condition
		const activePendingsubscriptionName = activePendingPlanSubscription
			? activePendingPlanSubscription.name?.toLowerCase()
			: null;
		const activePendingsubscriptionDate = activePendingPlanSubscription
			? activePendingPlanSubscription.startDate
			: null;
	
		const formattedsubscriptionEndDate = formatDate(subscriptionEndDate, "d");
		const formattedsubscriptionEndDateFull = formatDate(subscriptionEndDate, "m");
		const formattedsubscriptionStartDate = formatDate(
			activePendingsubscriptionDate,
			"m"
		);
		const storageEndDate = formatDate(storageSubscriptionEndDate, "m");
		const storageEndDateD = formatDate(storageSubscriptionEndDate, "d");
		const formatedactivePlanSubscriptionDate = formatDate(
			activePlanSubscriptionDate,
			"d"
		);
		const formattedsubscriptionStartDay = formatDate(
			activePendingsubscriptionDate,
			"d"
		);
	
	const handleClick = (event: any) => {
		if (storageButtonText === "pause") {
			reactivateCancelPlan(selectedPlan, event, "pause");
		} else {
			reactivateCancelPlan(selectedPlan, event, "reactivate");
		}
	};

	type Units = "B" | "KB" | "MB" | "GB" | "TB" | "PB" | "EB" | "ZB" | "YB";

	const getReadableFileSizeString = (fileSize: number, unit: Units = "B") => {
		if (fileSize) {
			let i = -1;
			const byteUnits = [
				" KB",
				" MB",
				" GB",
				" TB",
				" PB",
				" EB",
				" ZB",
				" YB",
			];

			const unitsMap: Record<Units, number> = {
				B: 0,
				KB: 1,
				MB: 2,
				GB: 3,
				TB: 4,
				PB: 5,
				EB: 6,
				ZB: 7,
				YB: 8,
			};

			const upperUnit = unit.toUpperCase() as Units;
			if (upperUnit in unitsMap) {
				i = unitsMap[upperUnit] - 1;
				fileSize = fileSize * Math.pow(1024, i);

				i--; // Adjust to start with the correct unit for the conversion loop
			}

			do {
				fileSize = fileSize / 1024;
				i++;
			} while (fileSize > 1024 && i < byteUnits.length - 1);

			return Math.max(fileSize, 0.1).toFixed(1) + byteUnits[i];
		} else {
			return "0 MB";
		}
	};

	useEffect(() => {
		// setLoading(false);
		getStorageConsumed();

		if (user) {
			if (user.storageCapacity != null) {
				let converterSize: any = getReadableFileSizeString(
					user.storageUsage,
					"MB"
				);
				setStorageConsumed(converterSize);
				// Set Storage as 50 GB default untill avaialble from backend
				let totalSize: any = getReadableFileSizeString(
					user.storageCapacity as any,
					"MB"
				);
				setStorageAvailable(totalSize);


				let consumptionPercent =
					(user.storageUsage / (user.storageCapacity as any)) * 100;
				setStorageConsumedPerc(consumptionPercent);

			}
		}
		User.fetchUserInfo((userInfo: any) => {
			if (userInfo.error) {
				console.error("Failed to fetch user info:", userInfo.error);
			} else {

			}
		});
	}, []);

	const getStorageConsumed = () => {
		setLoading(true);
		try {
			fetch(`${process.env.REACT_APP_BASE_URL}/api/get-storage-usage`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				
			})
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					let converterSize: any = getReadableFileSizeString(data, "MB");
						setStorageConsumed(converterSize);
						// Set Storage as 50 GB default untill avaialble from backend
						let totalSize: any = getReadableFileSizeString(
							user.storageCapacity as any,
							"MB"
						);
						setStorageAvailable(totalSize);

						let consumptionPercent =
							(data / (user.storageCapacity as any)) * 100;
						setStorageConsumedPerc(consumptionPercent);
				})
				.catch((err) => {})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<>
			<SettingsAlert />
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			{loading ? (
				<Grid container item justifyContent="center" sx={{ p: "16px" }}>
					<CenterCircularProgress /> {/* The loading spinner */}
				</Grid>
			) : (
				<Grid
					xs={12}
					sx={{
						marginTop:
							(subscriptionName === "standard" ||
								subscriptionName === "premium") &&
							userDeviceID !== null &&
							!disabledAll
								? "-45px"
								: userDeviceID === null || userDeviceID === ""
								? "-95px"
								: disabledAll
								? "-107px"
								: "-105px",
						paddingLeft: { md: "8px" },
					}}
				>
					{!userDeviceID && devicePurcasedName === null && !disabledAll ? (
						<Grid
							container
							sx={{ mt: 0, mb: 2, ml: "-220px", width: "1042px !important" }}
						>
							{" "}
							<Grid
								item
								justifyContent={"center"}
								sx={{
									display: "flex",
									flexDirection: "row",
									width: "1042px !important",
								}}
							>
								<img
									src={ErrorIcon}
									alt="error-icon"
									style={{
										marginRight: "10px",
										height: "32px",
										maxWidth: "100%",
									}}
								/>
								<Typography
									variant="body1"
									sx={{
										lineHeight: "2.5rem",
										color: "#FD4B24",
										fontSize: "18px",
										fontWeight: "500",
										width: "1045px",
									}}
								>
									You cannot upgrade subscription plans unless you first buy
									Device and add to your account.{" "}
									<a
										href="#/home/settings/deviceSetup/add"
										className={classes.link}
									>
										Visit Device Setup &gt;
									</a>
								</Typography>
							</Grid>
						</Grid>
					) : userDeviceID &&
					  (subscriptionName === null || subscriptionName === "free") &&
					  !disabledAll ? (
						<Grid item xs={12} container sx={{ mb: 3 }}>
							{" "}
							<Grid
								item
								xs={9}
								justifyContent={"center"}
								sx={{ display: "flex", flexDirection: "row" }}
							>
								<img
									src={ErrorIcon}
									alt="error-icon"
									style={{
										marginRight: "10px",
										height: "auto",
										maxWidth: "100%",
									}}
								/>
								<Typography
									variant="body1"
									sx={{
										lineHeight: "2.5rem",
										color: "#FD4B24",
										fontSize: "18px",
										fontWeight: "500",
									}}
								>
									No paid subscription plan exists for this account.
								</Typography>
							</Grid>
						</Grid>
					) : disabledAll ? (
						<Grid item xs={12} container sx={{ mb: 3 }}>
							<Grid
								item
								xs={9}
								justifyContent={"center"}
								sx={{ display: "flex", flexDirection: "row" }}
							>
								<img
									src={ErrorIcon}
									alt="error-icon"
									style={{
										marginRight: "10px",
										height: "auto",
										maxWidth: "100%",
									}}
								/>
								<Typography
									variant="body1"
									sx={{
										lineHeight: "2.5rem",
										color: "#FD4B24",
										fontSize: "18px",
										fontWeight: "500",
									}}
								>
									Your plan change is under process...
								</Typography>
							</Grid>
						</Grid>
					) : (
						""
					)}
					<Grid container={true} className="column jam-session">
						<Box sx={{ padding: "0px", paddingBottom: "20px" }}>
							<Grid container>
								<Grid
									item={true}
									xs={4}
									sx={{ display: "flex", justifyContent: "Start" }}
								>
									<Typography
										variant="h4"
										gutterBottom
										sx={{
											fontSize: "24px",
											fontWeight: "500",
											marginBottom: "15px",
										}}
									>
										Subscription
									</Typography>
								</Grid>

								{subscriptionName && subscriptionName !== "free" ? (
									<Grid
										item={true}
										xs={8}
										sx={{
											display: "flex",
											justifyContent: "end",
											marginBottom: "10px",
										}}
									>
										<Box
											component="span"
											sx={{ fontSize: "16px", paddingTop: "10px" }}
										>
											{" "}
											Billed every month on{" "}
											{activePendingPlanSubscription
												? formattedsubscriptionStartDay
												: formatedactivePlanSubscriptionDate}
										</Box>
										<Button
											//className="cancel-button button-field-custom-font-size"
											size="large"
											variant="outlined"
											sx={{
												fontSize: "0.9rem",
												lineHeight: "20px",
												border: "2px solid #0273E6",
												color: "#0273E6",
												transition: "border 0.1s",
												marginLeft: "30px",
												"&:hover": {
													border: "2px solid #0273E6",
													backgroundColor: "#E9F1FF",
												},
											}}
											onClick={getMagicLink}
										>
											View Invoices
											<img src={External} alt="External" />
										</Button>
									</Grid>
								) : (
									""
								)}
							</Grid>
							<Grid container spacing={3}>
								{subscriptionPlans && subscriptionPlans.map((plan: any, index) => {
									return plan.type === "Software" && plan.status ? (
										<Grid
											item
											xs={12}
											sm={6}
											md={4}
											key={index}
											sx={{
												paddingRight: { md: "16px !important" },
												paddingLeft: { md: "0" },
											}}
										>
											<Card className="planHeight">
												<CardContent className="planContent">
													<Grid
														container
														justifyContent="center"
														sx={{ marginBottom: "24px", marginTop: "8px" }}
													>
														<Typography
															variant="h5"
															sx={{
																fontWeight: 700,
																fontSize: "31px",
																color: (() => {
																	if (plan.name?.toLowerCase() === "free") {
																		return subscriptionName !== ""
																			? "#4D4C4F"
																			: "#0273E6";
																	} else if (
																		plan.name?.toLowerCase() === "standard"
																	) {
																		return userDeviceID ||
																			subscriptionName !== ""
																			? "#259a12"
																			: "#999999";
																	} else if (
																		plan.name?.toLowerCase() === "premium"
																	) {
																		return userDeviceID ||
																			subscriptionName !== ""
																			? "#9747ff"
																			: "#999999";
																	} else {
																		return "#7A7985"; // Default color
																	}
																})(),
															}}
														>
															{plan.name}
														</Typography>
													</Grid>
													<Grid container justifyContent="center">
														<Typography
															variant="h4"
															sx={{ fontWeight: 800, mt: 1, height: "40px" }}
														>
															<Box
																component="span"
																sx={{ color: "##313131", fontSize: "38px" }}
															>
																${plan.cost}{" "}
															</Box>
															<Box
																component="span"
																sx={{ color: "#888888", fontSize: "16px" }}
															>
																/mo
															</Box>
														</Typography>
													</Grid>

													<Grid container justifyContent="center">
														<Typography
															variant="body1"
															sx={{ color: "#7A7985", mb: 2 }}
														>
															{plan.planTitle}
														</Typography>
													</Grid>
													<Grid
														container
														justifyContent="center"
														sx={{ marginBottom: "16px" }}
													>
														{!userDeviceID &&
														subscriptionName === "free" &&
														(plan.name?.toLowerCase() === "standard" ||
															plan.name?.toLowerCase() === "premium") ? (
															<Button
																size="large"
																
																disabled={user.userAccountStatus !== "ACTIVE" || isAdmin || (userDeviceID === null ||
																	userDeviceID === "")} 
																className="subButton"
																sx={{
																	color: "#fff !important",
																	width: "243px",
																	background:((userDeviceID === null || userDeviceID === "") ||
																						user.userAccountStatus !== "ACTIVE" )
																						? "#C2C2C3 !important"
																						: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)"
																}}
															>
																Choose {plan.name}
															</Button>
														) : subscriptionName === "free" &&
														  plan.name?.toLowerCase() === "free" ? (
															<>
																<Button
																	size="large"
																	variant="outlined"
																	disabled={user.userAccountStatus !== "ACTIVE" || isAdmin}
																	sx={{
																		backgroundColor: "#fff",
																		color: "#ff702a !important",
																		width: "243px",
																		borderRadius: 0,
																		border: "0 !important",
																		borderBottom: "2px solid #999 !important",
																	}}
																>
																	<Typography
																		sx={{
																			fontSize: "1rem",
																			fontWeight: "500",
																			color: "#FF702A",
																		}}
																	>
																		<CheckCircleIconBtn
																			sx={{
																				color: "#259A12",
																				verticalAlign: "middle",
																				marginRight: "4px",
																			}}
																		/>
																		Current Plan
																	</Typography>
																</Button>
															</>
														) : (
															<>
																{plan.name?.toLowerCase() === "free" &&
																	activePlanSubscriptionName ===
																		plan.name?.toLowerCase() && (
																		<Box
																			sx={{
																				width: "100%",
																				textAlign: "center",
																				height: "38px",
																				borderBottom: "1px solid #999",
																			}}
																		>
																			<Typography
																				sx={{
																					fontSize: "1rem",
																					fontWeight: "500",
																					color: "#FF702A",
																				}}
																			>
																				<CheckCircleIcon
																					sx={{
																						color: "#259A12",
																						verticalAlign: "middle",
																						marginRight: "4px",
																						paddingRight: "0px",
																					}}
																				/>
																				Current Plan
																			</Typography>
																		</Box>
																	)}
																{plan.name?.toLowerCase() === "free" &&
																	activePlanSubscriptionName !==
																		plan.name?.toLowerCase() && (
																		<Button
																			size="large"
																			variant="contained"
																			onClick={(event) => {
																				if (
																					// (userDeviceID && subscriptionName !== "free") &&
																					(plan.name?.toLowerCase() ===
																						"free" ||
																						plan.name?.toLowerCase() ===
																							"premium" ||
																						plan.name?.toLowerCase() ===
																							"standard") &&
																					subscriptionName !==
																						plan.name?.toLowerCase()
																				) {
																					handleAddNow(plan, event, "");
																				}
																			}}
																			disabled={
																				(activePendingsubscriptionName ===
																					plan.name?.toLowerCase() &&
																					pausedsubscriptionName !==
																						plan.name?.toLowerCase()) ||
																				((userDeviceID === null ||
																					userDeviceID === "") &&
																					subscriptionName === "free") ||
																				disabledAll ||
																				user.userAccountStatus !== "ACTIVE" || isAdmin
																			}
																			sx={{
																				cursor:
																					subscriptionName !==
																					plan.name?.toLowerCase()
																						? "pointer"
																						: "default",
																				width: "100%",
																				height: "auto",
																				color:
																					(activePendingsubscriptionName === plan.name?.toLowerCase() &&
																						pausedsubscriptionName !== plan.name?.toLowerCase()) ||
																						((userDeviceID === null || userDeviceID === "") &&
																							subscriptionName === "free") ||
																						disabledAll ||
																						user.userAccountStatus !== "ACTIVE" || isAdmin
																						? "#FFFFFF !important"
																						: plan.name?.toLowerCase() === subscriptionName
																							? "#ff702a !important"
																							: "#fff",
																				background:
																					(activePendingsubscriptionName === plan.name?.toLowerCase() &&
																						pausedsubscriptionName !== plan.name?.toLowerCase()) ||
																						((userDeviceID === null || userDeviceID === "") &&
																							subscriptionName === "free") ||
																						disabledAll ||
																						user.userAccountStatus !== "ACTIVE" || isAdmin
																						? "#C2C2C3 !important"
																						: subscriptionName === plan.name?.toLowerCase() &&
																							pausedsubscriptionName !== plan.name?.toLowerCase()
																							? "#fff !important"
																							: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
																				border:
																					pausedsubscriptionName ===
																						"premium" ||
																					pausedsubscriptionName === "standard"
																						? "0px"
																						: plan.name?.toLowerCase() ===
																						  subscriptionName
																						? "0px"
																						: "0px",
																				borderRadius:
																					subscriptionName ===
																					plan.name?.toLowerCase()
																						? "0px"
																						: "20px",
																				borderBottom:
																					subscriptionName ===
																					plan.name?.toLowerCase()
																						? "1px solid #999"
																						: "20px",
																			}}
																			className="subButton"
																		>
																			{(subscriptionName === "standard" ||
																				subscriptionName === "premium") &&
																			activePendingsubscriptionName !==
																				"free" ? (
																				<Typography
																					variant="body2"
																					sx={{
																						fontSize: "1rem",
																						fontWeight: "400",
																						paddingRight: "0px",
																					}}
																				>
																					Choose {plan.name}
																				</Typography>
																			) : activePendingsubscriptionName ===
																			  "free" ? (
																				<Typography
																					variant="body2"
																					sx={{
																						fontSize: "1rem",
																						fontWeight: "400",
																						paddingRight: "0px",
																					}}
																				>
																					Starting on{" "}
																					{formattedsubscriptionStartDate}
																				</Typography>
																			) : (
																				""
																			)}
																		</Button>
																	)}
																{plan.name?.toLowerCase() === "standard" &&
																	activePlanSubscriptionName ===
																		plan.name?.toLowerCase() && (
																		<Box
																			sx={{
																				width: "100%",
																				textAlign: "center",
																				height: "38px",
																				borderBottom: "1px solid #999",
																			}}
																		>
																			<Typography
																				sx={{
																					fontSize: "1rem",
																					fontWeight: "500",
																					color: "#FF702A",
																				}}
																			>
																				<CheckCircleIcon
																					sx={{
																						color: "#259A12",
																						verticalAlign: "middle",
																						marginRight: "4px",
																						paddingRight: "0px",
																					}}
																				/>
																				Current Plan
																			</Typography>
																		</Box>
																	)}
																{plan.name?.toLowerCase() === "standard" &&
																	activePlanSubscriptionName !==
																		plan.name?.toLowerCase() && (
																		<Button
																			size="large"
																			variant="contained"
																			onClick={(event) => {
																				if (
																					// (userDeviceID && subscriptionName !== "free") &&
																					plan.name?.toLowerCase() === "free" ||
																					plan.name?.toLowerCase() ===
																						"premium" ||
																					plan.name?.toLowerCase() ===
																						"standard"
																				) {
																					handleAddNow(plan, event, "");
																				}
																			}}
																			disabled={
																				activePendingsubscriptionName ===
																					plan.name.toLowerCase() ||
																				((userDeviceID === null ||
																					userDeviceID === "") &&
																					subscriptionName === "free") ||
																				disabledAll ||
																				(!userDeviceID &&
																					devicePurcasedName === null) ||
																				user.userAccountStatus !== "ACTIVE" || isAdmin
																			}
																			sx={{
																				cursor:
																					subscriptionName !==
																						plan.name?.toLowerCase() ||
																					pausedsubscriptionName ===
																						plan.name?.toLowerCase()
																						? "pointer"
																						: "default",
																				width: "100%",
																				height: "auto",
																				color:
																					activePendingsubscriptionName === plan.name.toLowerCase() ||
																						((userDeviceID === null || userDeviceID === "") && subscriptionName === "free") ||
																						disabledAll ||
																						(!userDeviceID && devicePurcasedName === null) ||
																						user.userAccountStatus !== "ACTIVE" || isAdmin
																						? "#FFFFFF !important"
																						: plan.name?.toLowerCase() === subscriptionName &&
																							pausedsubscriptionName !== plan.name?.toLowerCase()
																						? "#ff702a !important"
																						: "#fff",
																				background:
																					activePendingsubscriptionName === plan.name.toLowerCase() ||
																						((userDeviceID === null || userDeviceID === "") && subscriptionName === "free") ||
																						disabledAll ||
																						(!userDeviceID && devicePurcasedName === null) ||
																						user.userAccountStatus !== "ACTIVE" || isAdmin
																						? "#C2C2C3 !important"
																						: subscriptionName === plan.name?.toLowerCase() &&
																							pausedsubscriptionName !== plan.name?.toLowerCase()
																						? "#fff !important"
																						: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
																				border:
																					pausedsubscriptionName ===
																						"premium" ||
																					pausedsubscriptionName === "standard"
																						? "0px"
																						: plan.name?.toLowerCase() ===
																						  subscriptionName
																						? "0px"
																						: "0px",
																				borderRadius:
																					subscriptionName ===
																						plan.name?.toLowerCase() &&
																					pausedsubscriptionName !==
																						plan.name?.toLowerCase()
																						? "0px"
																						: "20px",
																				borderBottom:
																					subscriptionName ===
																						plan.name?.toLowerCase() &&
																					pausedsubscriptionName !==
																						plan.name?.toLowerCase()
																						? "1px solid #999"
																						: "20px",
																			}}
																			className="subButton"
																		>
																			{subscriptionName === "premium" &&
																			activePendingsubscriptionName !==
																				"standard" ? (
																				<Typography
																					variant="body2"
																					sx={{
																						fontSize: "1rem",
																						fontWeight: "400",
																						paddingRight: "0px",
																					}}
																				>
																					Choose {plan.name}
																				</Typography>
																			) : subscriptionName === "free" ||
																			  subscriptionName === null ? (
																				<Typography
																					variant="body2"
																					sx={{
																						fontSize: "1rem",
																						fontWeight: "400",
																						paddingRight: "0px",
																					}}
																				>
																					Choose {plan.name}
																				</Typography>
																			) : activePendingsubscriptionName ===
																					"standard" ||
																			  (plan.name?.toLowerCase() === "free" &&
																					activePendingsubscriptionName ===
																						"free") ? (
																				<Typography
																					variant="body2"
																					sx={{
																						fontSize: "1rem",
																						fontWeight: "400",
																						paddingRight: "0px",
																					}}
																				>
																					Starting on{" "}
																					{formattedsubscriptionStartDate}
																				</Typography>
																			) : pausedsubscriptionName ===
																					"standard" &&
																			  activePendingsubscriptionName ===
																					"free" ? (
																				<Typography
																					variant="body2"
																					sx={{
																						fontSize: "1rem",
																						fontWeight: "400",
																						paddingRight: "0px",
																					}}
																				>
																					Reactivate {plan.name}
																				</Typography>
																			) : (
																				""
																			)}
																		</Button>
																	)}
																{plan.name?.toLowerCase() === "premium" &&
																	activePlanSubscriptionName ===
																		plan.name?.toLowerCase() && (
																		<Box
																			sx={{
																				width: "100%",
																				textAlign: "center",
																				height: "38px",
																				borderBottom: "1px solid #999",
																			}}
																		>
																			<Typography
																				sx={{
																					fontSize: "1rem",
																					fontWeight: "500",
																					color: "#FF702A",
																				}}
																			>
																				<CheckCircleIcon
																					sx={{
																						color: "#259A12",
																						verticalAlign: "middle",
																						marginRight: "4px",
																					}}
																				/>
																				Current Plan
																			</Typography>
																		</Box>
																	)}
																		{plan.name?.toLowerCase() === "premium" &&
																			activePlanSubscriptionName !==
																			plan.name?.toLowerCase() && (
																				<>
																					{showPremium ?
																						<Button
																							size="large"
																							variant="contained"
																							onClick={(event) => {
																								if (
																									// (userDeviceID && subscriptionName !== "free") &&
																									userDeviceID !== null &&
																									userDeviceID !== "" &&
																									(plan.name?.toLowerCase() ===
																										"free" ||
																										plan.name?.toLowerCase() ===
																										"premium" ||
																										plan.name?.toLowerCase() ===
																										"standard")
																									// ((userDeviceID !== null || userDeviceID !== "") && plan.name?.toLowerCase() === "free" || plan.name?.toLowerCase() === "premium" ||
																									// 	plan.name?.toLowerCase() === "standard")
																								) {
																									handleAddNow(plan, event, "");
																								}
																							}}
																							// disabled={activePendingsubscriptionName === plan.name.toLowerCase() || ((userDeviceID === null || userDeviceID === "") && subscriptionName !== "free") || disabledAll}
																							disabled={
																								!userDeviceID || // Disable button when userDeviceID is null or empty
																								activePendingsubscriptionName ===
																								plan.name?.toLowerCase() ||
																								disabledAll ||
																								(!userDeviceID &&
																									devicePurcasedName === null) ||
																								user.userAccountStatus !== "ACTIVE" || isAdmin
																							}
																							sx={{
																								cursor:
																									subscriptionName !==
																										plan.name?.toLowerCase() ||
																										pausedsubscriptionName ===
																										plan.name?.toLowerCase()
																										? "pointer"
																										: "default",
																								width: "100%",
																								height: "auto",
																								color:
																									!userDeviceID ||
																										activePendingsubscriptionName ===
																										plan.name?.toLowerCase() ||
																										disabledAll ||
																										(!userDeviceID &&
																											devicePurcasedName === null) ||
																										user.userAccountStatus !== "ACTIVE" || isAdmin
																										? "#FFFFFF !important"
																										: plan.name?.toLowerCase() === subscriptionName &&
																											pausedsubscriptionName !== plan.name?.toLowerCase()
																											? "#ff702a !important"
																											: "#fff",
																								background:
																									!userDeviceID ||
																										activePendingsubscriptionName ===
																										plan.name?.toLowerCase() ||
																										disabledAll ||
																										(!userDeviceID &&
																											devicePurcasedName === null) ||
																										user.userAccountStatus !== "ACTIVE" || isAdmin
																										? "#C2C2C3 !important"
																										: subscriptionName === plan.name?.toLowerCase() &&
																											pausedsubscriptionName !== plan.name?.toLowerCase()
																											? "#fff !important"
																											: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
																								border:
																									pausedsubscriptionName ===
																										"premium" ||
																										pausedsubscriptionName === "standard"
																										? "0px"
																										: plan.name?.toLowerCase() ===
																											subscriptionName
																											? "0px"
																											: "0px",
																								borderRadius:
																									subscriptionName ===
																										plan.name?.toLowerCase() &&
																										pausedsubscriptionName !==
																										plan.name?.toLowerCase()
																										? "0px"
																										: "20px",
																								borderBottom:
																									subscriptionName ===
																										plan.name?.toLowerCase() &&
																										pausedsubscriptionName !==
																										plan.name?.toLowerCase()
																										? "1px solid #999"
																										: "20px",
																							}}
																							className="subButton"
																						>
																							{subscriptionName === "standard" ||
																								subscriptionName === "free" ||
																								subscriptionName === null ? (
																								<Typography
																									variant="body2"
																									sx={{
																										fontSize: "1rem",
																										fontWeight: "400",
																									}}
																								>
																									Choose {plan.name}
																								</Typography>
																							) : pausedsubscriptionName ===
																								"premium" &&
																								(activePendingsubscriptionName ===
																									"standard" ||
																									activePendingsubscriptionName ===
																									"free") ? (
																								<Typography
																									variant="body2"
																									sx={{
																										fontSize: "1rem",
																										fontWeight: "400",
																									}}
																								>
																									Reactivate {plan.name}
																								</Typography>
																							) : (
																								<Typography
																									variant="body2"
																									sx={{
																										fontSize: "1rem",
																										fontWeight: "400",
																									}}
																								>
																									Choose {plan.name}
																								</Typography>
																							)}
																						</Button> :
																					<Button
																						size="large"
																						variant="contained"
																						onClick={(event) => {
																							if (
																								// (userDeviceID && subscriptionName !== "free") &&
																								userDeviceID !== null &&
																								userDeviceID !== "" &&
																								(pausedsubscriptionName ===
																									"premium" &&
																									(activePendingsubscriptionName ===
																										"standard" ||
																										activePendingsubscriptionName ===
																										"free"))
																							) {
																								handleAddNow(plan, event, "");
																							}
																						}}
																						disabled={
																							!(pausedsubscriptionName ===
																								"premium" &&
																								(activePendingsubscriptionName ===
																									"standard" ||
																									activePendingsubscriptionName ===
																									"free"))
																						}
																						sx={{
																							cursor:
																								subscriptionName !==
																									plan.name?.toLowerCase() ||
																									pausedsubscriptionName ===
																									plan.name?.toLowerCase()
																									? "pointer"
																									: "default",
																							width: "100%",
																							height: "auto",
																							color: "#fff !important",
																							background:
																								!(pausedsubscriptionName ===
																									"premium" &&
																									(activePendingsubscriptionName ===
																										"standard" ||
																										activePendingsubscriptionName ===
																										"free"))
																									? "#C2C2C3 !important"
																									: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
																							border:
																								pausedsubscriptionName ===
																									"premium" ||
																									pausedsubscriptionName === "standard"
																									? "0px"
																									: plan.name?.toLowerCase() ===
																										subscriptionName
																										? "0px"
																										: "0px",
																							borderRadius:
																								subscriptionName ===
																									plan.name?.toLowerCase() &&
																									pausedsubscriptionName !==
																									plan.name?.toLowerCase()
																									? "0px"
																									: "20px",
																							borderBottom:
																								subscriptionName ===
																									plan.name?.toLowerCase() &&
																									pausedsubscriptionName !==
																									plan.name?.toLowerCase()
																									? "1px solid #999"
																									: "20px",
																						}}
																						className="subButton"
																					>
																						{subscriptionName === "standard" ||
																							subscriptionName === "free" ||
																							subscriptionName === null ? (
																							<Typography
																								variant="body2"
																								sx={{
																									fontSize: "1rem",
																									fontWeight: "400",
																								}}
																							>
																								Coming Soon
																							</Typography>
																						) : pausedsubscriptionName ===
																							"premium" &&
																							(activePendingsubscriptionName ===
																								"standard" ||
																								activePendingsubscriptionName ===
																								"free") ? (
																							<Typography
																								variant="body2"
																								sx={{
																									fontSize: "1rem",
																									fontWeight: "400",
																								}}
																							>
																								Reactivate {plan.name}
																							</Typography>
																						) : (
																							<Typography
																								variant="body2"
																								sx={{
																									fontSize: "1rem",
																									fontWeight: "400",
																								}}
																							>
																								Choose {plan.name}
																							</Typography>
																						)}
																					</Button>
																					}

																				</>
																			)}

																{(plan.name?.toLowerCase() === "premium" &&
																	pausedsubscriptionName === "premium" &&
																	(activePendingsubscriptionName ===
																		"standard" ||
																		activePendingsubscriptionName ===
																			"free")) ||
																(plan.name?.toLowerCase() === "standard" &&
																	pausedsubscriptionName === "standard" &&
																	activePendingsubscriptionName === "free") ? (
																	<Typography
																		variant="body2"
																		sx={{
																			fontSize: "14px",
																			fontWeight: "400",
																			color:
																				pausedsubscriptionName === "premium"
																					? "#9747ff"
																					: pausedsubscriptionName ===
																					  "standard"
																					? "#259a12"
																					: "#4D4C4F",
																		}}
																	>
																		Expiring on{" "}
																		{formattedsubscriptionEndDateFull}
																	</Typography>
																) : (
																	""
																)}
															</>
														)}
													</Grid>
													<List>
														{plan.features.map((feature: any, index: any) => (
															<ListItem key={index}>
																<ListItemIcon>
																	{feature.included !== undefined &&
																		feature.included !== null &&
																		feature.included !== "" && (
																			<>
																				{feature.included ? (
																					<CheckCircleIcon
																						style={{ color: "gray" }}
																					/>
																				) : (
																					<CloseIcon style={{ color: "red" }} />
																				)}
																			</>
																		)}
																</ListItemIcon>
																<ListItemText
																	primary={
																		<span
																			dangerouslySetInnerHTML={{
																				__html: DOMPurify.sanitize(
																					feature.text
																				),
																			}}
																		/>
																	}
																/>
															</ListItem>
														))}
													</List>
												</CardContent>
											</Card>
										</Grid>
									) : (
										""
									);
								})}
							</Grid>
						</Box>

						<Box sx={{ padding: "0px", }}>
							{subscriptionPlans && subscriptionPlans.map((plan: any, index) => {
								return plan.type === "Storage" &&
									plan.name?.toLowerCase() === "storage+" ? (
									<Card sx={{ borderRadius: "16px", boxShadow: 3,}}>
										<CardContent sx={{paddingBottom: "16px !important"}}>
											<Grid container alignItems="center">
												<Grid item xs={12} sm={2} sx={{ height: "110px" }}>
													<Typography
														variant="body2"
														sx={{
															fontWeight: 700,
															fontSize: "31px",
															height: "40px",
															color:
																!userDeviceID || subscriptionName === "free"
																	? "#7A7985"
																	: "#0273E6",
														}}
													>
														Storage+
													</Typography>
													<Typography
														variant="h4"
														sx={{ fontWeight: 800, mt: 1, height: "40px" }}
													>
														<Box
															component="span"
															sx={{ color: "##313131", fontSize: "38px" }}
														>
															${plan.cost}{" "}
														</Box>
														<Box
															component="span"
															sx={{ color: "#888888", fontSize: "16px" }}
														>
															/mo
														</Box>
													</Typography>
													<Typography
														variant="body2"
														sx={{
															color: "#7A7985",
															height: "20px",
															textAlign: "center",
															fontWeight: 500,
															fontSize: "16px",
														}}
													>
														Billed monthly
													</Typography>
												</Grid>
												<Grid xs={1}>
													<Divider
														orientation="vertical"
														sx={{
															height: "167px",
															borderColor: "#7A7985",
															borderRightWidth: "2px",
															marginRight: "31px",
														}}
													/>
												</Grid>
												<Grid item xs={12} sm={5}>

													<Box sx={{width:"248px",mb:"25px"}}>
														<Grid
															container
															paddingTop={2}									
														>
															<Grid
																item
																xs={1}
																sx={{ mr: "12px" }}
																className="verticallyCenter"
															>
																<img src={CloudStorageBlue} alt="Storage" />
															</Grid>
															<Grid item xs={10}>
																<Typography variant="h6">Storage</Typography>
															</Grid>
															<Grid item xs={12} my={1}>
																<LinearProgress
																	className={classes.progressBar}
																	variant="determinate"
																	value={Math.min(storageConsumedPerc, 100)}
																	sx={{
																		"& .MuiLinearProgress-bar": {
																			backgroundColor: canStore
																				? "#888888 !important"
																				: "red !important",
																		},
																	}}
																/>
															</Grid>
															<Grid item xs={12}>
																<Typography variant="subtitle1" color="#888">
																	{storageConsumed + " of " + storageAvailable + " used"}
																</Typography>
															</Grid>
														</Grid>
													</Box>
													{!storageSubscriptionName && (
														<Typography
															variant="body2"
															sx={{ color: "#7A7985",  }}
														>
															Add to your existing Standard/Premium
															subscriptions
														</Typography>
													)}
													<Typography
														variant="body2"
														sx={{
															fontWeight: 500,
															fontSize: "24px",
															color: "##000",
														}}
													>
														100GB{" "}
														<Box
															component="span"
															sx={{
																color: "#5B5B65",
																fontSize: "16px",
																fontWeight: "400",
															}}
														>
															of music recording storage
														</Box>
													</Typography>
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													container
													justifyContent="center"
													alignItems="center"
												>
													{storageStatus === "active" && (
														<>
															{" "}
															<Box
																component="span"
																sx={{ fontSize: "16px", paddingTop: "10px" }}
															>
																{" "}
																Billed every month on {storageEndDateD}
															</Box>
															<Button
																variant="contained"
																className="reCancelStorageBtn"
																sx={{ backgroundColor: "#6C63FF" }}
																disabled={
																	disabledAll ||
																	user.userAccountStatus !== "ACTIVE" || isAdmin
																}
																onClick={(event) => {
																	handleAddNow(
																		storageSubscription,
																		event,
																		"pause"
																	);
																}}
															>
																{" "}
																Cancel Storage
															</Button>
														</>
													)}
													{storageStatus === "paused" && (
														<>
															<Box
																display="flex"
																justifyContent="center"
																alignItems="center"
																color="red"
															>
																<PriorityHighOutlinedIcon fontSize="medium" />
																<Typography
																	variant="body2"
																	sx={{
																		color: "red",
																		fontWeight: "400",
																		fontSize: "16px",
																	}}
																>
																	Storage Ending on {storageEndDate}
																</Typography>
															</Box>
															<Typography
																variant="body2"
																sx={{ color: "black" }}
															>
																Be sure to download your
															</Typography>
															<Typography
																variant="body2"
																sx={{ color: "black" }}
															>
																data before this date
															</Typography>
															<Button
																variant="contained"
																className="reCancelStorageBtn"
																sx={{ backgroundColor: "#6C63FF" }}
																disabled={
																	disabledAll ||
																	activePendingsubscriptionName === "free" ||
																	subscriptionName === "free" ||
																	user.userAccountStatus !== "ACTIVE" || isAdmin
																}
																onClick={(event) => {
																	handleAddNow(
																		storageSubscription,
																		event,
																		"reactivate"
																	);
																}}
															>
																{" "}
																Reactivate Storage
															</Button>{" "}
														</>
													)}

													{!storageSubscriptionName && (
														<Button
															variant="contained"
															sx={{
																backgroundColor:
																	userDeviceID ||
																	subscriptionName === "standard" ||
																	subscriptionName === "premium"
																		? "#6C63FF"
																		: "#7A7985 !important",
																color: "#fff !important",
																borderRadius: "24px",
																padding: "10px 20px",
																textTransform: "none",
																cursor: userDeviceID ? "pointer" : "default",
																opacity: userDeviceID ? 1 : 0.5,
																pointerEvents: userDeviceID ? "auto" : "none",
																width: "209px",
															}}
															disabled={
																!userDeviceID ||
																subscriptionName === "free" ||
																storageSubscriptionName === "storage+" ||
																disabledAll ||
																activePendingsubscriptionName === "free" ||
																user.userAccountStatus !== "ACTIVE" || isAdmin
															}
															onClick={(event) => {
																if (userDeviceID) {
																	// checkOut(plan);
																	handleAddNow(plan, event, "");
																} else {
																	console.log(
																		"User device ID not found. Handle accordingly."
																	);
																}
															}}
														>
															Add Storage
														</Button>
													)}
													<Typography
														variant="caption"
														sx={{ color: "#7A7985", mt: 1 }}
													>
														{subscriptionName === "free"
															? "Upgrade plan to enable this"
															: storageSubscriptionName !== "storage+" ||
															  subscriptionName !== "free"
															? ""
															: "Billed on " + storageEndDate}
													</Typography>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								) : (
									""
								);
							})}
						</Box>
					</Grid>
				</Grid>
			)}

			<Dialog
				open={dialogDownOpen}
				onClose={handleDialogDownClose}
				maxWidth="xs"
				fullWidth
			>
				<DialogTitle align="center" sx={{ pt: 3 }}>
					Subscription
				</DialogTitle>
				<DialogContent sx={{ textAlign: "center", pb: 2 }}>
					<Box sx={{ my: 2 }}>
						<OpenInNewIcon sx={{ fontSize: 50 }} />
					</Box>

					<Typography variant="body2" color="error" sx={{ mt: 2 }}>
						Are you sure you want to change the plan?
					</Typography>
				</DialogContent>
				<DialogActions sx={{ justifyContent: "center", pb: 3 }}>
					<Button
						variant="outlined"
						onClick={handleDialogDownClose}
						sx={{ minWidth: 100 }}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						disabled={loading || user.userAccountStatus !== "ACTIVE" || isAdmin}
						color="warning"
						onClick={(event) => downgradePlan(selectedPlan, event)}
						sx={{ minWidth: 100 }}
					>
						Proceed
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={dialogDownStorageCancelOpen}
				onClose={handleDialogDownStorageCancelClose}
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle align="center" sx={{ pt: 3 }}>
					Subscription
				</DialogTitle>
				<DialogContent sx={{ textAlign: "center", pb: 2 }}>
					<Box sx={{ my: 2 }}>
						<OpenInNewIcon sx={{ fontSize: 50 }} />
					</Box>

					<Typography variant="body2" color="error" sx={{ mt: 2 }}>
						Are you sure you want to change the plan?
					</Typography>
					<Typography variant="body2" sx={{ mt: 2 }}>
						Also, your subscription to Storage+ will be automatically cancelled
						if you decide to proceed further with this change of plan.
					</Typography>
				</DialogContent>
				<DialogActions sx={{ justifyContent: "center", pb: 3 }}>
					<Button
						variant="outlined"
						onClick={handleDialogDownStorageCancelClose}
						sx={{ minWidth: 100 }}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						disabled={loading || user.userAccountStatus !== "ACTIVE" || isAdmin}
						color="warning"
						onClick={(event) => {
							downgradePlan(selectedPlan, event);
							reactivateCancelPlan(storageSubscription, event, "pause");
						}}
						sx={{ minWidth: 100 }}
					>
						Proceed
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={dialogReactivateOpen}
				onClose={handleDialogReactivateClose}
				maxWidth="xs"
				fullWidth
			>
				<DialogTitle align="center" sx={{ pt: 3 }}>
					Subscription
				</DialogTitle>
				<DialogContent sx={{ textAlign: "center", pb: 2 }}>
					<Box sx={{ my: 2 }}>
						<OpenInNewIcon sx={{ fontSize: 50 }} />
					</Box>

					{storageButtonText === "reactivate" && (
						<Typography variant="body2" color="error" sx={{ mt: 2 }}>
							Are you sure you want to reactivate the storage?
						</Typography>
					)}
					{storageButtonText === "pause" && (
						<Typography variant="body2" color="error" sx={{ mt: 2 }}>
							Are you sure you want to cancel the storage?
						</Typography>
					)}
					{storageButtonText === "" && (
						<Typography variant="body2" color="error" sx={{ mt: 2 }}>
							Are you sure you want to reactivate the plan?
						</Typography>
					)}
				</DialogContent>
				<DialogActions sx={{ justifyContent: "center", pb: 3 }}>
					<Button
						variant="outlined"
						onClick={handleDialogReactivateClose}
						sx={{ minWidth: 100 }}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						disabled={loading || user.userAccountStatus !== "ACTIVE" || isAdmin}
						color="warning"
						//onClick={(event) => reactivateCancelPlan(selectedPlan, event, 'reactivate')}
						onClick={handleClick}
						sx={{ minWidth: 100 }}
					>
						Proceed
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={dialogOpen}
				onClose={handleDialogClose}
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle align="center" sx={{ pt: 3 }}>
					Subscription Payment
				</DialogTitle>
				<DialogContent sx={{ textAlign: "center", pb: 2 }}>
					<Box sx={{ my: 2 }}>
						<OpenInNewIcon sx={{ fontSize: 50 }} />
					</Box>
					<Typography variant="body1">
						You will be redirected to an external site to make payment.
					</Typography>
					<Stack direction="row" alignItems="center" >
   						<PriorityHighRoundedIconRounded sx={{ color: "#ff0000", fontSize: "3rem" }} />
    						<Typography variant="body1" sx={{ color: "#259a12", fontWeight: 600 }}>
        						Please ensure that the email address you use for payment matches the one you used to register your Lutefish account.
    						</Typography>
						<PriorityHighRoundedIconRounded sx={{ color: "#ff0000", fontSize: "3rem" }} />
					</Stack>	
					<Typography variant="body2" color="error" sx={{ mt: 2 }}>
						Please return back to this tab to view the status.
					</Typography>
				</DialogContent>
				<DialogActions sx={{ justifyContent: "center", pb: 3 }}>
					<Button
						variant="outlined"
						onClick={handleDialogClose}
						sx={{ minWidth: 100 }}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="warning"
						disabled={loading || user.userAccountStatus !== "ACTIVE" || isAdmin}
						onClick={(event) => checkOut(selectedPlan, event)}
						sx={{ minWidth: 100 }}
					>
						Proceed
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={dialogInfoOpen}
				onClose={handleDialogInfoClose}
				maxWidth="sm"
				fullWidth
				BackdropProps={{
					onClick: (event) => event.stopPropagation(),
				}}
			>
				<DialogTitle align="center" sx={{ pt: 3 }}>
					Subscription
				</DialogTitle>
				<DialogContent sx={{ textAlign: "center", pb: 2 }}>
					<Box sx={{ my: 2 }}>
						<OpenInNewIcon sx={{ fontSize: 50 }} />
					</Box>
					{reactiveCancel && (
						<Typography variant="body1">
							Please allow us a few minutes for the system to update.
						</Typography>
					)}
					{!reactiveCancel && (
						<Typography variant="body1">
							Once you have completed your transaction, please allow us a few
							minutes for the system to update.
						</Typography>
					)}

					<Typography variant="body1">
						Check back in about 5 minutes and we should be ready to get back to
						jamming.
					</Typography>
				</DialogContent>
				<DialogActions sx={{ justifyContent: "center", pb: 3 }}>
					<Button
						variant="contained"
						color="warning"
						// onClick={() => window.location.reload()}
						onClick={() => {
							navigate("/home");
							User.fetchUserInfo(() => {});
						}}
						sx={{ minWidth: 100 }}
					>
						Proceed
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				open={deviceError}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				sx={{
					marginTop: "50px",
					borderRadius: "16px",
					padding: "8px 0 !important",
					backgroundColor: "#ffffff",
					boxShadow:
						"0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
				}}
			>
				<Alert
					icon={false}
					action={
						<IconButton
							size="small"
							color="inherit"
							onClick={handleClose}
							sx={{ marginTop: "-30px" }}
						>
							<CloseIcon />
						</IconButton>
					}
					sx={{ width: "100%", backgroundColor: "#ffffff" }}
				>
					<Grid container spacing={2} alignItems="center">
						<Grid item sx={{ display: "flex" }}>
							<img
								src={ErrorMark}
								alt="Error Mark"
								style={{ height: "24px", width: "24px" }}
							/>
						</Grid>
						<Grid item justifyContent={"center"} alignItems="center">
							<Typography
								sx={{
									fontFamily: "Lexend",
									fontWeight: 500,
									fontSize: "18px",
									lineHeight: "22.5px",
								}}
							>
								"Something went wrong!"
							</Typography>
						</Grid>
					</Grid>
					<Grid container alignItems="center">
						<Grid
							item
							justifyContent={"center"}
							alignItems={"center"}
							sx={{ marginLeft: "2.5rem" }}
						>
							<Typography
								sx={{
									fontFamily: "Lexend",
									fontSize: "16px",
									fontWeight: 500,
									lineHeight: "25px",
									color: "#7A7985",
								}}
								dangerouslySetInnerHTML={{
									__html: linkifyMessage(
										"Please try again and contact us if the issue persists"
									),
								}}
							></Typography>
						</Grid>
					</Grid>
				</Alert>
			</Snackbar>
		</>
	);
};

export default SubscriptionPlans;
