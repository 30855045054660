import React, { useEffect, useRef, useState } from "react";
import {
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Slider,
	Typography,
} from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { Report, Share } from "@mui/icons-material";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import { useUser } from "../../context/UserContext";
import MyActivitiesSidePanel from "../Social/myActivitiesSidePanel";
import likeFill from "../../images/like-fill.svg";
import like from "../../images/like.svg";
import { styled } from "@mui/material/styles";
import ReportPost from "../Posts/ReportPost";
import ReportMusic from "./ReportMusic";
import ShareProfile from "../Messages/shareprofileinmessages/ShareProfileInMessages";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
export const MusicListing = (props: any) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const audioRef = useRef(new Audio(props.music.musicUrl));
	const [isLiked, setIsLiked] = useState(props.music.userReaction);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const [openReportPost, setOpenReportPost] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isReported, setIsReported] = useState(false);
	const [selectedViolations, setSelectedViolations] = useState<any>([]);
	const [openShareMusicDialog, setOpenShareMusicDialog] = useState(false);
	const [profileMusicLink, setProfileMusicLink] = useState("");
	const [open, setOpen] = useState(false);
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	const handleShareProfileDialog = () => {
		setOpenShareMusicDialog(false);
	};

	useEffect(() => {
		const isObjectEmpty = (obj: any) => {
			return obj && Object.keys(obj).length !== 0;
		};

		if (
			isObjectEmpty(props.music) &&
			isObjectEmpty(props.music.usersReportForPostMap)
		) {
			setSelectedViolations(
				Object.keys(props.music.usersReportForPostMap).filter(
					(key: any) => props.music.usersReportForPostMap[key]
				)
			);
		}
	}, [props.music]);


	const handleCloseReportPost = () => {
		setOpenReportPost(false);
	};

	const pauseAudioPlayer = () => {
		// Pause the currently playing audio player
		const audioPlayers = document.querySelectorAll("audio");
		audioPlayers.forEach((audioPlayer) => {
			const audioElement = audioPlayer as HTMLAudioElement;
			if (!audioElement.paused) {
				audioElement.pause();
			}
		});
	};

	const togglePlay = () => {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			pauseAudioPlayer();
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	const handleTimeUpdate = () => {
		const currentTime = audioRef.current.currentTime;
		setCurrentTime(currentTime);
		if (audioRef.current.duration === currentTime) {
			setCurrentTime(0);
			setIsPlaying(false);
		}
	};

	const handleSliderChange = (event: any, newValue: number | number[]) => {
		audioRef.current.currentTime = Number(newValue);
		setCurrentTime(Number(newValue));
	};

	const CustomSlider = styled(Slider)({
		color: "#5D5D65",
		height: 8,
		"& .MuiSlider-track": {
			border: "none",
		},
		"& .MuiSlider-thumb": {
			height: 24,
			width: 24,
			backgroundColor: "#fff",
			border: "2px solid currentColor",
			"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
				boxShadow: "inherit",
			},
			"&:before": {
				display: "none",
			},
		},
	});

	useEffect(() => {
		// Update the audio duration once the audio metadata is loaded
		audioRef?.current?.addEventListener("loadedmetadata", () => {
			setDuration(audioRef.current.duration);
		});
		// Remove event listener when component unmounts
		return () => {
			audioRef?.current?.removeEventListener("loadedmetadata", () => {});
		};
	}, []);

	const formatDuration = (duration: number) => {
		const minutes = Math.floor(duration / 60);
		const seconds = Math.floor(duration % 60);
		return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	};

	const toggleLike = () => {
		setIsLiked(!isLiked);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/like-music`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				userId: user.id,
				musicId: props.music.id,
				action: !isLiked,
			}),
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				}
			})
			.then((data) => {
				props.getAllMusicListing();
			})
			.catch((err) => {
			});
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleReport = () => {
		setOpenReportPost(true);
		setAnchorEl(null);
	};

	const handleShare = () => {
		setProfileMusicLink(
			`${process.env.REACT_APP_PICTURE_URL}${props.music?.musicUrl}`
		);
		setOpenShareMusicDialog(true);
		setAnchorEl(null);
	};

	return (
		<>
			{props.music.isDeletedByAdmin ? (
				<>
					<Grid
						container
						sx={{
							background: "#F7F8FB",
							border: "none",
							borderRadius: "20px",
							padding: "12px",
							display: "flex",
							justifyContent: "center",
							width: "577px",
							height: "110px",
							alignItems: "center"
						}}
					>
						<Grid item={true}>
							<Typography
								sx={{ color: "#888888", fontWeight: 400, fontSize: "16px" }}
							>
								This music is deleted by Admin
							</Typography>
						</Grid>
					</Grid>
				</>
			) : (
				<Grid
					container
					sx={{
						background: "#F7F8FB",
						border: "none",
						borderRadius: "20px",
						padding: "12px",
					}}
				>
					{/* Audio Button */}
					<Grid
						item
						xs={1}
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
						}}
					>
						<IconButton onClick={togglePlay}>
							{!audioRef.current.paused ? (
								<StopCircleIcon
									sx={{ fontSize: 64, width: 58, height: 58, color: "#1D2D53" }}
								/>
							) : (
								<PlayCircleIcon
									sx={{ fontSize: 64, width: 58, height: 58, color: "#1D2D53" }}
								/>
							)}
						</IconButton>
					</Grid>

					{/* Title and Contents */}
					<Grid
						container
						xs={11}
						sx={{ display: "flex", flexDirection: "column", paddingLeft: 1 }}
					>
						<Grid
							container
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "flex-start",
								justifyContent: "flex-end",
							}}
						>
							<Grid item xs={9} sx={{ pl: "15px", pt: "10px" }}>
								<div>
									<Typography
										sx={{ fontSize: "1.125rem", fontWeight: "500 !important" }}
									>
										{props.music.fileName}
									</Typography>
								</div>
							</Grid>
							<Grid item xs={1}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<IconButton sx={{ cursorPointer: "none" }}>
										<BarChartIcon
											sx={{ fontSize: 32, width: 32, height: 32 }}
										/>
									</IconButton>
									{/* {20} */}
								</div>
							</Grid>
							<Grid item xs={1}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<IconButton onClick={toggleLike} disabled={isAdmin}>
										{isLiked ? <img src={likeFill} /> : <img src={like} />}
									</IconButton>
									{props.music.totalLikes}
								</div>
							</Grid>

							<Grid
								item
								xs={1}
								style={{ display: "flex", justifyContent: "center" }}
							>
								<IconButton onClick={handleClick}>
									<MoreVertIcon />
								</IconButton>
								<Menu
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={handleClose}
									sx={{
										border: "1px",
										borderColor: "#888888",
										borderRadius: "2px",
									}}
								>
									<MenuItem onClick={handleReport}>
										<EmojiFlagsIcon /> Report
									</MenuItem>
									<MenuItem onClick={handleShare} disabled={isAdmin}>
										<Share /> Share
									</MenuItem>
								</Menu>
							</Grid>
						</Grid>

						<Grid container sx={{ marginLeft: 3 }}>
							<Grid item xs={9}>
								<audio
									ref={audioRef}
									onTimeUpdate={handleTimeUpdate}
									crossOrigin="anonymous"
								>
									<source
										src={`${process.env.REACT_APP_PICTURE_URL}${props.music.musicUrl}`}
										type="audio/mpeg"
									></source>
								</audio>
								<CustomSlider
									min={0}
									step={0.01}
									value={currentTime}
									max={duration}
									onChange={handleSliderChange}
								/>
							</Grid>

							<Grid item xs={3} sx={{ paddingLeft: "18px", paddingTop: "4px" }}>
								{formatDuration(currentTime) + " / " + formatDuration(duration)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}

			{openReportPost && (
				<ReportMusic
					open={openReportPost}
					handleClose={handleCloseReportPost}
					userProfileId={props?.id}
					musicId={props.music?.id}
					setLoading={setLoading}
					setSelectedViolations={setSelectedViolations}
					usersReportForPostMap={selectedViolations}
					musicUUID={props.music?.myMusicUuid}
					offenderUserProfileUUID={props?.uuid}
				/>
			)}
			{openShareMusicDialog && (
				<ShareProfile
					open={openShareMusicDialog}
					handleClose={handleShareProfileDialog}
					profileLink={profileMusicLink}
					menuClose={() => setOpen(false)}
				/>
			)}
		</>
	);
};
