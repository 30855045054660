import React from 'react'
import { PaginationWrapper, PaginationTitle, PaginationItem,  } from './PaginationStyled';
import { useDailyUtils } from '../Hooks/useDailyUtils';
import { DailyPaginationType } from '../Types/dailyTypes';
import { useDailyAppRoom } from '../Hooks/useDailyGlobalRoom';
import Tile from '../Tile/Tile';


const Pagination: React.FC<DailyPaginationType> = ({ participantsCount }) => {
  const { totalPages, currentPage, goToPage, tilesPerPage, totalData } = useDailyUtils();
  const {streamingViewType} = useDailyAppRoom();
  // console.log('Pagination', { totalPages, currentPage, goToPage, tilesPerPage, streamingViewType, totalData })
  const isSoloFocusView = streamingViewType === 'soloFocus';
  const isSoloRotateView = streamingViewType === 'soloRotate';

  if(participantsCount <= tilesPerPage){
    return null;
  }

  if(isSoloFocusView){
    return(
      <PaginationWrapper isSoloFocusView className="custom-h-scroll">
        <PaginationTitle>{participantsCount} Musicians playing</PaginationTitle>
          <div style={{display: 'flex', overflow: 'auto', paddingBottom: '6px' }}>
            {Array.from({ length: totalPages }, (_, index) => (
              <PaginationItem
                key={index}
                onClick={() => goToPage(index + 1)}
                isActive={currentPage === index + 1}
                isSoloFocusView
              >   
                <Tile id={totalData[index]} isLocal={false} isAlone={false} />
              </PaginationItem>
            ))}
          </div>
      </PaginationWrapper>
    )
  }

  if(isSoloRotateView){
    return(
      <PaginationWrapper isSoloFocusRotateView>
        <PaginationTitle>{participantsCount} Musicians playing</PaginationTitle>
            {Array.from({ length: totalPages }, (_, index) => (
              <PaginationItem
                key={index}
                onClick={() => goToPage(index + 1)}
                isActive={currentPage === index + 1}
                isSoloFocusRotateView
              >
                {''}
              </PaginationItem>
            ))}
      </PaginationWrapper>
    )
  }

  return (
    <PaginationWrapper>
      <PaginationTitle>{participantsCount} Musicians playing</PaginationTitle>
          {Array.from({ length: totalPages }, (_, index) => (
            <PaginationItem
              key={index}
              onClick={() => goToPage(index + 1)}
              isActive={currentPage === index + 1}
            >
              {index + 1}
            </PaginationItem>
          ))}
    </PaginationWrapper>
  )
}

export default Pagination;