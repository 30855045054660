import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Grid,
  CircularProgress
} from "@mui/material";
import { isEmpty, isEqual } from 'lodash';
import CloseIcon from "../../../images/close.svg";
import i18n from "../../../l10n/strings.json";
import ErrorIcon from "../../../images/danger.svg";
import SuccessIcon from "../../../images/tick-square.svg";
import { useAdminDevciceManagement } from "./useAdminDeviceManagement";
import { useAlertStatus } from "../../../context/AlertStatusContext";

interface StepContentType {
  [key: number]: React.ReactNode;
}

interface CSVData {
  [key: string]: string;
}

interface MasterDevicesDTO {
  deviceId: string;
  deviceManufacturedBy: string;
  deviceManufacturedYear: string;
  deviceVersion: string;
}

interface ImportResponse {
  message: string;
  emptyDeviceIds: MasterDevicesDTO[];
  existingDevices: MasterDevicesDTO[];
  invalidDeviceIds: MasterDevicesDTO[];
  assignedDevices: MasterDevicesDTO[];
  importedDevices: MasterDevicesDTO[];
  invalidManufacturer: MasterDevicesDTO[];
}


export default function AdminImportDevicesCsvModal(props: any) {

  const token = localStorage.getItem("sessionId");

  const { open, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [fileName, setFileName] = useState('');
  const [hasError, setHasError] = useState(false);
  const [validRecord, setValidRecords] = useState<CSVData[]>([]);
  const [importedResp, setImportedResp] = useState<ImportResponse | null>(null);

  const { updateAlertStatus } = useAlertStatus();
  const { fetchDevicesData } = useAdminDevciceManagement();

  useEffect(() => {

  }, []);



  const handleSubmit = async () => {
    if (hasError || isEmpty(validRecord) || step === 3) {
      startOver();
    } else if (fileName !== '') {
      importDevices();
      console.log('submit')
    }

  }

  const importDevices = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/devices/csv/import`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ devices: validRecord }),
      });

      if (response.ok) {
        const resp = await response.json();
        const { message = 'New Device Details Added Successfully!'} = resp;
        setStep(3);
        setImportedResp(resp);
        setTimeout(() => {
          setIsLoading(false);
          fetchDevicesData();
          updateAlertStatus(true, true, message);
        }, 1000);
      } else {
        const { message = '' } = await response.json();
        updateAlertStatus(true, false, message);
        setIsLoading(false);
      }
    } catch (error) {
      updateAlertStatus(true, false, 'Network Error! Please try again..');
      setIsLoading(false);
    }
  };


  const startOver = () => {
    setFileName('');
    setStep(1);
    setHasError(false);
  }

  const handleCloseModal = () => {
    startOver();
    onClose();
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const input = event.target;
    if (input.files && input.files.length > 0) {
      const newfile = input.files[0];
      setFileName(newfile.name);
      const reader = new FileReader();
      reader.onload = function (e: ProgressEvent<FileReader>) {
        const text = e.target?.result as string;
        const data = parseCSV(text);
        setValidRecords(data);
        console.log(data);
      };
      reader.readAsText(newfile);
    }
  };

  const parseCSV = (text: string) => {
    const lines = text.trim().split('\n');
    const initialHeaders = ["deviceId", "deviceManufacturedBy", "deviceManufacturedYear", "deviceVersion"];
    const headers = lines[0].replace(/"/g, '').replace(/\r/g, "").replace(/\r/g, '').replace(/\r/g, '').split(',');
    if (isEqual(initialHeaders, headers)) {
      const data = lines.slice(1).map((line) => {
        const values = line.replace(/"/g, '').split(',');
        const obj: CSVData = {};
        headers.forEach((header, index) => {
          obj[header] = values[index];
        });
        return obj;
      });
      setStep(2);
      return data;
    } else {
      setHasError(true);
      setStep(2);
    }
    return [];
  };


  const FirstStepContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          variant="contained"
          component="label"
          sx={{ marginBottom: 1 }}
        >
          {fileName || 'Select a CSV file to upload'}
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            hidden
          />

        </Button>

        <Typography
          variant="body4"
          color="customColor.label"
          sx={{ marginLeft: "0px !important", fontSize: '10px !important', textAlign: 'center' }}
        >
          {i18n.deviceManagement.templateHelper}

        </Typography>
      </div>
    )
  }

  const SecondStepComponent = () => {
    return (
      <Grid xs={12} sx={{ display: 'flex' }} alignItems={'center'} justifyContent={'center'}>
        {
          hasError ?
            <>
              <img
                src={ErrorIcon}
                alt="error-icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                variant="body4"
                color="customColor.label"
                sx={{ marginLeft: "0px !important", textAlign: 'center', color: '#FD4B24' }}
              >
                {i18n.deviceManagement.invalidCsvFormat}
              </Typography></> :
            <>
              <img
                src={SuccessIcon}
                alt="error-icon"
                style={{ marginRight: "10px" }}
              />
              <div>
                <Typography
                  variant="body4"
                  color="customColor.label"
                  sx={{ marginLeft: "0px !important", textAlign: 'center', color: '#3EB92A' }}
                >
                  {i18n.deviceManagement.validCsvFormat}
                </Typography>
                <br />
                <Typography
                  variant="body4"
                  color="customColor.label"
                  sx={{ marginLeft: "0px !important", textAlign: 'center' }}
                >
                  {validRecord.length} {i18n.deviceManagement.recordsToImport}
                </Typography>
              </div>
            </>
        }

      </Grid>
    )
  }

  const FinalStepComponent = () => {
    const { assignedDevices = [], emptyDeviceIds = [], existingDevices = [], invalidDeviceIds =[], invalidManufacturer = [], importedDevices } = importedResp || {};

    const hasSkippedRecords = !isEmpty([...emptyDeviceIds, ...existingDevices, ...invalidDeviceIds, ...assignedDevices, ...invalidManufacturer]);
    return (
      <Grid container rowGap={2}>
        <Grid item xs={12} sx={{ display: 'flex' }} alignItems={'center'}>
          <img
            src={SuccessIcon}
            alt="error-icon"
            style={{ marginRight: "10px" }}
          />
          <div>
            <Typography
              variant="body4"
              color="customColor.label"
              sx={{ marginLeft: "0px !important", textAlign: 'center', color: '#3EB92A' }}
            >

              {importedDevices?.length} {i18n.deviceManagement.recordsImported}
            </Typography>
          </div>
        </Grid>
        {hasSkippedRecords &&
          <Grid container rowGap={2}>
            <Grid item xs={12} sx={{ display: 'flex' }} alignItems={'center'} >
              <img
                src={ErrorIcon}
                alt="error-icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                variant="body4"
                color="customColor.label"
                sx={{ marginLeft: "0px !important", textAlign: 'center', color: '#FD4B24' }}
              >
                {i18n.deviceManagement.recirdsIgnored}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex' }} alignItems={'center'} >
              <Box component="section" sx={{ p: 2, border: '1px solid #D7D7D7', borderRadius: '4px', width:'100%', maxHeight: '10rem', overflow: 'auto' }}>
                {!isEmpty(existingDevices) &&
                  <Typography
                    variant="subtitle2"
                    color="customColor.label"
                    sx={{ marginLeft: "0px !important", marginBottom: 2 }}
                  >
                    <b>DeviceId already exists:</b> {existingDevices?.map(item => item.deviceId).join(', ')}
                  </Typography>
                }
                {!isEmpty(invalidDeviceIds) &&
                  <Typography
                    variant="subtitle2"
                    color="customColor.label"
                    sx={{ marginLeft: "0px !important", marginBottom: 2 }}
                  >
                    <b>DeviceId can not exceed 12 characters :</b> {invalidDeviceIds?.map(item => item.deviceId).join(', ')}
                  </Typography>
                }
                {!isEmpty(assignedDevices) &&
                  <Typography
                    variant="subtitle2"
                    color="customColor.label"
                    sx={{ marginLeft: "0px !important", marginBottom: 2 }}
                  >
                    <b>DeviceId already assigned to users:</b> {assignedDevices?.map(item => item.deviceId).join(', ')}
                  </Typography>
                }
                {!isEmpty(emptyDeviceIds) &&
                  <Typography
                    variant="subtitle2"
                    color="customColor.label"
                    sx={{ marginLeft: "0px !important", marginBottom: 2 }}
                  >
                    <b>DeviceId is null or empty for:</b> {emptyDeviceIds?.length} records
                  </Typography>
                }
                {!isEmpty(invalidManufacturer) &&
                  <Typography
                    variant="subtitle2"
                    color="customColor.label"
                    sx={{ marginLeft: "0px !important", marginBottom: 2 }}
                  >
                    <b>DeviceManufacturedBy is invalid for deviceId:</b> {invalidManufacturer?.map(item => item.deviceId).join(', ')}
                  </Typography>
                }
              </Box>
            </Grid>
          </Grid>
        }
      </Grid>
    )
  }

  const stepContent: StepContentType = {
    1: <FirstStepContent />,
    2: <SecondStepComponent />,
    3: <FinalStepComponent />
  }


  const isDisabled = () => {

    if (hasError) {
      return false
    }
    if (isLoading) {
      return true
    }
    if (fileName === '') {
      return true
    }
    return false
  }

  return (
    <Dialog
      sx={{
        border: "1px solid #888888",
        boxShadow: "customColor.dropShadow",
        borderRadius: "8px",
      }}
      fullWidth
      maxWidth='sm'
      open={open}
    >
      <DialogTitle
        className="edit-heading-name"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            aria-label="close dialogbox"
            onClick={handleCloseModal}
            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
              event.preventDefault()
            }
            edge="end"
            sx={{ marginLeft: "90%" }}
          >
            <img src={CloseIcon} alt="close" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography
            // className="dialog-title-custom-size"
            sx={{
              fontSize: "20px",
              fontWeight: 200,
              color: "#000000",
            }}
          >
            Import Devices
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ padding: "5px 20px" }}>
        <Grid container columnSpacing={2} rowSpacing={1} marginTop={step === 3 ? 0 : 5} marginBottom={5}>
          <Grid item xs={12}
            justifyContent={'center'}
            display={'flex'}
            sx={{
              div: {
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "20px",
              },
            }}
          >

            {stepContent[step]}

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container columnSpacing={1}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Grid item={true}>
            <Button
              className="cancel-button"
              size="large"
              variant="outlined"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              className="submit-button"
              size="large"
              variant="contained"
              onClick={handleSubmit}
              disabled={isDisabled()}
            >
              {isLoading ? <> Importing... <CircularProgress size={18} /> </> :
                <>{(hasError || isEmpty(validRecord) || step === 3) ? 'Start Over' : 'Import'} </>
              }
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
