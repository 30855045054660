import React, { useCallback, useEffect, useState } from "react";
import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Slider,
	Tooltip,
	Typography,
} from "@mui/material";
import Logo from "../../../images/LuteFish_Type_RevWhite.png";
import GridIcon from "../../../images/GridIcon.svg";
import FillLike from "../../../images/orange-fill-like.svg";
import Speaker from "../../../images/speaker.svg";
import FocusOptions from "../../../images/FocusOptions.svg";
import FocusOptionsExit from "../../../images/FocusOptionExit.svg";
import Like from "../../../images/like.svg";
import SoloFocused from "../../../images/SoloFocused.svg";
import SoloRotate from "../../../images/SoloRotate.svg";
import EnsembleView from "../../../images/ensembleView.svg";
import { TrayContainer, TrayWrapper } from "./TrayStyled";
import { useDailyAppRoom } from "../Hooks/useDailyGlobalRoom";
import i18n from "../../../l10n/strings.json";
import { useFullscreenModeContext } from "../../../context/FullscreenModeContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import Leave from "../../../images/phone-leave.svg";
import { useDaily, useParticipantCounts } from "@daily-co/daily-react";
import WebsocketClientUSerStatus from "../../../services/WebsocketClientUSerStatus";
import { useUser } from "../../../context/UserContext";

const FocusMenu = [
	{
		label: "Solo Focused",
		id: "soloFocus",
		icon: SoloFocused,
	},
	{
		label: "Solo Rotate",
		id: "soloRotate",
		icon: SoloRotate,
	},
	{
		label: "Ensemble View",
		id: "ensembleView",
		icon: EnsembleView,
	},
];

export default function StreamingMenuBar(props: any) {
	const user = useUser();
	let [searchParams] = useSearchParams();
	const sessionId = searchParams.get("id");

	const broadcastingInfo = JSON.parse(
		localStorage.getItem("broadcastingInfo") || "{}"
	);
	const [sessionDetails, setSessionDetails] = useState<any>();
	const [isLiveStreamLiked, setIsLiveStreamLiked] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [liveStreamVolume, setLiveStreamVolume] = React.useState<number>(5);
	const [liveStreamLikeCount, setLiveStreamLikeCount] = useState(
		broadcastingInfo?.likes?.totalLikesCount
	);
	const { setStreamingViewType, streamingViewType, allParticipantsCount } =
		useDailyAppRoom();
	const { isFullscreenMode, setIsFullscreenMode } = useFullscreenModeContext();
	const navigate = useNavigate();
	const [elapsedTime, setElapsedTime] = useState(0);
	const [likeLoading, setLikeLoading] = useState(false);

	const handleIconClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		WebsocketClientUSerStatus.on("liveStreamLiked", onMessageReceived);
		WebsocketClientUSerStatus.on(
			"liveStreaming",
			onMessageReceivedLiveStreaming
		);
		handeDefaultVolume();
		return () => {
			WebsocketClientUSerStatus.off("liveStreamLiked", onMessageReceived);
			WebsocketClientUSerStatus.off(
				"liveStreaming",
				onMessageReceivedLiveStreaming
			);
		};
	}, []);

	useEffect(() => {
		let timerInterval: NodeJS.Timeout;
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/session-details/${sessionId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((res: any) => {
				if (!res?.dailyLiveStreamStatus){
					navigate(`/liveStreamEnd?id=${sessionId}`);
				} else if (res?.dailyLiveStreamStatus) {
					setSessionDetails(res);
					setLiveStreamLikeCount(res.dailyLiveStreamingLikeCount);

					const startTime = new Date(res?.dailyLiveStreamStartTime).getTime();
					updateTimer(startTime);

					// Update timer every second
					timerInterval = setInterval(() => {
						updateTimer(startTime);
					}, 1000);

					const broadcastingInfo = JSON.parse(
						localStorage.getItem("broadcastingInfo") || "{}"
					);

					if (!broadcastingInfo.likes) {
						broadcastingInfo.likes = {};
					}

					if (broadcastingInfo.likes[res?.id] === undefined) {
						broadcastingInfo.likes[res?.id] = false;
					}

					localStorage.setItem(
						"broadcastingInfo",
						JSON.stringify({
							...broadcastingInfo,
							likes: {
								...broadcastingInfo.likes,
								[res.id]: broadcastingInfo.likes[res.id] ?? false,
							},
						})
					);
					setIsLiveStreamLiked(broadcastingInfo.likes[res.id] ?? false);
				}
			});

		return () => clearInterval(timerInterval);
	}, [sessionId]);

	function updateTimer(startTime: number) {
		const currentTime = Date.now();
		const elapsed = currentTime - startTime;
		setElapsedTime(elapsed);
	}

	function formatElapsedTime(milliseconds: number) {
		const totalSeconds = Math.floor(milliseconds / 1000);
		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = totalSeconds % 60;

		return `${hours.toString().padStart(2, "0")}:${minutes
			.toString()
			.padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
	}

	const updateLocalStorageForLikes = (sessionId: string, isLiked: boolean) => {
		const broadcastingInfo = JSON.parse(
			localStorage.getItem("broadcastingInfo") || "{}"
		);
		broadcastingInfo.likes = {
			...broadcastingInfo.likes,
			[sessionId]: isLiked,
		};

		localStorage.setItem("broadcastingInfo", JSON.stringify(broadcastingInfo));
	};

	const onMessageReceived = (responseMsg: any) => {
		if (responseMsg.action === "live_stream_like") {
			setLiveStreamLikeCount(responseMsg.live_stream_count);

			const broadcastingInfo = JSON.parse(
				localStorage.getItem("broadcastingInfo") || "{}"
			);

			localStorage.setItem(
				"broadcastingInfo",
				JSON.stringify({
					...broadcastingInfo,
					likes: {
						...broadcastingInfo.likes,
						totalLikesCount: responseMsg?.live_stream_count ?? 0,
					},
				})
			);
		}
	};

	const onMessageReceivedLiveStreaming = (response: any) => {
		const broadcastingInfo = JSON.parse(
			localStorage.getItem("broadcastingInfo") || "{}"
		);
		if (response?.dailyLiveStreamStatus === "STOP" && response?.session_id === broadcastingInfo?.broadCastingSession?.twilioSessionId) {
			endLiveStream();
		}
	};

	const likeLiveStream = (isLiked: boolean) => {
		setLikeLoading(true);
		const broadcastingInfo = JSON.parse(
			localStorage.getItem("broadcastingInfo") || "{}"
		);
		if (
			broadcastingInfo.likes &&
			broadcastingInfo.likes[sessionDetails?.id] !== undefined
		) {
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions
			broadcastingInfo.likes[sessionDetails?.id];
		}
		fetch(`${process.env.REACT_APP_BASE_URL}/api/public/session-like`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				id: sessionDetails?.id,
				isLiked: !broadcastingInfo.likes[sessionDetails?.id],
			}),
		})
			.then((res) => {
				setLikeLoading(false);
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data) {
					setIsLiveStreamLiked((prevLiked) => !prevLiked);
					updateLocalStorageForLikes(data?.id, isLiked);
				}
			})
			.catch((err) => {
				console.error("Error liking the session:", err);
			});
	};

	const handleMenuItemClick = (menuId: any) => {
		// Handle action based on menuId (e.g., soloFocus, changeLayout, ensembleView)
		console.log(`Menu item clicked: ${menuId}`);
		setStreamingViewType(menuId);
		handleClose();
	};

	const handleLiveStreamVolumeChange = (
		event: Event,
		newValue: number | number[]
	) => {
		setLiveStreamVolume(newValue as number);
		updateVolume(newValue as number);
	};

	const handeDefaultVolume = () => {
		setTimeout(()=> {
			updateVolume(5);
		},1000)
	}

	const updateVolume = (newValue: number) => {
		const volumesToUpdate = document.querySelectorAll('[data-subscribed="true"][data-session-id]:not([data-session-id=""])');
		volumesToUpdate.forEach((volumeToUpdate) => {
			(volumeToUpdate as HTMLAudioElement).volume = (newValue as number) / 100; 
		});
	}

	const toggleFullscreenMode = useCallback(() => {
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
			setIsFullscreenMode(true);
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
			setIsFullscreenMode(false);
		}
	}, [setIsFullscreenMode]);

	const endLiveStream = () => {
		props.leaveCall();
		navigate(sessionDetails?.dailyLiveStreamStatus ? `/liveStreamDetails?id=${sessionId}` : `/liveStreamEnd?id=${sessionId}`);
	};

	const handleDisable = () => {
		setTimeout(() => {}, 1000);
		if (likeLoading) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<TrayWrapper>
			<TrayContainer>
				<Grid
					container
					xs={12}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Grid item container xs={5}>
						<Grid item xs={3}>
							<Box
								sx={{
									display: { xs: "flex", md: "flex", xl: "flex" },
									height: "46px",
									width: "110px",
								}}
							>
								<img src={Logo} alt="logo" style={{ cursor: "pointer" }} />
							</Box>
						</Grid>
						<Grid
							item
							container
							xs={9}
							display="flex"
							alignItems="center"
							columnSpacing={1}
						>
							<Grid
								item
								xs={2.5}
								display="flex"
								alignItems="center"
								justifyContent="space-evenly"
							>
								<Typography
									sx={{
										fontSize: "1rem",
										marginRight: "5px !important",
										color: "#C5C5C5",
									}}
								>
									{formatElapsedTime(elapsedTime)}
								</Typography>
								<Divider
									className="jamsession-divider"
									sx={{ width: "0.1px" }}
									orientation="vertical"
								/>
							</Grid>
							<Grid item xs={9.5}>
							<Typography
									title={sessionDetails?.sessionName}
									sx={{
										fontSize: "1rem",
										marginRight: "5px !important",
										color: "#C5C5C5",
										maxWidth: "95%",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden"
									}}
								>
									{sessionDetails?.sessionName}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={7}>
						<Grid
							container
							xs={12}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<Grid item xs={1.5}>
								<Box
									sx={{
										width: "83px",
										backgroundColor: "#000",
										px: "1px",
										borderRadius: "6px",
									}}
									display="flex"
									alignItems="center"
									justifyContent="space-evenly"
								>
									<IconButton
										onClick={() => likeLiveStream(!isLiveStreamLiked)}
										color="primary"
										size="small"
										disabled={handleDisable()}
									>
										{isLiveStreamLiked ? (
											<img
												src={FillLike}
												alt="logo"
												style={{ cursor: "pointer" }}
											/>
										) : (
											<img
												src={Like}
												alt="Like"
												style={{ height: "24px", width: "24px" }}
											/>
										)}
									</IconButton>
									<Typography
										variant="body1"
										component="p"
										sx={{
											fontSize: "14px",
											color: "#FDFCFA",
											fontWeight: "500",
										}}
									>
										Like
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={3.5} display="flex" justifyContent="space-evenly">
								<Typography
									sx={{
										fontSize: "14px",
										color: "#C2C2C3",
									}}
								>
									{liveStreamLikeCount} Likes
								</Typography>
								<Typography
									sx={{
										fontSize: "14px",
										color: "#C2C2C3",
									}}
								>
									{allParticipantsCount?.hidden} Watching
								</Typography>
							</Grid>
							<Grid item xs={7}>
								<Grid container xs={12} display="flex" alignItems="center">
									<Grid item xs={2} display="flex">
										<Divider
											className="jamsession-divider"
											sx={{ margin: "0 15% !important", width: "0.1px" }}
											orientation="vertical"
										/>

										<Tooltip title={i18n.jamSession.switchLayout}>
											<IconButton
												id="go-button"
												sx={{ p: 0 }}
												className="sessionIconBtn"
												onClick={handleIconClick}
											>
												<img src={GridIcon} alt="Start Fullscreen" />
											</IconButton>
										</Tooltip>

										<Menu
											anchorEl={anchorEl}
											open={Boolean(anchorEl)}
											onClose={handleClose}
											sx={{
												"& .MuiPaper-root": {
													backgroundColor: "#454545",
													color: "white",
												},
											}}
										>
											{FocusMenu.map((menuItem) => (
												<MenuItem
													key={menuItem.id}
													onClick={() => handleMenuItemClick(menuItem.id)}
													selected={streamingViewType === menuItem.id}
												>
													<ListItemIcon>
														<img
															src={menuItem.icon}
															alt={`${menuItem.label} icon`}
															style={{ width: 24, height: 24 }}
														/>
													</ListItemIcon>
													<ListItemText
														primary={
															<Typography
																variant="body1"
																sx={{ color: "#FFF" }}
															>
																{menuItem.label}
															</Typography>
														}
													/>
												</MenuItem>
											))}
										</Menu>
									</Grid>
									<Grid item xs={2} display="flex">
										<Tooltip
											title={
												isFullscreenMode
													? i18n.jamSession.stopFullscreenTooltip
													: i18n.jamSession.fullscreenTooltip
											}
										>
											<IconButton
												id="go-button"
												sx={{ p: 0 }}
												className="sessionIconBtn"
												onClick={toggleFullscreenMode}
											>
												<img
													src={
														isFullscreenMode ? FocusOptionsExit : FocusOptions
													}
													alt="Start Fullscreen"
												/>
											</IconButton>
										</Tooltip>
										<Divider
											className="jamsession-divider"
											sx={{ margin: "0 15% !important", width: "0.1px" }}
											orientation="vertical"
										/>
									</Grid>
									<Grid
										item
										container
										xs={5}
										spacing={3}
										display="flex"
										alignItems="center"
									>
										<Grid item xs={2}>
											<img
												src={Speaker}
												alt="logo"
												style={{ cursor: "pointer" }}
											/>
										</Grid>
										<Grid item xs={10}>
											<Box sx={{ width: '80%', ml: 2 }}>
												<Slider
													aria-label="Volume"
													value={liveStreamVolume}
													onChange={handleLiveStreamVolumeChange}
													sx={{
														"& .MuiSlider-thumb": {
															backgroundColor: "#CFD9DF",
															"&:hover, &.Mui-focusVisible": {
																boxShadow: "none",
															},
															"&:active": {
																boxShadow: "none",
															},
														},
														"& .MuiSlider-track": {
															backgroundColor: "#CFD9DF",
															border: "none",
															boxShadow: "none",
														},
														"& .MuiSlider-rail": {
															backgroundColor: "#888888",
														},
													}}
												/>
											</Box>
										</Grid>
									</Grid>
									<Grid item xs={3} sx={{ textAlign: 'right' }}>
										<Tooltip title="Leave Session">
											<Button
												data-cy-disconnect
												sx={{
													backgroundColor: "#FD4B24",
													borderRadius: "6px",
													height: "36px",
													width: "119px",
													"&:hover": {
														backgroundColor: "#F95454",
													},
												}}
												onClick={endLiveStream}
												startIcon={<img src={Leave} alt="leave session" />}
											>
												<Typography
													sx={{
														fontSize: "16px",
														fontWeight: "500",
														color: "#fff",
													}}
												>
													Leave
												</Typography>
											</Button>
										</Tooltip>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</TrayContainer>
		</TrayWrapper>
	);
}
